import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../../../Components/Desktop/Navbar/Navbar";
import MFooter from "../../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../../Components/Mobile/MNavBar/MNavBar";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import "./../../../../../SCSS/ProductPages.scss";
import PPAccordionComponent from "../../../../../Components/PPAccordionComponent/PPAccordionComponent";
import ReadMoreText from "../../../../../Components/ReadMoreTextComponent/ReadMoreText";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const ErrorsOmmissionInsurance = () => {
  const isMobile = useIsMobile();

  const coveragePointsArray = [
    {
      title: "Coverage:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Professional Liability: </span> Covers the insured against
              claims arising from negligent acts, errors, or omissions in the
              professional services rendered by the company.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Special Features: ",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Claims-made Basis:</span> Provides coverage for claims first
              made against the insured during the policy period, regardless of
              when the alleged error or omission occurred, subject to any
              retroactive date specified in the policy.
            </li>
            <li>
              <span>Defense Costs: </span> Covers defense costs, including
              attorney fees, court costs, and other legal expenses, incurred in
              defending a covered claim.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Enhancements:  ",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Technology Errors and Omissions:</span> Specifically
              tailored for IT companies, this enhancement provides coverage for
              claims arising from errors or omissions in software development,
              implementation, or other IT services.
            </li>
            <li>
              <span>Cyber Liability: </span> Extends coverage to include claims
              arising from data breaches, cyber-attacks, and other cyber risks,
              including legal defense costs, regulatory fines, and penalties.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Add-on Covers:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Media Liability:</span> Covers claims related to defamation,
              libel, slander, copyright infringement, and other media-related
              liabilities.
            </li>
            <li>
              <span>Network Security Liability: </span> Provides coverage for
              claims arising from unauthorized access, denial of service
              attacks, malware, and other network security breaches.
            </li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="subProducts_pp">
          <Grid container columnSpacing={3} className="row">
            <Grid xs={12} textAlign="center" marginBottom="24px">
              <h1>Errors and Omissions (E&O) Liability Insurance Policy</h1>
            </Grid>
          </Grid>

          <Box className="coverages-section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md className="mb-margin2">
                <PPAccordionComponent data={coveragePointsArray} />
              </Grid>
              <Grid xs={12} md={"auto"} margin={"auto"}>
                <img
                  src="/images/EO_liability_img.svg"
                  alt=""
                  style={{
                    width: "100%",
                    maxWidth: isMobile ? "180px" : "230px",
                    margin: "auto",
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="errors-ommission-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom="12px" textAlign="center">
                <h2>Definition of Errors or Omissions</h2>
              </Grid>
              <Grid xs={12} marginBottom={"24px"} textAlign="center">
                <h5>Errors or omissions refer to:</h5>
              </Grid>
              <Grid xs={12}>
                <ul className="omissionPointList mb-6">
                  <li>
                    <h4>Professional Mistakes</h4>
                    <p>
                      Unintentional errors or oversights made by the insured
                      while performing professional services that lead to
                      financial loss or harm to a third party.
                    </p>
                  </li>
                  <li>
                    <h4>Negligence</h4>
                    <p>
                      Failure to exercise the level of skill, care, or diligence
                      that a reasonable professional would exercise in similar
                      circumstances, resulting in harm or damage.at lead to
                      financial loss or harm to a third party.
                    </p>
                  </li>
                  <li>
                    <h4>Misrepresentation</h4>
                    <p>
                      Providing incorrect, incomplete, or misleading information
                      to a client, leading to financial loss or harm.
                    </p>
                  </li>
                  <li>
                    <h4>Failure to Perform</h4>
                    <p>
                      Omission or failure to perform professional services as
                      promised or expected, leading to financial loss or harm to
                      the client.
                    </p>
                  </li>
                  <li>
                    <h4>Breach of Duty</h4>
                    <p>
                      Failure to 30ulfil the duties and responsibilities owed to
                      clients, resulting in financial loss, harm, or damage.
                    </p>
                  </li>
                </ul>
                <p>
                  In the context of an E&O Insurance Policy, coverage is
                  typically provided for claims arising from these errors or
                  omissions, subject to the policy’s terms, conditions, and
                  exclusions. The policy aims to protect professionals and
                  companies from the financial consequences of unintentional
                  mistakes or negligence in their professional services.
                </p>
              </Grid>
            </Grid>
          </Box>

          <Box className="claimProcedure-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom="24px" textAlign="center">
                <h2>Claims Procedure</h2>
              </Grid>
              <Grid xs={12}>
                <ul className="claimProcedure_list mb-6">
                  <li>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom="24px"
                    >
                      <Box textAlign="left">
                        <h4>01</h4>
                        <h6>Immediate Notification</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon1.svg"
                        alt="notification icon"
                        style={{
                          width: "52px",
                          height: "52px",
                          borderRadius: "4px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "In case of a claim or potential claim, the insured must notify the insurance company or broker as soon as practicable"
                      }
                      maxlength={100}
                    />
                  </li>
                  <li>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom="24px"
                    >
                      <Box textAlign="left">
                        <h4>02</h4>
                        <h6>Claim Documentation</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon2.svg"
                        alt="documentation icon"
                        style={{
                          width: "52px",
                          height: "52px",
                          borderRadius: "4px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "Submit a detailed claim form along with all relevant documents, including legal notices, correspondence, and evidence supporting the claim, to the insurance company."
                      }
                      maxlength={100}
                    />
                  </li>
                  <li>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom="24px"
                    >
                      <Box textAlign="left">
                        <h4>03</h4>
                        <h6>Claim Evaluation and Settlement</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon3.svg"
                        alt="evaluation and settlement icon"
                        style={{
                          width: "52px",
                          height: "52px",
                          borderRadius: "4px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "The insurance company will evaluate the claim, conduct an investigation if necessary, and either approve or deny the claim based on the policy terms and conditions. If approved, the insurer will indemnify the insured or reimburse for covered losses."
                      }
                      maxlength={100}
                    />
                  </li>
                </ul>
                <p>
                  It’s important to review the specific policy wording and
                  definitions outlined in an E&O Insurance Policy to understand
                  the scope of coverage and any limitations or exclusions that
                  may apply. Always consult with an experienced insurance broker
                  to ensure that your policy provides the appropriate coverage
                  tailored to your professional risks and exposures.
                </p>
              </Grid>
            </Grid>
          </Box>

          <Box className="termsConditions_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems="center"
            >
              <Grid xs={12} md={8} className="mb-margin2">
                <h2 className="mb-3">Terms and Conditions</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    <span>Policy Period:</span> Typically, the policy is issued
                    for a one-year period and needs to be renewed annually.
                  </li>
                  <li>
                    <span>Retroactive Date:</span>
                    Specifies the date from which coverage begins, excluding
                    claims arising from incidents that occurred before this
                    date.
                  </li>
                  <li>
                    <span>Limits of Liability:</span> The policy limit is the
                    maximum amount the insurer will pay for covered claims
                    during the policy period, subject to deductibles and
                    sub-limits.
                  </li>
                  <li>
                    <span>Deductibles:</span> A deductible is the amount the
                    insured must pay before the insurance coverage kicks in.
                  </li>
                </ol>
              </Grid>
              <Grid xs={12} md={4}>
                <img
                  src="../images/termsconditions_img.svg"
                  alt="terms and conditions icon"
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="excluison_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems="center"
            >
              <Grid xs={12} md={4} order={{ xs: 2, md: 1 }}>
                <img
                  src="../images/exclusions_img.svg"
                  alt="exclusion icon"
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
              <Grid
                xs={12}
                md={8}
                className="mb-margin2"
                order={{ xs: 1, md: 2 }}
              >
                <h2 className="mb-3">Exclusions</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    <span>Fraud and Dishonesty: </span> Claims arising from
                    fraudulent, dishonest, or criminal acts committed by the
                    insured.
                  </li>
                  <li>
                    <span> Bodily Injury and Property Damage:</span> Claims
                    related to bodily injury or property damage unless directly
                    resulting from a covered error or omission.
                  </li>
                  <li>
                    <span>Intentional Acts: </span> Claims arising from
                    intentional acts or violations of laws, regulations, or
                    company policies.
                  </li>
                </ol>
              </Grid>
            </Grid>
          </Box>

          <Box className="whyChoose_pp_section">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems="center"
            >
              <Grid xs={12} md={6} className="mb-margin2">
                <h2>Why Choose Amaze Insurance Brokers?</h2>
                <img
                  src="../images/whychoose_pp_img.svg"
                  alt="why choose us icon"
                  style={{ maxWidth: "450px", width: "100%", margin: "auto" }}
                  className="wchoose_img"
                />
              </Grid>
              <Grid xs={12} md={6}>
                <ul className="whyChoosepp_list">
                  <li>
                    <Box className="wc_box">
                      <img
                        src="../images/wc_img1.svg"
                        alt="expert guidance icon"
                      />
                      <h6>Expert Guidance</h6>
                      <p>
                        Our team of experts understands the complex nature of
                        E&O risks and can provide you with expert guidance and
                        support every step of the way.
                      </p>
                    </Box>
                    <Box className="wc_box">
                      <img
                        src="../images/wc_img2.svg"
                        alt="claim assistance icon"
                      />
                      <h6>Seamless Claims Assistance</h6>
                      <p>
                        We provide comprehensive support throughout the claims
                        process.
                      </p>
                    </Box>
                  </li>
                  <li>
                    <Box className="wc_box">
                      <img src="../images/wc_img3.svg" alt="quotes icon" />
                      <h6>Competitive Quotes</h6>
                      <p>
                        We work with leading insurance companies to secure the
                        best deals for you.
                      </p>
                    </Box>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default ErrorsOmmissionInsurance;
