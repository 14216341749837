import { lightBlue } from "@mui/material/colors";

export const COLORS = {
  black: "#000000",
  white: "#ffffff",
  primary: "#1E2E5F",
  secondary: "#BE2532",
  grey: "#808080",
  Red: "#D9132E",
  lightBlue: "#E9F1FD",
};
