import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../../../Components/Desktop/Navbar/Navbar";
import MFooter from "../../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../../Components/Mobile/MNavBar/MNavBar";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import "./../../../../../SCSS/ProductPages.scss";
import PPAccordionComponent from "../../../../../Components/PPAccordionComponent/PPAccordionComponent";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useState } from "react";
import Slider from "react-slick";
import ReadMoreText from "../../../../../Components/ReadMoreTextComponent/ReadMoreText";

const HouseholderInsurance = () => {
  const isMobile = useIsMobile();

  const coveragePointsArray = [
    {
      title: "Coverage:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span> Building Coverage:</span> Protects the structure of the
              insured residence, including walls, roof, floors, and fixtures,
              against risks like fire, lightning, explosion, storm, flood,
              earthquake, and malicious damage.
            </li>
            <li>
              <span>Contents Coverage:</span> Insures household items,
              furniture, appliances, clothing, and personal belongings against
              damage or loss due to insured perils.
            </li>
            <li>
              <span>All Risks for Valuables:</span> Extends coverage to
              high-value items such as jewelry, watches, art pieces, and
              electronic gadgets against accidental damage, loss, or theft, both
              inside and outside the insured premises.{" "}
            </li>
            <li>
              <span>Breakdown of Domestic Appliances: </span>Covers repair or
              replacement costs for sudden and unforeseen breakdowns of domestic
              appliances like refrigerators, washing machines, air conditioners,
              and television sets.
            </li>
            <li>
              <span>Public Liability: </span>Provides indemnity against legal
              liability arising from accidental bodily injury or property damage
              to third parties within the insured premises.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Special Features:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Customizable Coverage:</span> Policyholders can tailor their
              coverage based on their specific needs, adding or removing covers
              as required.
            </li>
            <li>
              <span>Cashless Claim Settlement: </span>Many insurers offer
              cashless claim settlement facilities at networked repair centers
              for faster and hassle-free claim processing.
            </li>
            <li>
              <span> Additional Living Expenses:</span> Some policies may cover
              additional living expenses if the insured residence becomes
              uninhabitable due to a covered peril, providing temporary
              accommodation costs.
            </li>
            <li>
              <span> Emergency Assistance Services:</span> Offers access to
              round-the-clock emergency assistance services for services like
              locksmith services, plumbing repairs, and electrical assistance.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Enhancements:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span> Rider Coverage:</span> Policyholders can enhance their
              coverage by adding riders such as rent for alternative
              accommodation, loss of rent, and terrorism cover.
            </li>
            <li>
              <span>Increased Sum Insured:</span> Policyholders have the option
              to increase the sum insured periodically to keep pace with
              inflation and rising property values.
            </li>
          </ul>
        </>
      ),
    },
  ];

  const whychoosePoints = [
    {
      innerArray: [
        {
          img_url: "../images/wc_img1.svg",
          main_head: "Tailored Solutions:",
          desc: `We work closely with leading insurance providers in the Indian market to offer personalized insurance solutions tailored to your specific needs. Whether you're a homeowner or a tenant, we ensure that you get the right coverage to safeguard your property and belongings. `,
        },

        {
          img_url: "../images/wc_img12.svg",
          main_head: "Extensive Coverage:",
          desc: ` Our comprehensive Householders' Insurance Policies provide coverage for a wide range of risks, including damage to your building and contents, loss of valuables, breakdown of domestic appliances, and public liability. With our policies, you can have peace of mind knowing that your home and belongings are protected against unforeseen events. `,
        },
        {
          img_url: "../images/wc_img3.svg",
          main_head: "Customizable Options:",
          desc: ` We offer customizable options to enhance your coverage according to your preferences and requirements. Whether you need additional coverage for high-value items, increased sum insured, or specialized riders, we'll help you tailor your policy to provide maximum protection. `,
        },
        {
          img_url: "../images/wc_img2.svg",
          main_head: "Expert Guidance:",
          desc: ` Our team of experienced insurance professionals is dedicated to providing expert guidance and support throughout the insurance process. From helping you understand policy terms and conditions to assisting you with claims settlement, we're here to ensure a smooth and hassle-free experience. `,
        },
      ],
    },
    {
      innerArray: [
        {
          img_url: "../images/wc_img14.svg",
          main_head: "Claims Assistance:",
          desc: ` In the event of a claim, we'll assist you at every step of the process, from filing the claim to negotiating with the insurer for a fair settlement. Our goal is to ensure that you receive the compensation you deserve promptly and efficiently. `,
        },
        {
          img_url: "../images/wc_img10.svg",
          main_head: "Transparency and Trust:",
          desc: ` At Amaze Insurance Brokers, we prioritize transparency and integrity in all our dealings. You can trust us to provide honest and reliable advice, helping you make informed decisions about your insurance coverage. `,
        },
        {
          img_url: "../images/wc_img13.svg",
          main_head: "Customer Satisfaction: ",
          desc: ` We are committed to exceeding our customers' expectations by delivering exceptional service and value. Your satisfaction is our top priority, and we'll go the extra mile to ensure that you're completely satisfied with your Householders' Insurance Policy. `,
        },
      ],
    },
  ];

  const [settings] = useState({
    className: "center",
    // centerMode: true,
    infinite: true,
    // centerPadding: "200px",
    slidesToShow: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 2000,
    // cssEase: "linear",
    arrows: true,
    dots: true,
    responsive: [
      {
        breakpoint: 1024, // For tablets and above
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600, // For mobile devices
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });

  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="subProducts_pp">
          <Grid container columnSpacing={3} className="row">
            <Grid xs={12} textAlign={"center"} marginBottom="24px">
              <h1>Householder's Insurance Policy</h1>
            </Grid>
          </Grid>

          <Box className="coverages-section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md className="mb-margin2">
                <PPAccordionComponent data={coveragePointsArray} />
              </Grid>
              <Grid xs={12} md={"auto"} margin={"auto"}>
                <img
                  src="/images/householders_ins_policy.svg"
                  alt=""
                  style={{
                    width: "100%",
                    maxWidth: isMobile ? "260px" : "375px",
                    margin: "auto",
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="claimProcedure-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom={"24px"} textAlign="center">
                <h2 className="mb-6">Claims Procedure</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    In the event of a loss, policyholders must notify Amaze
                    Insurance Brokers or the insurer immediately and provide all
                    necessary information and documentation.
                  </li>
                  <li>
                    The claims procedure involves submitting a detailed claims
                    form along with supporting evidence such as police reports,
                    inventory records, and proof of ownership.
                  </li>
                  <li>
                    Our experienced claims team will guide you through the
                    process, ensuring prompt and fair settlement of claims to
                    minimize disruption to your business operations.
                  </li>
                </ol>
              </Grid>
            </Grid>
          </Box>
          <Box className="termsConditions_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems="center"
            >
              <Grid xs={12} md={8} className="mb-margin2">
                <h2 className="mb-3">
                  Terms, Conditions, and Claims Procedure
                </h2>
                <ol type="1" className="ordered-list">
                  <li>
                    <span> Policy Terms and Conditions:</span> Policyholders
                    must carefully review the policy documents to understand the
                    coverage, terms, conditions, and exclusions.
                  </li>
                  <li>
                    <span>Claims Procedure:</span> In the event of a loss,
                    policyholders should notify the insurer immediately and
                    submit a duly filled claim form along with supporting
                    documents such as a police report, repair estimates, and
                    proof of ownership.
                  </li>
                  <li>
                    <span>Survey and Assessment:</span> The insurer may appoint
                    a surveyor to assess the extent of the damage and determine
                    the claim amount.
                  </li>
                  <li>
                    <span>Settlement:</span> Upon approval of the claim, the
                    insurer will settle the claim amount as per the policy
                    terms, either through cashless repair services or
                    reimbursement, depending on the nature of the loss.
                  </li>
                </ol>
              </Grid>
              <Grid xs={12} md={4}>
                <img
                  src="../images/termsconditions_img.svg"
                  alt=""
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="excluison_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems="center"
            >
              <Grid xs={12} md={4} order={{ xs: 2, md: 1 }}>
                <img
                  src="../images/exclusions_img.svg"
                  alt=""
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
              <Grid
                xs={12}
                md={8}
                className="mb-margin2"
                order={{ xs: 1, md: 2 }}
              >
                <h2 className="mb-3">Exclusions</h2>
                <p className="mb-3">
                  Aviation Hull Insurance typically excludes coverage for:
                </p>
                <ol type="1" className="ordered-list">
                  <li>
                    <span>Wear and Tear:</span> Typically excludes damage caused
                    by wear and tear, gradual deterioration, and lack of
                    maintenance.
                  </li>
                  <li>
                    <span>Negligence:</span> Damage or loss resulting from the
                    insured's negligence or deliberate acts are usually not
                    covered.
                  </li>
                  <li>
                    <span>War and Nuclear Perils:</span> Losses arising from
                    war, nuclear perils, civil unrest, and acts of terrorism may
                    be excluded.
                  </li>
                  <li>
                    <span>Unoccupied Property:</span> Some policies may have
                    restrictions on coverage if the insured property remains
                    unoccupied for an extended period.{" "}
                  </li>
                </ol>
              </Grid>
            </Grid>
          </Box>

          <Box className="whyChoose_pp_section">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md={6} className="mb-margin2">
                <h2 className="mb-2">
                  Choose Amaze Insurance Brokers for Your Householders'
                  Insurance Needs
                </h2>
                <p className="mb-6">
                  At Amaze Insurance Brokers, we understand that protecting your
                  home and belongings is a top priority. Here's why you should
                  partner with us for your Householders' Insurance Policy:
                </p>
                <p style={{ fontWeight: 500, marginBottom: "52px" }}>
                  Contact Amaze Insurance Brokers today to explore our
                  Shopkeepers Insurance solutions and receive a personalized
                  quote tailored to your requirements.
                </p>
                <img
                  src="../images/whychoose_pp_img.svg"
                  alt=""
                  style={{ maxWidth: "450px", width: "100%", margin: "auto" }}
                  className="wchoose_img"
                />
              </Grid>
              <Grid xs={12} md={6}>
                <Slider {...settings} className="mt-6 points-slider">
                  {whychoosePoints?.map((data, index) => (
                    <Box sx={{ padding: "24px 12px" }}>
                      <ul className="whyChoosepp_list2">
                        {data.innerArray.map((innerData, index) => (
                          <li>
                            <Box className="wc_box">
                              <img src={innerData.img_url} alt="" />
                              <h6>{innerData.main_head}</h6>
                              <ReadMoreText
                                text={innerData.desc}
                                maxlength={112}
                              />
                            </Box>
                          </li>
                        ))}
                      </ul>
                    </Box>
                  ))}
                </Slider>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default HouseholderInsurance;
