import React, { useState } from "react";
import { Box, Link, Rating } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import Navbar from "../../../../Components/Desktop/Navbar/Navbar";
import "./../../../../SCSS/CMSPages.scss";
import Footer from "../../../../Components/Desktop/Footer/Footer";
import { COLORS } from "../../../../SupportingFiles/colors";
import MFooter from "../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../Components/Mobile/MNavBar/MNavBar";

const TermsConditions = () => {
  const isMobile = useIsMobile();
  return (
    <Box className="cmsPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="cms-inner">
        <Grid container columnSpacing={3} className="row" marginBottom={"24px"}>
          <Grid xs={12} textAlign={"center"}>
            <h2>Terms & Conditions</h2>
          </Grid>
        </Grid>

        <Box className="terms-section mb-margin1">
          <Grid
            container
            columnSpacing={3}
            className="row"
            marginBottom={"24px"}
          >
            <Grid xs={12}>
              <p>
                Welcome to Amaze Insurance Brokers' online insurance sales
                platform, www.bimastreet.com. These Terms and Conditions govern
                your access to and use of our platform. By accessing or using
                the website, you agree to be bound by these Terms and
                Conditions. If you do not agree with any part of these terms,
                please refrain from using our platform.
              </p>
            </Grid>
          </Grid>

          <Grid container columnSpacing={3} className="row">
            <Grid xs={12} marginBottom={"24px"}>
              <h4 className="mb-3">1) Platform Use </h4>
              <ul className="points_list">
                <li>
                  <span>1.1 Eligibility:</span> You must be at least 18 years
                  old to use our platform. By using the website, you represent
                  that you are of legal age and have the capacity to enter into
                  a legally binding agreement.
                </li>
                <li>
                  <span>1.2 Account Registration:</span> Some features of our
                  platform may require account registration. You agree to
                  provide accurate, complete, and current information during the
                  registration process and to keep your account information up
                  to date.
                </li>
                <li>
                  <span>1.3 Prohibited Activities:</span> You agree not to
                  engage in any activities that may violate applicable laws,
                  infringe upon the rights of others, or disrupt the proper
                  functioning of the website. Prohibited activities include, but
                  are not limited to, unauthorized access, use of automated
                  systems, and uploading malicious content.
                </li>
                <li>
                  <span>1.4 Suspension or Termination:</span> Amaze Insurance
                  Brokers reserves the right to suspend or terminate your access
                  to the platform, at our sole discretion, for any reason
                  including, but not limited to, violation of these Terms and
                  Conditions.
                </li>
              </ul>
            </Grid>
            <Grid xs={12} marginBottom={"24px"}>
              <h4 className="mb-3">2) Insurance Services</h4>
              <ul className="points_list">
                <li>
                  <span>2.1 Insurance Quotes:</span>
                  Our platform provides insurance quotes based on the
                  information you provide. These quotes are for informational
                  purposes only and do not constitute an offer or contract.
                </li>
                <li>
                  <span>2.2 Policy Purchase:</span> If you choose to purchase an
                  insurance policy through our platform, the terms and
                  conditions of the policy will be governed by the respective
                  insurance provider. Amaze Insurance Brokers acts as an
                  intermediary, and we do not underwrite or provide insurance
                  policies directly.
                </li>
                <li>
                  <span> 2.3 Accuracy of Information:</span> You are responsible
                  for providing accurate and up-to-date information when using
                  our platform. Any discrepancies or inaccuracies in the
                  information you provide may affect the accuracy of insurance
                  quotes and policy issuance.
                </li>
              </ul>
            </Grid>
            <Grid xs={12} marginBottom={"24px"}>
              <h4 className="mb-3">3) Intellectual Property</h4>
              <ul className="points_list">
                <li>
                  <span>3.1 Ownership:</span>
                  All content, materials, and trademarks displayed on the
                  website are the property of Amaze Insurance Brokers or its
                  licensors and are protected by applicable intellectual
                  property laws.
                </li>
                <li>
                  <span>3.2 Limited License:</span> You are granted a limited,
                  non-exclusive, and non-transferable license to access and use
                  the content on our platform for personal and non-commercial
                  purposes only.
                </li>
                <li>
                  <span> 3.3 Restrictions:</span> You may not reproduce, modify,
                  distribute, or exploit any content on our platform without
                  prior written consent from Amaze Insurance Brokers.
                </li>
              </ul>
            </Grid>
            <Grid xs={12} marginBottom={"24px"}>
              <h4 className="mb-3">4) Privacy</h4>
              <p>
                Your privacy is important to us. Please refer to our Privacy
                Policy for information on how we collect, use, and protect your
                personal information.
              </p>
            </Grid>
            <Grid xs={12} marginBottom={"24px"}>
              <h4 className="mb-3">5) Disclaimers</h4>
              <p>
                Please refer to the Disclaimer section on our platform for
                important disclaimers related to the information provided and
                the use of our platform.
              </p>
            </Grid>
            <Grid xs={12} marginBottom={"24px"}>
              <h4 className="mb-3">6) Limitation of Liability</h4>
              <p>
                Amaze Insurance Brokers shall not be liable for any direct,
                indirect, incidental, consequential, or special damages arising
                from your use of our platform or reliance on the information
                provided. This includes, but is not limited to, damages for loss
                of profits, data, or other intangible losses.
              </p>
            </Grid>
            <Grid xs={12} marginBottom={"24px"}>
              <h4 className="mb-3">7) Modifications</h4>
              <p>
                Amaze Insurance Brokers reserves the right to modify or update
                these Terms and Conditions at any time without prior notice. It
                is your responsibility to review these terms periodically for
                any changes. Continued use of our platform after any
                modifications constitutes your acceptance of the updated terms.
              </p>
            </Grid>
            <Grid xs={12} marginBottom={"24px"}>
              <h4 className="mb-3">8) Governing Law</h4>
              <p>
                These Terms and Conditions shall be governed by and construed in
                accordance with the laws of the jurisdiction where Amaze
                Insurance Brokers is registered, without regard to its conflicts
                of law principles.
              </p>
            </Grid>
          </Grid>

          <Grid container columnSpacing={3} className="row">
            <Grid xs={12}>
              <h4 className="mb-2">Contact Us</h4>
              <p className="mb-2">
                Amaze Insurance Brokers Private Limited, IRDAI license Number:
                549, Valid till: 24/02/2025
              </p>
              <p className="mb-2">
                <span style={{ fontWeight: 500, color: COLORS.secondary }}>
                  Regd.Office :
                </span>{" "}
                Ground Floor,Villa No.10, Richmond Villas, Near Glendale
                International School, Hydershakote, Hyderabad-500086.
              </p>
              <p className="mb-2">
                <span style={{ fontWeight: 500, color: COLORS.secondary }}>
                  RCorp Office:
                </span>{" "}
                #403, THE LEGEND, Metro Pillar C1338, Motilal Nehru Nagar,
                Begumpet, Hyderabad - 500016.
              </p>
              <p>Phone: 040-27765823/21/22/24</p>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default TermsConditions;
