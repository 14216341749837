import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../../../Components/Desktop/Navbar/Navbar";
import MFooter from "../../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../../Components/Mobile/MNavBar/MNavBar";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import "./../../../../../SCSS/ProductPages.scss";
import PPAccordionComponent from "../../../../../Components/PPAccordionComponent/PPAccordionComponent";
import ReadMoreText from "../../../../../Components/ReadMoreTextComponent/ReadMoreText";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const MarineCargoInsurance = () => {
  const isMobile = useIsMobile();

  const coveragePointsArray = [
    {
      title: "Coverage:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Transit Risks:</span> Covers loss or damage to goods during
              transit by sea, air, road, rail, or post.
            </li>
            <li>
              <span>Loading and Unloading:</span> Covers risks associated with
              the loading and unloading of goods.
            </li>
            <li>
              <span>Transit Extensions:</span> Optional extensions for
              additional perils like war and strikes, delay in start-up, etc.,
              may be available at an additional premium.
            </li>
            <li>
              <span>Freight Charges:</span> Covers the cost of freight if the
              goods are damaged and cannot be delivered to the consignee.
            </li>
            <li>
              <span>Customs Duty:</span> Covers payment of customs duty in the
              event of loss or damage to insured goods.
            </li>
            <li>
              <span>Storage:</span> Covers goods stored in warehouses awaiting
              transit.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Clauses:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Institute Cargo Clauses (A, B, C):</span> These are standard
              clauses that define the scope of coverage and set the terms,
              conditions, and exclusions of the policy. Clause A provides the
              widest coverage, followed by Clause B and Clause C.
            </li>
            <li>
              <span>Institute War Clauses:</span> Covers risks of war, strikes,
              riots, and civil commotions.
            </li>
            <li>
              <span>Institute Strikes Clauses: </span> Covers risks of strikes,
              riots, and civil commotions excluding war risks.
            </li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="subProducts_pp">
          <Grid container columnSpacing={3} className="row">
            <Grid xs={12} textAlign="center" marginBottom="24px">
              <h1>Marine Cargo Insurance Policy</h1>

              <p className="mt-3">
                Marine Cargo Insurance is designed to provide coverage for goods
                and merchandise while they are being transported by various
                modes of transportation, such as sea, air, road, or rail. Below
                is a general overview of the coverage, clauses, exclusions,
                terms, conditions, and claims procedure typically associated
                with Marine Transit Insurance in the Indian insurance market.
              </p>
            </Grid>
          </Grid>

          <Box className="coverages-section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md className="mb-margin2">
                <PPAccordionComponent data={coveragePointsArray} />
              </Grid>
              <Grid xs={12} md={"auto"} margin={"auto"}>
                <img
                  src="/images/marine_cargo_ip_img.svg"
                  alt=""
                  style={{
                    width: "100%",
                    maxWidth: isMobile ? "200px" : "275px",
                    margin: "auto",
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="claimProcedure-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom="24px" textAlign="center">
                <h2>Claims Procedure</h2>
              </Grid>
              <Grid xs={12}>
                <ul className="claimProcedure_list">
                  <li>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom="24px"
                    >
                      <Box textAlign="left">
                        <h4>01</h4>
                        <h6>Immediate Notification</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon1.svg"
                        alt="notification icon"
                        style={{
                          width: "52px",
                          height: "52px",
                          borderRadius: "4px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "In the event of loss or damage to insured goods, the insured must notify the insurance company immediately and provide details of the incident. "
                      }
                      maxlength={100}
                    />
                  </li>
                  <li>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom="24px"
                    >
                      <Box textAlign="left">
                        <h4>02</h4>
                        <h6>Claim Documentation</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon2.svg"
                        alt="documentation icon"
                        style={{
                          width: "52px",
                          height: "52px",
                          borderRadius: "4px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "The insured must submit a detailed claim form along with supporting documents, such as shipping documents, invoices, packing lists, survey reports, etc. "
                      }
                      maxlength={100}
                    />
                  </li>
                  <li>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom="24px"
                    >
                      <Box textAlign="left">
                        <h4>03</h4>
                        <h6>Claim Inspection</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon3.svg"
                        alt="inspection icon"
                        style={{
                          width: "52px",
                          height: "52px",
                          borderRadius: "4px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "The insurance company may appoint a surveyor to inspect the damaged goods and assess the extent of the damage. "
                      }
                      maxlength={100}
                    />
                  </li>
                  <li>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom="24px"
                    >
                      <Box textAlign="left">
                        <h4>04</h4>
                        <h6>Claim Settlement</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon4.svg"
                        alt="settlement icon"
                        style={{
                          width: "52px",
                          height: "52px",
                          borderRadius: "4px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "Upon approval of the claim, the insurance company will settle the claim amount as per the policy terms and conditions, either by repairing or replacing the damaged goods, reimbursing the insured for the loss, or paying the insured amount, depending on the nature of the damage and policy coverage. "
                      }
                      maxlength={100}
                    />
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>

          <Box className="termsConditions_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems="center"
            >
              <Grid xs={12} md={8} className="mb-margin2">
                <h2 className="mb-3">Terms and Conditions</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    <span>Policy Period: </span> Typically, the policy is issued
                    for a specific voyage or an open cover basis for a one-year
                    period, which needs to be renewed annually.
                  </li>
                  <li>
                    <span>Sum Insured:</span> The sum insured is based on the
                    value of the goods plus freight and other charges.
                  </li>
                  <li>
                    <span>Deductibles:</span> Policies may include deductibles,
                    which are the amounts that the insured must bear in the
                    event of a claim.
                  </li>
                  <li>
                    <span>Premium Payment:</span> Premiums are payable annually
                    or per shipment, depending on the policy terms.
                  </li>
                </ol>
              </Grid>
              <Grid xs={12} md={4}>
                <img
                  src="../images/termsconditions_img.svg"
                  alt="terms and conditions icon"
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="excluison_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems="center"
            >
              <Grid xs={12} md={4} order={{ xs: 2, md: 1 }}>
                <img
                  src="../images/exclusions_img.svg"
                  alt="exclusions icon"
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
              <Grid
                xs={12}
                md={8}
                className="mb-margin2"
                order={{ xs: 1, md: 2 }}
              >
                <h2 className="mb-3">Exclusions</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    <span>Inherent Vice: </span> Damage due to the nature or
                    inherent vice of the goods.
                  </li>
                  <li>
                    <span>Insufficient Packing: </span> Damage due to
                    insufficient or improper packing.
                  </li>
                  <li>
                    <span>Delay or Loss of Market:</span> Loss caused by delay
                    in transit or loss of market.
                  </li>
                  <li>
                    <span>Nuclear Perils:</span> Damage caused by nuclear
                    reactions, radiation, or radioactive contamination.
                  </li>
                  <li>
                    <span>Unattended Vehicle:</span> Theft or damage to goods
                    from an unattended vehicle.
                  </li>
                  <li>
                    <span>Consequential Loss:</span> Loss of profit, loss of
                    market, or any indirect losses due to insured perils.
                  </li>
                </ol>
              </Grid>
            </Grid>
          </Box>

          <Box className="whyChoose_pp_section">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems="center"
            >
              <Grid xs={12} md={6} className="mb-margin2">
                <h2>Why Choose Amaze Insurance Brokers?</h2>
                <img
                  src="../images/whychoose_pp_img.svg"
                  alt="why choose us icon"
                  style={{ maxWidth: "450px", width: "100%", margin: "auto" }}
                  className="wchoose_img"
                />
              </Grid>
              <Grid xs={12} md={6}>
                <ul className="whyChoosepp_list">
                  <li>
                    <Box className="wc_box">
                      <img
                        src="../images/wc_img1.svg"
                        alt="expert guidance icon"
                      />
                      <h6>Expert Guidance</h6>
                      <p>
                        Our team of experts understands coverages/clauses under
                        Marine Policy and can provide you with expert guidance
                        and support every step of the way.
                      </p>
                    </Box>
                    <Box className="wc_box">
                      <img
                        src="../images/wc_img2.svg"
                        alt="claims assistance icon"
                      />
                      <h6>Seamless Claims Assistance</h6>
                      <p>
                        We provide comprehensive support throughout the claims
                        process.
                      </p>
                    </Box>
                  </li>
                  <li>
                    <Box className="wc_box">
                      <img src="../images/wc_img3.svg" alt="quotes icon" />
                      <h6>Competitive Quotes</h6>
                      <p>
                        We work with leading insurance companies to secure the
                        best deals for you.
                      </p>
                    </Box>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default MarineCargoInsurance;
