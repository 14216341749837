import React from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

const ManagingDirectorTab = () => {
  return (
    <Box className="managingDirectorTab">
      <Box className="profileBox">
        <Grid container columnSpacing={3} className="row" alignItems={"center"}>
          <Grid xs={12} md={4}>
            <img
              src="./images/Muralidhara_Reddy.png"
              alt=""
              className="personnel_img"
            />
          </Grid>
          <Grid xs={12} md={8} className="mb-margin2">
            <h4 className="name">Dr. Muralidhara Reddy</h4>
            <h4 className="designation">
              Managing Director & Principal Officer, Amaze Insurance Brokers Pvt
              Ltd, Hyderabad, India
            </h4>
            <h4 className="designation">
              Insurance Industry Veteran with 35 Years of Experience
            </h4>
            <p className="mb-2">
              Dr. K Muralidhara Reddy, also known as Dr. Reddy, boasts an
              illustrious 35-year tenure within the insurance industry. His
              journey began with The New India Assurance Co. Ltd, where he
              served as a Direct Recruit Officer from August 1988 to July 2002,
              establishing himself as a specialist in various insurance schemes.
              In 2002, Dr. Reddy transitioned into the realm of insurance
              broking, becoming a pioneer in the Indian Insurance Industry. He
              co-founded and served as Managing Director & Principal Officer of
              Excellent Insurance Broking Services Ltd, which obtained one of
              the first licenses granted by the Insurance Regulatory &
              Development Authority of India (IRDAI).
            </p>
            <p>
              Dr. Reddy wanted to develop an organization under his leadership,
              which provides world-class and innovative Risk Management and
              Insurance solutions to bring international repute to Indian
              Insurance Brokers. To pursue his dreams with freedom to innovate
              and bring great change. Dr. Reddy joined Amaze Insurance Brokers
              Pvt Ltd in January 2016 as Managing Director & Principal Officer.
            </p>
          </Grid>
        </Grid>
      </Box>

      <Box className="achievements_sec mb-margin1">
        <Grid container columnSpacing={3} className="row" alignItems={"center"}>
          <Grid xs={12} md={6} className="mb-margin2">
            <img
              src="./images/achievement_sec_img.svg"
              alt=""
              style={{ width: "100%", maxWidth: "460px", margin: "auto" }}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <h2 className="mb-2">Achievements and Recognition</h2>
            <h4 className="mb-4">
              Dr. Reddy's contributions have been recognized through numerous
              accolades, including:
            </h4>
            <ul className="circle_ticklist">
              <li>Insurance Broker of The Year 2024</li>
              <li>Best Customer Service Insurance Provider 2024</li>
              <li>CEO with Highest Quality Orientation 2023</li>
              <li>
                Award for High Reputation 2022 by Standard Chartered Rating GmbH
                Germany 2023
              </li>
              <li>The 10 fastest Growing Companies in 2022</li>
              <li>
                10 Most Promising Insurance Broking Company providers 2021
              </li>
              <li>Best of 5 Insurance Advisors 2021</li>
              <li>India 500 Quality Leaders 2020</li>
              <li>Insurance Broker of the Year 2018</li>
              <li>Telangana Best Employer Brand Award2018</li>
              <li>SKOCH Award 2016</li>
            </ul>
          </Grid>
        </Grid>
      </Box>

      <Box className="prof_endeavors_sec mb-margin1">
        <Grid container columnSpacing={3} className="row" alignItems={"center"}>
          <Grid xs={12} textAlign={"center"}>
            <h2 className="mb-2">Professional Endeavors</h2>
            <p>
              With a commitment to innovation and excellence, Dr. Reddy pursued
              further education, completing a PG Diploma in Digital Business
              from Columbia Business School in 2022, aligning himself with the
              digital era's demands.
            </p>
          </Grid>
        </Grid>
      </Box>

      <Box className="sector_exp_sector mb-margin1">
        <Grid container columnSpacing={3} className="row" alignItems={"center"}>
          <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
            <h2 className="mb-2">Sector Experience</h2>
            <p>
              Dr. Reddy's expertise spans across diverse sectors, including
              Pharma, Power, IT & ITES, Hospitality, Hospitals, Bio Tech,
              Manufacturing, Service, Rural, SMEs, Government, and more. His
              holistic approach ensures tailored solutions for clients across
              various industries.
            </p>
          </Grid>
          <Grid xs={12}>
            <ul>
              <li>
                <img src="./images/sector_exp_icon1.svg" alt="" />
                <p>
                  Pharma Sector Including Big Pharma Companies Listed On NYSE
                  And NASDA
                </p>
              </li>
              <li>
                <img src="./images/sector_exp_icon2.svg" alt="" />
                <p>
                  Power Sector - Windmills/ Hydel/ Solar/ Biomass/ Gas/ Thermal
                </p>
              </li>
              <li>
                <img src="./images/sector_exp_icon3.svg" alt="" />
                <p>
                  IT & ITES - Software Companies, HNIs And Other Individuals
                </p>
              </li>
              <li>
                <img src="./images/sector_exp_icon4.svg" alt="" />
                <p>
                  Hospitality - Hotels Including 5-Star Hotels, Hospitals
                  Including Corporate Hospitals
                </p>
              </li>
              <li>
                <img src="./images/sector_exp_icon5.svg" alt="" />
                <p>Bio Tech And Clinical Research Organizations</p>
              </li>
              <li>
                <img src="./images/sector_exp_icon6.svg" alt="" />
                <p>
                  Government Departments/Organizations/Government
                  Undertakings/Subsidiaries
                </p>
              </li>
              <li>
                <img src="./images/sector_exp_icon7.svg" alt="" />
                <p>Manufacturing Sector, Service Sector, Rural Sector, SMEs</p>
              </li>
              <li>
                <img src="./images/sector_exp_icon8.svg" alt="" />
                <p>Other Sectors Include Educational Institutions, FMCG Etc.</p>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Box>

      <Box className="client_solution_sections mb-margin1">
        <Grid container columnSpacing={3} className="row" alignItems={"center"}>
          <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
            <h2 className="mb-2">Client solutions</h2>
            <p>
              Dr. Reddy specializes in offering comprehensive insurance
              solutions, encompassing project insurances, operational covers,
              business interruption covers, liability insurances, credit
              insurance, employee benefits, rural insurance, and various other
              lines of insurance, both in life and non-life sectors.
            </p>
          </Grid>
          <Grid xs={12}>
            <ul>
              <li>
                <img src="./images/client_solution_icon1.svg" alt="" />
                <h4>Project Insurances</h4>
                <p>EAR/CAR/CPM/Marine Cum EAR (MCE)/Global WC etc</p>
              </li>
              <li>
                <img src="./images/client_solution_icon2.svg" alt="" />
                <h4>Operational Covers</h4>
                <p>
                  IAR/ Fire &Special Perils Policy/Marine/MBD/BPP/EEI/Money
                  Insurance – Cash in Transit & safe/ Employee Crime (Fidelity
                  Guarantee), Jewellers All Risks Insurance etc
                </p>
              </li>
              <li>
                <img src="./images/client_solution_icon3.svg" alt="" />
                <h4>Business Interruption Covers</h4>
                <p>
                  Advance Loss of Profits (ALOP), Marine Delay in Start Up
                  (Marine DSU), Weather Insurance, Fire Loss of Profits (FLOP),
                  Machinery Loss of Profits (MLOP)
                </p>
              </li>
              <li>
                <img src="./images/client_solution_icon4.svg" alt="" />
                <h4>Liability Insurances</h4>
                <p>
                  CGL/ Public Liability (Act)/E&O/D&O/Professional
                  Indemnity/Product Liability/Clinical Trials/WC or Employee
                  Compensation etc
                </p>
              </li>
              <li>
                <img src="./images/client_solution_icon5.svg" alt="" />
                <h4>Employee Benefits</h4>
                <p>
                  Group Health/Group Top Up/Group PA/Group Term/Group
                  Gratuity/Superannuation/Leave Encashment/Overseas Travel etc
                </p>
              </li>
              <li>
                <img src="./images/client_solution_icon6.svg" alt="" />
                <h4>Retail Segment</h4>
                <p>
                  Motor Insurance, Health, PA, Overseas Travel, Life
                  Covers,Personal Lines of Insurances under life & Non-life
                </p>
              </li>
              <li>
                <img src="./images/client_solution_icon7.svg" alt="" />
                <h4>Rural Insurance</h4>
                <p>
                  Crop Insurance, Horticulture Crops Insurance, Cattle Insurance
                  and Sheep Insurance duly designing end to end software
                  platform-based scheme administration
                </p>
              </li>
              <li>
                <img src="./images/client_solution_icon8.svg" alt="" />
                <h4>Other Insurances</h4>
                <p>
                  Vehicle Insurance for company’s and employees’ vehicles/top up
                  health/unnamed PA/Special Contingency covers/Export and
                  Domestic Credit Insurance
                </p>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Box>

      <Box className="journey_section mb-margin1">
        <Grid container columnSpacing={3} className="row" alignItems={"center"}>
          <Grid xs={12} marginBottom={"24px"}>
            <h2 className="mb-5">
              Dr. Reddy’s tenure with new India assurance and his achievements
              as an insurer
            </h2>
            <p>
              <span>August 1988 to July 2002:</span> Dr. Reddy joined The New
              India Assurance Co Ltd as a Direct Recruit Specialist Officer in
              1988 and worked till 2002.During this period he has finalized,
              handled and implemented the following unique insurance schemes.
            </p>
          </Grid>
          <Grid xs={12}>
            <ul className="redcircle_ticklist">
              <li>
                Handled Rural insurance portfolio of the New IndiaAssurance at
                state level comprising of insurance of rural properties, lives
                of rural people, assets like animals (cattle, calves, sheep&
                poultry), dwellings, crops (sugarcane, horticulture crops etc)
                Personal Accident policy for rural people, Inland fish & prawn
                insurance schemes etc.
              </li>
              <li>
                During this tenure with New India, he finalized sugarcane crop
                insurance for KCP Ltd Vuyyuru, which was unique and first of its
                kind during that period. This policy covered the entire
                sugarcane crop under KCP Ltd against Natural Calamities like
                flood, cyclone, Fire & Pests & Diseases.
              </li>
              <li>
                Finalized Group Personal Accident policy for all the employees
                of Government of Andhra Pradesh in1994-1995 which was
                implemented till 1997-1998. This policy covered all the 7 Lakh
                employees of Government of Andhra Pradesh.
              </li>
              <li>
                Finalized long term Personal Accident for the members of Andhra
                Pradesh IAS Officers’ Association, employees of Andhra Pradesh
                Judiciary and the members of Government Vehicles Drivers’
                Association at state level during 1999-2000.
              </li>
              <li>
                Finalized unique and first of its kind Group Top-Up Health
                Insurance Coverage for the employees of Andhra Pradesh Judiciary
                at the instructions of Honorable Justice Sri Umesh Chandra
                Banerjee, the then Honorable Chief Justice of Andhra Pradesh
                High Court during 1999-2000. This was the first top up health
                insurance policy in the insurance industry in 1999 itself
                designed by Dr. Reddy.
              </li>
              <li>
                Monitored at the state level as In-Charge of Rural Insurance
                department, the implementation of various insurance schemes
                under Integrated Rural Development Program (IRDP) scheme
                covering Livestock such as cattle, sheep & poultry during 1990-
                1994
              </li>
              <li>
                During his development assignment from 1994 to 2002, he
                developed and handled several Mega Projects and corporate
                clients like:
                <ul className="circle_ticklist mt-5">
                  <li>
                    Lanco Power (Formerly known as Lanco Kondapalli Power
                    Projects Ltd)
                  </li>
                  <li>Nagarjuna Fertilizers and Chemicals Ltd (NFCL)</li>
                  <li>Nagarjuna Green Power Ltd</li>
                  <li>
                    Indian Immunologicals Ltd (Wholly Owned Subsidiary of NDDB)
                  </li>
                  <li>
                    Government of Andhra Pradesh – Group Personal Accident
                    Policy for all the employees of Government of AP
                  </li>
                  <li>AP IAS Officers’ Association</li>
                  <li>
                    AP Judiciary – Group Top Up Health Insurance for all the
                    employees of AP Judiciary.
                  </li>
                </ul>
              </li>
              <li>
                He ranked top in Business Development among his peers during his
                development assignment in New India Assurance
              </li>
            </ul>
          </Grid>
        </Grid>
      </Box>

      <Box className="journey_section mb-margin1">
        <Grid container columnSpacing={3} className="row" alignItems={"center"}>
          <Grid xs={12} marginBottom={"24px"}>
            <h2 className="mb-5">
              The journey of Dr. Reddy in the earlier phase as insurance broker
              and his achievements
            </h2>
            <p>
              <span>July 2002-Nov 2015:</span> Dr. Reddy worked as Managing
              Director & Principal Officer of Excellent Insurance Broking
              Services Ltd, which was given License by IRDAI in the first batch
              of Licenses that were issued in January 2003. During his tenure
              DR. Reddy contributed his might to the growth of the company
              building it from a scratch to around 50 Crores in 2015-16. During
              this tenure, the following achievements are the major ones.
            </p>
          </Grid>
          <Grid xs={12}>
            <ul className="redcircle_ticklist">
              <li>
                Handled total Insurance Portfolio of Dr. Reddy’s Laboratories
                Ltd, a well-known Indian Multi National Pharma Company, from
                2004-2007 covering various insurances like D&O Insurance,
                Product Liability Insurance, Commercial General Liability
                Insurance, Marine Insurance, Property Insurance, Business
                Interruption cover, Employee Benefits like Group Health, Group
                Personal Accident, Group Term Life Insurance etc.
              </li>
              <li>
                Handled the total insurance portfolio of Andhra Pradesh Tourism
                Development Corporation (APTDC) from 2003-2015, comprising of
                various insurances like assets, employees and boats of APTDC.
              </li>
              <li>
                Handled the entire insurance portfolio of Hyderabad Metropolitan
                Water Supply & Sewerage Board (HMWSSB) from 2005 to 2015.
              </li>
              <li>
                Handled total insurance portfolio of Hyderabad Metropolitan
                Development Authority (HMDA) covering various assets like HMDA
                property, employees and assets under various insurances.
              </li>
              <li>
                Handled group health Insurance policy for the employees of SERP
                (Society for Elimination of Rural Poverty of Telangana State)
                during 2014-2015.
              </li>
              <li>
                Handled cattle & sheep insurance scheme under IRDP in Kurnool
                district during 2004- 2006.
              </li>
              <li>
                Designed and Implemented cattle insurance in the name of
                “Ksheerasagara” and Calf rearing scheme “Sunandini” for both
                Government of Andhra Pradesh and Telangana during 2012 to 2015.
                Under the schemes, he designed end to end software platform for
                successful implementation and monitoring of these insurance
                schemes.
              </li>
            </ul>
          </Grid>
        </Grid>
      </Box>

      <Box className=" mb-margin1" marginBottom={"80px"}>
        <Grid container columnSpacing={3} className="row" alignItems={"center"}>
          <Grid xs={12} textAlign={"center"}>
            <h2 className="mb-2">Legacy of Excellence</h2>
            <p>
              Throughout his career, Dr. Reddy has pioneered unique insurance
              schemes, facilitated corporate partnerships, and provided
              invaluable risk management solutions. His dedication to client
              satisfaction and industry innovation continues to define his
              legacy in the insurance landscape.
            </p>
          </Grid>
        </Grid>
      </Box>
      <Box className=" mb-margin1" marginBottom={"80px"}>
        <Grid container columnSpacing={3} className="row" alignItems={"center"}>
          <Grid xs={12} textAlign={"center"}>
            <h2 className="mb-2">The Journey Continues</h2>
            <p>
              As Managing Director & Principal Officer of Amaze Insurance
              Brokers Pvt Ltd since January 2016, Dr. Reddy remains steadfast in
              his mission to deliver world-class insurance solutions, driving
              positive change and elevating the reputation of Indian Insurance
              Brokers on the global stage.
            </p>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ManagingDirectorTab;
