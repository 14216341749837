import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../../../Components/Desktop/Navbar/Navbar";
import MFooter from "../../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../../Components/Mobile/MNavBar/MNavBar";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import "./../../../../../SCSS/ProductPages.scss";
import PPAccordionComponent from "../../../../../Components/PPAccordionComponent/PPAccordionComponent";

const EmployeeCrimeGuaranteeInsurance = () => {
  const isMobile = useIsMobile();

  const coveragePointsArray = [
    {
      title: "Coverage",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              Employee Crime or Fidelity Guarantee Insurance provides coverage
              for financial losses incurred by a business due to acts of
              dishonesty, fraud, or theft committed by its employees. This
              policy typically covers various forms of employee dishonesty,
              including embezzlement, forgery, theft of money or property, and
              fraudulent acts.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Wordings",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              The policy wording outlines the terms, conditions, and coverage
              details of Employee Crime or Fidelity Guarantee Insurance. It
              specifies the insured perils, coverage limits, deductible amounts,
              premium rates, and claims procedures.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Special Features ",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Comprehensive Coverage:</span> Offers broad coverage for
              losses resulting from employee dishonesty, providing protection
              against financial harm caused by fraudulent activities within the
              organization.
            </li>
            <li>
              <span>Tailored Solutions: </span> Policies can be customized to
              meet the specific needs and risk profile of the insured business,
              with coverage options ranging from basic fidelity coverage to
              comprehensive crime insurance.
            </li>
            <li>
              <span>Employee Benefit Coverage: </span> Some policies may extend
              coverage to include losses arising from employee benefit plans,
              such as pension funds, health insurance, or retirement savings
              accounts.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Enhancements  ",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Third-Party Coverage: </span>
              Provides coverage for losses resulting from the dishonest acts of
              third parties, such as contractors, consultants, or temporary
              employees.
            </li>
            <li>
              <span>Extended Reporting Period: </span> Offers an extended
              reporting period for discovering and reporting losses incurred
              during the policy period, providing additional time for
              identifying fraudulent activities.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Additional Covers:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Computer Fraud: </span> Offers coverage for losses resulting
              from unauthorized access or manipulation of computer systems or
              data by employees.
            </li>
            <li>
              <span>Forgery or Alteration: </span>
              Provides coverage for losses resulting from forged or altered
              documents, including checks, contracts, or financial records.
            </li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="subProducts_pp">
          <Grid
            container
            columnSpacing={3}
            className="row"
            marginBottom={"24px"}
            alignItems={"center"}
          >
            <Grid xs={12} md className="mb-margin2">
              <h1>Employee Crime or Fidelity Guarantee Insurance Policies</h1>
            </Grid>
            <Grid xs={12} md={"auto"} margin={"auto"}>
              <img
                src="/images/crime_fidelity_img.svg"
                alt=""
                style={{
                  width: "100%",
                  maxWidth: isMobile ? "180px" : "240px",
                  margin: "auto",
                }}
              />
            </Grid>
          </Grid>

          <Box className="coverages-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12}>
                <PPAccordionComponent data={coveragePointsArray} />
              </Grid>
            </Grid>
          </Box>

          <Box className="claimProcedure-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom={"24px"} textAlign={"center"}>
                <h2>Claims Procedure</h2>
              </Grid>
              <Grid xs={12} textAlign={"center"}>
                <p>
                  In the event of a covered loss, the insured must notify the
                  insurer promptly and provide supporting documentation, such as
                  evidence of the dishonest act, financial records, and employee
                  statements. The insurer will then assess the claim, verify the
                  loss, and facilitate the claims settlement process in
                  accordance with the policy terms.
                </p>
              </Grid>
            </Grid>
          </Box>

          <Box className="termsConditions_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md={8} className="mb-margin2">
                <h2 className="mb-3">Terms and Conditions</h2>
                <p>
                  The policy's terms and conditions specify the rights and
                  obligations of both the insurer and the insured. These include
                  coverage duration, premium payment terms, deductible amounts,
                  claims reporting procedures, and policy renewal conditions.
                </p>
              </Grid>
              <Grid xs={12} md={4}>
                <img
                  src="../images/termsconditions_img.svg"
                  alt=""
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="excluison_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md={4} order={{ xs: 2, md: 1 }}>
                <img
                  src="../images/exclusions_img.svg"
                  alt=""
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
              <Grid
                xs={12}
                md={8}
                className="mb-margin2"
                order={{ xs: 1, md: 2 }}
              >
                <h2 className="mb-3">Exclusions</h2>
                <p>
                  Employee Crime or Fidelity Guarantee Insurance policies
                  typically exclude coverage for losses resulting from acts of
                  war, terrorism, or intentional misconduct by the insured
                  business. Additionally, losses arising from errors or
                  omissions in financial transactions may be excluded from
                  coverage.
                </p>
              </Grid>
            </Grid>
          </Box>

          <Box className="claimProcedure-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom={"24px"} textAlign={"center"}>
                <h2>Conclusion</h2>
              </Grid>
              <Grid xs={12} textAlign={"center"}>
                <p>
                  Employee Crime or Fidelity Guarantee Insurance is essential
                  for businesses to protect against financial losses resulting
                  from employee dishonesty or fraudulent activities. By
                  partnering with Amaze Insurance Brokers, you gain access to
                  comprehensive coverage options, expert advice, and dedicated
                  support throughout the insurance process.
                </p>
              </Grid>
              <Grid xs={12} textAlign={"center"}>
                <p>
                  For further inquiries or to explore Employee Crime Insurance
                  options tailored to your business needs, contact us today.
                </p>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default EmployeeCrimeGuaranteeInsurance;
