import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../../../Components/Desktop/Navbar/Navbar";
import MFooter from "../../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../../Components/Mobile/MNavBar/MNavBar";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import "./../../../../../SCSS/ProductPages.scss";
import PPAccordionComponent from "../../../../../Components/PPAccordionComponent/PPAccordionComponent";
import ReadMoreText from "../../../../../Components/ReadMoreTextComponent/ReadMoreText";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const MachineryBreakdownInsurance = () => {
  const isMobile = useIsMobile();

  const coveragePointsArray = [
    {
      title: "Coverage:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Accidental Damage:</span> Covers accidental physical damage
              to insured machinery and equipment.
            </li>
            <li>
              <span>Electrical and Mechanical Breakdown: </span> Covers
              breakdown due to electrical or mechanical failures.
            </li>
            <li>
              <span>Faulty Design, Material, or Workmanship: </span> Covers
              damage caused by faulty design, material, or workmanship.
            </li>
            <li>
              <span>Boiler Explosion and Implosion: </span>
              Covers damage caused by boiler explosion or implosion.
            </li>
            <li>
              <span>Turbine Damage: </span>
              Covers damage to turbines, generators, and associated equipment.
            </li>
            <li>
              <span>Additional Coverages: </span>
              Some policies may offer optional coverages for additional perils
              like deterioration of stock, consequential loss, etc., at an
              additional premium.
            </li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="subProducts_pp">
          <Grid container columnSpacing={3} className="row">
            <Grid xs={12} textAlign="center" marginBottom="24px">
              <h1>Machinery Breakdown Loss of Profits Insurance (MLOP)</h1>
              <p className="mt-3">
                Machinery Breakdown Insurance is designed to provide coverage
                for sudden and unforeseen physical damage to machinery and
                equipment due to internal defects, faulty material, design
                defects, and other specified perils. Below is a general overview
                of the coverage, exclusions, terms, conditions, and claims
                procedure typically associated with Machinery Breakdown
                Insurance in the Indian insurance market.
              </p>
            </Grid>
          </Grid>

          <Box className="coverages-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} md>
                <PPAccordionComponent data={coveragePointsArray} />
              </Grid>
              <Grid xs={12} md={"auto"}>
                <img
                  src="/images/MLOP_img.svg"
                  alt=""
                  style={{
                    width: "100%",
                    maxWidth: isMobile ? "210px" : "287px",
                    margin: "auto",
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="claimProcedure-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom="24px" textAlign="center">
                <h2>Claims Procedure</h2>
              </Grid>
              <Grid xs={12}>
                <ul className="claimProcedure_list">
                  <li>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom="24px"
                    >
                      <Box textAlign="left">
                        <h4>01</h4>
                        <h6>Immediate Notification</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon1.svg"
                        alt="notification icon"
                        style={{
                          width: "52px",
                          height: "52px",
                          borderRadius: "4px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "In the event of machinery breakdown, the insured must notify the insurance company immediately and provide details of the incident."
                      }
                      maxlength={100}
                    />
                  </li>
                  <li>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom="24px"
                    >
                      <Box textAlign="left">
                        <h4>02</h4>
                        <h6>Claim Documentation</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon2.svg"
                        alt="documentation icon"
                        style={{
                          width: "52px",
                          height: "52px",
                          borderRadius: "4px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "The insured must submit a detailed claim form along with supporting documents, such as repair estimates, invoices, photographs, etc."
                      }
                      maxlength={100}
                    />
                  </li>
                  <li>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom="24px"
                    >
                      <Box textAlign="left">
                        <h4>03</h4>
                        <h6>Claim Inspection</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon3.svg"
                        alt="inspection icon"
                        style={{
                          width: "52px",
                          height: "52px",
                          borderRadius: "4px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "The insurance company may appoint a surveyor to inspect the damaged machinery and assess the extent of the damage."
                      }
                      maxlength={100}
                    />
                  </li>
                  <li>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom="24px"
                    >
                      <Box textAlign="left">
                        <h4>04</h4>
                        <h6>Claim Settlement</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon4.svg"
                        alt="settlement icon"
                        style={{
                          width: "52px",
                          height: "52px",
                          borderRadius: "4px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "Upon approval of the claim, the insurance company will settle the claim amount as per the policy terms and conditions, either by repairing the machinery, replacing it, or paying the insured amount, depending on the nature of the damage and policy coverage."
                      }
                      maxlength={100}
                    />
                  </li>
                </ul>
                <p className="mt-7">
                  Please note that the above information is general in nature
                  and may vary depending on the specific policy terms,
                  conditions, and exclusions offered by different insurance
                  companies in the Indian market.
                </p>
              </Grid>
            </Grid>
          </Box>

          <Box className="termsConditions_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems="center"
            >
              <Grid xs={12} md={8} className="mb-margin2">
                <h2 className="mb-3">Terms and Conditions</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    <span>Policy Period: </span> Typically, the policy is issued
                    for a one-year period and needs to be renewed annually.
                  </li>
                  <li>
                    <span>Sum Insured:</span> The sum insured is based on the
                    current replacement value of the machinery and equipment.
                  </li>
                  <li>
                    <span>Deductibles:</span> Policies may include deductibles,
                    which are the amounts that the insured must bear in the
                    event of a claim.
                  </li>
                  <li>
                    <span>Premium Payment:</span> Premiums are payable annually
                    and must be paid to keep the policy in force.
                  </li>
                </ol>
              </Grid>
              <Grid xs={12} md={4}>
                <img
                  src="../images/termsconditions_img.svg"
                  alt="terms and conditions icon"
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="excluison_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems="center"
            >
              <Grid xs={12} md={4} order={{ xs: 2, md: 1 }}>
                <img
                  src="../images/exclusions_img.svg"
                  alt="exclusions icon"
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
              <Grid
                xs={12}
                md={8}
                className="mb-margin2"
                order={{ xs: 1, md: 2 }}
              >
                <h2 className="mb-3">Exclusions</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    <span>War and Nuclear Risks:</span> Damage or destruction
                    resulting from war, invasion, act of foreign enemy,
                    hostilities, civil war, rebellion, revolution, insurrection,
                    military or usurped power, and nuclear reactions.
                  </li>
                  <li>
                    <span>Wear and Tear:</span> Damage due to wear and tear,
                    gradual deterioration, inherent defects, or faulty
                    workmanship.
                  </li>
                  <li>
                    <span>Consequential Loss: </span> Loss of profit, loss of
                    market, or any other consequential loss arising out of
                    damage to the insured property.
                  </li>
                  <li>
                    <span>Intentional Acts:</span> Damage caused intentionally
                    by the insured or any person acting on behalf of the
                    insured.
                  </li>
                  <li>
                    <span>Pollution and Contamination:</span> Damage or loss
                    resulting from pollution, contamination, or environmental
                    impairment.
                  </li>
                </ol>
              </Grid>
            </Grid>
          </Box>

          <Box className="whyChoose_pp_section">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems="center"
            >
              <Grid xs={12} md={6} className="mb-margin2">
                <h2>Why Choose Amaze Insurance Brokers?</h2>
                <img
                  src="../images/whychoose_pp_img.svg"
                  alt="why choose us icon"
                  style={{ maxWidth: "450px", width: "100%", margin: "auto" }}
                  className="wchoose_img"
                />
              </Grid>
              <Grid xs={12} md={6}>
                <ul className="whyChoosepp_list">
                  <li>
                    <Box className="wc_box">
                      <img
                        src="../images/wc_img1.svg"
                        alt="expert guidance icon"
                      />
                      <h6>Expert Guidance</h6>
                      <p>
                        Our team of experts understands coverages under MBD
                        Policy and can provide you with expert guidance and
                        support every step of the way.
                      </p>
                    </Box>
                    <Box className="wc_box">
                      <img
                        src="../images/wc_img2.svg"
                        alt="claims assistance icon"
                      />
                      <h6>Seamless Claims Assistance</h6>
                      <p>
                        We provide comprehensive support throughout the claims
                        process.
                      </p>
                    </Box>
                  </li>
                  <li>
                    <Box className="wc_box">
                      <img src="../images/wc_img3.svg" alt="quotes icon" />
                      <h6>Competitive Quotes</h6>
                      <p>
                        We work with leading insurance companies to secure the
                        best deals for you.
                      </p>
                    </Box>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default MachineryBreakdownInsurance;
