import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../../../Components/Desktop/Navbar/Navbar";
import MFooter from "../../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../../Components/Mobile/MNavBar/MNavBar";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import "./../../../../../SCSS/ProductPages.scss";
import PPAccordionComponent from "../../../../../Components/PPAccordionComponent/PPAccordionComponent";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const MiscellaneousVehicles = () => {
  const isMobile = useIsMobile();

  const coveragePointsArray = [
    {
      title: "Coverage:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              Our Motor Insurance for Miscellaneous Vehicles provides protection
              against various risks, including accidental damage, theft,
              third-party liabilities, and natural calamities.
            </li>
            <li>
              Coverage extends to a wide range of vehicles such as commercial
              vehicles, agricultural vehicles, construction equipment, trailers,
              and special-purpose vehicles.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Wordings and Special Features:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              Policies are crafted with clear and concise language to ensure
              understanding and transparency for policyholders.
            </li>
            <li>
              Special features may include flexible coverage options, customized
              policy terms, and additional benefits tailored to the specific
              requirements of miscellaneous vehicle owners.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Enhancements and Additional Covers:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              Enhanced coverage options may include roadside assistance,
              emergency repairs, loss of personal belongings, and medical
              expenses for occupants.
            </li>
            <li>
              Additional covers are available for specific risks such as damage
              to accessories, loss of revenue due to vehicle downtime, and legal
              expenses.
            </li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="subProducts_pp">
          <Grid container columnSpacing={3} className="row">
            <Grid xs={12} textAlign={"center"} marginBottom="24px">
              <h1>Motor Insurance for Miscellaneous Vehicles</h1>
            </Grid>
          </Grid>

          <Box className="coverages-section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md className="mb-margin2">
                <PPAccordionComponent data={coveragePointsArray} />
              </Grid>
              <Grid xs={12} md={"auto"} margin={"auto"}>
                <img
                  src="/images/motor_ins_misc_vehicle.svg"
                  alt=""
                  style={{
                    width: "100%",
                    maxWidth: isMobile ? "260px" : "376px",
                    margin: "auto",
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="claimProcedure-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom={"24px"} textAlign="center">
                <h2 className="mb-6">Claims Procedure</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    In the event of an accident or loss, policyholders must
                    notify Amaze Insurance Brokers or the insurer promptly.
                  </li>
                  <li>
                    The claims procedure typically involves submitting a claims
                    form along with supporting documentation such as accident
                    reports, repair estimates, and relevant receipts.
                  </li>
                  <li>
                    Our dedicated claims team will guide you through the
                    process, ensuring prompt and efficient settlement of claims
                    to minimize disruption to your operations.
                  </li>
                </ol>
              </Grid>
            </Grid>
          </Box>
          <Box className="claimProcedure-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom={"24px"} textAlign="center">
                <h2 className="mb-6">Claims Procedure</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    In the event of an accident or loss, policyholders must
                    notify Amaze Insurance Brokers or the insurer promptly.
                  </li>
                  <li>
                    The claims procedure typically involves submitting a claims
                    form along with supporting documentation such as accident
                    reports, repair estimates, and relevant receipts.
                  </li>
                  <li>
                    Our dedicated claims team will guide you through the
                    process, ensuring prompt and efficient settlement of claims
                    to minimize disruption to your operations.
                  </li>
                </ol>
              </Grid>
            </Grid>
          </Box>
          <Box className="claimProcedure-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom={"24px"} textAlign="center">
                <h2 className="mb-6">Terms and Conditions</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    The terms and conditions of the policy outline the rights,
                    obligations, and responsibilities of both the insurer and
                    the insured.
                  </li>
                  <li>
                    Policyholders are required to adhere to the terms and
                    conditions specified in the policy document to maintain
                    coverage eligibility and facilitate seamless claims
                    processing.
                  </li>
                </ol>
              </Grid>
            </Grid>
          </Box>

          <Box className="claimProcedure-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom={"24px"} textAlign="center">
                <h2 className="mb-6">Exclusions</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    Exclusions vary depending on the policy and insurer but may
                    typically include intentional acts, racing activities,
                    unauthorized modifications, and wear and tear.
                  </li>
                </ol>
              </Grid>
              <p style={{ textAlign: "center", marginTop: "80px" }}>
                For comprehensive Motor Insurance solutions for Miscellaneous
                Vehicles, trust Amaze Insurance Brokers to safeguard your assets
                and liabilities. Contact us today to learn more about our
                offerings and how we can tailor a policy to suit your unique
                needs.
              </p>
            </Grid>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default MiscellaneousVehicles;
