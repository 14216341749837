import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../../Components/Desktop/Navbar/Navbar";
import MFooter from "../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../Components/Mobile/MNavBar/MNavBar";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import "./../../../../SCSS/ProductPages.scss";

const ProjectInsurance = () => {
  const isMobile = useIsMobile();

 return (
   <Box className="productPages-layout">
     {isMobile ? <MNavBar /> : <Navbar />}

     <Box className="pp-inner">
       <Box className="main_pp">
         <Grid
           container
           columnSpacing={3}
           className="row mb-margin1"
           marginBottom={"80px"}
         >
           <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
             <h2 className="mb-3">
               Project Insurances: Protecting Every Phase of Your Ambitious
               Ventures
             </h2>
             <p>
               From groundbreaking to grand openings, every project brings its
               own set of challenges and risks. At Amaze Insurance Brokers, we
               offer comprehensive Project Insurance Solutions designed to
               safeguard your investments, timelines, and success. Whether
               you're building infrastructure, setting up industrial units, or
               managing complex installations, our policies have you covered.
             </p>
           </Grid>
           <Grid xs={12} textAlign={"center"}>
             <h4 className="mb-3">Comprehensive Coverage for Every Stage:</h4>
           </Grid>
           <Grid xs={12} className="mb-4">
             <h5 className="mb-3">Contractor All Risks (CAR) Insurance</h5>
             <p>
               Protect your construction projects against unforeseen risks,
               including physical damage, third-party liabilities, and more,
               ensuring smooth progress from start to finish.
             </p>
           </Grid>
           <Grid xs={12} className="mb-4">
             <h5 className="mb-3">Erection All Risks (EAR) Insurance</h5>
             <p>
               Shield your machinery, plant, and equipment during installation
               or erection, minimizing risks during these critical phases of
               your project.
             </p>
           </Grid>
           <Grid xs={12} className="mb-4">
             <h5 className="mb-3">Advance Loss of Profits (ALOP) Insurance</h5>
             <p>
               Safeguard your revenue streams against delays caused by insured
               perils, ensuring that unexpected setbacks don’t derail your
               financial projections.
             </p>
           </Grid>
           <Grid xs={12} className="mb-4">
             <h5 className="mb-3">Marine Delay In Start-Up (DSU) Insurance</h5>
             <p>
               Cover the financial implications of delayed project start-ups due
               to marine transit risks, protecting your timeline and
               profitability.
             </p>
           </Grid>
           <Grid xs={12} className="mb-4">
             <h5 className="mb-3">Marine Cum Erection Insurance</h5>
             <p>
               Combine marine and erection coverage into a seamless policy that
               ensures uninterrupted protection from the time goods leave their
               origin to their installation on-site.
             </p>
           </Grid>
         </Grid>
         <Box className="whyChoose_pp_section mb-margin1" marginBottom={"80px"}>
           <Grid
             container
             columnSpacing={3}
             className="row mb-6"
             alignItems={"center"}
           >
             <Grid xs={12} md={6} className="mb-margin2">
               <h2>Why Choose Amaze Insurance Brokers?</h2>
               <img
                 src="../images/whychoose_pp_img.svg"
                 alt=""
                 style={{ maxWidth: "450px", width: "100%", margin: "auto" }}
                 className="wchoose_img"
               />
             </Grid>
             <Grid xs={12} md={6}>
               <ul className="whyChoosepp_list">
                 <li>
                   <Box className="wc_box">
                     <img src="../images/wc_img1.svg" alt="" />
                     <h6>Tailored Policies</h6>
                     <p>Every project is unique, and so is our approach.</p>
                   </Box>
                   <Box className="wc_box">
                     <img src="../images/wc_img2.svg" alt="" />
                     <h6>End-to-End Support</h6>
                     <p>
                       From risk analysis to claims settlement, we’re with you
                       at every step.
                     </p>
                   </Box>
                 </li>
                 <li>
                   <Box className="wc_box">
                     <img src="../images/wc_img3.svg" alt="" />
                     <h6>Proven Expertise</h6>
                     <p>
                       Trusted by leading corporates to manage their project
                       risks effectively.
                     </p>
                   </Box>
                 </li>
               </ul>
             </Grid>
           </Grid>
         </Box>
       </Box>
     </Box>

     {isMobile ? <MFooter /> : <Footer />}
   </Box>
 );
};

export default ProjectInsurance;
