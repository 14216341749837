import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../../../Components/Desktop/Navbar/Navbar";
import MFooter from "../../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../../Components/Mobile/MNavBar/MNavBar";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import "./../../../../../SCSS/ProductPages.scss";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const MarineHullInsurance = () => {
  const isMobile = useIsMobile();

  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="subProducts_pp">
          <Grid container columnSpacing={3} className="row" alignItems={"center"}>
            <Grid xs={12} md className="mb-margin2" textAlign={isMobile ? "center" : "left"}>
              <h1>Marine Hull Insurance</h1>

              <p className="mt-3">
                Safeguard your vessels and maritime assets against physical
                damage, collisions, and other maritime perils with our marine
                hull insurance coverage. Our policies are designed to provide
                comprehensive protection for shipowners, operators, and
                charterers, ensuring financial stability and peace of mind in
                the maritime industry.
              </p>
            </Grid>
            <Grid xs={12} md={"auto"} margin={"auto"}>
              <img
                src="/images/marine_hull_ins_img.svg"
                alt=""
                style={{
                  width: "100%",
                  maxWidth: isMobile ? "210px" : "277px",
                  margin: "auto",
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default MarineHullInsurance;