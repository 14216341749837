import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../../../Components/Desktop/Navbar/Navbar";
import MFooter from "../../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../../Components/Mobile/MNavBar/MNavBar";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import "./../../../../../SCSS/ProductPages.scss";
import PPAccordionComponent from "../../../../../Components/PPAccordionComponent/PPAccordionComponent";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const ShopkeepersInsurance = () => {
  const isMobile = useIsMobile();

  const coveragePointsArray = [
    {
      title: "Coverage:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Building and Contents:</span> Safeguard your shop premises
              and inventory against perils such as fire, theft, burglary,
              vandalism, and natural disasters.
            </li>
            <li>
              <span>Business Interruption:</span> Receive coverage for loss of
              income and additional expenses incurred due to a covered event
              that disrupts your business operations.
            </li>
            <li>
              <span>Public Liability:</span> Protect your business against
              third-party claims for bodily injury or property damage that occur
              on your premises.
            </li>
            <li>
              <span>Money and Cash in Transit:</span> Enjoy coverage for loss of
              money kept on your premises or while in transit to the bank.{" "}
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Fidelity Guarantee Insurance for Employees:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Coverage:</span> Provides indemnity to the insured against
              any direct pecuniary loss sustained due to fraud or dishonesty
              committed by employees.
            </li>
            <li>
              <span> Special Features:</span> Coverage extends to acts of fraud,
              dishonesty, embezzlement, or misappropriation of funds by
              employees.
            </li>
            <li>
              <span>Exclusions:</span> Typically excludes losses due to acts
              committed with the knowledge or consent of the insured, losses
              covered under other insurance policies, and losses exceeding the
              policy limit.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Plate-glass Insurance:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Coverage:</span> Protects the shop's plate-glass windows,
              doors, and glass partitions against accidental breakage.
            </li>
            <li>
              <span>Special Features:</span> Coverage extends to accidental
              breakage caused by various perils, including vandalism, riots, and
              natural disasters.
            </li>
            <li>
              <span>Exclusions: </span>Typically excludes losses due to wear and
              tear, gradual deterioration, and deliberate acts of the insured.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Money Insurance for Cash in Counters:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Coverage:</span> Provides coverage for loss of money kept on
              the insured premises, including cash in the cash register or safe.
            </li>
            <li>
              <span>Special Features:</span> Coverage extends to loss of money
              due to theft, burglary, robbery, or any other insured peril.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Special Features and Enhancements:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span> Access to Multiple Insurers:</span> As insurance brokers,
              we have access to a wide network of insurance companies in the
              Indian market, allowing us to source the best policy that meets
              your specific requirements.
            </li>
            <li>
              <span>Tailored Solutions:</span> Benefit from customized insurance
              solutions that address the unique risks faced by your shop or
              showroom, ensuring comprehensive protection.
            </li>
            <li>
              <span>Expert Advice:</span> Our experienced insurance
              professionals provide expert guidance throughout the policy
              selection process, helping you make informed decisions.
            </li>
            <li>
              <span>Claims Assistance: </span>In the event of a claim, our
              dedicated claims team is available to provide prompt assistance
              and guide you through the claims process.{" "}
            </li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="subProducts_pp">
          <Grid container columnSpacing={3} className="row">
            <Grid xs={12} textAlign={"center"} marginBottom="24px">
              <h1>
                Comprehensive Shopkeepers Insurance Solutions from Amaze
                Insurance Brokers
              </h1>
              <p>
                As leading insurance brokers in the Indian Insurance Market,
                Amaze Insurance Brokers takes pride in offering comprehensive
                Shopkeepers Insurance policies tailored to the unique needs of
                shop and showroom owners. Our extensive range of coverage,
                special features, and enhancements ensures that your business is
                adequately protected against a wide range of risks.
              </p>
            </Grid>
          </Grid>

          <Box className="coverages-section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md className="mb-margin2">
                <PPAccordionComponent data={coveragePointsArray} />
              </Grid>
              <Grid xs={12} md={"auto"} margin={"auto"}>
                <img
                  src="/images/comprehensive_shopkeeper_insurance_img.svg"
                  alt=""
                  style={{
                    width: "100%",
                    maxWidth: isMobile ? "220px" : "337px",
                    margin: "auto",
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="claimProcedure-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom={"24px"} textAlign="center">
                <h2 className="mb-6">Exclusions and Terms</h2>
                <p>
                  While our Shopkeepers Insurance policies offer extensive
                  coverage, it's essential to be aware of exclusions and terms
                  outlined in the policy documents. Our team will ensure that
                  you have a clear understanding of these aspects before
                  finalizing the policy.
                </p>
                <p style={{ marginTop: "80px", fontWeight: 500 }}>
                  For comprehensive Jeweler's Block Insurance solutions for big
                  jewelry showrooms and jewelers, trust Amaze Insurance Brokers
                  to protect your valuable assets. Contact us today to learn
                  more about our offerings and how we can tailor a policy to
                  suit your specific needs.
                </p>
              </Grid>
            </Grid>
          </Box>
          <Box className="claimProcedure-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom={"24px"} textAlign="center">
                <h2 className="mb-6">Why Choose Amaze Insurance Brokers?</h2>
                <p>
                  At Amaze Insurance Brokers, we are committed to delivering
                  value-added services to our clients. With our vast industry
                  experience, strong relationships with insurers, and dedication
                  to client satisfaction, we are your trusted partner for all
                  your insurance needs. Let us help you secure the best
                  Shopkeepers Insurance policy that provides comprehensive
                  coverage and peace of mind for your business.
                </p>
                <p style={{ marginTop: "24px", fontWeight: 500 }}>
                  Contact Amaze Insurance Brokers today to explore our
                  Shopkeepers Insurance solutions and receive a personalized
                  quote tailored to your requirements.
                </p>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default ShopkeepersInsurance;
