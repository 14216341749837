import { Box, Link, Rating } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Footer from "../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../Components/Desktop/Navbar/Navbar";
import FAQ from "../../../Components/FAQ/FAQ";
import MNavBar from "../../../Components/Mobile/MNavBar/MNavBar";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import MFooter from "../../../Components/Mobile/MFooter/MFooter";
import { useNavigate } from "react-router-dom";
import { PI_ROUTES } from "../../../Routing/Path/PIPaths";
import "./HomePage.scss";
import { EB_ROUTES } from "../../../Routing/Path/EBPaths";
import { MARINE_ROUTES } from "../../../Routing/Path/MarineTransitionPaths";
import { LIABILITY_INSURANCE_ROUTES } from "../../../Routing/Path/LiabilityInsurancePaths";
import { BIINSURANCE_ROUTES } from "../../../Routing/Path/BIInsurancePaths";
import { PROJECT_INSURANCE_PATHS } from "../../../Routing/Path/ProjectInsurancePaths";
import { MISC_PATHS } from "../../../Routing/Path/MiscellaneousPaths";

const HomePage = () => {
  const [value, setValue] = React.useState<number | null>();
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const whychoosePoints = [
    {
      title: "Premium Savings",
      desc: `Our extensive network of partnerships with all major insurance companies in the market allows us to negotiate the best prices and terms on behalf of our clients. By leveraging our industry connections and market expertise, we help you maximize cost savings on your insurance premiums without compromising on coverage or quality.`,
    },
    {
      title: "Proper and Adequate Coverage",
      desc: ``,
    },
    {
      title: "Speedy and Hassle-Free Claims Settlement",
      desc: ``,
    },
    {
      title: "Expertise",
      desc: ``,
    },
    {
      title: "Personalized Service",
      desc: ``,
    },
    {
      title: "Comprehensive Coverage",
      desc: ``,
    },
    {
      title: "Tailored Solutions",
      desc: ``,
    },
    {
      title: "Proactive Risk Management",
      desc: ``,
    },
    {
      title: "Commitment to Excellence",
      desc: ``,
    },
    {
      title: "Trusted Partner",
      desc: ``,
    },
  ];

  const insurerPartners = [
    "./images/insurers/BANDHAN_LIFE.png",
    "./images/insurers/Future-Generali-India-Insurance-Company-Limited.svg",
    "./images/insurers/HDFC-ERGO-General-Insurance-Co-Ltd.svg",
    "./images/insurers/ICICI-Lombard-General-Insurance-Co-Ltd.svg",
    "./images/insurers/KOTAK_LIFE.webp",
    "./images/insurers/Magma-HDI-General-Insurance-Company-Limited.svg",
    "./images/insurers/National-Insurance-Co-Ltd.svg",
    "./images/insurers/PNB-Metlife-India-Insurance-Co-Ltd.svg",
    "./images/insurers/Reliance-General-Insurance-Co-Ltd_Backup.svg",
    "./images/insurers/sbi_general_insurance_company_limited.svg",
    "./images/insurers/Shriram-General-Insurance-Company-Limited.svg",
    "./images/insurers/Tata-AIG-General-Insurance-Co-Ltd.svg",
    "./images/insurers/THE_NEW_INDIA_ASSURANCE.png",
    "./images/insurers/Manipal-CignaTTK-Health-Insurance-Company-Limited.svg",
    "./images/insurers/Magma-HDI-General-Insurance-Company-Limited.svg",
    "./images/insurers/Liberty-Videocon-General-Insurance-Co-Ltd.svg",
    "./images/insurers/kotak_general_insurance.svg",
    "./images/insurers/IFFCO-Tokio-General-Insurance-Co-Ltd.svg",
    "./images/insurers/ICICI-Prudential-Life-Insurance-Co-Ltd.svg",
    "./images/insurers/Go-Digit-General-Insurance.svg",
    "./images/insurers/Religare-Health-Insurance-Company-Limited.svg",
    "./images/insurers/Bajaj_Alianz_2.svg",
    "./images/insurers/aditya_birla_capital.svg",
    "./images/insurers/Reliance-General-Insurance-Co-Ltd.svg",
    "./images/insurers/Max-Life-Insurance-Co-Ltd.svg",
    "./images/insurers/Royal_Sundaram.svg",
  ];

  const [settings] = useState({
    className: "center",
    // centerMode: true,
    infinite: true,
    // centerPadding: "200px",
    slidesToShow: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 4000,
    // cssEase: "linear",
    arrows: true,
    dots: true,
  });

  const [partnerssettings] = useState({
    className: "center",
    // centerMode: true,
    infinite: true,
    // centerPadding: "200px",
    slidesToShow: 3,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 2000,
    // cssEase: "linear",
    arrows: false,
    dots: false,
  });

  const testimonialsArray = [
    {
      reviewer_img: "./images/avatar.svg",
      reviewer_name: "Nirup Kuttappa",
      review: `“Hi Amaze Insurance team, I thank you all for the support on medical insurance clearance & service towards associates of our organization. It was an excellent quick support on our associate dependent activation at the time of medical emergency.”`,
    },
    {
      reviewer_img: "./images/avatar.svg",
      reviewer_name: "Vinay Tatras",
      review: `"I extend my heartfelt appreciation to the exceptional Amaze Insurance team. Your invaluable assistance played a pivotal role in ensuring the successful reimbursement of my ICICI claim. Your unwavering support made all the difference."`,
    },
    {
      reviewer_img: "./images/avatar.svg",
      reviewer_name: "Raj Kumar",
      review: `"Amaze Insurance Brokers provided exceptional service in a dire time. Despite a serious accident and broken leg, their swift and professional handling of my health insurance claim, approved within 12 hours, amazed me. They ensured timely surgery, earning."`,
    },
    {
      reviewer_img: "./images/avatar.svg",
      reviewer_name: "Kameshwar Rao",
      review: `"During the tense period of my mother's COVID-related hospitalization and passing, Amaze Insurance Brokers' prompt support was invaluable. Despite not originating the policy, they swiftly ensured full claim settlement. Deep gratitude to Dr. K Muralidhara."`,
    },
    {
      reviewer_img: "./images/avatar.svg",
      reviewer_name: "S.G. Krishnamraju",
      review: `"Deeply grateful for the swift and secure medical insurance coverage amid the COVID-19 pandemic. Our family received efficient support throughout the claiming process, with a reimbursement of 60,000 out of a 62,276 bill. We wholeheartedly recommend AMAZE."`,
    },
    {
      reviewer_img: "./images/avatar.svg",
      reviewer_name: "Subba Rao",
      review: `"Commendations to you and your team for the dedicated efforts in resolving my car insurance claim. Your hard work and commitment are truly valued. We sincerely acknowledge your role in the settlement and look forward to your continued excellence."`,
    },
    {
      reviewer_img: "./images/avatar.svg",
      reviewer_name: "G. Tarun Reddy",
      review: `"Amaze Insurance Brokers provided extraordinary and swift service for my wife and son's hospitalization claim. Their immediate response, seamless process, and alleviation of insurance-related stress were truly appreciated during a turbulent time. Highly recommend them."`,
    },
    {
      reviewer_img: "./images/avatar.svg",
      reviewer_name: "Y. Shobha Rani",
      review: `"Appreciate the prompt, excellent service during claim settlement. Mr. Muralidhar Reddy and team's intervention was marvelous in crunch time. Your client-centric approach, ownership, and follow-up were reassuring. I look forward to recommending your services to others."`,
    },
    {
      reviewer_img: "./images/avatar.svg",
      reviewer_name: "Ananth Krishnamoorthy",
      review: `"Expressing gratitude for your exceptional assistance in swiftly repairing my shattered car windows and windshield. Your seamless management of tasks, document handling, and arranging cashless garage services made the process hassle-free. Your excellent work is highly appreciated."`,
    },
    {
      reviewer_img: "./images/avatar.svg",
      reviewer_name: "Aparna Chitta",
      review: `"Amaze Insurance brokers have been our trusted partners for years. Their excellent team excels in quotes, comparisons, claims guidance, and feedback. Their commendable support during claims is highly appreciated. Their professionalism and transparency in the claims process are commendable."`,
    },
    {
      reviewer_img: "./images/avatar.svg",
      reviewer_name: "Bhaskar Babu",
      review: `"Our employee's tragic accident was handled with utmost care by M/s. Amaze Insurance Brokers. Their swift, professional guidance, document handling, and discussions with insurers were impressive. Their support ensured proper compensation for the family. Highly recommend them."`,
    },
    {
      reviewer_img: "./images/avatar.svg",
      reviewer_name:
        "Jonna Kuti Dharma Rao- Satya Maharshi Power Plant Pvt. Ltd",
      review: `"Amaze Insurance Brokers provided exceptional service in a dire time. Despite a serious accident and broken leg, their swift and professional handling of my health insurance claim, approved within 12 hours, amazed me. They ensured timely surgery, earning."`,
    },
    {
      reviewer_img: "./images/avatar.svg",
      reviewer_name:
        "Ms. Avanija Reddy, Managing Director · INNMAR TOURISM AND HOTELS PRIVATE LIMITED",
      review: `"Since 2016, Amaze is our trusted one-stop for insurance, providing optimal rates and seamless claim servicing. Dr. Muralidhar Reddy, known since 2009, even facilitated my successful cashless Mediclaim during a Craniotomy. Thrilled with their service and professionalism."`,
    },
    {
      reviewer_img: "./images/avatar.svg",
      reviewer_name: "Vikas Shah",
      review: `"Switching to Amaze Insurance in 2020 was transformative. Fed up with my old agent, I was impressed by their customer focus and industry know-how. Three years on, I've stayed impressed. Rapid, empathetic service from the team, especially Mr. Reddy. Kudos to them."`,
    },
    {
      reviewer_img: "./images/avatar.svg",
      reviewer_name: "G Soma Sekharam",
      review: `"Kudos to AMAZE Insurance Broking Agency for their relentless efforts in securing a denied claim settlement with FHPL. Their expertise and professionalism shone through as they advocated for the client, resulting in a commendable turnaround. Highly recommended."`,
    },
  ];

  const faqArray = [
    {
      title: "What does Insurance mean?",
      desc: `Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.`,
    },
    {
      title: "What does vero eos et accusamus dignissimos?",
      desc: ``,
    },
    {
      title: "What does vero eos et accusamus dignissimos?",
      desc: ``,
    },
    {
      title: "What does vero eos et accusamus dignissimos?",
      desc: ``,
    },
    {
      title: "What does vero eos et accusamus dignissimos?",
      desc: ``,
    },
    {
      title: "What does vero eos et accusamus dignissimos?",
      desc: ``,
    },
    {
      title: "What does vero eos et accusamus dignissimos?",
      desc: ``,
    },
  ];

  useEffect(() => {
     const video = document.querySelector(".vedioWrapper") as HTMLVideoElement;
     if (video) {
       video.muted = false; // Unmute the video
       video.play().catch((error:any) => {
         console.error("Autoplay blocked:", error.message);
       });
     }
  },[])

  return (
    <Box className="homepage-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="header-section mb-margin1" marginBottom={"80px"}>
        <Box className="hs-inner">
          <Grid container columnSpacing={5}>
            <Grid xs={12} className="text-center mb-5">
              <h6>Where industry experience meets clients expectations</h6>
              <h1>Commercial Lines Insurance</h1>
            </Grid>
            <Grid xs={12}>
              <Box className="cli-productBox">
                <ul>
                  <li>
                    <Link
                      onClick={() => navigate(PI_ROUTES.PROPERTY_INSURANCE)}
                    >
                      <img
                        src="./images/property_engg_icon.svg"
                        alt="property_engg_icon"
                      />
                      <p>
                        Property &<br /> Engineering
                      </p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => navigate(BIINSURANCE_ROUTES.BIINSURANCE)}
                    >
                      <img
                        src="./images/business_interuption_icon.svg"
                        alt="business_interuption_icon"
                      />
                      <p>
                        Business <br /> Interruption
                      </p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => navigate(MARINE_ROUTES.MARINE_TRANSITION)}
                    >
                      <img
                        src="./images/marine_cargo_icon.svg"
                        alt="marine_cargo_icon"
                      />
                      <p>
                        Marine
                        <br /> Cargo
                      </p>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => navigate(EB_ROUTES.EMPLOYEE_BENEFITS)}>
                      <img
                        src="./images/employee_benefits_icon.svg"
                        alt="employee_benefits_icon"
                      />
                      <p>
                        Employee
                        <br /> Benefits
                      </p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() =>
                        navigate(LIABILITY_INSURANCE_ROUTES.LIABILITY_INSURANCE)
                      }
                    >
                      <img
                        src="./images/liability_icon.svg"
                        alt="liability_icon"
                      />
                      <p>Liability</p>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => navigate(MISC_PATHS.MISC_CLASS)}>
                      <img
                        src="./images/miscellaneous_class_icon.svg"
                        alt="miscellaneous_class_icon"
                      />
                      <p>
                        Miscellaneous <br /> Class
                      </p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() =>
                        navigate(PROJECT_INSURANCE_PATHS.PROJECT_INSURANCE)
                      }
                    >
                      <img
                        src="./images/project_insurance_icon.svg"
                        alt="project_insurance_icon"
                      />
                      <p>
                        Project
                        <br /> Insurance
                      </p>
                    </Link>
                  </li>
                </ul>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box marginBottom={"80px"} className="vediobox mb-margin1">
        <Grid container className="row" columnSpacing={3} alignItems={"center"}>
          <Grid xs={12} textAlign={"center"} margin={"auto"}>
            <video
              className="vedioWrapper"
              width="100%"
              style={{ maxWidth: "800px" }}
              autoPlay
              loop
              preload="auto"
              playsInline
              controls
              muted // Initially muted
            >
              <source src="./images/amaze_ins_vedio.mp4" type="video/mp4" />
            </video>
          </Grid>
        </Grid>
      </Box>

      <Box className="make-life-section mb-margin1" marginBottom={"80px"}>
        <Grid container className="row" columnSpacing={3} alignItems={"center"}>
          <Grid xs={12} className="mb-6" textAlign={"center"}>
            <h2 className="mb-3">Empowering You with Insurance Knowledge</h2>
            <p className="mb-3" style={{ fontWeight: 700, fontSize: "16px" }}>
              Welcome to Amaze Insurance Brokers - Your Trusted Partner for
              Comprehensive Insurance Solutions
            </p>
            <p className="mb-3">
              At Amaze Insurance Brokers, we pride ourselves on delivering
              superior insurance solutions tailored to meet the diverse needs of
              our clients. With our extensive experience and commitment to
              excellence, we advise you on a wide range of insurance products to
              safeguard your assets, mitigate risks, and protect what matters
              most to you.
            </p>
          </Grid>
          <Grid xs={12}>
            <ul>
              <li>
                <span>Property Insurance:</span> Protect your physical assets,
                including buildings, machinery, and equipment, against risks
                such as fire, theft, natural disasters, and vandalism with our
                comprehensive property insurance policies. Our customizable
                plans ensure that your property remains safeguarded against
                unforeseen events, allowing you to focus on your core business
                activities.
              </li>
              <li>
                <span>Engineering Insurance:</span> Minimize financial losses
                resulting from construction and operational risks with our
                specialized engineering insurance solutions. From construction
                all risks (CAR) to machinery breakdown insurance, we offer
                comprehensive coverage to mitigate potential disruptions and
                ensure the smooth operation of your projects and facilities.
              </li>
              <li>
                <span>Marine Transit Insurance:</span> Ensure the safe and
                secure transportation of goods across domestic and international
                waters with our marine transit insurance policies. Whether you
                are shipping cargo by sea, air, or land, our coverage protects
                against risks such as damage, theft, and liability, allowing you
                to conduct business with confidence.
              </li>
              <li>
                <span>Marine Hull Insurance:</span> Safeguard your vessels and
                maritime assets against physical damage, collisions, and other
                maritime perils with our marine hull insurance coverage. Our
                policies are designed to provide comprehensive protection for
                shipowners, operators, and charterers, ensuring financial
                stability and peace of mind in the maritime industry.
              </li>
              <li>
                <span>Employee Benefits Insurances:</span> Invest in the
                well-being and satisfaction of your employees with our
                comprehensive employee benefits insurance plans. From health
                insurance to retirement benefits, we offer customizable
                solutions to attract and retain top talent while promoting a
                positive work environment and employee loyalty.
              </li>
            </ul>
          </Grid>
          <Grid xs={12} md={7} className="mb-margin2">
            <ul>
              {/* <li>
                <span>Property Insurance:</span> Protect your physical assets,
                including buildings, machinery, and equipment, against risks
                such as fire, theft, natural disasters, and vandalism with our
                comprehensive property insurance policies. Our customizable
                plans ensure that your property remains safeguarded against
                unforeseen events, allowing you to focus on your core business
                activities.
              </li>
              <li>
                <span>Engineering Insurance:</span> Minimize financial losses
                resulting from construction and operational risks with our
                specialized engineering insurance solutions. From construction
                all risks (CAR) to machinery breakdown insurance, we offer
                comprehensive coverage to mitigate potential disruptions and
                ensure the smooth operation of your projects and facilities.
              </li>
              <li>
                <span>Marine Transit Insurance:</span> Ensure the safe and
                secure transportation of goods across domestic and international
                waters with our marine transit insurance policies. Whether you
                are shipping cargo by sea, air, or land, our coverage protects
                against risks such as damage, theft, and liability, allowing you
                to conduct business with confidence.
              </li>
              <li>
                <span>Marine Hull Insurance:</span> Safeguard your vessels and
                maritime assets against physical damage, collisions, and other
                maritime perils with our marine hull insurance coverage. Our
                policies are designed to provide comprehensive protection for
                shipowners, operators, and charterers, ensuring financial
                stability and peace of mind in the maritime industry.
              </li>
              <li>
                <span>Employee Benefits Insurances:</span> Invest in the
                well-being and satisfaction of your employees with our
                comprehensive employee benefits insurance plans. From health
                insurance to retirement benefits, we offer customizable
                solutions to attract and retain top talent while promoting a
                positive work environment and employee loyalty.
              </li> */}
              <li>
                <span>Various Liability Insurances:</span> Protect your business
                from legal liabilities and financial losses arising from
                third-party claims with our various liability insurance options.
                Whether it's general liability, professional indemnity, or
                product liability insurance, we provide robust coverage to
                shield your business against litigation and reputational risks.
              </li>
              <li>
                <span>Money Insurance:</span> Safeguard your cash, securities,
                and valuable assets against theft, burglary, and other perils
                with our specialized money insurance coverage. Our policies
                offer comprehensive protection for businesses of all sizes,
                ensuring financial security and peace of mind in an increasingly
                complex world.
              </li>
              <li>
                <span>Health Insurance:</span> Take care of yourself and your
                loved ones with our comprehensive health insurance plans. From
                hospitalization expenses to critical illness coverage, we offer
                personalized solutions to meet your healthcare needs and provide
                financial protection against medical emergencies.
              </li>
              <li>
                <span>Vehicle Insurance:</span> Hit the road with confidence
                with our vehicle insurance policies. Whether you're driving a
                car, motorcycle, or commercial vehicle, we offer comprehensive
                coverage against accidents, theft, and third-party liabilities,
                ensuring that you're protected wherever the road takes you.
              </li>
              <li>
                <span>Personal Accident Insurance:</span> Protect yourself and
                your family against the unexpected with our personal accident
                insurance plans. From accidental death and disability to medical
                expenses and income replacement, we offer financial support and
                peace of mind when you need it most.
              </li>
              <li>
                <span>Life Insurance:</span> Secure the future of your loved
                ones with our life insurance solutions. Whether you're looking
                for term life, whole life, or investment-linked policies, we
                offer comprehensive coverage and financial security to help you
                achieve your long-term goals and provide for your family's
                future.
              </li>
            </ul>
          </Grid>
          <Grid xs={12} md={5} textAlign={"center"}>
            <img
              src="./images/make-life-easy-img.svg"
              alt="make-life-easy-img"
              className="make_life_esy_img"
            />
          </Grid>
        </Grid>
      </Box>

      {/* <Box className="why-choose-section mb-margin1" marginBottom={"80px"}>
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12} md={6} className="mb-margin2">
            <h2 className="mb-3">Why Choose Amaze Insurance Brokers ?</h2>
            <p>
              At Amaze Insurance Brokers, we understand that selecting the right
              insurance partner is a critical decision for individuals and
              businesses alike. Here's why we stand out as your preferred
              choice:
            </p>
            <Box>
              <img src="./images/why-choose-img.svg" alt="why-choose-img" />
            </Box>
            <h4 className="mb-3">Experience the Amaze Difference Today!</h4>
            <p>
              Contact us to learn more about our insurance solutions and
              discover why Amaze Insurance Brokers is the right choice for all
              your insurance needs. Let us help you navigate the complexities of
              insurance and protect what matters most to you.
            </p>
          </Grid>
          <Grid xs={12} md={6}>
            <FAQ data={whychoosePoints} />
          </Grid>
        </Grid>
      </Box> */}

      <Box className="awards-section mb-margin1" marginBottom={"80px"}>
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12}>
            <h2 className="text-center mb-12">Awards & Recognition </h2>
          </Grid>
          <Grid xs={12}>
            <ul className="awards-list">
              <li>
                <img src="./images/Awards1.png" alt="Awards" />
              </li>
              <li>
                <img src="./images/Awards2.png" alt="Awards" />
              </li>
              <li>
                <img src="./images/Awards3.png" alt="Awards" />
              </li>
              <li>
                <img src="./images/Awards4.png" alt="Awards" />
              </li>
              <li>
                <img src="./images/Awards5.png" alt="Awards" />
              </li>
              <li>
                <img src="./images/Awards6.png" alt="Awards" />
              </li>
              <li>
                <img src="./images/Awards7.png" alt="Awards" />
              </li>
              <li>
                <img src="./images/Awards8.png" alt="Awards" />
              </li>
            </ul>
          </Grid>
        </Grid>
      </Box>

      <Box className="clients-section" marginBottom={"80px"}>
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12} marginBottom={"24px"}>
            <h2 className="text-center mb-3">Our Clients</h2>
          </Grid>
          {/* <Grid xs={12}>
            {isMobile ? (
              <Slider {...partnerssettings}>
                {insurerPartners?.map((data, index) => (
                  <Box padding={"0 12px"}>
                    <Box textAlign={"center"} className="clientBox">
                      <img src="./images/PyroMobilePvtLtd.svg" alt="" />
                    </Box>
                  </Box>
                ))}
              </Slider>
            ) : (
              <ul className="clients_list">
                <li>
                  <img src="./images/clients/" alt="" />
                </li>
                <li>
                  <img src="./images/client2.png" alt="" />
                </li>
                <li>
                  <img src="./images/client3.png" alt="" />
                </li>
                <li>
                  <img src="./images/client4.png" alt="" />
                </li>
                <li>
                  <img src="./images/client5.png" alt="" />
                </li>
                <li>
                  <img src="./images/client6.png" alt="" />
                </li>
                <li>
                  <img src="./images/client7.png" alt="" />
                </li>
                <li>
                  <img src="./images/client8.png" alt="" />
                </li>
                <li>
                  <img src="./images/client9.png" alt="" />
                </li>
                <li>
                  <img src="./images/client10.png" alt="" />
                </li>
                <li>
                  <img src="./images/client11.png" alt="" />
                </li>
                <li>
                  <img src="./images/client12.png" alt="" />
                </li>
                <li>
                  <img src="./images/client13.png" alt="" />
                </li>
                <li>
                  <img src="./images/client14.png" alt="" />
                </li>
                <li>
                  <img src="./images/client15.png" alt="" />
                </li>
                <li>
                  <img src="./images/client16.png" alt="" />
                </li>
              </ul>
            )}
          </Grid> */}
          <Grid xs={12}>
            <div className="logos">
              <div className="logos-outer">
                <div className="logos-slide">
                  <span className="logobox">
                    <img
                      src="./images/clients/Administrative_Staff_College_of_India.jpg"
                      alt=""
                    />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/ANVAYAA_KIN_CARE.png" alt="" />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/APSDC.png" alt="" />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/APSSAAT.png" alt="" />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/APTDC.png" alt="" />
                  </span>
                  <span className="logobox">
                    <img
                      src="./images/clients/AVANTHI_WAREHOUSING_SERVICE_PRIVATE_LIMITED.png"
                      alt=""
                    />
                  </span>
                  <span className="logobox">
                    <img
                      src="./images/clients/avantika_contractors_ltd.webp"
                      alt=""
                    />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/BEKEM.jpg" alt="" />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/Cyient.jpg" alt="" />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/ez.png" alt="" />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/axis.png" alt="" />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/ASTRA MICROWAVE.png" alt="" />
                  </span>

                  <span className="logobox">
                    <img
                      src="./images/clients/Administrative_Staff_College_of_India.jpg"
                      alt=""
                    />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/ANVAYAA_KIN_CARE.png" alt="" />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/APSDC.png" alt="" />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/APSSAAT.png" alt="" />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/APTDC.png" alt="" />
                  </span>
                  <span className="logobox">
                    <img
                      src="./images/clients/AVANTHI_WAREHOUSING_SERVICE_PRIVATE_LIMITED.png"
                      alt=""
                    />
                  </span>
                  <span className="logobox">
                    <img
                      src="./images/clients/avantika_contractors_ltd.webp"
                      alt=""
                    />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/BEKEM.jpg" alt="" />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/Cyient.jpg" alt="" />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/ez.png" alt="" />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/axis.png" alt="" />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/ASTRA MICROWAVE.png" alt="" />
                  </span>
                </div>
              </div>
            </div>

            <div className="logos">
              <div className="logos-outer">
                <div className="logos-slide">
                  <span className="logobox">
                    <img src="./images/clients/AGENCO.png" alt="" />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/Green_park.png" alt="" />
                  </span>
                  <span className="logobox">
                    <img
                      src="./images/clients/Hyderabad_Metropolitan_Water_Supply_and_Sewerage_Board (HMWSSB).jpg"
                      alt=""
                    />
                  </span>
                  <span className="logobox">
                    <img
                      src="./images/clients/hyundai_motor_india_engineering_pvt_ltd.png"
                      alt=""
                    />
                  </span>
                  <span className="logobox">
                    <img
                      src="./images/clients/indian_immunologicals_ltd.jpg"
                      alt=""
                    />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/Indus.png" alt="" />
                  </span>
                  <span className="logobox">
                    <img
                      src="./images/clients/Jagati_Publications_Ltd.jpg"
                      alt=""
                    />
                  </span>
                  <span className="logobox">
                    <img
                      src="./images/clients/jodas_expoim_pvt_ltd.png"
                      alt=""
                    />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/Lanco_hoskate.gif" alt="" />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/Living_avaenue.jpg" alt="" />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/Mak.jpg" alt="" />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/MSD_technologies.png" alt="" />
                  </span>
                  <span className="logobox">
                    <img
                      src="./images/clients/Musaddilal_Jewellers.png"
                      alt=""
                    />
                  </span>

                  <span className="logobox">
                    <img src="./images/clients/AGENCO.png" alt="" />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/Green_park.png" alt="" />
                  </span>
                  <span className="logobox">
                    <img
                      src="./images/clients/Hyderabad_Metropolitan_Water_Supply_and_Sewerage_Board (HMWSSB).jpg"
                      alt=""
                    />
                  </span>
                  <span className="logobox">
                    <img
                      src="./images/clients/hyundai_motor_india_engineering_pvt_ltd.png"
                      alt=""
                    />
                  </span>
                  <span className="logobox">
                    <img
                      src="./images/clients/indian_immunologicals_ltd.jpg"
                      alt=""
                    />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/Indus.png" alt="" />
                  </span>
                  <span className="logobox">
                    <img
                      src="./images/clients/Jagati_Publications_Ltd.jpg"
                      alt=""
                    />
                  </span>
                  <span className="logobox">
                    <img
                      src="./images/clients/jodas_expoim_pvt_ltd.png"
                      alt=""
                    />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/Lanco_hoskate.gif" alt="" />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/Living_avaenue.jpg" alt="" />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/Mak.jpg" alt="" />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/MSD_technologies.png" alt="" />
                  </span>
                  <span className="logobox">
                    <img
                      src="./images/clients/Musaddilal_Jewellers.png"
                      alt=""
                    />
                  </span>
                </div>
              </div>
            </div>

            <div className="logos">
              <div className="logos-outer">
                <div className="logos-slide">
                  <span className="logobox">
                    <img src="./images/clients/Pyro_mobile.png" alt="" />
                  </span>
                  <span className="logobox">
                    <img
                      src="./images/clients/Rane Brakelining Limited.png"
                      alt=""
                    />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/Ritwik.jpg" alt="" />
                  </span>
                  <span className="logobox">
                    <img
                      src="./images/clients/Rooshna_constructions.jpg"
                      alt=""
                    />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/saro_Rubber.jpg" alt="" />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/stv.jpg" alt="" />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/surana_group.jpg" alt="" />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/SVIMS.png" alt="" />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/tatras.jpg" alt="" />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/tirumala_tirupati.png" alt="" />
                  </span>
                  <span className="logobox">
                    <img
                      src="./images/clients/zr_renewable_energy.jpg"
                      alt=""
                    />
                  </span>

                  <span className="logobox">
                    <img src="./images/clients/Pyro_mobile.png" alt="" />
                  </span>
                  <span className="logobox">
                    <img
                      src="./images/clients/Rane Brakelining Limited.png"
                      alt=""
                    />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/Ritwik.jpg" alt="" />
                  </span>
                  <span className="logobox">
                    <img
                      src="./images/clients/Rooshna_constructions.jpg"
                      alt=""
                    />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/saro_Rubber.jpg" alt="" />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/stv.jpg" alt="" />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/surana_group.jpg" alt="" />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/SVIMS.png" alt="" />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/tatras.jpg" alt="" />
                  </span>
                  <span className="logobox">
                    <img src="./images/clients/tirumala_tirupati.png" alt="" />
                  </span>
                  <span className="logobox">
                    <img
                      src="./images/clients/zr_renewable_energy.jpg"
                      alt=""
                    />
                  </span>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>

      <Box className="sectors-section mb-margin1" marginBottom={"80px"}>
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12} marginBottom={"24px"}>
            <h2 className="text-center mb-3">Sectors </h2>
            <p className="text-center">
              At Amaze Insurance Brokers, we specialize in providing insurance
              solutions across a wide array of sectors, ensuring that businesses
              of all types and sizes have access to comprehensive coverage and
              risk management strategies. Our expertise spans:
            </p>
          </Grid>
        </Grid>
        <Grid container className="row" columnSpacing={3} alignItems={"center"}>
          <Grid xs={6} md={2} className="mb-margin2">
            <ul className="sectors_list">
              <li>
                <img src="./images/government_org_sector.svg" alt="" />
                <p>Government & Government Organizations </p>
              </li>
              <li>
                <img src="./images/hospitality_sector.svg" alt="" />
                <p>Hospitality </p>
              </li>
              <li>
                <img src="./images/retail_sector.svg" alt="" />
                <p>Retail</p>
              </li>
            </ul>
          </Grid>
          <Grid xs={6} md={2} className="mb-margin2">
            <ul className="sectors_list">
              <li>
                <img src="./images/pharma_sector.svg" alt="" />
                <p>Pharma & Biotech </p>
              </li>
              <li>
                <img src="./images/it_sector.svg" alt="" />
                <p>IT & ITES</p>
              </li>
            </ul>
          </Grid>
          <Grid xs={12} md={4} textAlign={"center"} className="mb-margin2">
            <img src="./images/sectors_bg.svg" alt="" className="m-auto" />
          </Grid>
          <Grid xs={6} md={2}>
            <ul className="sectors_list">
              <li>
                <img src="./images/power_sector.svg" alt="" />
                <p>Power Sector </p>
              </li>
              <li>
                <img src="./images/rural_insurance_sector.svg" alt="" />
                <p>Rural insurance </p>
              </li>
            </ul>
          </Grid>
          <Grid xs={6} md={2}>
            <ul className="sectors_list">
              <li>
                <img src="./images/project_insurance_sector.svg" alt="" />
                <p>Project Insurance </p>
              </li>
              <li>
                <img src="./images/sme_sector.svg" alt="" />
                <p>SME’S</p>
              </li>
              <li>
                <img src="./images/manufacturing_sector.svg" alt="" />
                <p>Manufacturing</p>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Box>

      <Box className="partners-section mb-margin1" marginBottom={"80px"}>
        <Grid
          container
          className="row"
          columnSpacing={3}
          alignItems={"center"}
          marginBottom={"24px"}
        >
          <Grid xs={12} md={8} className="mb-margin2">
            <h2 className="mb-3">Our Partners</h2>

            <p>
              At Amaze Insurance Brokers, we take pride in our extensive network
              of partnerships with all major insurance companies in India. These
              partnerships allow us to offer you unparalleled access to a wide
              range of insurance products and services tailored to meet your
              unique needs.
            </p>
            <p>
              As insurance brokers, our allegiance lies with you, our valued
              clients. We work tirelessly to understand your goals, assess your
              risks, and recommend the most suitable insurance solutions from
              our partner insurers. With access to multiple carriers, we have
              the flexibility to negotiate competitive rates and secure
              comprehensive coverage options on your behalf.
            </p>
            <p>
              Our partnerships with insurance companies extend beyond mere
              transactions – they are built on a foundation of trust,
              transparency, and mutual respect. We collaborate closely with our
              partner insurers to stay abreast of the latest industry trends,
              regulatory changes, and emerging risks, ensuring that you receive
              the highest level of expertise and service.
            </p>
            <p>
              Whether you're seeking coverage for your home, business, vehicle,
              or health, you can trust that our partnerships with leading
              insurers enable us to deliver tailored insurance solutions that
              provide peace of mind and financial security. With Amaze Insurance
              Brokers by your side, you can rest assured that you have a trusted
              advisor and advocate working tirelessly to protect what matters
              most to you.
            </p>
            <p>
              Experience the power of partnership with Amaze Insurance Brokers.
              Contact us today to explore our comprehensive insurance offerings
              and discover how we can help safeguard your assets and interests.
            </p>
          </Grid>
          <Grid xs={12} md={4} className="mb-margin2">
            <img
              src="./images/our-partners-img.svg"
              alt=""
              className="partners_main_img"
            />
          </Grid>
        </Grid>
        <Grid
          container
          className="row"
          columnSpacing={3}
          alignItems={"center"}
          marginBottom={"24px"}
        >
          <Grid xs={12}>
            {isMobile ? (
              <Slider {...partnerssettings}>
                {insurerPartners?.map((data, index) => (
                  <Box padding={"0 12px"}>
                    <Box textAlign={"center"} className="partnerBox">
                      <img src={data} alt="" className="partner_img" />
                    </Box>
                  </Box>
                ))}
              </Slider>
            ) : (
              <ul>
                {insurerPartners.map((data) => (
                  <li>
                    <img src={data} alt="" />
                  </li>
                ))}
              </ul>
            )}
          </Grid>
        </Grid>
      </Box>

      <Box className="testimonial-section mb-margin1" marginBottom={"80px"}>
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12} marginBottom={"40px"} className="mb-margin2">
            <h2 className="text-center">What People say about us </h2>
          </Grid>
        </Grid>
        <Grid container className="row" columnSpacing={3} alignItems={"center"}>
          <Grid xs={12}>
            <Slider {...settings}>
              {testimonialsArray?.map((data, index) => (
                <Box className="testmonial_box">
                  <Box textAlign={"center"}>
                    <Box className="reviewer_outer_profile_box">
                      <img
                        src={data.reviewer_img}
                        alt=""
                        className="reviewer_profile_pic"
                      />
                    </Box>
                    <p className="rev_name">{data.reviewer_name}</p>
                    {/* <p
                      style={{
                        fontSize: "12px",
                        lineHeight: "18px",
                        fontWeight: 500,
                        marginBottom: "12px",
                      }}
                    >
                      {data.reviewer_loc}
                    </p>
                    <Rating
                      name="simple-controlled"
                      value={value}
                      defaultValue={data.ratingPoints}
                      precision={0.5}
                      onChange={(event, newValue) => {
                        setValue(newValue);
                      }}
                      readOnly
                    /> */}
                  </Box>

                  <Box className="review_box">
                    <p>{data.review}</p>
                  </Box>
                </Box>
              ))}
            </Slider>
          </Grid>
        </Grid>
      </Box>

      {/* <Box className="faq-section mb-margin1" marginBottom={"80px"}>
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12} md={6} className="mb-margin2">
            <h2 className="mb-6">Any Question ?</h2>
            <Box>
              <img src="./images/faq_sec_img.svg" alt="" />
            </Box>
          </Grid>
          <Grid xs={12} md={6}>
            <FAQ data={faqArray} />
          </Grid>
        </Grid>
      </Box> */}

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default HomePage;
