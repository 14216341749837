import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../../Components/Desktop/Navbar/Navbar";
import MFooter from "../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../Components/Mobile/MNavBar/MNavBar";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import "./../../../../SCSS/ProductPages.scss";
import PPAccordionComponent from "../../../../Components/PPAccordionComponent/PPAccordionComponent";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const RuralInsurance = () => {
  const isMobile = useIsMobile();

  const coveragePointsArray = [
    {
      title: "Coverage:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              Cattle Insurance provides coverage for losses arising from death,
              theft, or injury to cattle owned by rural farmers and livestock
              owners. It safeguards against financial losses due to unforeseen
              events such as accidents, diseases, or natural disasters.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Wordings: ",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              The policy specifies the coverage terms, conditions, and
              exclusions related to cattle insurance. It outlines the insured
              perils, policy duration, premium rates, and claims procedure.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Special Features:  ",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Comprehensive Coverage:</span> Protects against various
              risks, including accidental death, illness, surgical expenses, and
              theft of cattle.
            </li>
            <li>
              <span>Flexible Policy Options: </span> Offers customizable
              coverage options based on the breed, age, and value of cattle.
            </li>
            <li>
              <span>Loss of Income Coverage: </span>
              Some policies may include coverage for loss of income resulting
              from the inability to use or breed cattle due to insured perils.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Enhancements:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Increased Coverage Limits:</span> Policyholders can opt for
              higher coverage limits to ensure adequate protection for
              high-value cattle breeds or herds.
            </li>
            <li>
              <span>Additional Insureds:</span>
              Allows for the inclusion of additional insured parties, such as
              farm workers or caretakers, under the policy for broader
              protection.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Additional Covers:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Calving Risk Insurance:</span>
              Covers losses due to complications during calving, including
              stillbirths, dystocia, or postpartum health issues.
            </li>
            <li>
              <span>Livestock Transit Insurance:</span>
              Extends coverage to cattle during transportation, protecting
              against accidents, injuries, or theft during transit.
            </li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="subProducts_pp">
          <Grid container columnSpacing={3} className="row">
            <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
              <h1>Rural Insurance Policies</h1>
            </Grid>
          </Grid>

          <Box className="coverages-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom={"24px"}>
                <h2>Cattle Insurance</h2>
              </Grid>
              <Grid xs={12}>
                <PPAccordionComponent data={coveragePointsArray} />
              </Grid>
            </Grid>
          </Box>

          <Box className="excluison_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} className="mb-margin2">
                <h2 className="mb-3" style={{ textAlign: "center" }}>
                  Exclusions
                </h2>
              </Grid>
              <Grid xs={12}>
                <p style={{ textAlign: "center" }}>
                  Cattle Insurance typically excludes coverage for pre-existing
                  conditions, intentional acts, negligence, and specific
                  exclusions outlined in the policy wording.
                </p>
              </Grid>
            </Grid>
          </Box>

          <Box className="termsConditions_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} className="mb-margin2">
                <h2 className="mb-3" style={{ textAlign: "center" }}>
                  Terms and Conditions
                </h2>
              </Grid>
              <Grid xs={12}>
                <p style={{ textAlign: "center" }}>
                  The policy's terms and conditions specify the rights and
                  obligations of both the insurer and the insured. These include
                  premium payment terms, coverage duration, claims reporting
                  procedures, and policy renewal conditions.
                </p>
              </Grid>
            </Grid>
          </Box>

          <Box className="claimProcedure-section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} className="mb-margin2">
                <h2 className="mb-3" style={{ textAlign: "center" }}>
                  Claims Procedure
                </h2>
              </Grid>
              <Grid xs={12}>
                <p style={{ textAlign: "center" }}>
                  In the event of a claim, policyholders must notify the insurer
                  promptly and provide necessary documentation, such as
                  veterinary reports, proof of ownership, and incident details.
                  The insurer will then assess the claim, verify the losses, and
                  facilitate the claims settlement process in accordance with
                  the policy terms.
                </p>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};


export default RuralInsurance;
