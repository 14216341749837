import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../../../Components/Desktop/Navbar/Navbar";
import MFooter from "../../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../../Components/Mobile/MNavBar/MNavBar";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import "./../../../../../SCSS/ProductPages.scss";
import PPAccordionComponent from "../../../../../Components/PPAccordionComponent/PPAccordionComponent";
import ReadMoreText from "../../../../../Components/ReadMoreTextComponent/ReadMoreText";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const ALOPInsurance = () => {
  const isMobile = useIsMobile();

  const coveragePointsArray = [
    {
      title: "Coverage:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Delay in Start-Up Costs:</span> Covers financial losses due
              to delays in project completion caused by insured perils,
              including additional expenses incurred to expedite project
              completion.
            </li>
            <li>
              <span>Lost Revenue: </span>Reimburses lost revenue or income
              resulting from the delay in the commencement of business
              operations.
            </li>
            <li>
              <span>Additional Costs:</span> Covers additional costs incurred
              for temporary relocation, overtime wages, and other expenses to
              mitigate the delay.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Additional Covers:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Extended Maintenance Cover:</span> Extends coverage beyond
              the completion of the project during the maintenance period,
              typically up to 12 months, to cover defects, errors, or omissions
              in the insured project.
            </li>
            <li>
              <span>Terrorism Cover:</span> Extends coverage to include loss or
              damage caused by acts of terrorism, sabotage, or political
              violence.
            </li>
            <li>
              <span>Third-party Liability:</span> Extends coverage to include
              legal liabilities arising from bodily injury or property damage to
              third parties caused by the insured's construction activities.{" "}
            </li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="subProducts_pp">
          <Grid container columnSpacing={3} className="row">
            <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
              <h1>Advance Loss of Profit Insurance (ALOP)</h1>
            </Grid>
          </Grid>

          <Box className="alopTop-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12}>
                <ul className="alop_list">
                  <li>
                    <h6>01</h6>
                    <h4>Overview</h4>

                    <ReadMoreText
                      text={
                        "Advance Loss of Profit (ALOP) Insurance, also known as Delay in Start-Up (DSU) Insurance, is a specialized insurance product designed to protect businesses from financial losses incurred due to delays in the commencement of business operations caused by insured perils. ALOP insurance is essential for businesses involved in construction, infrastructure projects, manufacturing, and other ventures where project delays can result in significant financial repercussions."
                      }
                      maxlength={150}
                    />
                  </li>
                  <li>
                    <h6>02</h6>
                    <h4>Purpose and Scope</h4>

                    <ReadMoreText
                      text={`ALOP insurance covers financial losses arising from delays in project completion due to insured perils, such as fire, flood, natural disasters, equipment breakdown, or other unforeseen events.
It aims to reimburse the insured for lost revenue, increased costs, and additional expenses incurred during the delayed period, allowing the business to maintain its financial stability.
`}
                      maxlength={150}
                    />
                  </li>
                  <li>
                    <h6>03</h6>
                    <h4>Coverage Period</h4>

                    <ReadMoreText
                      text={
                        "ALOP insurance typically provides coverage from the date of the insured event (e.g., fire, flood) until the project reaches a state of readiness to commence business operations or the predetermined maximum indemnity period expires."
                      }
                      maxlength={150}
                    />
                  </li>
                  <li>
                    <h6>04</h6>
                    <h4>Indemnity Period</h4>

                    <ReadMoreText
                      text={`The indemnity period is the maximum duration for which the insurer will indemnify the insured for covered losses, usually ranging from 12 to 36 months, depending on the policy terms.
It is essential to select an appropriate indemnity period that reflects the time required to recover from the delay and resume normal business operations.
`}
                      maxlength={150}
                    />
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>

          <Box className="coverages-section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md className="mb-margin2">
                <PPAccordionComponent data={coveragePointsArray} />
              </Grid>
              <Grid xs={12} md={"auto"} margin={"auto"}>
                <img
                  src="/images/marine_cargo_ip_img.svg"
                  alt=""
                  style={{
                    width: "100%",
                    maxWidth: isMobile ? "200px" : "275px",
                    margin: "auto",
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="claimProcedure-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom={"24px"} textAlign={"center"}>
                <h2>Claims Procedure</h2>
              </Grid>
              <Grid xs={12}>
                <ul className="claimProcedure_list">
                  <li>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      marginBottom={"24px"}
                    >
                      <Box textAlign={"left"}>
                        <h4>01</h4>
                        <h6>Immediate Notification</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon1.svg"
                        alt=""
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "In case of a delay or claim, the insured must notify the insurance company or broker as soon as practicable. "
                      }
                      maxlength={100}
                    />
                  </li>
                  <li>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      marginBottom={"24px"}
                    >
                      <Box textAlign={"left"}>
                        <h4>02</h4>
                        <h6>Claim Documentation</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon2.svg"
                        alt=""
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "Submit a detailed claim form along with all relevant documents, including project schedule, financial statements, invoices, and other evidence supporting the claim, to the insurance company."
                      }
                      maxlength={100}
                    />
                  </li>
                  <li>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      marginBottom={"24px"}
                    >
                      <Box textAlign={"left"}>
                        <h4>03</h4>
                        <h6>Claim Evaluation and Settlement:</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon3.svg"
                        alt=""
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "The insurance company will evaluate the claim, conduct an investigation if necessary, and either approve or deny the claim based on the policy terms and conditions. If approved, the insurer will indemnify the insured for covered losses."
                      }
                      maxlength={100}
                    />
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>

          <Box className="termsConditions_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md={8} className="mb-margin2">
                <h2 className="mb-3">Terms and Conditions</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    <span>Policy Period: </span> Typically, the policy is issued
                    for the duration of the construction project, including the
                    construction period and the maintenance period.
                  </li>
                  <li>
                    <span>Limits of Liability:</span> The policy limit is the
                    maximum amount the insurer will pay for covered claims
                    during the policy period, subject to deductibles and
                    sub-limits.
                  </li>
                  <li>
                    <span>Deductibles:</span> A deductible is the amount the
                    insured must pay before the insurance coverage kicks in.
                  </li>
                </ol>
              </Grid>
              <Grid xs={12} md={4}>
                <img
                  src="../images/termsconditions_img.svg"
                  alt=""
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
            </Grid>
          </Box>

          {/* {exclusions   section included in some pages that why i have created here} */}
          <Box className="excluison_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md={4} order={{ xs: 2, md: 1 }}>
                <img
                  src="../images/exclusions_img.svg"
                  alt=""
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
              <Grid
                xs={12}
                md={8}
                className="mb-margin2"
                order={{ xs: 1, md: 2 }}
              >
                <h2 className="mb-3">Exclusions</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    <span>Normal Project Risks: </span> Excludes losses
                    resulting from normal project risks, wear and tear, gradual
                    deterioration, or gradual depreciation.
                  </li>
                  <li>
                    <span>Wilful Acts:</span> Excludes losses caused
                    intentionally by the insured or any of its employees.
                  </li>
                  <li>
                    <span>War and Nuclear Risks:</span> Excludes losses caused
                    due to war, invasion, act of foreign enemy, hostilities,
                    civil war, rebellion, revolution, nuclear risks, or
                    radioactive contamination.
                  </li>
                </ol>
              </Grid>
            </Grid>
          </Box>
          <Box className="excluison_section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} textAlign={"center"}>
                <h2>Calculation of Loss</h2>
                <p>
                  The calculation of the loss under ALOP insurance is based on
                  the projected revenue and profit that the business would have
                  earned if the project had been completed on time, compared to
                  the actual revenue and profit earned during the delay period.
                  Additional costs incurred to mitigate the delay, such as
                  expedited construction costs, temporary relocation expenses,
                  and overtime wages, are also covered under the policy.
                </p>
              </Grid>
            </Grid>
          </Box>

          <Box className="whyChoose_pp_section">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md={6} className="mb-margin2">
                <h2 className="mb-2">Why Choose Amaze Insurance Brokers?</h2>

                <img
                  src="../images/whychoose_pp_img.svg"
                  alt=""
                  style={{ maxWidth: "450px", width: "100%", margin: "auto" }}
                  className="wchoose_img"
                />
              </Grid>
              <Grid xs={12} md={6}>
                <ul className="whyChoosepp_list">
                  <li>
                    <Box className="wc_box">
                      <img src="../images/wc_img1.svg" alt="" />
                      <h6>Risk Assessment & Mitigation</h6>
                      <p>
                        <ReadMoreText
                          text={
                            "Our experienced insurance professionals play a crucial role in assessing the insured's risk profile, identifying potential delays, and recommending risk mitigation strategies to minimize the likelihood of delays and associated losses. By understanding the insured's business operations, project timeline, and risk exposures, our professionals can tailor ALOP insurance solutions to provide comprehensive coverage and value-added services. "
                          }
                          maxlength={100}
                        />
                      </p>
                    </Box>
                    <Box className="wc_box">
                      <img src="../images/wc_img2.svg" alt="" />
                      <h6>Policy Terms & Conditions</h6>
                      <p>
                        <ReadMoreText
                          text={
                            "ALOP insurance policies contain specific terms, conditions, and exclusions that define the scope of coverage, limits of liability, deductibles, and other policy provisions. Our experienced professionals play a vital role in interpreting and explaining these terms to the insured, ensuring transparency and clarity in understanding the policy coverage and obligations. "
                          }
                          maxlength={100}
                        />
                      </p>
                    </Box>
                  </li>
                  <li>
                    <Box className="wc_box">
                      <img src="../images/wc_img3.svg" alt="" />
                      <h6>Claims Handling and Settlement</h6>
                      <p>
                        <ReadMoreText
                          text={
                            "In the event of a delay, the insured must notify the insurer promptly and submit a detailed claim form supported by relevant documents, such as project schedules, financial statements, invoices, and other evidence of the delay and associated losses.  Our experienced Insurance professionals facilitate the claims handling process, liaise with the insurer on behalf of the insured, and ensure timely and fair claim settlement in accordance with the policy terms and conditions. "
                          }
                          maxlength={100}
                        />
                      </p>
                    </Box>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default ALOPInsurance;
