import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../../Components/Desktop/Navbar/Navbar";
import MFooter from "../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../Components/Mobile/MNavBar/MNavBar";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import "./../../../../SCSS/ProductPages.scss";

const MiscellaneousClass = () => {
  const isMobile = useIsMobile();

  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="main_pp">
          <Grid
            container
            columnSpacing={3}
            className="row mb-margin1"
            marginBottom={"80px"}
          >
            <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
              <h2 className="mb-3">
                Miscellaneous Insurance: Versatile Solutions for Unique Risks
              </h2>
              <p>
                At Amaze Insurance Brokers, we understand that not all risks fit
                neatly into predefined categories. That’s where our
                Miscellaneous Insurance solutions step in. Designed to cater to
                specialized needs, these policies provide businesses with the
                confidence to navigate uncertainties, no matter how unique they
                may be.
              </p>
            </Grid>
            <Grid xs={12} textAlign={"center"}>
              <h4 className="mb-3">Explore Our Key Covers:</h4>
            </Grid>
            <Grid xs={12} className="mb-4">
              <h5 className="mb-3">Money Insurance</h5>
              <p>
                Safeguard your cash in transit or at premises against theft,
                loss, or unforeseen incidents. Whether it’s daily operations or
                special transactions, ensure peace of mind for your financial
                assets.
              </p>
            </Grid>
            <Grid xs={12} className="mb-4">
              <h5 className="mb-3">Fidelity Guarantee Insurance</h5>
              <p>
                Protect your organization against financial losses caused by
                dishonest acts of employees. Secure your trust in the team that
                powers your success.
              </p>
            </Grid>
            <Grid xs={12} className="mb-4">
              <h5 className="mb-3">Plate Glass Insurance</h5>
              <p>
                Your glass installations are more than just aesthetic; they are
                an investment. This policy shields your property’s glass
                components from accidental damage, ensuring seamless operations.
              </p>
            </Grid>
            <Grid xs={12} className="mb-4">
              <h5 className="mb-3">Portable Electronic Equipment Insurance</h5>
              <p>
                In today’s digital world, your gadgets drive your business. From
                laptops to other portable devices, protect them against damage,
                theft, or unforeseen accidents.
              </p>
            </Grid>
            <Grid xs={12} className="mb-4">
              <h5 className="mb-3">And More</h5>
              <p>
                Our Miscellaneous Insurance category is as diverse as your
                needs. We’re here to provide tailored protection for risks that
                don’t fit the usual mold.
              </p>
            </Grid>
          </Grid>
          <Box
            className="whyChoose_pp_section mb-margin1"
            marginBottom={"80px"}
          >
            <Grid
              container
              columnSpacing={3}
              className="row mb-6"
              alignItems={"center"}
            >
              <Grid xs={12} md={6} className="mb-margin2">
                <h2>Why Choose Amaze Insurance Brokers?</h2>
                <img
                  src="../images/whychoose_pp_img.svg"
                  alt=""
                  style={{ maxWidth: "450px", width: "100%", margin: "auto" }}
                  className="wchoose_img"
                />
              </Grid>
              <Grid xs={12} md={6}>
                <ul className="whyChoosepp_list">
                  <li>
                    <Box className="wc_box">
                      <img src="../images/wc_img1.svg" alt="" />
                      <h6>Expertise You Trust</h6>
                      <p>Decades of experience, customized for you.</p>
                    </Box>
                    <Box className="wc_box">
                      <img src="../images/wc_img2.svg" alt="" />
                      <h6>Comprehensive Coverage</h6>
                      <p>Address niche risks with precision.</p>
                    </Box>
                  </li>
                  <li>
                    <Box className="wc_box">
                      <img src="../images/wc_img3.svg" alt="" />
                      <h6>Simplified Processes</h6>
                      <p>Streamlined guidance from selection to claims.</p>
                    </Box>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default MiscellaneousClass;
