import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { EB_ROUTES } from "../Path/EBPaths";
import EmployeeBenefitsInurance from "../../Pages/Desktop/ProductPages/EmployeeBenefitsInurance/EmployeeBenefitsInurance";
import GroupPersonalAccidentInsurance from "../../Pages/Desktop/ProductPages/EmployeeBenefitsInurance/GroupPersonalAccidentInsurance/GroupPersonalAccidentInsurance";
import GroupTermLifeInsurance from "../../Pages/Desktop/ProductPages/EmployeeBenefitsInurance/GroupTermLifeInsurance/GroupTermLifeInsurance";
import GroupMedicalInsurance from "../../Pages/Desktop/ProductPages/EmployeeBenefitsInurance/GroupMedicalInsurance/GroupMedicalInsurance";
import EmployeeProvidentFund from "../../Pages/Desktop/ProductPages/EmployeeBenefitsInurance/EmployeeProvidentFund/EmployeeProvidentFund";
import EmployeeStateInsurance from "../../Pages/Desktop/ProductPages/EmployeeBenefitsInurance/EmployeeStateInsurance/EmployeeStateInsurance";

function EBROUTES() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Routes>
        <Route
          path={EB_ROUTES.EMPLOYEE_BENEFITS}
          element={<EmployeeBenefitsInurance />}
        />
        <Route path={EB_ROUTES.GPA_INSURANCE} element={<GroupPersonalAccidentInsurance />} />
        <Route path={EB_ROUTES.GTL_INSURANCE} element={<GroupTermLifeInsurance />} />
        <Route path={EB_ROUTES.GRP_MEDICAL_INSURANCE} element={<GroupMedicalInsurance />} />
        <Route path={EB_ROUTES.EPF} element={<EmployeeProvidentFund />} />
        <Route path={EB_ROUTES.ESI} element={<EmployeeStateInsurance />} />
      </Routes>
    </div>
  );
}

export default EBROUTES;
