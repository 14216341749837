import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../../../Components/Desktop/Navbar/Navbar";
import MFooter from "../../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../../Components/Mobile/MNavBar/MNavBar";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import "./../../../../../SCSS/ProductPages.scss";
import PPAccordionComponent from "../../../../../Components/PPAccordionComponent/PPAccordionComponent";
import ReadMoreText from "../../../../../Components/ReadMoreTextComponent/ReadMoreText";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const GroupPersonalAccidentInsurance = () => {
  const isMobile = useIsMobile();

  const coveragePointsArray = [
    {
      title: "Coverage:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Accidental Death:</span> Provides a lump-sum benefit to the
              nominee in case of the insured's death due to an accident.
            </li>
            <li>
              <span>Permanent Total Disability (PTD): </span> Offers
              compensation in case of total and permanent disability due to an
              accident, rendering the insured incapable of performing any
              occupation.
            </li>
            <li>
              <span>Permanent Partial Disability (PPD): </span> Provides a
              percentage of the sum insured in case of partial and permanent
              disability due to an accident, such as loss of limb, vision, or
              hearing.
            </li>
            <li>
              <span>Temporary Total Disability (TTD): </span> Offers weekly
              compensation for temporary total disability, which prevents the
              insured from working for a specified period due to an accident.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Special Features:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Worldwide Coverage:</span> Provides coverage for accidents
              occurring anywhere in the world, including travel for business
              purposes.
            </li>
            <li>
              <span>Education Benefit: </span> Some policies offer an education
              grant for dependent children in case of the insured's accidental
              death or permanent total disability.
            </li>
            <li>
              <span>Transportation of Mortal Remains: </span> Covers the cost of
              transporting the insured's mortal remains to the place of
              residence or burial site.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Add-on Covers:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Medical Expenses Reimbursement:</span> Reimburses medical
              expenses incurred due to accidental injuries, including
              hospitalization, surgery, and medication.
            </li>
            <li>
              <span>Funeral Expenses: </span> Covers funeral and burial expenses
              in case of accidental death.
            </li>
            <li>
              <span>Temporary Partial Disability: </span> Offers compensation
              for temporary partial disability, allowing the insured to work in
              a reduced capacity due to an accident.
            </li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="subProducts_pp">
          <Grid
            container
            columnSpacing={3}
            className="row"
            marginBottom={"24px"}
          >
            <Grid xs={12} md className="mb-margin2">
              <h1>
                Group Personal Accident Insurance Policy: Protecting Employees
                Against Life's Uncertainties
              </h1>
            </Grid>
            <Grid xs={12} md={"auto"} margin={"auto"}>
              <img
                src="/images/grp_personal_accident.svg"
                alt=""
                style={{
                  width: "100%",
                  maxWidth: isMobile ? "220px" : "305px",
                  margin: "auto",
                }}
              />
            </Grid>
          </Grid>

          <Box className="coverages-section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} className="mb-margin2">
                <PPAccordionComponent data={coveragePointsArray} />
              </Grid>
            </Grid>
          </Box>

          <Box className="claimProcedure-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom={"24px"} textAlign={"center"}>
                <h2>Claims Procedure</h2>
              </Grid>
              <Grid xs={12}>
                <ul className="claimProcedure_list">
                  <li>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      marginBottom={"24px"}
                    >
                      <Box textAlign={"left"}>
                        <h4>01</h4>
                        <h6>Immediate Notification</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon1.svg"
                        alt=""
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "In the event of an accident resulting in bodily injury, the insured or the employer must notify the insurance company or TPA (Third-Party Administrator) as soon as possible."
                      }
                      maxlength={100}
                    />
                  </li>
                  <li>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      marginBottom={"24px"}
                    >
                      <Box textAlign={"left"}>
                        <h4>02</h4>
                        <h6>Claim Documentation</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon2.svg"
                        alt=""
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "Submit a duly filled claim form along with medical reports, hospital bills, police report (if applicable), and other necessary documents to the TPA or insurance company."
                      }
                      maxlength={100}
                    />
                  </li>
                  <li>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      marginBottom={"24px"}
                    >
                      <Box textAlign={"left"}>
                        <h4>03</h4>
                        <h6>Claim Settlement</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon3.svg"
                        alt=""
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "Upon verification and approval of the claim, the insurance company will settle the claim amount directly with the insured (or nominee) as per the policy terms and coverage limits."
                      }
                      maxlength={100}
                    />
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>

          <Box className="termsConditions_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md={8} className="mb-margin2">
                <h2 className="mb-3">Terms and Conditions</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    <span>Policy Period: </span> Typically, the policy is issued
                    for a one-year period and needs to be renewed annually.
                  </li>
                  <li>
                    <span>Sum Insured:</span> The sum insured is based on the
                    company's group size and the chosen coverage amount per
                    employee.
                  </li>
                  <li>
                    <span>Premium Payment: </span> Premiums are payable
                    annually, and the cost is shared between the employer and
                    employees based on the policy terms.
                  </li>
                  <li>
                    <span>Age Limit:</span> Coverage is usually available for
                    employees aged between 18 and 65 years.
                  </li>
                </ol>
              </Grid>
              <Grid xs={12} md={4}>
                <img
                  src="../images/termsconditions_img.svg"
                  alt=""
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="excluison_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md={4} order={{ xs: 2, md: 1 }}>
                <img
                  src="../images/exclusions_img.svg"
                  alt=""
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
              <Grid
                xs={12}
                md={8}
                className="mb-margin2"
                order={{ xs: 1, md: 2 }}
              >
                <h2 className="mb-3">Exclusions</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    <span>Self-inflicted Injuries:</span> Injuries resulting
                    from attempted suicide or self-harm.
                  </li>
                  <li>
                    <span>War and Terrorism:</span> Injuries or death due to
                    war, invasion, act of foreign enemy, hostilities, civil war,
                    rebellion, revolution, etc.
                  </li>
                  <li>
                    <span>Participation in Hazardous Activities:</span>
                    Injuries sustained while participating in hazardous
                    activities such as skydiving, mountaineering, racing, etc.,
                    unless specifically covered.
                  </li>
                  <li>
                    <span>Pre-existing Disabilities:</span> Disabilities
                    existing prior to the inception of the policy.
                  </li>
                </ol>
              </Grid>
            </Grid>
          </Box>

          <Box className="excluison_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} textAlign={"center"}>
                <h2 className="mb-3">Conclusion</h2>
                <p>
                  A Group Personal Accident Insurance Policy is an essential
                  Employee Benefit offered by big corporates in India to
                  safeguard their employees against the financial repercussions
                  of accidental injuries or death. By providing comprehensive
                  coverage, special features, and add-on covers, companies can
                  create a safety net that offers peace of mind to employees and
                  their families during challenging times.
                </p>
              </Grid>
            </Grid>
          </Box>

          <Box className="whyChoose_pp_section">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md={6} className="mb-margin2">
                <h2>Why Choose Amaze Insurance Brokers?</h2>
                <img
                  src="../images/whychoose_pp_img.svg"
                  alt=""
                  style={{ maxWidth: "450px", width: "100%", margin: "auto" }}
                  className="wchoose_img"
                />
              </Grid>
              <Grid xs={12} md={6}>
                <ul className="whyChoosepp_list">
                  <li>
                    <Box className="wc_box">
                      <img src="../images/wc_img1.svg" alt="" />
                      <h6>Expert Guidance</h6>
                      <p>
                        Our experienced professionals will design the best
                        coverage for your employees under Group Personal
                        Accident Insurance.
                      </p>
                    </Box>
                    <Box className="wc_box">
                      <img src="../images/wc_img2.svg" alt="" />
                      <h6>Seamless Claims Assistance</h6>
                      <p>
                        We provide comprehensive support throughout the claims
                        process.
                      </p>
                    </Box>
                  </li>
                  <li>
                    <Box className="wc_box">
                      <img src="../images/wc_img3.svg" alt="" />
                      <h6>Competitive Quotes</h6>
                      <p>
                        We work with leading insurance companies to secure the
                        best deals for you.
                      </p>
                    </Box>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default GroupPersonalAccidentInsurance;
