import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../../../Components/Desktop/Navbar/Navbar";
import MFooter from "../../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../../Components/Mobile/MNavBar/MNavBar";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import "./../../../../../SCSS/ProductPages.scss";
import PPAccordionComponent from "../../../../../Components/PPAccordionComponent/PPAccordionComponent";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useState } from "react";
import Slider from "react-slick";
import ReadMoreText from "../../../../../Components/ReadMoreTextComponent/ReadMoreText";

const AviationHullInsurance = () => {
  const isMobile = useIsMobile();

  const coveragePointsArray = [
    {
      title: "Coverage:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              {" "}
              Aviation Hull Insurance provides coverage for physical damage to
              the aircraft itself, including the airframe, engines, and other
              components. It typically covers loss or damage caused by various
              perils such as accidents, collisions, fire, lightning, explosion,
              and certain natural disasters.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Wordings:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              The policy contains specific wordings detailing the scope of
              coverage, conditions, and exclusions. It's essential to review
              these wordings carefully to understand the extent of protection
              offered under the policy.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Special Features:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span> Comprehensive Coverage:</span> Protects against a wide
              range of risks, ensuring financial security for the aircraft
              owner/operator.
            </li>
            <li>
              <span>Worldwide Coverage:</span> Coverage extends globally,
              providing protection during flights across different countries and
              territories.{" "}
            </li>
            <li>
              <span>Tailored Policies:</span> Policies can be customized to suit
              the unique needs and requirements of individual aircraft
              owners/operators.{" "}
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Enhancements:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Additional Perils Coverage:</span> Optional coverage
              enhancements may include coverage for war risks, terrorism,
              hijacking, and other specific perils.
            </li>
            <li>
              <span>Increased Limits:</span> Policyholders can opt for increased
              coverage limits to ensure adequate protection for high-value
              aircraft.
            </li>
            <li>
              <span>Deductible Options:</span> Flexibility in choosing
              deductible amounts allows policyholders to manage premiums
              effectively.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Additional Covers:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Spare Parts Coverage:</span> Extends coverage to spare parts
              and equipment essential for aircraft maintenance and operation.
            </li>
            <li>
              <span>In-flight Coverage:</span> Provides coverage for damage or
              loss that occurs during flight operations.
            </li>
            <li>
              <span>Liability Coverage:</span> Optional liability coverage may
              be available to protect against third-party claims arising from
              aircraft operations.
            </li>
          </ul>
        </>
      ),
    },
  ];

  const whychoosePoints = [
    {
      innerArray: [
        {
          img_url: "../images/wc_img1.svg",
          main_head: "Access to Leading Insurers:",
          desc: `As licensed insurance brokers, we have established strong partnerships with leading insurers in the aviation sector. This gives our clients access to a wide range of insurance products and ensures they receive competitive premiums and favorable policy terms.`,
        },

        {
          img_url: "../images/wc_img3.svg",
          main_head: "Expertise in Aviation Insurance:",
          desc: `At Amaze Insurance Brokers, we specialize in aviation insurance and have extensive experience in providing tailored solutions for aircraft owners and operators. Our team of experts understands the unique risks and challenges faced by the aviation industry, allowing us to offer comprehensive coverage that meets your specific needs. `,
        },
        {
          img_url: "../images/wc_img4.svg",
          main_head: "Customized Coverage Solutions:",
          desc: `We recognize that every aircraft operation is unique, and one-size-fits-all insurance solutions may not suffice. That's why we take the time to understand your individual requirements and tailor insurance policies to provide the right level of coverage for your aircraft assets. `,
        },
        {
          img_url: "../images/wc_img2.svg",
          main_head: "Comprehensive Protection:",
          desc: `With Amaze Insurance Brokers, you can expect comprehensive protection for your aviation assets. Our Aviation Hull Insurance policies cover physical damage to the aircraft, including the airframe, engines, and other components, ensuring financial security in the event of accidents, collisions, or other covered perils. `,
        },
      ],
    },
    {
      innerArray: [
        {
          img_url: "../images/wc_img7.svg",
          main_head: "Personalized Service:",
          desc: `We believe in providing personalized service and building long-term relationships with our clients. Our dedicated team of insurance professionals is committed to offering expert advice, guiding you through the insurance process, and providing ongoing support to address your evolving needs. `,
        },
        {
          img_url: "../images/wc_img6.svg",
          main_head: "Efficient Claims Handling:",
          desc: `In the unfortunate event of a claim, you can rely on Amaze Insurance Brokers to facilitate a smooth and efficient claims process. Our experienced claims specialists will work tirelessly on your behalf, liaising with insurers and handling all aspects of the claims procedure to ensure a prompt and fair settlement. `,
        },
        {
          img_url: "../images/wc_img5.svg",
          main_head: "Transparent Communication",
          desc: `Transparency is at the core of our business values. We believe in fostering open and honest communication with our clients, providing clear and accurate information about insurance products, coverage options, terms, and conditions, empowering you to make informed decisions. `,
        },
        {
          img_url: "../images/wc_img8.svg",
          main_head: "Commitment to Excellence:",
          desc: ` At Amaze Insurance Brokers, we are committed to excellence in everything we do. Whether you're insuring a single aircraft or managing a fleet, you can trust us to deliver superior service, innovative solutions, and peace of mind, every step of the way. `,
        },
      ],
    },
  ];

  const [settings] = useState({
    className: "center",
    // centerMode: true,
    infinite: true,
    // centerPadding: "200px",
    slidesToShow: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 2000,
    // cssEase: "linear",
    arrows: true,
    dots: true,
    responsive: [
      {
        breakpoint: 1024, // For tablets and above
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600, // For mobile devices
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });

  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="subProducts_pp">
          <Grid container columnSpacing={3} className="row">
            <Grid xs={12} textAlign={"center"} marginBottom="24px">
              <h1>Aviation Hull Insurance Policy</h1>
            </Grid>
          </Grid>

          <Box className="coverages-section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md className="mb-margin2">
                <PPAccordionComponent data={coveragePointsArray} />
              </Grid>
              <Grid xs={12} md={"auto"} margin={"auto"}>
                <img
                  src="/images/aviation_hull_insurance_img.svg"
                  alt=""
                  style={{
                    width: "100%",
                    maxWidth: isMobile ? "300px" : "414px",
                    margin: "auto",
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="claimProcedure-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom={"24px"} textAlign="center">
                <h2>Claims Procedure</h2>
                <p>
                  In the event of damage or loss, policyholders must promptly
                  notify the insurer and submit a detailed claim report. The
                  insurer will then assess the claim, conduct necessary
                  investigations, and process the claim settlement in accordance
                  with the terms of the policy.
                </p>
              </Grid>
            </Grid>
          </Box>

          <Box className="termsConditions_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems="center"
            >
              <Grid xs={12} md={8} className="mb-margin2">
                <h2 className="mb-3">Terms and Conditions</h2>
                <p>
                  The policy's terms and conditions outline the obligations of
                  both the insurer and the insured. These include premium
                  payment terms, coverage periods, claims procedures, and policy
                  renewal conditions.
                </p>
              </Grid>
              <Grid xs={12} md={4}>
                <img
                  src="../images/termsconditions_img.svg"
                  alt=""
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
            </Grid>
          </Box>

          {/* {exclusions   section included in some pages that why i have created here} */}
          <Box className="excluison_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems="center"
            >
              <Grid xs={12} md={4} order={{ xs: 2, md: 1 }}>
                <img
                  src="../images/exclusions_img.svg"
                  alt=""
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
              <Grid
                xs={12}
                md={8}
                className="mb-margin2"
                order={{ xs: 1, md: 2 }}
              >
                <h2 className="mb-3">Exclusions</h2>
                <p className="mb-3">
                  Aviation Hull Insurance typically excludes coverage for:
                </p>
                <ol type="1" className="ordered-list">
                  <li>Wear and tear</li>
                  <li>Damage due to inadequate maintenance</li>
                  <li>War and related perils (unless specifically endorsed)</li>
                  <li>Consequential losses</li>
                </ol>
              </Grid>
            </Grid>
          </Box>

          <Box className="excluison_section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} textAlign={"center"}>
                <h2>Conclusion</h2>
                <p>
                  Aviation Hull Insurance is essential for aircraft owners and
                  operators to protect their valuable assets against unforeseen
                  risks and liabilities. By partnering with Amaze Insurance
                  Brokers, you gain access to comprehensive coverage options,
                  expert advice, and dedicated support throughout the insurance
                  process. For further inquiries or to explore Aviation Hull
                  Insurance options tailored to your needs, contact us today.
                </p>
              </Grid>
            </Grid>
          </Box>

          <Box className="whyChoose_pp_section">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md={6} className="mb-margin2">
                <h2 className="mb-2">
                  Why Choose Amaze Insurance Brokers for Your Aviation Hull
                  Insurance Policy?
                </h2>
                <p className="mb-6">
                  Expertise in Aviation Insurance: At Amaze Insurance Brokers,
                  we specialize in aviation insurance and have extensive
                  experience in providing tailored solutions for aircraft owners
                  and operators. Our team of experts understands the unique
                  risks and challenges faced by the aviation industry, allowing
                  us to offer comprehensive coverage that meets your specific
                  needs.
                </p>
                <img
                  src="../images/whychoose_pp_img.svg"
                  alt=""
                  style={{ maxWidth: "450px", width: "100%", margin: "auto" }}
                  className="wchoose_img"
                />
              </Grid>
              <Grid xs={12} md={6}>
                {/* <ul className="whyChoosepp_list">
                  <li>
                    <Box className="wc_box">
                      <img src="../images/wc_img1.svg" alt="" />
                      <h6>Expert Guidance</h6>
                      <p>
                        Our experienced professionals will help you choose the
                        right coverage under SFSP for your property.
                      </p>
                    </Box>
                    <Box className="wc_box">
                      <img src="../images/wc_img2.svg" alt="" />
                      <h6>Seamless Claims Assistance</h6>
                      <p>
                        We provide comprehensive support throughout the claims
                        process.
                      </p>
                    </Box>
                  </li>
                  <li>
                    <Box className="wc_box">
                      <img src="../images/wc_img3.svg" alt="" />
                      <h6>Competitive Quotes</h6>
                      <p>
                        We work with leading insurance companies to secure the
                        best deals for you.
                      </p>
                    </Box>
                  </li>
                </ul> */}

                <Slider {...settings} className="mt-6 points-slider">
                  {whychoosePoints?.map((data, index) => (
                    <Box sx={{ padding: "24px 12px" }}>
                      <ul className="whyChoosepp_list2">
                        {data.innerArray.map((innerData, index) => (
                          <li>
                            <Box className="wc_box">
                              <img src={innerData.img_url} alt="" />
                              <h6>{innerData.main_head}</h6>
                              <ReadMoreText
                                text={innerData.desc}
                                maxlength={99}
                              />
                            </Box>
                          </li>
                        ))}
                      </ul>
                    </Box>
                  ))}
                </Slider>
              </Grid>
              <p className="mt-10">
                At Amaze Insurance Brokers, we are committed to providing
                superior Business Interruption Insurance solutions that protect
                your business and help you recover swiftly from unforeseen
                disruptions. Contact us today to learn more about our
                comprehensive coverage options and how we can safeguard your
                business against financial loss.{" "}
              </p>
            </Grid>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default AviationHullInsurance;
