import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { MISC_PATHS } from "../Path/MiscellaneousPaths";
import EmployeeCrimeGuaranteeInsurance from "../../Pages/Desktop/ProductPages/MiscellaneousClass/EmployeeCrimeGuaranteeInsurance/EmployeeCrimeGuaranteeInsurance";
import PlateGlassInsurance from "../../Pages/Desktop/ProductPages/MiscellaneousClass/PlateGlassInsurance/PlateGlassInsurance";
import PortableElectronicEquipmentInsurance from "../../Pages/Desktop/ProductPages/MiscellaneousClass/PortableElectronicEquipmentInsurance/PortableElectronicEquipmentInsurance";
import MoneyInsurancePolicies from "../../Pages/Desktop/ProductPages/MiscellaneousClass/MoneyInsurancePolicies/MoneyInsurancePolicies";
import JewelryShowrooms from "../../Pages/Desktop/ProductPages/MiscellaneousClass/JewelryShowrooms/JewelryShowrooms";
import ShopkeepersInsurance from "../../Pages/Desktop/ProductPages/MiscellaneousClass/ShopkeepersInsurance/ShopkeepersInsurance";
import HouseholderInsurance from "../../Pages/Desktop/ProductPages/MiscellaneousClass/HouseholderInsurance/HouseholderInsurance";
import MiscellaneousClass from "../../Pages/Desktop/ProductPages/MiscellaneousClass/MiscellaneousClass";

function MIscellaneousRoutes() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Routes>
        <Route
          path={MISC_PATHS.MISC_CLASS}
          element={<MiscellaneousClass />}
        />
        <Route
          path={MISC_PATHS.PORTABLE_ELECTRONIC_EQUIPMENT_INSURANCE}
          element={<PortableElectronicEquipmentInsurance />}
        />
        <Route
          path={MISC_PATHS.MONEY_INSURANCE_POLICIES}
          element={<MoneyInsurancePolicies />}
        />
        <Route
          path={MISC_PATHS.EMPLOYEE_CRIME_FIDELITY_GUARANTEE_INSURANCE}
          element={<EmployeeCrimeGuaranteeInsurance />}
        />
        <Route
          path={MISC_PATHS.PLATE_GLASS_INSURANCE_POLICY}
          element={<PlateGlassInsurance />}
        />
      </Routes>
    </div>
  );
}

export default MIscellaneousRoutes;
