import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../../../Components/Desktop/Navbar/Navbar";
import MFooter from "../../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../../Components/Mobile/MNavBar/MNavBar";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import "./../../../../../SCSS/ProductPages.scss";
import PPAccordionComponent from "../../../../../Components/PPAccordionComponent/PPAccordionComponent";
import ReadMoreText from "../../../../../Components/ReadMoreTextComponent/ReadMoreText";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const CyberLiabilityInsurance = () => {
  const isMobile = useIsMobile();

  const coveragePointsArray = [
    {
      title: "Coverage Highlights:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Data Breach Response: </span> Covers the costs associated
              with responding to a data breach, including forensic
              investigations, notification expenses, credit monitoring services
              for affected individuals, and public relations expenses to manage
              reputational damage.
            </li>
            <li>
              <span> Data Loss and Restoration::</span> Provides coverage for
              the costs of restoring or recreating lost or damaged data, as well
              as expenses related to data recovery and business interruption
              resulting from data loss.
            </li>
            <li>
              <span>Cyber Extortion:</span> Protects your business against cyber
              extortion threats, including ransomware attacks, by covering
              ransom payments and expenses incurred in negotiating with cyber
              criminals.{" "}
            </li>
            <li>
              <span>Legal Expenses:</span> Covers legal costs and expenses
              incurred in defending against cyber-related lawsuits, regulatory
              investigations, and fines resulting from data breaches or privacy
              violations.{" "}
            </li>
            <li>
              <span>Third-Party Liability:</span>
              Protects your business against claims and lawsuits brought by
              third parties, such as customers, vendors, or business partners,
              alleging negligence or failure to safeguard sensitive information.{" "}
            </li>
            <li>
              <span>Claims Assistance:</span> In the event of a cyber incident,
              our dedicated claims team will work with you to expedite the
              claims process and ensure that you receive prompt and fair
              compensation for your losses.{" "}
            </li>
            <li>
              <span>Risk Management Support:</span> We go beyond insurance by
              offering proactive risk management services to help you identify
              and mitigate cyber risks, reducing the likelihood and severity of
              cyber incidents.{" "}
            </li>
            <li>
              <span>Peace of Mind:</span> With Cyber Liability Insurance, you
              can focus on growing your business with confidence, knowing that
              you’re protected against the financial and reputational impacts of
              cyber threats.{" "}
              <p className="mt-4" style={{ textAlign: "center" }}>
                Don’t wait until it’s too late – protect your business with
                Cyber Liability Insurance arranged by Amaze Insurance Brokers.
                Contact us today to learn more about comprehensive coverage
                options available in the market and how we can help safeguard
                your business against cyber risks.{" "}
              </p>
            </li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="subProducts_pp">
          <Grid
            container
            columnSpacing={3}
            className="row mb-margin1"
            alignItems={"center"}
            marginBottom={"40px"}
          >
            <Grid
              xs={12}
              md
              className="mb-margin2"
              textAlign={isMobile ? "center" : "left"}
            >
              <h1>Safeguard Your Business with Cyber Liability Insurance</h1>
              <p>
                In today’s digital age, businesses are more vulnerable than ever
                to cyber threats. Data breaches, hacking attacks, and other
                cyber incidents can have devastating consequences, ranging from
                financial loss to reputational damage. That’s where Cyber
                Liability Insurance comes in – offering comprehensive protection
                against the evolving landscape of cyber risks.At Amaze Insurance
                Brokers, we understand the critical importance of safeguarding
                your business against cyber threats. We arrange the most
                suitable Cyber Liability Insurance policy from the insurance
                market at competitive premiums, which is designed to provide you
                with peace of mind, knowing that your business is protected
                against the unexpected.
              </p>
            </Grid>
            <Grid xs={12} md={"auto"} margin={"auto"}>
              <img
                src="/images/safeguard_business_cyber_liability_img.svg"
                alt=""
                style={{
                  width: "100%",
                  maxWidth: isMobile ? "200px" : "290px",
                  margin: "auto",
                }}
              />
            </Grid>
          </Grid>

          <Box className="coverages-section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12}>
                <PPAccordionComponent data={coveragePointsArray} />
              </Grid>
            </Grid>
          </Box>

          <Box className="whyChoose_pp_section">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems="center"
            >
              <Grid xs={12} md={6} className="mb-margin2">
                <h2>Why Choose Amaze Insurance Brokers?</h2>
                <img
                  src="../images/whychoose_pp_img.svg"
                  alt="why choose us icon"
                  style={{ maxWidth: "450px", width: "100%", margin: "auto" }}
                  className="wchoose_img"
                />
              </Grid>
              <Grid xs={12} md={6}>
                <ul className="whyChoosepp_list">
                  <li>
                    <Box className="wc_box">
                      <img
                        src="../images/wc_img1.svg"
                        alt="expert guidance icon"
                      />
                      <h6>Expert Guidance</h6>
                      <ReadMoreText
                        text={
                          "Our experienced professionals will help you choose the right coverage to meet the unique needs and risk profile of your business, ensuring that you get the coverage you need without paying for unnecessary extras. Our team of experts understands the complex nature of cyber risks and can provide you with expert guidance and support every step of the way.  "
                        }
                        maxlength={100}
                      />
                    </Box>
                    <Box className="wc_box">
                      <img
                        src="../images/wc_img2.svg"
                        alt="claims assistance icon"
                      />
                      <h6>Seamless Claims Assistance</h6>
                      <p>
                        We provide comprehensive support throughout the claims
                        process.
                      </p>
                    </Box>
                  </li>
                  <li>
                    <Box className="wc_box">
                      <img src="../images/wc_img3.svg" alt="quotes icon" />
                      <h6>Competitive Quotes</h6>
                      <p>
                        We work with leading insurance companies to secure the
                        best deals for you.
                      </p>
                    </Box>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default CyberLiabilityInsurance;
