import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Box, Button } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import "./DocUpload.scss";
import { error } from "console";

interface DocUploadProps {
  buttonLabel: string;
  icon?: React.ReactNode;
  multiple?: boolean;
  onFileSelect: (files: FileList | null) => void;
  warn_status?: boolean;
  error_message?: string;
}

const DocUpload: React.FC<DocUploadProps> = ({
  buttonLabel,
  icon = <DescriptionIcon />,  // Default icon
  onFileSelect,
  warn_status,
  error_message,
}) => {
  const [fileNames, setFileNames] = useState<string | string[]>(buttonLabel);

  // Update fileNames when buttonLabel prop changes
  useEffect(() => {
    setFileNames(buttonLabel);
  }, [buttonLabel]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const namesArray = Array.from(files).map((file) => file.name);
      setFileNames(namesArray.join(", "));
      if (onFileSelect) onFileSelect(files); // Call the callback with selected files
    }
  };

  const handleFileDelete = () => {
    setFileNames("Choose file");
    if (onFileSelect) onFileSelect(null); // Notify parent component that files are removed
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const showDeleteIcon = fileNames !== "Choose file";

  return (
    <Box display="flex" alignItems="center" className="fileUploadContainer">
      <Button
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={icon}
        className="fileUpload"
      >
        {typeof fileNames === "string" ? fileNames : "Choose file"}
        <VisuallyHiddenInput type="file" onChange={handleFileChange} />
      </Button>
      {showDeleteIcon && (
        <DeleteForeverOutlinedIcon
          onClick={handleFileDelete}
          style={{
            cursor: "pointer",
            color: "#EB5757",
            marginLeft: "10px",
            height: "24px",
            width: "24px",
            padding: "2px",
          }}
        />
      )}
      {warn_status ? <span className="error">{error_message}</span> : null}
    </Box>
  );
};

export default DocUpload;