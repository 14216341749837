import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../../../Components/Desktop/Navbar/Navbar";
import MFooter from "../../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../../Components/Mobile/MNavBar/MNavBar";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import "./../../../../../SCSS/ProductPages.scss";
import PPAccordionComponent from "../../../../../Components/PPAccordionComponent/PPAccordionComponent";
import ReadMoreText from "../../../../../Components/ReadMoreTextComponent/ReadMoreText";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const DSUInsurance = () => {
  const isMobile = useIsMobile();

  const coveragePointsArray = [
    {
      title: "Coverage:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Delay in Start-Up Costs:</span>Covers financial losses due
              to delays in receiving essential goods, equipment, or machinery
              caused by insured perils during marine transit.
            </li>
            <li>
              <span>Lost Revenue: </span>Reimburses lost revenue or income
              resulting from the delay in the commencement of business
              operations due to marine transit delays.
            </li>
            <li>
              <span>Additional Costs:</span> Covers additional costs incurred
              for expedited shipping, temporary storage, demurrage charges, and
              other expenses to mitigate the delay.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Indemnity Period:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              The indemnity period is a crucial aspect of Marine DSU Insurance,
              specifying the maximum duration for which the insurer will
              indemnify the insured for covered losses.
            </li>
            <li>
              It is essential to align the indemnity period with the anticipated
              time required to recover from the delay and resume normal business
              operations, considering factors such as the complexity of the
              project, availability of replacement equipment, and contractual
              obligations.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Additional Covers:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span> Transshipment and Multimodal Transport:</span>
              Marine DSU Insurance can be extended to cover delays caused by
              transshipment of goods from one vessel to another or other modes
              of transportation during marine transit, known as multimodal
              transport.
            </li>
            <li>
              <span>Strike, Riot, and Civil Commotion (SRCC): </span>Extends
              coverage to include delays caused by strikes, riots, and civil
              commotions at ports or during transit.
            </li>
            <li>
              <span>Terrorism Cover:</span> Extends coverage to include loss or
              damage caused by acts of terrorism, sabotage, or political
              violence during marine transit.{" "}
            </li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="subProducts_pp">
          <Grid container columnSpacing={3} className="row">
            <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
              <h1>Marine Delay in Start-Up Insurance Policy (Marine DSU)</h1>
              <p>
                Marine Delay in Start-Up (Marine DSU) Insurance provides
                coverage for financial losses incurred due to delays in the
                commencement of business operations caused by insured perils
                during marine transit. This insurance safeguards businesses
                involved in import, export, and marine-related projects against
                the financial impact of delays in receiving goods and equipment,
                allowing them to recover lost profits and additional expenses
                incurred during the delayed period.
              </p>
            </Grid>
          </Grid>

          <Box className="coverages-section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md className="mb-margin2">
                <PPAccordionComponent data={coveragePointsArray} />
              </Grid>
              <Grid xs={12} md={"auto"} margin={"auto"}>
                <img
                  src="/images/Marine_delay_ins_img.svg"
                  alt=""
                  style={{
                    width: "100%",
                    maxWidth: isMobile ? "220px" : "335px",
                    margin: "auto",
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="claimProcedure-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom={"24px"} textAlign={"center"}>
                <h2>Claims Procedure</h2>
              </Grid>
              <Grid xs={12}>
                <ul className="claimProcedure_list">
                  <li>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      marginBottom={"24px"}
                    >
                      <Box textAlign={"left"}>
                        <h4>01</h4>
                        <h6>Immediate Notification</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon1.svg"
                        alt=""
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "In case of a delay or claim, the insured must notify the insurance company or broker as soon as practicable. "
                      }
                      maxlength={100}
                    />
                  </li>
                  <li>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      marginBottom={"24px"}
                    >
                      <Box textAlign={"left"}>
                        <h4>02</h4>
                        <h6>Claim Documentation</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon2.svg"
                        alt=""
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "Submit a detailed claim form along with all relevant documents, including project schedule, financial statements, invoices, and other evidence supporting the claim, to the insurance company."
                      }
                      maxlength={100}
                    />
                  </li>
                  <li>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      marginBottom={"24px"}
                    >
                      <Box textAlign={"left"}>
                        <h4>03</h4>
                        <h6>Claim Evaluation and Settlement:</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon3.svg"
                        alt=""
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "The insurance company will evaluate the claim, conduct an investigation if necessary, and either approve or deny the claim based on the policy terms and conditions. If approved, the insurer will indemnify the insured for covered losses."
                      }
                      maxlength={100}
                    />
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>

          <Box className="termsConditions_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md={8} className="mb-margin2">
                <h2 className="mb-3">Terms and Conditions</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    <span>Policy Period: </span> Typically, the policy is issued
                    for the duration of the construction project, including the
                    construction period and the maintenance period.
                  </li>
                  <li>
                    <span>Limits of Liability:</span> The policy limit is the
                    maximum amount the insurer will pay for covered claims
                    during the policy period, subject to deductibles and
                    sub-limits.
                  </li>
                  <li>
                    <span>Deductibles:</span> A deductible is the amount the
                    insured must pay before the insurance coverage kicks in.
                  </li>
                </ol>
              </Grid>
              <Grid xs={12} md={4}>
                <img
                  src="../images/termsconditions_img.svg"
                  alt=""
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
            </Grid>
          </Box>

          {/* {exclusions   section included in some pages that why i have created here} */}
          <Box className="excluison_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md={4} order={{ xs: 2, md: 1 }}>
                <img
                  src="../images/exclusions_img.svg"
                  alt=""
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
              <Grid
                xs={12}
                md={8}
                className="mb-margin2"
                order={{ xs: 1, md: 2 }}
              >
                <h2 className="mb-3">Exclusions</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    <span> Normal Marine Risks: </span>Excludes losses resulting
                    from normal marine risks, wear and tear, gradual
                    deterioration, or gradual depreciation of goods and
                    equipment.
                  </li>
                  <li>
                    <span>Wilful Acts:</span> Excludes losses caused
                    intentionally by the insured or any of its employees.
                  </li>
                  <li>
                    <span>War and Nuclear Risks:</span> Excludes losses caused
                    due to war, invasion, act of foreign enemy, hostilities,
                    civil war, rebellion, revolution, nuclear risks, or
                    radioactive contamination.
                  </li>
                </ol>
              </Grid>
            </Grid>
          </Box>
          <Box className="excluison_section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} textAlign={"left"}>
                <h2>Calculation of Loss</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    The calculation of the loss under Marine DSU Insurance is
                    based on the projected revenue and profit that the business
                    would have earned if the marine transit had been completed
                    on time, compared to the actual revenue and profit earned
                    during the delay period.
                  </li>
                  <li>
                    Additional costs incurred to mitigate the delay, such as
                    expedited shipping, temporary storage, demurrage charges,
                    and other related expenses, are also covered under the
                    policy.
                  </li>
                </ol>
              </Grid>
            </Grid>
          </Box>

          <Box className="whyChoose_pp_section">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md={6} className="mb-margin2">
                <h2 className="mb-2">Why Choose Amaze Insurance Brokers?</h2>
                <p className="mb-6">
                  Our experienced professionals understand the intricacies of
                  multimodal transport and the associated risks, which is
                  essential for accurately assessing the coverage needs and
                  potential exposures of our clients.
                </p>
                <img
                  src="../images/whychoose_pp_img.svg"
                  alt=""
                  style={{ maxWidth: "450px", width: "100%", margin: "auto" }}
                  className="wchoose_img"
                />
              </Grid>
              <Grid xs={12} md={6}>
                <ul className="whyChoosepp_list">
                  <li>
                    <Box className="wc_box">
                      <img src="../images/wc_img1.svg" alt="" />
                      <h6>Claims Handling and Settlement</h6>
                      <p>
                        <ReadMoreText
                          text={
                            "Effective claims handling and settlement require meticulous documentation, thorough investigation, and proactive communication between the insured, insurer, and other stakeholders involved.Our experienced Insurance professionals play a pivotal role in guiding the insured through the claims process, ensuring compliance with policy terms, and advocating for fair and timely claim settlement.  "
                          }
                          maxlength={100}
                        />
                      </p>
                    </Box>
                    <Box className="wc_box">
                      <img src="../images/wc_img2.svg" alt="" />
                      <h6>Risk Management and Mitigation</h6>
                      <p>
                        <ReadMoreText
                          text={
                            "Proactive risk management and mitigation strategies are integral to minimizing the likelihood and impact of delays during marine transit.Our experienced Insurance professionals offer valuable insights and recommendations on implementing risk mitigation measures, such as contingency planning, supplier diversification, and contractual risk allocation, to enhance resilience and safeguard business continuity. "
                          }
                          maxlength={100}
                        />
                      </p>
                    </Box>
                  </li>
                  <li>
                    <Box className="wc_box">
                      <img src="../images/wc_img3.svg" alt="" />
                      <h6>Policy Endorsements and Extensions</h6>
                      <p>
                        <ReadMoreText
                          text={
                            "Marine DSU Insurance policies often offer various endorsements and extensions to tailor coverage to the specific needs and risks of the insured, such as extended maintenance cover, strike, riot, and civil commotion (SRCC) cover, and terrorism cover. We advise our clients on the availability and relevance of these endorsements and extensions can enhance the scope of coverage and provide comprehensive protection against unforeseen events. "
                          }
                          maxlength={100}
                        />
                      </p>
                    </Box>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default DSUInsurance;
