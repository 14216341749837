import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { LIABILITY_INSURANCE_ROUTES } from "../Path/LiabilityInsurancePaths";
import PLIPolicyInsurance from "../../Pages/Desktop/ProductPages/LiabilityInsurance/PLIPolicyInsurance/PLIPolicyInsurance";
import LiabilityInsurance from "../../Pages/Desktop/ProductPages/LiabilityInsurance/LiabilityInsurance";
import CGLInsurancePolicy from "../../Pages/Desktop/ProductPages/LiabilityInsurance/CGLInsurancePolicy/CGLInsurancePolicy";
import DOLiabilityInsurancePolicy from "../../Pages/Desktop/ProductPages/LiabilityInsurance/DOLiabilityInsurancePolicy/DOLiabilityInsurancePolicy";
import ErrorsOmmissionInsurance from "../../Pages/Desktop/ProductPages/LiabilityInsurance/ErrorsOmmissionInsurance/ErrorsOmmissionInsurance";
import CyberLiabilityInsurance from "../../Pages/Desktop/ProductPages/LiabilityInsurance/CyberLiabilityInsurance/CyberLiabilityInsurance";
import ProductLiabilityInsurance from "../../Pages/Desktop/ProductPages/LiabilityInsurance/ProductLiabilityInsurance/ProductLiabilityInsurance";
import ProfessionalIndemnityInsurance from "../../Pages/Desktop/ProductPages/LiabilityInsurance/ProfessionalIndemnityInsurance/ProfessionalIndemnityInsurance";
import EmployeesCompensationInsurancePolicy from "../../Pages/Desktop/ProductPages/LiabilityInsurance/EmployeesCompensationInsurancePolicy/EmployeesCompensationInsurancePolicy";

function LiabiltyInsuranceRoutes() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Routes>
        <Route path={LIABILITY_INSURANCE_ROUTES.LIABILITY_INSURANCE} element={<LiabilityInsurance />} />
        <Route
          path={LIABILITY_INSURANCE_ROUTES.PUBLIC_LIABILITY_ACT}
          element={<PLIPolicyInsurance />}
        />
        <Route
          path={LIABILITY_INSURANCE_ROUTES.COMMERCIAL_GENERAL_LIABILITY}
          element={<CGLInsurancePolicy />}
        />
        <Route
          path={LIABILITY_INSURANCE_ROUTES.DIRECTORS_AND_OFFICERS_LIABILITY}
          element={<DOLiabilityInsurancePolicy />}
        />
        <Route
          path={LIABILITY_INSURANCE_ROUTES.ERRORS_AND_OMISSIONS_LIABILITY}
          element={<ErrorsOmmissionInsurance />}
        />
        <Route
          path={LIABILITY_INSURANCE_ROUTES.CYBER_LIABILITY}
          element={<CyberLiabilityInsurance />}
        />
        <Route
          path={LIABILITY_INSURANCE_ROUTES.PRODUCT_LIABILITY}
          element={<ProductLiabilityInsurance />}
        />
        <Route
          path={LIABILITY_INSURANCE_ROUTES.PROFESSIONAL_INDEMNITY}
          element={<ProfessionalIndemnityInsurance />}
        />  
        <Route
          path={LIABILITY_INSURANCE_ROUTES.EMPLOYEES_COMPENSATION}
          element={<EmployeesCompensationInsurancePolicy />}
        />
      </Routes>
    </div>
  );
}

export default LiabiltyInsuranceRoutes;
