import React, { useState } from "react";
import { Box, Link, Button, Menu, MenuItem, Collapse } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./MNavbar.scss";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import MLoginPopup from "./MLoginPopup/MLoginPopup";
import { COLORS } from "../../../SupportingFiles/colors";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { COMMON_ROUTES } from "../../../Routing/Path/CommonPaths";
import { useNavigate } from "react-router-dom";
import { PI_ROUTES } from "../../../Routing/Path/PIPaths";
import { BIINSURANCE_ROUTES } from "../../../Routing/Path/BIInsurancePaths";
import { MARINE_ROUTES } from "../../../Routing/Path/MarineTransitionPaths";
import { LIABILITY_INSURANCE_ROUTES } from "../../../Routing/Path/LiabilityInsurancePaths";
import { PROJECT_INSURANCE_PATHS } from "../../../Routing/Path/ProjectInsurancePaths";
import { EB_ROUTES } from "../../../Routing/Path/EBPaths";
import { MISC_PATHS } from "../../../Routing/Path/MiscellaneousPaths";
import { RETAIL_INSURANCE_PATHS } from "../../../Routing/Path/RetailInsurancePaths";
import { ru } from "date-fns/locale";
import { AVIATION_INSURANCE_PATHS } from "../../../Routing/Path/AviationInsurancePaths";

function MNavBar() {
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = React.useState(false);
  const [openLoginPopup, setOpenLoginPopup] = React.useState(false);
  const [openProductMenu, setOpenProductMenu] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState({
    pi: false,
    bii: false,
    mi: false,
    li: false,
    epi: false,
    ebi: false,
    fli: false,
    mc: false,
    ru: false,
    av:false,
    ri: false,
    ci: false,
    wi: false,
  });

  // const menuItems = [{
  //   mainMenu:
  // }]

  const toggleDrawer =
    (anchor: "left", open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpenMenu(open);
    };

  return (
    <Box className="mnavbar">
      <Grid container className="row" columnSpacing={3} alignItems={"center"}>
        <Grid xs={6}>
          <Link onClick={() => navigate(COMMON_ROUTES.HOME)}>
            <img
              src="../images/amaze_broker_logo.svg"
              alt="amaze"
              width={"70px"}
            />
          </Link>
        </Grid>
        <Grid xs={6} textAlign={"right"}>
          <Link className="hamburger-menu" onClick={toggleDrawer("left", true)}>
            <img
              src="../images/menu_icon.svg"
              alt=""
              height={"25px "}
              width={"25px"}
            />
          </Link>
        </Grid>
      </Grid>

      {/* sidemenu */}
      <SwipeableDrawer
        anchor="left"
        open={openMenu}
        onClose={toggleDrawer("left", false)}
        onOpen={toggleDrawer("left", true)}
      >
        <Box className="menuWrapper">
          {/* login button */}
          {/* <Box className="top-sec">
            <Grid
              container
              className="row"
              columnSpacing={3}
              alignItems={"center"}
            >
              <Grid xs={12}>
                <Link
                  className="dash-link-login"
                >
                  <span>Login</span>
                </Link>
                <MLoginPopup
                  open={openLoginPopup}
                  setOpen={setOpenLoginPopup}
                />
              </Grid>
            </Grid>
          </Box> */}

          <Box className="menu-section">
            <ul>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => navigate(COMMON_ROUTES.ABOUT_US)}
                >
                  About Us
                </Link>
              </li>

              <li>
                <Link
                  className="nav-link"
                  onClick={() => setOpenProductMenu(!openProductMenu)}
                >
                  Our Insurance Solutions  <KeyboardArrowDownIcon />
                </Link>
                <Collapse
                  className="mainMenusec"
                  in={openProductMenu}
                  timeout="auto"
                  unmountOnExit
                >
                  <Link
                    className="nav-link-inner"
                    onClick={() =>
                      setOpenSubMenu((prev) => ({ ...prev, pi: !prev.pi }))
                    }
                  >
                    Property & Engineering Insurance
                    <KeyboardArrowDownIcon />
                  </Link>
                  <Collapse
                    className="subMenusec"
                    in={openSubMenu.pi}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Link
                      className="subMenu-innerlink"
                      onClick={() => navigate(PI_ROUTES.FIRE_INSURANCE)}
                    >
                      Fire Insurance
                    </Link>
                    <Link
                      className="subMenu-innerlink"
                      onClick={() => navigate(PI_ROUTES.INDUSTRIAL_RISK_POLICY)}
                    >
                      Industrial All Risk Insurance
                    </Link>
                    {/* <Link
                      className="subMenu-innerlink"
                    >
                      Burglary Insurance
                    </Link> */}
                    <Link
                      className="subMenu-innerlink"
                      onClick={() =>
                        navigate(PI_ROUTES.MACHINERY_BREAKDOWN_INSURANCE)
                      }
                    >
                      Machinery Breakdown Insurance
                    </Link>
                    <Link
                      className="subMenu-innerlink"
                      onClick={() =>
                        navigate(PI_ROUTES.ELECTRONIC_EQUP_INS_POLICY)
                      }
                    >
                      Electronic Equipment Insurance
                    </Link>
                    {/* <Link className="subMenu-innerlink">
                      Boiler and Pressure Plant Insurance 
                    </Link>*/}
                  </Collapse>

                  <Link
                    className="nav-link-inner"
                    onClick={() =>
                      setOpenSubMenu((prev) => ({ ...prev, bii: !prev.bii }))
                    }
                  >
                    Business Interruption Insurance
                    <KeyboardArrowDownIcon />
                  </Link>
                  <Collapse
                    className="subMenusec"
                    in={openSubMenu.bii}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Link
                      className="subMenu-innerlink"
                      onClick={() =>
                        navigate(BIINSURANCE_ROUTES.BIINSURANCE_FLOP)
                      }
                    >
                      Fire Loss Of Profits (FLOP) Insurance
                    </Link>
                    <Link
                      className="subMenu-innerlink"
                      onClick={() =>
                        navigate(BIINSURANCE_ROUTES.BIINSURANCE_MFLOP)
                      }
                    >
                      MLOP Insurance
                    </Link>
                  </Collapse>

                  <Link
                    className="nav-link-inner"
                    onClick={() =>
                      setOpenSubMenu((prev) => ({ ...prev, mi: !prev.mi }))
                    }
                  >
                    Marine Cargo Insurance
                    <KeyboardArrowDownIcon />
                  </Link>
                  <Collapse
                    className="subMenusec"
                    in={openSubMenu.mi}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Link
                      className="subMenu-innerlink"
                      onClick={() => navigate(MARINE_ROUTES.MARINE_CARGO)}
                    >
                      Marine Cargo Insurance
                    </Link>
                    <Link
                      className="subMenu-innerlink"
                      onClick={() => navigate(MARINE_ROUTES.MARINE_STOP)}
                    >
                      Marine Sales Turn Over Policy (Marine STOP)
                    </Link>
                    <Link
                      className="subMenu-innerlink"
                      onClick={() => navigate(MARINE_ROUTES.MARINE_HULL)}
                    >
                      Marine Hull Insurance
                    </Link>
                  </Collapse>

                  <Link
                    className="nav-link-inner"
                    onClick={() =>
                      setOpenSubMenu((prev) => ({ ...prev, li: !prev.li }))
                    }
                  >
                    Liability Insurance
                    <KeyboardArrowDownIcon />
                  </Link>
                  <Collapse
                    className="subMenusec"
                    in={openSubMenu.li}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Link
                      className="subMenu-innerlink"
                      onClick={() =>
                        navigate(
                          LIABILITY_INSURANCE_ROUTES.PUBLIC_LIABILITY_ACT
                        )
                      }
                    >
                      Public Liability (Act) Insurance
                    </Link>
                    <Link
                      className="subMenu-innerlink"
                      onClick={() =>
                        navigate(
                          LIABILITY_INSURANCE_ROUTES.COMMERCIAL_GENERAL_LIABILITY
                        )
                      }
                    >
                      Commercial General Liability (CGL) Insurance
                    </Link>
                    <Link
                      className="subMenu-innerlink"
                      onClick={() =>
                        navigate(
                          LIABILITY_INSURANCE_ROUTES.DIRECTORS_AND_OFFICERS_LIABILITY
                        )
                      }
                    >
                      Directors and Officers Liability Insurance
                    </Link>
                    <Link
                      className="subMenu-innerlink"
                      onClick={() =>
                        navigate(
                          LIABILITY_INSURANCE_ROUTES.ERRORS_AND_OMISSIONS_LIABILITY
                        )
                      }
                    >
                      Errors and Omissions Insurance
                    </Link>
                    <Link
                      className="subMenu-innerlink"
                      onClick={() =>
                        navigate(LIABILITY_INSURANCE_ROUTES.CYBER_LIABILITY)
                      }
                    >
                      Cyber Liability Insurance
                    </Link>
                    <Link
                      className="subMenu-innerlink"
                      onClick={() =>
                        navigate(LIABILITY_INSURANCE_ROUTES.PRODUCT_LIABILITY)
                      }
                    >
                      Product Liability Insurance
                    </Link>
                    <Link
                      className="subMenu-innerlink"
                      onClick={() =>
                        navigate(
                          LIABILITY_INSURANCE_ROUTES.PROFESSIONAL_INDEMNITY
                        )
                      }
                    >
                      Professional Indemnity Insurance
                    </Link>
                    <Link
                      className="subMenu-innerlink"
                      onClick={() =>
                        navigate(
                          LIABILITY_INSURANCE_ROUTES.EMPLOYEES_COMPENSATION
                        )
                      }
                    >
                      Employees Compensation (WC) Insurance Policy
                    </Link>
                  </Collapse>

                  <Link
                    className="nav-link-inner"
                    onClick={() =>
                      setOpenSubMenu((prev) => ({ ...prev, epi: !prev.epi }))
                    }
                  >
                    Engineering/Project Plans
                    <KeyboardArrowDownIcon />
                  </Link>
                  <Collapse
                    className="subMenusec"
                    in={openSubMenu.epi}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Link
                      className="subMenu-innerlink"
                      onClick={() =>
                        navigate(
                          PROJECT_INSURANCE_PATHS.ERECTION_ALL_RISK_INSURANCE
                        )
                      }
                    >
                      Erection All Risk Insurance
                    </Link>
                    <Link
                      className="subMenu-innerlink"
                      onClick={() =>
                        navigate(
                          PROJECT_INSURANCE_PATHS.CONTRACTOR_ALL_RISK_INSURANCE
                        )
                      }
                    >
                      Contractor's All Risk Insurance
                    </Link>
                    <Link
                      className="subMenu-innerlink"
                      onClick={() => navigate(PROJECT_INSURANCE_PATHS.ALOP)}
                    >
                      Advance Loss of Profit Insurance (ALOP)
                    </Link>
                    <Link
                      className="subMenu-innerlink"
                      onClick={() => navigate(PROJECT_INSURANCE_PATHS.DSU)}
                    >
                      Delay in Start-Up Insurance (DSU)
                    </Link>
                    <Link
                      className="subMenu-innerlink"
                      onClick={() =>
                        navigate(PROJECT_INSURANCE_PATHS.MARINE_CUM)
                      }
                    >
                      Marine Cum Erection Insurance
                    </Link>
                  </Collapse>

                  <Link
                    className="nav-link-inner"
                    onClick={() =>
                      setOpenSubMenu((prev) => ({ ...prev, ebi: !prev.ebi }))
                    }
                  >
                    Employee Benefits Insurance
                    <KeyboardArrowDownIcon />
                  </Link>
                  <Collapse
                    className="subMenusec"
                    in={openSubMenu.ebi}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Link
                      className="subMenu-innerlink"
                      onClick={() => navigate(EB_ROUTES.GPA_INSURANCE)}
                    >
                      Group Personal Accident Insurance
                    </Link>
                    <Link
                      className="subMenu-innerlink"
                      onClick={() => navigate(EB_ROUTES.GTL_INSURANCE)}
                    >
                      Group Term Life Insurance
                    </Link>
                    <Link
                      className="subMenu-innerlink"
                      onClick={() => navigate(EB_ROUTES.GRP_MEDICAL_INSURANCE)}
                    >
                      Group Medical Insurance
                    </Link>
                    {/* <Link
                      className="subMenu-innerlink"
                      onClick={() => navigate(EB_ROUTES.EPF)}
                    >
                      Employee Provident Fund (EPF)
                    </Link>
                    <Link
                      className="subMenu-innerlink"
                      onClick={() => navigate(EB_ROUTES.ESI)}
                    >
                      Employee State Insurance (ESI)
                    </Link> */}
                  </Collapse>

                  {/* <Link
                    className="nav-link-inner"
                    onClick={() =>
                      setOpenSubMenu((prev) => ({ ...prev, fli: !prev.fli }))
                    }
                  >
                    Financial Lines Insurance
                    <KeyboardArrowDownIcon />
                  </Link>
                  <Collapse
                    className="subMenusec"
                    in={openSubMenu.fli}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Link className="subMenu-innerlink">Crime Insurance</Link>
                    <Link className="subMenu-innerlink">
                      Kidnap and Ransom Insurance
                    </Link>
                    <Link className="subMenu-innerlink">
                      Fidelity Guarantee Insurance
                    </Link>
                    <Link className="subMenu-innerlink">Money Insurance</Link>
                  </Collapse> */}

                  <Link
                    className="nav-link-inner"
                    onClick={() =>
                      setOpenSubMenu((prev) => ({ ...prev, mc: !prev.mc }))
                    }
                  >
                    Miscellaneous Class <KeyboardArrowDownIcon />
                  </Link>
                  <Collapse
                    className="subMenusec"
                    in={openSubMenu.mc}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Link
                      className="subMenu-innerlink"
                      onClick={() =>
                        navigate(
                          MISC_PATHS.PORTABLE_ELECTRONIC_EQUIPMENT_INSURANCE
                        )
                      }
                    >
                      Portable Electronic Equipment Insurance
                    </Link>
                    <Link
                      className="subMenu-innerlink"
                      onClick={() =>
                        navigate(MISC_PATHS.MONEY_INSURANCE_POLICIES)
                      }
                    >
                      Money Insurance
                    </Link>
                    <Link
                      className="subMenu-innerlink"
                      onClick={() =>
                        navigate(
                          MISC_PATHS.EMPLOYEE_CRIME_FIDELITY_GUARANTEE_INSURANCE
                        )
                      }
                    >
                      Employee Crime/Fidelity Guarantee Insurance
                    </Link>
                    <Link
                      className="subMenu-innerlink"
                      onClick={() =>
                        navigate(MISC_PATHS.PLATE_GLASS_INSURANCE_POLICY)
                      }
                    >
                      Plate Glass Insurance
                    </Link>
                  </Collapse>

                  <Link
                    className="nav-link-inner"
                    onClick={() =>
                      setOpenSubMenu((prev) => ({ ...prev, ru: !prev.ru }))
                    }
                  >
                    Rural Insurance
                    <KeyboardArrowDownIcon />
                  </Link>
                  <Collapse
                    className="subMenusec"
                    in={openSubMenu.ru}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Link
                      className="subMenu-innerlink"
                      onClick={() => navigate(COMMON_ROUTES.CROP_INSURANCE)}
                    >
                      Crop Insurance
                    </Link>
                    <Link
                      className="subMenu-innerlink"
                      onClick={() => navigate(COMMON_ROUTES.WEATHER_INSURANCE)}
                    >
                      Weather Insurance
                    </Link>
                  </Collapse>

                  <Link
                    className="nav-link-inner"
                    onClick={() =>
                      setOpenSubMenu((prev) => ({ ...prev, av: !prev.av }))
                    }
                  >
                    Aviation Insurance
                    <KeyboardArrowDownIcon />
                  </Link>
                  <Collapse
                    className="subMenusec"
                    in={openSubMenu.av}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Link
                      className="subMenu-innerlink"
                      onClick={() =>
                        navigate(
                          AVIATION_INSURANCE_PATHS.AVIATION_HULL_INSURANCE
                        )
                      }
                    >
                      Aviation Hull Insurance
                    </Link>
                    <Link
                      className="subMenu-innerlink"
                      onClick={() =>
                        navigate(
                          AVIATION_INSURANCE_PATHS.AVIATION_LIABILITY_INSURANCE
                        )
                      }
                    >
                      Aviation Liability Insurance
                    </Link>
                    <Link
                      className="subMenu-innerlink"
                      onClick={() =>
                        navigate(AVIATION_INSURANCE_PATHS.AVIATION_PACKAGE)
                      }
                    >
                      Aviation Package Insurance
                    </Link>
                  </Collapse>

                  <Link
                    className="nav-link-inner"
                    onClick={() =>
                      setOpenSubMenu((prev) => ({ ...prev, ri: !prev.ri }))
                    }
                  >
                    Retail Insurance
                    <KeyboardArrowDownIcon />
                  </Link>
                  <Collapse
                    className="subMenusec"
                    in={openSubMenu.ri}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Link
                      className="subMenu-innerlink"
                      onClick={() =>
                        navigate(RETAIL_INSURANCE_PATHS.JEWELRY_SHOWROOMS)
                      }
                    >
                      Jewelry Showrooms Insurance
                    </Link>
                    <Link
                      className="subMenu-innerlink"
                      onClick={() =>
                        navigate(RETAIL_INSURANCE_PATHS.SHOPKEEPERS_INSURANCE)
                      }
                    >
                      Comprehensive Shopkeeper's Insurance
                    </Link>
                    <Link
                      className="subMenu-innerlink"
                      onClick={() =>
                        navigate(RETAIL_INSURANCE_PATHS.HOUSEHOLDER_INSURANCE)
                      }
                    >
                      Householder's Insurance Policy
                    </Link>
                  </Collapse>
                </Collapse>
              </li>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => navigate(COMMON_ROUTES.OUR_SERVICES)}
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => navigate(COMMON_ROUTES.OUR_SECTORS)}
                >
                  Sectors
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => navigate(COMMON_ROUTES.CONTACT)}
                >
                  Contact us
                </Link>
              </li>
              <li>
                <Link
                  className="ca-link"
                  onClick={() => navigate(COMMON_ROUTES.IPAR)}
                >
                  IPA Report
                  <span className="boxblink">
                    <p className="blink_text"> Free</p>
                  </span>
                </Link>
              </li>
              {/* <li>
                <Link
                  className="pir-link"
                  onClick={() =>
                    window.open(
                      "https://bimastreet.com/claims",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                >
                  {" "}
                  Claims Consultancy
                </Link>
              </li> */}
              <li>
                <Link
                  className="logo-link"
                  onClick={() =>
                    window.open(
                      "https://bimastreet.com/",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                >
                  <img src="./images/BimaStreet-Logo.svg" alt="" />
                </Link>
              </li>
              <li>
                <Link
                  className="pir-link"
                  onClick={() =>
                    window.open(
                      "https://login.amazeinsurancebrokers.com/auth/login",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                >
                  {" "}
                  Corporate Login
                </Link>
              </li>
              <li>
                <Link
                  className="login"
                  onClick={() =>
                    window.open(
                      "https://eb.amazeinsurancebrokers.com/auth/dob",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                >
                  Employee Login
                </Link>
              </li>

              {/* <li>
                <Link
                  className="nav-link"
                  onClick={() => setOpenLoginMenu(!openLoginMenu)}
                >
                  Login <KeyboardArrowDownIcon />
                </Link>
                <Collapse in={openLoginMenu} timeout="auto" unmountOnExit>
                  <Link className="nav-link-inner">Corporate Login</Link>
                  <Link className="nav-link-inner">Customer Login</Link>
                </Collapse>
              </li> */}
            </ul>
          </Box>
        </Box>
      </SwipeableDrawer>
    </Box>
  );
}

export default MNavBar;
