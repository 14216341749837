import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../../Components/Desktop/Navbar/Navbar";
import MFooter from "../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../Components/Mobile/MNavBar/MNavBar";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import "./../../../../SCSS/ProductPages.scss";
import PPAccordionComponent from "../../../../Components/PPAccordionComponent/PPAccordionComponent";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const WeatherInsurance = () => {
  const isMobile = useIsMobile();

  const coveragePointsArray = [
    {
      title: "Coverage",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              Weather Insurance covers various weather-related risks such as
              excessive rainfall, drought, snowfall, temperature extremes,
              windstorms, and hurricanes.
            </li>
            <li>
              It compensates policyholders for losses resulting from disruptions
              in business operations, event cancellations, crop damage, property
              damage, and other weather-related events.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Wordings and Special Features",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              Policies are customized to meet the specific needs of businesses,
              event organizers, farmers, and other stakeholders.
            </li>
            <li>
              Special features may include flexibility in coverage limits,
              trigger points, and payout structures based on the insured's
              requirements.
            </li>
            <li>
              Some policies offer parametric coverage, where payouts are based
              on predetermined weather parameters such as rainfall levels or
              temperature deviations.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Enhancements and Additional Covers",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              Enhanced coverage options may include protection against named
              storms, flood damage, business interruption losses, and revenue
              shortfall due to adverse weather.
            </li>
            <li>
              Additional covers may be available for specific industries such
              as agriculture, construction, outdoor events, tourism, and
              hospitality.
            </li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="subProducts_pp">
          <Grid container columnSpacing={3} className="row">
            <Grid xs={12} textAlign="center" marginBottom="24px">
              <h1>Weather Insurance Coverage</h1>
              <p className="mt-3">
                Weather Insurance provides protection against financial losses
                incurred due to adverse weather conditions that affect
                businesses, events, or agriculture. Here's what you need to know
                about the coverage, features, and claims procedure:
              </p>
            </Grid>
          </Grid>

          <Box className="coverages-section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md className="mb-margin2">
                <PPAccordionComponent data={coveragePointsArray} />
              </Grid>
              <Grid xs={12} md={"auto"} margin={"auto"}>
                <img
                  src="/images/weather_insurance_img.svg"
                  alt=""
                  style={{
                    width: "100%",
                    maxWidth: isMobile ? "220px" : "300px",
                    margin: "auto",
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="excluison_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems="center"
            >
              <Grid xs={12} className="mb-margin2">
                <h2 className="mb-3" style={{ textAlign: "center" }}>
                  Exclusions
                </h2>
              </Grid>
              <Grid xs={12}>
                <p style={{ textAlign: "center" }}>
                  Exclusions vary depending on the policy and insurer but may
                  typically include losses caused by natural disasters not
                  covered under standard weather events, intentional acts, and
                  other specified perils.
                </p>
              </Grid>
            </Grid>
          </Box>

          <Box className="termsConditions_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems="center"
            >
              <Grid xs={12} className="mb-margin2">
                <h2 className="mb-3" style={{ textAlign: "center" }}>
                  Terms and Conditions
                </h2>
              </Grid>
              <Grid xs={12} textAlign={"center"}>
                <ol type="1" className="ordered-list">
                  <li>
                    Terms and conditions outline the scope of coverage, policy
                    duration, premium payments, deductibles, and other important
                    provisions.
                  </li>
                  <li>
                    Policyholders are required to adhere to the terms and
                    conditions outlined in the policy document to ensure
                    coverage eligibility and claims processing.
                  </li>
                </ol>
              </Grid>
            </Grid>
          </Box>

          <Box className="claimProcedure-section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems="center"
            >
              <Grid xs={12} className="mb-margin2">
                <h2 className="mb-3" style={{ textAlign: "center" }}>
                  Claims Procedure
                </h2>
              </Grid>
              <Grid xs={12}>
                <ol type="1" className="ordered-list">
                  <li>
                    In the event of a weather-related loss, policyholders must
                    notify their insurance broker or provider as soon as
                    possible.
                  </li>
                  <li>
                    The claims procedure typically involves submitting a claims
                    form along with supporting documentation such as weather
                    reports, event cancellations, financial statements, and
                    other relevant evidence.
                  </li>
                  <li>
                    Upon receipt of the claim, the insurer will assess the
                    validity of the claim and process the payout according to
                    the terms of the policy.
                  </li>
                </ol>
              </Grid>
              <Grid xs={12}>
                <p
                  className="mt-3"
                  style={{
                    textAlign: "center",
                    fontWeight: 500,
                    color: "#000000",
                  }}
                >
                  For comprehensive Weather Insurance solutions tailored to your
                  needs, trust Amaze Insurance Brokers. Contact us today to
                  learn more about our offerings and how we can help protect
                  your interests against weather-related risks.
                </p>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default WeatherInsurance;
