import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import AviationHullInsurance from "../../Pages/Desktop/ProductPages/AviationInsurance/AviationHullInsurance/AviationHullInsurance";
import { AVIATION_INSURANCE_PATHS } from "../Path/AviationInsurancePaths";
import AviationLiabilityInsurance from "../../Pages/Desktop/ProductPages/AviationInsurance/AviationLiabilityInsurance/AvationLiabilityInsurance";
import AviationPackagePolicy from "../../Pages/Desktop/ProductPages/AviationInsurance/AviationPackage/AviationPackagePolicy";

function AviationInsuranceRoutes() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Routes>
        <Route
          path={AVIATION_INSURANCE_PATHS.AVIATION_HULL_INSURANCE}
          element={<AviationHullInsurance />}
        />
        <Route
          path={AVIATION_INSURANCE_PATHS.AVIATION_LIABILITY_INSURANCE}
          element={<AviationLiabilityInsurance />}
        />
        <Route
          path={AVIATION_INSURANCE_PATHS.AVIATION_PACKAGE}
          element={<AviationPackagePolicy />}
        />
      </Routes>
    </div>
  );
}

export default AviationInsuranceRoutes;
