import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../../../Components/Desktop/Navbar/Navbar";
import MFooter from "../../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../../Components/Mobile/MNavBar/MNavBar";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import "./../../../../../SCSS/ProductPages.scss";
import PPAccordionComponent from "../../../../../Components/PPAccordionComponent/PPAccordionComponent";
import { useState } from "react";
import ReadMoreText from "../../../../../Components/ReadMoreTextComponent/ReadMoreText";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const ProfessionalIndemnityInsurance = () => {
  const isMobile = useIsMobile();

  const coveragePointsArray = [
    {
      title: "Coverage:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Professional Negligence:</span> Covers liability arising
              from negligent acts, errors, or omissions committed by the insured
              while providing professional services, leading to financial loss
              or harm to a third party.
            </li>
            <li>
              <span>Misrepresentation:</span> Covers liability arising from
              providing incorrect, incomplete, or misleading information in the
              provision of professional services, leading to financial loss or
              harm to a third party.
            </li>
            <li>
              <span>Breach of Professional Duty:</span> Covers liability arising
              from the insured’s failure to ulfil their professional duties and
              responsibilities owed to clients, resulting in financial loss or
              harm.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Special Features: ",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Worldwide Coverage:</span> Provides coverage for claims
              arising from professional services rendered worldwide, subject to
              policy terms and conditions.
            </li>
            <li>
              <span>Run-off Coverage:</span> Extends coverage for claims made
              against the insured after the policy has expired or been canceled,
              provided the claim arises from professional services rendered
              during the policy period.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Enhancements:  ",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Loss of Documents:</span> Covers liability for the loss of
              documents entrusted to the insured in connection with the
              provision of professional services.
            </li>
            <li>
              <span>Joint Venture Coverage:</span> Extends coverage to include
              liability arising from professional services provided through
              joint ventures or partnerships.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Add-on Covers:  ",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Legal Defense Costs:</span> Covers legal defense costs,
              including attorney fees, court costs, and other legal expenses
              incurred in defending a covered claim.
            </li>
            <li>
              <span>Data Protection and Privacy Breach:</span> Extends coverage
              to include liability arising from data breaches, cyber-attacks,
              and other privacy-related incidents.
            </li>
          </ul>
        </>
      ),
    },
  ];

  const [settings] = useState({
    className: "center",
    // centerMode: true,
    infinite: true,
    // centerPadding: "200px",
    slidesToShow: 3,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 2000,
    // cssEase: "linear",
    arrows: true,
    dots: true,
    responsive: [
      {
        breakpoint: 1024, // For tablets and above
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600, // For mobile devices
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });

  const pointsArray = [
    {
      title: "Doctors and Medical Practitioners",
      desc: "Including general practitioners, surgeons, specialists, and other healthcare professionals who provide medical advice and treatment.",
    },
    {
      title: "Lawyers and Legal Professionals",
      desc: "Including solicitors, barristers, legal consultants, and paralegals who provide legal advice and representation.",
    },
    {
      title: "Architects and Engineers",
      desc: "Including architects, structural engineers, civil engineers, and other professionals involved in the design and construction industry.",
    },
    {
      title: "Accountants and Financial Advisors",
      desc: "Including certified public accountants (CPAs), chartered accountants, financial planners, and investment advisors who provide financial advice and services.",
    },
    {
      title: "IT and Technology Professionals:",
      desc: "Including software developers, IT consultants, web designers, and other tech professionals who provide IT solutions and services. ",
    },
    {
      title: "Real Estate Agents and Brokers:",
      desc: "Including property agents, real estate brokers, and property managers involved in buying, selling, or managing real estate properties. ",
    },
    {
      title: "Consultants and Business Advisors: ",
      desc: "Including management consultants, business analysts, HR consultants, and other professionals who provide consulting services to businesses. ",
    },
    {
      title: "Designers and Creative Professionals:",
      desc: "Including graphic designers, interior designers, advertising agencies, and other creative professionals who provide design and creative services. ",
    },
    {
      title: "Educational and Training Professionals:",
      desc: "Including teachers, trainers, and educators who provide educational and training services. ",
    },
    {
      title: "Insurance Brokers and Agents:",
      desc: " Including insurance brokers, agents, and intermediaries involved in selling and advising on insurance products. ",
    },
    {
      title: "Media and Entertainment Professionals: ",
      desc: "Including journalists, publishers, broadcasters, filmmakers, and other media and entertainment professionals who provide content creation and distribution services. ",
    },
  ];

  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="subProducts_pp">
          <Grid
            container
            columnSpacing={3}
            className="row"
            alignItems={"center"}
          >
            <Grid
              xs={12}
              md
              className="mb-margin2"
              textAlign={isMobile ? "center" : "left"}
            >
              <h1>Professional Indemnity Insurance</h1>

              <p className="mt-3">
                Professional Indemnity Insurance (PII) is designed to protect
                professionals from potential financial losses arising from
                claims made against them for alleged negligence, errors, or
                omissions in the professional services they provide. Various
                professionals across different industries can benefit from
                having a Professional Indemnity Insurance Policy. Here are some
                professionals who typically take out Professional Indemnity
                Insurance:
              </p>
            </Grid>
            <Grid xs={12} md={"auto"} margin={"auto"}>
              <img
                src="/images/prof_indemnity_insurance.svg"
                alt=""
                style={{
                  width: "100%",
                  maxWidth: isMobile ? "190px" : "242px",
                  margin: "auto",
                }}
              />
            </Grid>

            <Grid xs={12}>
              <Slider {...settings} className="mt-6 points-slider">
                {pointsArray?.map((data, index) => (
                  <Box sx={{ padding: "0px 12px" }}>
                    <Box className="pointBox">
                      <h4 className="mb-3">{data.title}</h4>
                      <p>{data.desc}</p>
                    </Box>
                  </Box>
                ))}
              </Slider>
            </Grid>
          </Grid>

          <Box className="coverages-section mb-margin1 mt-4">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12}>
                <PPAccordionComponent data={coveragePointsArray} />
              </Grid>
            </Grid>
          </Box>

          <Box className="claimProcedure-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom="24px" textAlign="center">
                <h2>Claims Procedure</h2>
              </Grid>
              <Grid xs={12}>
                <ul className="claimProcedure_list">
                  <li>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom="24px"
                    >
                      <Box textAlign="left">
                        <h4>01</h4>
                        <h6>Immediate Notification</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon1.svg"
                        alt="notification icon"
                        style={{
                          width: "52px",
                          height: "52px",
                          borderRadius: "4px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "In case of an incident or claim, the insured must notify the insurance company or broker as soon as practicable. "
                      }
                      maxlength={100}
                    />
                  </li>
                  <li>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom="24px"
                    >
                      <Box textAlign="left">
                        <h4>02</h4>
                        <h6>Claim Documentation</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon2.svg"
                        alt="documentation icon"
                        style={{
                          width: "52px",
                          height: "52px",
                          borderRadius: "4px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "Submit a detailed claim form along with all relevant documents, including incident reports, client communications, and other evidence supporting the claim, to the insurance company. "
                      }
                      maxlength={100}
                    />
                  </li>
                  <li>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom="24px"
                    >
                      <Box textAlign="left">
                        <h4>03</h4>
                        <h6>Claim Evaluation and Settlement</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon3.svg"
                        alt="evaluation and settlement icon"
                        style={{
                          width: "52px",
                          height: "52px",
                          borderRadius: "4px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "The insurance company will evaluate the claim, conduct an investigation if necessary, and either approve or deny the claim based on the policy terms and conditions. If approved, the insurer will indemnify the insured or reimburse for covered losses. "
                      }
                      maxlength={100}
                    />
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>

          <Box className="termsConditions_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems="center"
            >
              <Grid xs={12} md={8} className="mb-margin2">
                <h2 className="mb-3">Terms and Conditions</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    <span>Policy Period:</span> Typically, the policy is issued
                    for a one-year period and needs to be renewed annually.
                  </li>
                  <li>
                    <span>Limits of Liability:</span> The policy limit is the
                    maximum amount the insurer will pay for covered claims
                    during the policy period, subject to deductibles and
                    sub-limits.
                  </li>
                  <li>
                    <span>Deductibles:</span> A deductible is the amount the
                    insured must pay before the insurance coverage kicks in.
                  </li>
                </ol>
              </Grid>
              <Grid xs={12} md={4}>
                <img
                  src="../images/termsconditions_img.svg"
                  alt="terms and conditions icon"
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="excluison_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems="center"
            >
              <Grid xs={12} md={4} order={{ xs: 2, md: 1 }}>
                <img
                  src="../images/exclusions_img.svg"
                  alt="exclusions icon"
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
              <Grid
                xs={12}
                md={8}
                className="mb-margin2"
                order={{ xs: 1, md: 2 }}
              >
                <h2 className="mb-3">Exclusions</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    <span>Intentional Acts: </span> Claims arising from
                    intentional acts or violations of laws committed by the
                    insured with the intent to cause harm.
                  </li>
                  <li>
                    <span>Criminal Acts:</span> Claims arising from criminal
                    acts, fraud, or dishonesty committed by the insured.
                  </li>
                  <li>
                    <span>Property Damage or Bodily Injury:</span> Claims
                    related to bodily injury or property damage unless directly
                    resulting from a covered professional negligence or error.
                  </li>
                </ol>
              </Grid>
            </Grid>
          </Box>

          <Box className="whyChoose_pp_section">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems="center"
            >
              <Grid xs={12} md={6} className="mb-margin2">
                <h2>Why Choose Amaze Insurance Brokers?</h2>
                <img
                  src="../images/whychoose_pp_img.svg"
                  alt="why choose us icon"
                  style={{ maxWidth: "450px", width: "100%", margin: "auto" }}
                  className="wchoose_img"
                />
              </Grid>
              <Grid xs={12} md={6}>
                <ul className="whyChoosepp_list">
                  <li>
                    <Box className="wc_box">
                      <img
                        src="../images/wc_img1.svg"
                        alt="expert guidance icon"
                      />
                      <h6>Expert Guidance</h6>
                      <p>
                        Our experienced professionals will get the best coverage
                        with appropriate liability limits under Professional
                        Indemnity Insurance for you giving you peace of mind
                        while your perform your professional duties .
                      </p>
                    </Box>
                    <Box className="wc_box">
                      <img src="../images/wc_img2.svg" alt="assistance icon" />
                      <h6>Seamless Claims Assistance</h6>
                      <p>
                        We provide comprehensive support throughout the claims
                        process.
                      </p>
                    </Box>
                  </li>
                  <li>
                    <Box className="wc_box">
                      <img src="../images/wc_img3.svg" alt="quotes icon" />
                      <h6>Competitive Quotes</h6>
                      <p>
                        We work with leading insurance companies to secure the
                        best deals for you.
                      </p>
                    </Box>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default ProfessionalIndemnityInsurance;
