import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../../Components/Desktop/Navbar/Navbar";
import MFooter from "../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../Components/Mobile/MNavBar/MNavBar";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import "./../../../../SCSS/ProductPages.scss";

const PropertyInsurance = () => {
  const isMobile = useIsMobile();
  
  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="main_pp">
          <Grid
            container
            columnSpacing={3}
            className="row mb-margin1"
            marginBottom={"80px"}
          >
            <Grid xs={12} textAlign={"center"}>
              <h2 className="mb-3">Property Insurance</h2>
              <p>
                Protect your physical assets, including buildings, machinery,
                and equipment, against risks such as fire, theft, natural
                disasters, and vandalism with our comprehensive property
                insurance policies. Our customizable plans ensure that your
                property remains safeguarded against unforeseen events, allowing
                you to focus on your core business activities.
              </p>
            </Grid>
          </Grid>
          <Grid
            container
            columnSpacing={3}
            className="row mb-margin1"
            marginBottom="80px"
          >
            <Grid xs={12} textAlign="center">
              <h2 className="mb-3">Engineering Insurance</h2>
              <p>
                Minimize financial losses resulting from construction and
                operational risks with our specialized engineering insurance
                solutions. From construction all risks (CAR) to machinery
                breakdown insurance, we offer comprehensive coverage to mitigate
                potential disruptions and ensure the smooth operation of your
                projects and facilities.
              </p>
            </Grid>
          </Grid>
          <Box
            className="whyChoose_pp_section mb-margin1"
            marginBottom={"80px"}
          >
            <Grid
              container
              columnSpacing={3}
              className="row mb-6"
              alignItems={"center"}
            >
              <Grid xs={12} md={6} className="mb-margin2">
                <h2>Why Choose Amaze Insurance Brokers?</h2>
                <img
                  src="../images/whychoose_pp_img.svg"
                  alt=""
                  style={{ maxWidth: "450px", width: "100%", margin: "auto" }}
                  className="wchoose_img"
                />
              </Grid>
              <Grid xs={12} md={6}>
                <ul className="whyChoosepp_list">
                  <li>
                    <Box className="wc_box">
                      <img src="../images/wc_img1.svg" alt="" />
                      <h6>Expertise You Trust</h6>
                      <p>Decades of experience, customized for you.</p>
                    </Box>
                    <Box className="wc_box">
                      <img src="../images/wc_img2.svg" alt="" />
                      <h6>Comprehensive Coverage</h6>
                      <p>Address niche risks with precision.</p>
                    </Box>
                  </li>
                  <li>
                    <Box className="wc_box">
                      <img src="../images/wc_img3.svg" alt="" />
                      <h6>Simplified Processes</h6>
                      <p>Streamlined guidance from selection to claims.</p>
                    </Box>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default PropertyInsurance;
