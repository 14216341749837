import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { MARINE_ROUTES } from "../Path/MarineTransitionPaths";
import MarineCargoInsurance from "../../Pages/Desktop/ProductPages/MarineTransitInsurance/MarineCargoInsurance/MarineCargoInsurance";
import MarineSTOPInsurance from "../../Pages/Desktop/ProductPages/MarineTransitInsurance/MarineSTOPInsurance/MarineSTOPInsurance";
import MarineHullInsurance from "../../Pages/Desktop/ProductPages/MarineTransitInsurance/MarineHullInsurance/MarineHullInsurance";
import MarineTransitInsurance from "../../Pages/Desktop/ProductPages/MarineTransitInsurance/MarineTransitInsurance";

function MarineTransitionRoutes() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Routes>
        <Route
          path={MARINE_ROUTES.MARINE_TRANSITION}
          element={<MarineTransitInsurance />}
          />
        <Route
          path={MARINE_ROUTES.MARINE_CARGO}
          element={<MarineCargoInsurance />}
        />
          <Route
          path={MARINE_ROUTES.MARINE_STOP}
          element={<MarineSTOPInsurance />}
        />
          <Route
          path={MARINE_ROUTES.MARINE_HULL}
          element={<MarineHullInsurance />}
        />
      </Routes>
    </div>
  );
}

export default MarineTransitionRoutes;
