import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../../../Components/Desktop/Navbar/Navbar";
import MFooter from "../../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../../Components/Mobile/MNavBar/MNavBar";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import "./../../../../../SCSS/ProductPages.scss";
import PPAccordionComponent from "../../../../../Components/PPAccordionComponent/PPAccordionComponent";
import ReadMoreText from "../../../../../Components/ReadMoreTextComponent/ReadMoreText";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const GroupMedicalInsurance = () => {
  const isMobile = useIsMobile();

  const coveragePointsArray = [
    {
      title: "Coverage:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>In-patient Hospitalization:</span> Covers medical expenses
              incurred during hospitalization for illness or injury, including
              room rent, nursing, and ICU charges.
            </li>
            <li>
              <span>Pre and Post-Hospitalization:</span> Covers medical expenses
              incurred 30 days before hospitalization and 60 days after
              discharge.
            </li>
            <li>
              <span>Day-care Procedures:</span> Covers medical treatments and
              procedures that do not require 24-hour hospitalization due to
              technological advancements.
            </li>
            <li>
              <span>Maternity Benefits:</span> Covers expenses related to
              maternity, including delivery, pre, and post-natal care, and
              newborn baby coverage.
            </li>
            <li>
              <span>Ambulance Charges:</span> Covers the cost of ambulance
              services during an emergency.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Special Features:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Cashless Hospitalization:</span> Allows employees to avail
              cashless treatment at network hospitals without paying upfront
              medical expenses.
            </li>
            <li>
              <span>Pre-existing Disease Coverage:</span>
              Some policies offer coverage for pre-existing diseases after a
              waiting period.
            </li>
            <li>
              <span>Wellness Benefits:</span>
              Offers wellness programs, health check-ups, and preventive care
              services to promote employee health and well-being.
            </li>
            <li>
              <span>Global Coverage:</span>
              Some policies provide coverage for medical treatments availed
              outside India, especially for employees traveling abroad for work
              purposes.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Add-on Covers:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Critical Illness Cover:</span> Provides a lump-sum benefit
              upon diagnosis of specified critical illnesses like cancer, heart
              attack, etc.
            </li>
            <li>
              <span>Personal Accident Cover:</span> Offers financial
              compensation in case of accidental death or disability due to an
              accident.
            </li>
            <li>
              <span>Top-up Coverage:</span>
              Allows employees to enhance their coverage limits by opting for
              additional coverage at an extra premium.
            </li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="subProducts_pp">
          <Grid container columnSpacing={3} className="row">
            <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
              <h1>
                Group Health Insurance Policy: Comprehensive Coverage for
                Employee Well-being
              </h1>
            </Grid>
          </Grid>

          <Box className="coverages-section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md className="mb-margin2">
                <PPAccordionComponent data={coveragePointsArray} />
              </Grid>
              <Grid xs={12} md={"auto"} margin={"auto"}>
                <img
                  src="/images/grp_medical_insurance.svg"
                  alt=""
                  style={{
                    width: "100%",
                    maxWidth: isMobile ? "200px" : "275px",
                    margin: "auto",
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="claimProcedure-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom={"24px"} textAlign={"center"}>
                <h2>Claims Procedure</h2>
              </Grid>
              <Grid xs={12}>
                <ul className="claimProcedure_list">
                  <li>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      marginBottom={"24px"}
                    >
                      <Box textAlign={"left"}>
                        <h4>01</h4>
                        <h6>Immediate Notification</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon1.svg"
                        alt=""
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "In case of hospitalization or medical emergency, the insured employee or the employer must notify the insurance company or TPA (Third-Party Administrator) as soon as possible."
                      }
                      maxlength={100}
                    />
                  </li>
                  <li>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      marginBottom={"24px"}
                    >
                      <Box textAlign={"left"}>
                        <h4>02</h4>
                        <h6>Claim Documentation</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon2.svg"
                        alt=""
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "Submit a duly filled claim form along with original bills, medical reports, discharge summary, and other necessary documents to the TPA or insurance company."
                      }
                      maxlength={100}
                    />
                  </li>
                  <li>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      marginBottom={"24px"}
                    >
                      <Box textAlign={"left"}>
                        <h4>03</h4>
                        <h6>Claim Settlement</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon3.svg"
                        alt=""
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "Upon verification and approval of the claim, the insurance company will settle the claim amount directly with the hospital (cashless) or reimburse the insured (reimbursement basis) as per the policy terms and coverage limits."
                      }
                      maxlength={100}
                    />
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>

          <Box className="termsConditions_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md={8} className="mb-margin2">
                <h2 className="mb-3">Terms and Conditions</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    <span>Policy Period: </span> Typically, the policy is issued
                    for a one-year period and needs to be renewed annually.
                  </li>
                  <li>
                    <span>Sum Insured:</span> The sum insured is based on the
                    company's group size and the chosen coverage amount per
                    employee.
                  </li>
                  <li>
                    <span>Premium Payment: </span> Premiums are payable
                    annually, and the cost is shared between the employer and
                    employees based on the policy terms.
                  </li>
                  <li>
                    <span>Waiting Period:</span> A waiting period may apply for
                    specific treatments and pre-existing diseases.
                  </li>
                </ol>
              </Grid>
              <Grid xs={12} md={4}>
                <img
                  src="../images/termsconditions_img.svg"
                  alt=""
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="excluison_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md={4} order={{ xs: 2, md: 1 }}>
                <img
                  src="../images/exclusions_img.svg"
                  alt=""
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
              <Grid
                xs={12}
                md={8}
                className="mb-margin2"
                order={{ xs: 1, md: 2 }}
              >
                <h2 className="mb-3">Exclusions</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    <span>Pre-existing Diseases:</span>
                    Initial waiting period before coverage starts for
                    pre-existing diseases.
                  </li>
                  <li>
                    <span>Non-allopathic Treatment:</span>
                    Expenses incurred on alternative treatments like Ayurveda,
                    Homeopathy, etc., unless specified.
                  </li>
                  <li>
                    <span>Cosmetic or Plastic Surgery:</span>
                    Procedures done for aesthetic purposes unless medically
                    necessary.
                  </li>
                  <li>
                    <span>Self-inflicted Injuries:</span> Injuries resulting
                    from attempted suicide or self-harm.
                  </li>
                </ol>
              </Grid>
            </Grid>
          </Box>

          <Box className="excluison_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} textAlign={"center"}>
                <h2 className="mb-3">Conclusion</h2>
                <p>
                  A Group Health Insurance Policy is an essential Employee
                  Benefit offered by big corporates in India to ensure the
                  health and well-being of their employees. By providing
                  comprehensive coverage, special features, and add-on covers,
                  companies can create a supportive and secure work environment
                  that fosters employee satisfaction, loyalty, and productivity.
                </p>
              </Grid>
            </Grid>
          </Box>

          <Box className="whyChoose_pp_section">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md={6} className="mb-margin2">
                <h2>Why Choose Amaze Insurance Brokers?</h2>
                <img
                  src="../images/whychoose_pp_img.svg"
                  alt=""
                  style={{ maxWidth: "450px", width: "100%", margin: "auto" }}
                  className="wchoose_img"
                />
              </Grid>
              <Grid xs={12} md={6}>
                <ul className="whyChoosepp_list">
                  <li>
                    <Box className="wc_box">
                      <img src="../images/wc_img1.svg" alt="" />
                      <h6>Expert Guidance</h6>
                      <p>
                        Our experienced professionals will design the best
                        coverage for your employees under Group Personal
                        Accident Insurance.
                      </p>
                    </Box>
                    <Box className="wc_box">
                      <img src="../images/wc_img2.svg" alt="" />
                      <h6>Seamless Claims Assistance</h6>
                      <p>
                        We provide comprehensive support throughout the claims
                        process.
                      </p>
                    </Box>
                  </li>
                  <li>
                    <Box className="wc_box">
                      <img src="../images/wc_img3.svg" alt="" />
                      <h6>Competitive Quotes</h6>
                      <p>
                        We work with leading insurance companies to secure the
                        best deals for you.
                      </p>
                    </Box>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};


export default GroupMedicalInsurance;
