import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../../../Components/Desktop/Navbar/Navbar";
import MFooter from "../../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../../Components/Mobile/MNavBar/MNavBar";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import "./../../../../../SCSS/ProductPages.scss";
import PPAccordionComponent from "../../../../../Components/PPAccordionComponent/PPAccordionComponent";
import { useState } from "react";
import ReadMoreText from "../../../../../Components/ReadMoreTextComponent/ReadMoreText";

const PortableElectronicEquipmentInsurance = () => {
  const isMobile = useIsMobile();

  const coveragePointsArray = [
    {
      title: "Coverage",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              Portable Electronic Equipment Insurance provides coverage for loss
              or damage to portable electronic devices such as laptops,
              smartphones, tablets, cameras, and other handheld gadgets. This
              policy typically covers risks including theft, accidental damage,
              fire, and electrical or mechanical breakdown.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Wordings",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              The policy wording outlines the terms, conditions, and coverage
              details of Portable Electronic Equipment Insurance. It specifies
              the insured perils, coverage limits, deductible amounts, premium
              rates, and claims procedures.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Special Features ",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>All-Risk Coverage:</span> Offers comprehensive coverage
              against a wide range of perils, ensuring protection for portable
              electronic devices in various situations.
            </li>
            <li>
              <span>Worldwide Coverage: </span> Provides coverage for loss or
              damage to insured devices anywhere in the world, offering peace of
              mind to travelers and mobile professionals.
            </li>
            <li>
              <span>New for Old Replacement: </span> In the event of a covered
              loss, the policy may provide for the replacement of the damaged
              device with a new one of similar make and model.
            </li>
            <li>
              <span>Emergency Assistance: </span> Some policies offer emergency
              assistance services, including device replacement or temporary
              rentals, in case of loss or damage while traveling.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Enhancements  ",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Extended Warranty: </span>
              Provides extended warranty coverage for electronic devices beyond
              the manufacturer's warranty period, offering additional protection
              against defects and malfunctions.
            </li>
            <li>
              <span>Accidental Damage Cover: </span> Enhances coverage to
              include accidental damage caused by drops, spills, or other
              unforeseen events not covered under standard warranties.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Additional Covers:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Accessories Coverage: </span> Offers coverage for
              accessories such as chargers, cases, and peripherals associated
              with insured electronic devices.
            </li>
            <li>
              <span>Data Loss Protection: </span>
              Provides coverage for data recovery and restoration expenses in
              the event of data loss due to covered perils.
            </li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="subProducts_pp">
          <Grid container columnSpacing={3} className="row">
            <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
              <h1>Portable Electronic Equipment Insurance Policies</h1>
            </Grid>
          </Grid>

          <Box className="coverages-section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md className="mb-margin2">
                <PPAccordionComponent data={coveragePointsArray} />
              </Grid>
              <Grid xs={12} md={"auto"} margin={"auto"}>
                <img
                  src="/images/erection_allrisk_ins_policy.svg"
                  alt=""
                  style={{
                    width: "100%",
                    maxWidth: isMobile ? "220px" : "310",
                    margin: "auto",
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="claimProcedure-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom={"24px"} textAlign={"center"}>
                <h2>Claims Procedure</h2>
              </Grid>
              <Grid xs={12} textAlign={"center"}>
                <p>
                  In the event of loss or damage to a portable electronic
                  device, the insured must notify the insurer promptly and
                  provide supporting documentation, such as a police report,
                  proof of ownership, and evidence of the loss or damage. The
                  insurer will then assess the claim, verify the loss, and
                  facilitate the claims settlement process in accordance with
                  the policy terms.
                </p>
              </Grid>
            </Grid>
          </Box>

          <Box className="termsConditions_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md={8} className="mb-margin2">
                <h2 className="mb-3">Terms and Conditions</h2>
                <p>
                  The policy's terms and conditions specify the rights and
                  obligations of both the insurer and the insured. These include
                  coverage duration, premium payment terms, deductible amounts,
                  claims reporting procedures, and policy renewal conditions.
                </p>
              </Grid>
              <Grid xs={12} md={4}>
                <img
                  src="../images/termsconditions_img.svg"
                  alt=""
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="excluison_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md={4} order={{ xs: 2, md: 1 }}>
                <img
                  src="../images/exclusions_img.svg"
                  alt=""
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
              <Grid
                xs={12}
                md={8}
                className="mb-margin2"
                order={{ xs: 1, md: 2 }}
              >
                <h2 className="mb-3">Exclusions</h2>
                <p>
                  Portable Electronic Equipment Insurance policies may exclude
                  coverage for losses resulting from intentional acts, wear and
                  tear, cosmetic damage, and unauthorized modifications or
                  repairs.
                </p>
              </Grid>
            </Grid>
          </Box>

          <Box className="claimProcedure-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom={"24px"} textAlign={"center"}>
                <h2>Conclusion</h2>
              </Grid>
              <Grid xs={12} textAlign={"center"}>
                <p>
                  Portable Electronic Equipment Insurance provides essential
                  protection for valuable electronic devices against unforeseen
                  risks and perils. By partnering with Amaze Insurance Brokers,
                  you gain access to comprehensive coverage options, expert
                  advice, and dedicated support throughout the insurance
                  process.
                </p>
              </Grid>
              <Grid xs={12} textAlign={"center"}>
                <p>
                  For further inquiries or to explore Portable Electronic
                  Equipment Insurance options tailored to your needs, contact us
                  today.
                </p>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default PortableElectronicEquipmentInsurance;
