import React, { useState } from "react";
import { Box, Link, Rating } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import Navbar from "../../../../Components/Desktop/Navbar/Navbar";
import "./../../../../SCSS/CMSPages.scss";
import Footer from "../../../../Components/Desktop/Footer/Footer";
import { COLORS } from "../../../../SupportingFiles/colors";
import MFooter from "../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../Components/Mobile/MNavBar/MNavBar";

const OurServices = () => {
  const isMobile = useIsMobile();
  return (
    <Box className="cmsPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="cms-inner">
        <Grid container columnSpacing={3} className="row" marginBottom={"24px"}>
          <Grid xs={12} textAlign={"center"}>
            <h2>Our Services</h2>
          </Grid>
        </Grid>

        <Box className="services-header-section mb-margin1">
          <Grid
            container
            columnSpacing={3}
            className="row"
            alignItems={"center"}
          >
            <Grid xs={12} md={6} className="mb-margin2">
              <h4 className="mb-2">
                Welcome to Amaze Insurance Brokers: Your Partner in
                Comprehensive Insurance Portfolio Management
              </h4>
              <p>
                At Amaze Insurance Brokers, we offer bespoke Insurance Portfolio
                Management Services designed to meet the unique needs and
                objectives of corporate clients. With our expertise, dedication,
                and commitment to excellence, we ensure that your insurance
                portfolio is optimized to provide maximum protection and value
                for your business
              </p>
            </Grid>
            <Grid xs={12} md={6}>
              <img
                src="./images/servicesHeader-img.svg"
                alt=""
                style={{ width: "100%", maxWidth: "460px", margin: "auto" }}
              />
            </Grid>
          </Grid>
        </Box>

        <Box className="services-list-section mb-margin1">
          <Grid container columnSpacing={3} className="row">
            <Grid xs={12}>
              <ul className="services_list">
                <li>
                  <h6>Tailored Insurance Programs (MSIP)</h6>
                  <p>
                    We specialize in designing the Most Suitable Insurance
                    Programs (MSIP) for our clients, meticulously tailored to
                    meet their specific insurance requirements. Our expert
                    advisors conduct comprehensive assessments to understand
                    your business needs and objectives, ensuring that your
                    insurance program provides comprehensive coverage and meets
                    regulatory compliance.
                  </p>
                </li>
                <li>
                  <h6>Market Access:</h6>
                  <p>
                    As licensed insurance brokers, we have access to all
                    insurance companies in the market, allowing us to negotiate
                    the best price and terms on your behalf. We leverage our
                    industry connections and market expertise to secure
                    competitive rates and favorable terms that meet your
                    budgetary constraints and risk tolerance.
                  </p>
                </li>
                <li>
                  <h6>Efficient Renewals:</h6>
                  <p>
                    We understand the importance of prompt and timely renewals
                    to maintain continuous coverage and compliance. Our team
                    ensures that your insurance policies are renewed promptly
                    and effectively, minimizing any potential gaps in coverage
                    and ensuring uninterrupted protection for your business.
                  </p>
                </li>
                <li>
                  <h6>Employee Benefits Solutions:</h6>
                  <p>
                    In addition to traditional insurance covers, we offer
                    Employee Benefits solutions such as Group Health, Group
                    Personal Accident (GPA), and Group Term Insurance (GTI). Our
                    experts design and implement customized Employee Benefits
                    programs that attract and retain top talent while providing
                    financial security and peace of mind to your employees.
                  </p>
                </li>
                <li>
                  <h6>Claims Administration and Management:</h6>
                  <p>
                    We design systems and procedures for proper claims
                    administration, ensuring timely and judicious settlements in
                    the event of a claim. Our dedicated claims management team
                    handles the entire claims process on your behalf, providing
                    expert guidance and support to expedite settlements and
                    minimize disruptions to your business operations.
                  </p>
                </li>
                <li>
                  <h6>Insurance Audits:</h6>
                  <p>
                    Our Insurance Audits enable a comprehensive audit of your
                    insurance portfolio, identifying any gaps, redundancies, or
                    areas for improvement. Based on the audit findings, we
                    provide actionable recommendations to optimize your
                    insurance program and enhance your risk management
                    strategies.
                  </p>
                </li>
                <li>
                  <h6>Thorough Analysis and Evaluation:</h6>
                  <p>
                    Our team conducts a thorough analysis and evaluation of your
                    existing insurance covers to identify any gaps or
                    deficiencies in coverage. We then recommend definitive
                    solutions to fill these gaps, ensuring that your business is
                    adequately protected against potential risks and
                    liabilities.
                  </p>
                </li>
                <li>
                  <h6>Asset and Liability Coverage Recommendations:</h6>
                  <p>
                    Our advisors provide recommendations on ensuring proper and
                    adequate coverage of your assets, operations, and liability
                    areas. We work closely with you to identify potential risks
                    and exposures, recommending insurance solutions that
                    mitigate these risks and protect your financial interests.
                  </p>
                </li>
                <li>
                  <h6>Total Claims Management:</h6>
                  <p>
                    We provide total claims management services to ensure
                    expeditious settlement of claims, giving you peace of mind
                    during challenging times. Our team advocates on your behalf
                    to facilitate fair and timely claims settlements, allowing
                    you to focus on running your business with confidence.
                    Experience the Amaze Difference.
                    <br /> At Amaze Insurance Brokers, we go above and beyond to
                    deliver exceptional Insurance Portfolio Management Services
                    that meet the evolving needs of your business. Contact us
                    today to learn more about how we can help optimize your
                    insurance portfolio and provide you with the peace of mind
                    and financial security you deserve.
                  </p>
                </li>
              </ul>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default OurServices;
