import React, { useState } from "react";
import { Box, Link, Rating } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import Navbar from "../../../../Components/Desktop/Navbar/Navbar";
import "./../../../../SCSS/CMSPages.scss";
import Footer from "../../../../Components/Desktop/Footer/Footer";
import { COLORS } from "../../../../SupportingFiles/colors";
import MNavBar from "../../../../Components/Mobile/MNavBar/MNavBar";
import MFooter from "../../../../Components/Mobile/MFooter/MFooter";

const PrivacyPolicy = () => {
  const isMobile = useIsMobile();
  return (
    <Box className="cmsPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="cms-inner">
        <Grid container columnSpacing={3} className="row" marginBottom={"24px"}>
          <Grid xs={12} textAlign={"center"}>
            <h2>Privacy Policy</h2>
          </Grid>
        </Grid>

        <Box className="privacypolicy-section mb-margin1">
          <Grid
            container
            columnSpacing={3}
            className="row"
            marginBottom={"24px"}
          >
            <Grid xs={12}>
              <p>
                At Amaze Insurance Brokers, we are committed to protecting the
                privacy and security of your personal information. This Privacy
                Policy outlines the types of information we collect when you use
                our online insurance sales platform, www.bimastreet.com, and how
                we use, share, and safeguard that information. By accessing or
                using our platform, you consent to the practices described in
                this Privacy Policy.
              </p>
            </Grid>
          </Grid>

          <Grid container columnSpacing={3} className="row">
            <Grid xs={12} marginBottom={"24px"}>
              <h4 className="mb-3">1) Information Collected</h4>
              <ul className="points_list">
                <li>
                  <span>1.1 Personal Information:</span> When you use our
                  platform, we may collect personal information that you
                  voluntarily provide, such as your name, address, email
                  address, phone number, date of birth, and other relevant
                  details necessary for the insurance application and purchase
                  process.
                </li>
                <li>
                  <span>1.2 Payment Information:</span> For insurance purchases,
                  we may collect payment information, including credit card
                  numbers or bank account details, in a secure manner to process
                  transactions.
                </li>
                <li>
                  <span>1.3 Log Data:</span> Like many websites, we
                  automatically collect certain information when you visit our
                  platform, including your IP address, browser type,
                  referring/exit pages, and operating system. This information
                  is used for analyzing trends, administering the site, and
                  improving the overall user experience.
                </li>
                <li>
                  <span>1.4 Cookies and Similar Technologies:</span> We may use
                  cookies and similar technologies to collect information about
                  your browsing behavior, track usage patterns, and remember
                  your preferences. You can control the use of cookies through
                  your browser settings.
                </li>
              </ul>
            </Grid>
            <Grid xs={12} marginBottom={"24px"}>
              <h4 className="mb-3">2) Use of Information</h4>
              <ul className="points_list">
                <li>
                  <span>2.1 Provide Services:</span>
                  We use the collected information to facilitate insurance
                  applications, process payments, and deliver insurance policies
                  to you. process.
                </li>
                <li>
                  <span>2.2 Customer Support:</span> We may use your information
                  to respond to your inquiries, provide customer support, and
                  address any issues or concerns you may have.
                </li>
                <li>
                  <span> 2.3 Marketing:</span> With your consent, we may use
                  your contact information to send you promotional materials and
                  relevant offers about our services and products. You can
                  opt-out of receiving marketing communications at any time.
                </li>
                <li>
                  <span>2.4 Legal Compliance:</span> We may use and disclose
                  your information as required by law, legal processes, or to
                  enforce our platform's terms and conditions.
                </li>
              </ul>
            </Grid>
            <Grid xs={12} marginBottom={"24px"}>
              <h4 className="mb-3">3) Sharing of Information</h4>
              <ul className="points_list">
                <li>
                  <span>3.1 Third-Party Service Providers:</span>
                  We may share your personal information with trusted
                  third-party service providers who assist us in operating our
                  platform, conducting business activities, and delivering
                  services to you.
                </li>
                <li>
                  <span>3.2 Business Transfers:</span> In the event of a merger,
                  acquisition, or sale of all or a portion of our assets, your
                  information may be transferred to the acquiring entity.
                </li>
                <li>
                  <span> 3.3 Legal Requirements:</span> We may share your
                  information with law enforcement, regulatory authorities, or
                  other third parties if required by law or to protect our
                  rights, privacy, safety, or property.
                </li>
              </ul>
            </Grid>
            <Grid xs={12} marginBottom={"24px"}>
              <h4 className="mb-3">4) Data Security</h4>
              <p>
                We implement appropriate technical and organizational measures
                to safeguard your personal information from unauthorized access,
                disclosure, or alteration. However, please note that no method
                of transmission over the internet or electronic storage is 100%
                secure, and we cannot guarantee the absolute security of your
                data.
              </p>
            </Grid>
            <Grid xs={12} marginBottom={"24px"}>
              <h4 className="mb-3">5) Your Rights</h4>
              <p>
                You have the right to access, correct, or delete your personal
                information that we hold. You can exercise these rights by
                contacting us through the contact information provided below. We
                will respond to your requests within a reasonable timeframe.
              </p>
            </Grid>
            <Grid xs={12} marginBottom={"24px"}>
              <h4 className="mb-3">6) Children's Privacy</h4>
              <p>
                Our platform is not intended for use by individuals under the
                age of 18. We do not knowingly collect personal information from
                minors. If you believe we have inadvertently collected
                information from a minor, please contact us, and we will
                promptly delete it.
              </p>
            </Grid>
            <Grid xs={12} marginBottom={"24px"}>
              <h4 className="mb-3">7) Changes to the Privacy Policy</h4>
              <p>
                We may update this Privacy Policy from time to time to reflect
                changes in our practices or for other operational, legal, or
                regulatory reasons. Any modifications will be effective upon
                posting the updated policy on the platform. Please check this
                page periodically for the most current version of our Privacy
                Policy.
              </p>
            </Grid>
          </Grid>

          <Grid container columnSpacing={3} className="row">
            <Grid xs={12}>
              <h4 className="mb-2">Contact Us</h4>
              <p className="mb-2">
                Amaze Insurance Brokers Private Limited, IRDAI license Number:
                549, Valid till: 24/02/2025
              </p>
              <p className="mb-2">
                <span style={{ fontWeight: 500, color: COLORS.secondary }}>
                  Regd.Office :
                </span>{" "}
                Ground Floor,Villa No.10, Richmond Villas, Near Glendale
                International School, Hydershakote, Hyderabad-500086.
              </p>
              <p className="mb-2">
                <span style={{ fontWeight: 500, color: COLORS.secondary }}>
                  RCorp Office:
                </span>{" "}
                #403, THE LEGEND, Metro Pillar C1338, Motilal Nehru Nagar,
                Begumpet, Hyderabad - 500016.
              </p>
              <p>Phone: 040-27765823/21/22/24</p>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default PrivacyPolicy;
