import { CommonURLs } from "../../URLCollection/Common/CommonURLs";
import { GetAPI } from "../../Axios/AxiosInstance";
import { PostAPI } from "../../Axios/AxiosInstanceToken";
import { FormDataPostAPI } from "../../Axios/FormDataAxiosInstanceToken";

const ANALYSIS_FORM_SUBMIT = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  FormDataPostAPI.call(
    CommonURLs.ANALYSIS_FORM_SUBMIT,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

export const HOME_SERVICES = {
  ANALYSIS_FORM_SUBMIT
};
