import React from "react";
import { Box, Tab } from "@mui/material";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import Navbar from "../../../../Components/Desktop/Navbar/Navbar";
import "./../../../../SCSS/CMSPages.scss";
import Footer from "../../../../Components/Desktop/Footer/Footer";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AboutusTab from "./AboutUsTab/AboutusTab";
import ManagementPersonnelTab from "./ManagementPersonnelTab/ManagementPersonnelTab";
import ManagingDirectorTab from "./ManagingDirectorTab/ManagingDirectorTab";
import MNavBar from "../../../../Components/Mobile/MNavBar/MNavBar";
import MFooter from "../../../../Components/Mobile/MFooter/MFooter";

const Aboutus = () => {
  const isMobile = useIsMobile();
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <Box className="cmsPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="cms-inner">
        <Box className="aboutsus-section mb-margin1">
          <Box sx={{ width: "100%" }}>
            <TabContext value={value}>
              <Box>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="About us" value="1" />
                  <Tab label="Management Personnel" value="2" />
                  <Tab label="Managing Director and CEO" value="3" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <AboutusTab />
              </TabPanel>
              <TabPanel value="2">
                <ManagementPersonnelTab />
              </TabPanel>
              <TabPanel value="3">
                <ManagingDirectorTab/>
              </TabPanel>
            </TabContext>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default Aboutus;
