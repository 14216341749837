import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../../../Components/Desktop/Navbar/Navbar";
import MFooter from "../../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../../Components/Mobile/MNavBar/MNavBar";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import "./../../../../../SCSS/ProductPages.scss";
import PPAccordionComponent from "../../../../../Components/PPAccordionComponent/PPAccordionComponent";
import ReadMoreText from "../../../../../Components/ReadMoreTextComponent/ReadMoreText";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const DOLiabilityInsurancePolicy = () => {
  const isMobile = useIsMobile();

  const coveragePointsArray = [
    {
      title: "Coverage:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Directors and Officers Liability: </span>Protects directors
              and officers against claims arising from their decisions and
              actions while managing the company.
            </li>
            <li>
              <span>Corporate Entity Reimbursement:</span> Reimburses the
              company for the costs incurred in indemnifying the directors and
              officers for covered claims.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Special Features: ",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Legal Defense Costs:</span> Covers legal defense costs,
              including attorney fees, court costs, and settlements or judgments
              against the directors and officers.
            </li>
            <li>
              <span>Severability of Exclusions: </span> Protects innocent
              directors and officers if one or more individuals are found guilty
              of fraudulent or dishonest acts.
            </li>
            <li>
              <span>Advance Payment of Defense Costs: </span> Provides an
              advance payment for defense costs to enable timely defense against
              claims.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Enhancements:  ",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Entity Coverage Extension:</span> Extends coverage to the
              corporate entity for claims related to securities, employment
              practices, and other specified wrongful acts.
            </li>
            <li>
              <span>Worldwide Coverage: </span> Provides coverage for claims
              arising from the company’s operations worldwide, subject to policy
              terms and conditions.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Add-on Covers:  ",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Employment Practices Liability: </span> Covers claims
              related to wrongful termination, discrimination, harassment, and
              other employment-related issues.
            </li>
            <li>
              <span> Cyber Liability: </span> Covers claims arising from data
              breaches, cyber-attacks, and other cyber risks, including legal
              defense costs and regulatory fines.
            </li>
            <li>
              <span> Outside Directorship Liability:</span> Extends coverage to
              directors serving on the boards of other companies, subject to
              policy terms.
            </li>
          </ul>
        </>
      ),
    },
  ];


  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="subProducts_pp">
          <Grid container columnSpacing={3} className="row">
            <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
              <h1>
                Directors and Officers (D&O) Liability Insurance Policy:
                Protecting Corporate Leaders
              </h1>
            </Grid>
          </Grid>

          <Box className="coverages-section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md className="mb-margin2">
                <PPAccordionComponent data={coveragePointsArray} />
              </Grid>
              <Grid xs={12} md={"auto"} margin={"auto"}>
                <img
                  src="/images/DO_ins_policy_img.svg"
                  alt=""
                  style={{
                    width: "100%",
                    maxWidth: isMobile ? "180px" : "208px",
                    margin: "auto",
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="scroll-box-section mb-20">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom="24px" textAlign="center">
                <h2>
                  Coverage Section – Directors & Officers Liability Insurance
                </h2>
              </Grid>
              <Grid xs={12}>
                <Box className="scrollBox">
                  <Box className="scrollBox-inner">
                    <ul>
                      <li>
                        <h3 className="mb-3">Coverage A: Insuring Agreement</h3>
                        <p>
                          The insurer agrees to indemnify the insured persons
                          for Loss arising from a Claim first made against them
                          during the policy period, or Extended Reporting Period
                          if applicable, for a Wrongful Act committed or
                          allegedly committed by them in their capacity as a
                          director or officer of the company.
                        </p>
                      </li>
                      <li>
                        <h3 className="mb-3">
                          Coverage B: Corporate Entity Reimbursement
                        </h3>
                        <p>
                          The insurer agrees to reimburse the company for Loss
                          that the company is legally obligated to pay as
                          indemnification to the insured persons for a Wrongful
                          Act committed or allegedly committed by them in their
                          capacity as directors or officers of the company,
                          provided that the company is permitted or required by
                          law to indemnify such persons.
                        </p>
                      </li>
                      <li>
                        <h3 className="mb-3">Coverage Extensions:</h3>
                        <h5 className="mb-1">Defense Costs:</h5>
                        <p>
                          The insurer agrees to pay Defense Costs on behalf of
                          the insured persons in connection with a Claim for a
                          Wrongful Act, including attorney fees, court costs,
                          and other legal expenses.
                        </p>
                      </li>
                      <li>
                        <h3 className="mb-3">Coverage Extensions:</h3>
                        <h5 className="mb-1">Investigation Costs: </h5>
                        <p>
                          The insurer agrees to pay Investigation Costs incurred
                          by the insured persons in connection with a regulatory
                          or criminal investigation arising from a Wrongful Act.
                        </p>
                      </li>
                      <li>
                        <h3 className="mb-3">Coverage Extensions:</h3>
                        <h5 className="mb-1">Settlement and Judgment Costs:</h5>
                        <p>
                          The insurer agrees to pay Settlement and Judgment
                          Costs resulting from a Claim for a Wrongful Act,
                          subject to the applicable policy limits and
                          deductibles.
                        </p>
                        <p>
                          The term
                          <span style={{ fontWeight: 600 }}>
                            “wrongful act”
                          </span>
                          in the context of Directors and Officers (D&O)
                          Liability Insurance refers to any actual or alleged
                          act, error, misstatement, misleading statement,
                          omission, neglect, or breach of duty committed or
                          attempted by a director or officer of a company in
                          their capacity as a director or officer. In the Indian
                          Insurance market, the definition of “wrongful act”
                          under D&O Liability Insurance is typically broad and
                          encompasses a wide range of actions and decisions made
                          by directors and officers while performing their
                          duties. Some examples of wrongful acts covered may
                          include:
                        </p>
                        <ul className="dottted-list">
                          <li>Breach of fiduciary duty </li>
                          <li>Negligence or mismanagement </li>
                          <li>Errors or omissions in judgment </li>
                          <li>Misrepresentation or misleading statements </li>
                          <li>
                            Violation of laws, regulations, or company policies{" "}
                          </li>
                          <li>
                            Employment-related issues, such as wrongful
                            termination, discrimination, or harassment{" "}
                          </li>
                          <li>Breach of contract </li>
                          <li>
                            Insider trading or other securities violations{" "}
                          </li>
                          <li>
                            Failure to comply with corporate governance
                            standards{" "}
                          </li>
                          <li>
                            Cyber breaches or data privacy violations (if cyber
                            liability coverage is included){" "}
                          </li>
                        </ul>
                      </li>
                      <p>
                        It’s essential to review the specific policy wording and
                        terms and conditions of a D&O Liability Insurance policy
                        to understand the scope of coverage and any exclusions
                        that may apply. The definition of “wrongful act” can
                        vary between insurance providers and policy forms, so
                        it’s crucial to consult with an experienced insurance
                        broker to ensure that your policy provides the
                        appropriate coverage for your organization’s specific
                        risks and exposures.{" "}
                      </p>
                    </ul>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box className="claimProcedure-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom="24px" textAlign="center">
                <h2>Claims Procedure</h2>
              </Grid>
              <Grid xs={12}>
                <ul className="claimProcedure_list">
                  <li>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom="24px"
                    >
                      <Box textAlign="left">
                        <h4>01</h4>
                        <h6>Immediate Notification</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon1.svg"
                        alt="notification icon"
                        style={{
                          width: "52px",
                          height: "52px",
                          borderRadius: "4px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "In case of a claim or potential claim, the insured directors, officers, or the company must notify the insurance company or broker as soon as possible. "
                      }
                      maxlength={100}
                    />
                  </li>
                  <li>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom="24px"
                    >
                      <Box textAlign="left">
                        <h4>02</h4>
                        <h6>Claim Documentation</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon2.svg"
                        alt="documentation icon"
                        style={{
                          width: "52px",
                          height: "52px",
                          borderRadius: "4px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "Submit a detailed claim form along with all relevant documents, including legal notices, correspondence, and evidence supporting the claim, to the insurance company.  "
                      }
                      maxlength={100}
                    />
                  </li>
                  <li>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom="24px"
                    >
                      <Box textAlign="left">
                        <h4>03</h4>
                        <h6>Claim Evaluation and Settlement</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon3.svg"
                        alt="evaluation and settlement icon"
                        style={{
                          width: "52px",
                          height: "52px",
                          borderRadius: "4px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "The insurance company will evaluate the claim, conduct an investigation if necessary, and either approve or deny the claim based on the policy terms and conditions. If approved, the insurer will indemnify the insured directors and officers or reimburse the company for covered losses.  "
                      }
                      maxlength={100}
                    />
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>

          <Box className="termsConditions_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems="center"
            >
              <Grid xs={12} md={8} className="mb-margin2">
                <h2 className="mb-3">Terms and Conditions</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    <span>Policy Period:</span>Typically, the policy is issued
                    for a one-year period and needs to be renewed annually.
                  </li>
                  <li>
                    <span>Limits of Liability:</span> The policy limit is the
                    maximum amount the insurer will pay for covered claims
                    during the policy period, subject to deductibles and
                    sub-limits.
                  </li>
                  <li>
                    <span>Deductibles:</span> A deductible is the amount the
                    insured must pay before the insurance coverage kicks in.
                  </li>
                  <li>
                    <span>Premium Payment:</span> Premiums are payable annually,
                    based on the company’s size, industry, risk profile, and
                    coverage limits.
                  </li>
                </ol>
              </Grid>
              <Grid xs={12} md={4}>
                <img
                  src="../images/termsconditions_img.svg"
                  alt="terms and conditions icon"
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="excluison_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems="center"
            >
              <Grid xs={12} md={4} order={{ xs: 2, md: 1 }}>
                <img
                  src="../images/exclusions_img.svg"
                  alt="exclusions icon"
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
              <Grid
                xs={12}
                md={8}
                className="mb-margin2"
                order={{ xs: 1, md: 2 }}
              >
                <h2 className="mb-3">Exclusions</h2>
                <Box className="exclusion_list">
                  <ol className="ordered-list">
                    <li>
                      <span>This policy does not cover Loss: </span>
                      <p className="mt-2">
                        Arising from fraudulent, dishonest, or criminal acts
                        committed by the insured persons.
                      </p>
                      <p className="mt-2">
                        Resulting from bodily injury or property damage unless
                        directly caused by a covered Wrongful Act.
                      </p>
                      <p className="mt-2">
                        Arising from claims made before the retroactive date (if
                        applicable) or after the end of the policy period.
                      </p>
                      <p className="mt-2">
                        Arising from claims under other specified policy
                        exclusions as set forth in the policy wording.{" "}
                      </p>
                    </li>
                    <li>
                      <span>Conditions: </span>
                      <ul className="ordered-inner-list">
                        <li>
                          <span>Notice of Claim: </span>
                          The insured persons or the company must provide
                          written notice of a Claim to the insurer as soon as
                          practicable but no later than the end of the policy
                          period or Extended Reporting Period, if applicable.
                        </li>
                        <li>
                          <span>Cooperation: </span>
                          The insured persons and the company must cooperate
                          fully with the insurer in the investigation, defense,
                          and settlement of any Claim.
                        </li>
                        <li>
                          <span>Subrogation:</span> If the insurer pays a claim,
                          it will be subrogated to the insured’s rights to
                          recover from any third party responsible for the loss.
                          The insured must cooperate with the insurer in any
                          recovery efforts.{" "}
                        </li>
                        <li>
                          <span>Limits of Liability:</span> The insurer’s total
                          liability under this policy shall not exceed the Limit
                          of Liability specified in the Declarations, inclusive
                          of Defense Costs, Investigation Costs, and Settlement
                          and Judgment Costs.{" "}
                        </li>
                      </ul>
                    </li>
                  </ol>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box className="excluison_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems="center"
            >
              <Grid xs={12} textAlign="center">
                <h2 className="mb-3">Conclusion</h2>
                <p>
                  A Directors and Officers (D&O) Liability Insurance Policy is
                  essential for big corporates, especially in industries like
                  pharma and IT, where corporate leaders face significant risks
                  and exposures in managing complex operations and regulatory
                  compliance. By providing comprehensive coverage, special
                  features, and add-on covers, companies can protect their
                  directors, officers, and corporate entity from the financial
                  repercussions of claims arising from management decisions and
                  actions.
                </p>
                <p className="mt-4">
                  If you’re a big corporate looking to enhance your risk
                  management strategy seeking more information about D&O
                  Liability Insurance, it’s essential to consult us to
                  understand the various options available and tailor a coverage
                  solution that meets your specific needs and objectives.{" "}
                </p>
              </Grid>
            </Grid>
          </Box>

          <Box className="whyChoose_pp_section">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems="center"
            >
              <Grid xs={12} md={6} className="mb-margin2">
                <h2>Why Choose Amaze Insurance Brokers?</h2>
                <img
                  src="../images/whychoose_pp_img.svg"
                  alt=""
                  style={{ maxWidth: "450px", width: "100%", margin: "auto" }}
                  className="wchoose_img"
                />
              </Grid>
              <Grid xs={12} md={6}>
                <ul className="whyChoosepp_list">
                  <li>
                    <Box className="wc_box">
                      <img src="../images/wc_img1.svg" alt="guidance icon" />
                      <h6>Expert Guidance</h6>
                      <p>
                        Our team of experts understands the complex nature of
                        D&O Liability and can provide you with expert guidance
                        and support every step of the way.
                      </p>
                    </Box>
                    <Box className="wc_box">
                      <img
                        src="../images/wc_img2.svg"
                        alt="claims assistance icon"
                      />
                      <h6>Seamless Claims Assistance</h6>
                      <p>
                        We provide comprehensive support throughout the claims
                        process.
                      </p>
                    </Box>
                  </li>
                  <li>
                    <Box className="wc_box">
                      <img src="../images/wc_img3.svg" alt="quotes icon" />
                      <h6>Competitive Quotes</h6>
                      <p>
                        We work with leading insurance companies to secure the
                        best deals for you.
                      </p>
                    </Box>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default DOLiabilityInsurancePolicy;
