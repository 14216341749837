import React, { useState } from "react";
import { Box, Link, Rating } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import Navbar from "../../../../Components/Desktop/Navbar/Navbar";
import "./../../../../SCSS/CMSPages.scss";
import Footer from "../../../../Components/Desktop/Footer/Footer";
import RKTextField from "../../../../Components/InputFields/RKTextField/RKTextField";
import CustomButton from "../../../../Components/InputFields/CustomButton/CustomButton";
import SelectDropdown from "../../../../Components/InputFields/SelectDropdown/SelectDropdown";
import MNavBar from "../../../../Components/Mobile/MNavBar/MNavBar";
import MFooter from "../../../../Components/Mobile/MFooter/MFooter";

const Contactus = () => {
  const isMobile = useIsMobile();
  const [formFields, setFormFields] = useState<{
    name: { value: string; warn_status: boolean };
    mobile: { value: string; warn_status: boolean };
    email: { value: string; warn_status: boolean };
    interestedin: { value: string; warn_status: boolean };
  }>({
    name: { value: "", warn_status: false },
    mobile: { value: "", warn_status: false },
    email: { value: "", warn_status: false },
    interestedin: { value: "", warn_status: false },
  });

  const interestedinData = [
    {
      label: "Health Insurance",
      value: "Health Insurance",
    },
    {
      label: "Term Insurance",
      value: "Term Insurance",
    },
    {
      label: "Car Insurance",
      value: "Car Insurance",
    },
    {
      label: "Bike Insurance",
      value: "Bike Insurance",
    },
  ];

  const updateFieldsState = (attrName: string, value: string) => {
    setFormFields((prev) => ({ ...prev, [attrName]: { value: value } }));
  };
  return (
    <Box className="cmsPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="cms-inner">
        <Grid container columnSpacing={3} className="row" marginBottom={"24px"}>
          <Grid xs={12} textAlign={"center"}>
            <h2>Contact Us</h2>
          </Grid>
        </Grid>

        <Box className="contact-section mb-margin1">
          <Grid
            container
            columnSpacing={3}
            className="row"
            justifyContent={"center"}
          >
            <Grid xs={12} md={6} textAlign={"center"} className="mb-margin2">
              <img
                src="./images/contactPage_img.svg"
                alt=""
                style={{ width: "100%", maxWidth: "380px", margin: "auto" }}
              />
              <h4>Amaze Insurance Brokers Pvt Ltd.</h4>
              <p>
                #403, THE LEGEND, Metro Pillar C1338, Motilal Nehru Nagar,
                Begumpet, Hyderabad - 500016
              </p>
            </Grid>
            {/* <Grid xs={12} md={6}>
              <Box className="formBox">
                <Grid container columnSpacing={3} marginBottom={"24px"}>
                  <Grid xs={12} textAlign={"center"}>
                    <h5 className="mb-2">Fill the Details</h5>
                    <p>Share your information and expect a swift response!</p>
                  </Grid>
                </Grid>
                <Grid container columnSpacing={3}>
                  <Grid xs={12} md={6}>
                    <RKTextField
                      class_name="inputField mb-6"
                      title={"Full Name"}
                      value={formFields.name.value}
                      attrName={"name"}
                      value_update={updateFieldsState}
                      warn_status={formFields.name.warn_status}
                    />
                  </Grid>
                  <Grid xs={12} md={6}>
                    <RKTextField
                      class_name="inputField mb-6"
                      title={"Email"}
                      value={formFields.email.value}
                      attrName={"email"}
                      value_update={updateFieldsState}
                      warn_status={formFields.email.warn_status}
                    />
                  </Grid>
                </Grid>
                <Grid container columnSpacing={3}>
                  <Grid xs={12} md={6}>
                    <RKTextField
                      class_name="inputField mb-6"
                      title={"Mobile"}
                      value={formFields.mobile.value}
                      attrName={"mobile"}
                      value_update={updateFieldsState}
                      warn_status={formFields.mobile.warn_status}
                    />
                  </Grid>
                  <Grid xs={12} md={6}>
                    <SelectDropdown
                      class_name="inputField mb-6"
                      title="Interested in"
                      attrName={"interestedin"}
                      value={formFields.interestedin.value}
                      value_update={updateFieldsState}
                      data={interestedinData}
                      warn_status={formFields.interestedin.warn_status}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  columnSpacing={3}
                  marginBottom={"12px"}
                  justifyContent={"center"}
                >
                  <Grid xs={6} md>
                    <p className="mailP mb-3">
                      Support mail:{" "}
                      <a href="mailto:care@bimastreet.com">
                        care@bimastreet.com
                      </a>
                    </p>
                  </Grid>
                  <Grid xs={6} md={"auto"}>
                    <p className="mailP mb-3">
                      Other Query:
                      <a href="mailto:care@bimastreet.com">info@amaze.com</a>
                    </p>
                  </Grid>
                  <Grid xs={"auto"} md>
                    <p className="mailP mb-3">
                      Phone Number:
                      <a href="">040-27765821/22/23/24</a>
                    </p>
                  </Grid>
                </Grid>
                <Grid container columnSpacing={3}>
                  <Grid xs={12} textAlign={"center"}>
                    <CustomButton
                      className="primaryBtn"
                      fullWidth={false}
                      variant="contained"
                      text="Submit Details"
                      onClick={() => {}}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid> */}
          </Grid>
        </Box>

        <Box className="map-section mb-margin1">
          <Grid container columnSpacing={3} className="row">
            <Grid xs={12}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.2902831750866!2d78.46616037594073!3d17.44581500111775!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb90a3fa4a9041%3A0x15d48fed0e20343d!2sMotilal%20Nehru%20Nagar%20Rd%2C%20Motilal%20Nehru%20Nagar%2C%20Begumpet%2C%20Hyderabad%2C%20Telangana%20500016!5e0!3m2!1sen!2sin!4v1731929532799!5m2!1sen!2sin"
                width="100%"
                height="400"
                style={{ border: 0, borderRadius: "12px" }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default Contactus;
