import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../../../Components/Desktop/Navbar/Navbar";
import MFooter from "../../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../../Components/Mobile/MNavBar/MNavBar";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import "./../../../../../SCSS/ProductPages.scss";
import PPAccordionComponent from "../../../../../Components/PPAccordionComponent/PPAccordionComponent";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useState } from "react";
import Slider from "react-slick";
import ReadMoreText from "../../../../../Components/ReadMoreTextComponent/ReadMoreText";

const JewelryShowrooms = () => {
  const isMobile = useIsMobile();

  const coveragePointsArray = [
    {
      title: "Coverage:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              Jeweler's Block Insurance provides extensive coverage for jewelry
              inventory, including precious stones, metals, and finished jewelry
              items, against risks such as theft, burglary, fire, and accidental
              damage.
            </li>
            <li>
              Coverage extends to various locations, including jewelry
              showrooms, manufacturing facilities, storage facilities, transit,
              and exhibitions.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Wordings and Special Features:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              Policies are designed with clear and precise language to ensure
              policyholders understand their coverage and obligations.
            </li>
            Special features may include coverage for loss of revenue due to
            business interruption, loss of reputation expenses, and protection
            against counterfeit goods.
          </ul>
        </>
      ),
    },
    {
      title: "Enhancements and Additional Covers:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
            Enhanced coverage options may include coverage for employee dishonesty, mysterious disappearance, and damage during repair or restoration. 
            </li>
            <li>
            Additional covers are available for specific risks such as loss or damage during transit, terrorism, and natural disasters. 
            </li>
          </ul>
        </>
      ),
    },
  ];

  const whychoosePoints = [
    {
      innerArray: [
        {
          img_url: "../images/wc_img1.svg",
          main_head: "Tailored Solutions:",
          desc: `We understand the intricacies of the jewelry industry and offer customized insurance solutions that align with your specific needs and risks. Our Jewelers Block Insurance is designed to comprehensively safeguard your valuable inventory, ensuring you're covered against a wide range of perils. `,
        },

        {
          img_url: "../images/wc_img3.svg",
          main_head: "Expertise:",
          desc: `With years of experience and expertise in the insurance sector, our team possesses in-depth knowledge of the jewelry market and its associated risks. We leverage this expertise to craft policies with robust coverage and tailored endorsements, providing you with the protection you deserve. `,
        },
        {
          img_url: "../images/wc_img11.svg",
          main_head: "Comprehensive Coverage:",
          desc: ` Our Jewelers Block Insurance offers comprehensive coverage for your jewelry inventory, including precious stones, metals, and finished pieces. We go beyond standard coverage to protect you against theft, burglary, fire, and accidental damage, ensuring your assets are safeguarded in every scenario. `,
        },
        {
          img_url: "../images/wc_img5.svg",
          main_head: "Transparent Policies:",
          desc: ` At Amaze Insurance Brokers, transparency is key. We provide clear and concise policy documentation, outlining the coverage, terms, and conditions in plain language. You can trust that our policies are easy to understand, enabling you to make informed decisions about your insurance needs. `,
        },
      ],
    },
    {
      innerArray: [
        {
          img_url: "../images/wc_img7.svg",
          main_head: "Dedicated Claims Assistance:",
          desc: ` In the unfortunate event of a loss, our dedicated claims team is here to support you every step of the way. We streamline the claims process, ensuring prompt and fair settlement while minimizing disruption to your business operations. With Amaze Insurance Brokers, you can rest assured that your claims will be handled with care and efficiency. `,
        },
        {
          img_url: "../images/wc_img6.svg",
          main_head: "Client-Centric Approach:",
          desc: ` We prioritize our clients' needs above all else, striving to exceed expectations with every interaction. Whether you're a small jewelry boutique or a large showroom, we treat every client with the same level of attention and dedication, ensuring you receive personalized service tailored to your unique requirements.  `,
        },
        {
          img_url: "../images/wc_img8.svg",
          main_head: "Trusted Partnerships: ",
          desc: `As licensed insurance brokers, we have established strong relationships with leading insurers in the market. This allows us to negotiate competitive premiums and favorable terms on your behalf, ensuring you get the best value for your insurance investment. `,
        },
      ],
    },
  ];

  const [settings] = useState({
    className: "center",
    // centerMode: true,
    infinite: true,
    // centerPadding: "200px",
    slidesToShow: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 2000,
    // cssEase: "linear",
    arrows: true,
    dots: true,
    responsive: [
      {
        breakpoint: 1024, // For tablets and above
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600, // For mobile devices
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });

  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="subProducts_pp">
          <Grid container columnSpacing={3} className="row">
            <Grid xs={12} textAlign={"center"} marginBottom="24px">
              <h1>Jeweler's Block Insurance for Jewelry Showrooms</h1>
              <p>
                At Amaze Insurance Brokers, we understand the unique risks faced
                by jewelry businesses, which is why we offer comprehensive
                Jeweler's Block Insurance tailored specifically for big jewelry
                showrooms and jewelers. Here's a detailed overview of coverage,
                features, and procedures:
              </p>
            </Grid>
          </Grid>

          <Box className="coverages-section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md className="mb-margin2">
                <PPAccordionComponent data={coveragePointsArray} />
              </Grid>
              <Grid xs={12} md={"auto"} margin={"auto"}>
                <img
                  src="/images/jewellers_block_insurance.svg"
                  alt=""
                  style={{
                    width: "100%",
                    maxWidth: isMobile ? "172px" : "222px",
                    margin: "auto",
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="claimProcedure-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom={"24px"} textAlign="center">
                <h2 className="mb-6">Claims Procedure</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    In the event of a loss, policyholders must notify Amaze
                    Insurance Brokers or the insurer immediately and provide all
                    necessary information and documentation.
                  </li>
                  <li>
                    The claims procedure involves submitting a detailed claims
                    form along with supporting evidence such as police reports,
                    inventory records, and proof of ownership.
                  </li>
                  <li>
                    Our experienced claims team will guide you through the
                    process, ensuring prompt and fair settlement of claims to
                    minimize disruption to your business operations.
                  </li>
                </ol>
              </Grid>
            </Grid>
          </Box>
          <Box className="claimProcedure-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom={"24px"} textAlign="center">
                <h2 className="mb-6">Terms and Conditions</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    The policy's terms and conditions outline the rights,
                    duties, and responsibilities of both the insured and the
                    insurer.
                  </li>
                  <li>
                    Policyholders are required to comply with the terms and
                    conditions specified in the policy document to maintain
                    coverage eligibility.
                  </li>
                </ol>
              </Grid>
            </Grid>
          </Box>

          <Box className="claimProcedure-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom={"24px"} textAlign="center">
                <h2 className="mb-6">Exclusions</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    Exclusions typically include losses due to wear and tear,
                    gradual deterioration, faulty workmanship, inherent vice,
                    and nuclear perils.
                  </li>
                  <li>
                    Intentional acts, fraud, and dishonesty are also commonly
                    excluded from coverage.{" "}
                  </li>
                </ol>
              </Grid>
              <p
                style={{
                  textAlign: "center",
                  marginTop: "80px",
                }}
              >
                For comprehensive Jeweler's Block Insurance solutions for big
                jewelry showrooms and jewelers, trust Amaze Insurance Brokers to
                protect your valuable assets. Contact us today to learn more
                about our offerings and how we can tailor a policy to suit your
                specific needs.
              </p>
            </Grid>
          </Box>

          <Box className="whyChoose_pp_section">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md={6} className="mb-margin2">
                <h2 className="mb-2">
                  Why Amaze Insurance Brokers under Jewelers Block Insurance?
                </h2>
                <p className="mb-6">
                  Choosing Amaze Insurance Brokers for your Jewelers Block
                  Insurance ensures unparalleled protection and peace of mind
                  for your jewelry business. Here's why we stand out:
                </p>
                <img
                  src="../images/whychoose_pp_img.svg"
                  alt=""
                  style={{ maxWidth: "450px", width: "100%", margin: "auto" }}
                  className="wchoose_img"
                />
              </Grid>
              <Grid xs={12} md={6}>
                <Slider {...settings} className="mt-6 points-slider">
                  {whychoosePoints?.map((data, index) => (
                    <Box sx={{ padding: "24px 12px" }}>
                      <ul className="whyChoosepp_list2">
                        {data.innerArray.map((innerData, index) => (
                          <li>
                            <Box className="wc_box">
                              <img src={innerData.img_url} alt="" />
                              <h6>{innerData.main_head}</h6>
                              <ReadMoreText
                                text={innerData.desc}
                                maxlength={99}
                              />
                            </Box>
                          </li>
                        ))}
                      </ul>
                    </Box>
                  ))}
                </Slider>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default JewelryShowrooms;
