import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../../../Components/Desktop/Navbar/Navbar";
import MFooter from "../../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../../Components/Mobile/MNavBar/MNavBar";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import "./../../../../../SCSS/ProductPages.scss";
import PPAccordionComponent from "../../../../../Components/PPAccordionComponent/PPAccordionComponent";
import ReadMoreText from "../../../../../Components/ReadMoreTextComponent/ReadMoreText";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useState } from "react";
import Slider from "react-slick";

const MarineCumErectionALOPInsurance = () => {
  const isMobile = useIsMobile();

  const coveragePointsArray = [
    {
      title: "Overview:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              This policy provides comprehensive coverage for marine transit,
              erection/installation projects, and Advanced Loss of Profit (ALOP)
              following delays due to insured perils.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Salient Features:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Integrated Coverage:</span> Combines Marine, Erection All
              Risks, and ALOP coverages into a single policy.
            </li>
            <li>
              <span>Project Specific:</span> Tailored to cover specific marine
              transit and erection projects, including machinery, equipment, and
              construction materials.
            </li>
            <li>
              <span>Financial Protection:</span> Protects against financial
              losses due to project delays and interruptions.{" "}
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Coverages:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Marine Transit:</span> Coverage for goods, machinery, and
              equipment during transit by sea, air, or land.
            </li>
            <li>
              <span>Erection All Risks:</span> Protection for construction and
              installation projects, including machinery, equipment, and
              third-party liability.
            </li>
            <li>
              <span>Advanced Loss of Profit (ALOP):</span> Compensation for
              financial losses resulting from project delays due to insured
              perils.{" "}
            </li>
          </ul>
        </>
      ),
    },
  ];

  const [settings] = useState({
    className: "center",
    // centerMode: true,
    infinite: true,
    // centerPadding: "200px",
    slidesToShow: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 2000,
    // cssEase: "linear",
    arrows: true,
    dots: true,
    responsive: [
      {
        breakpoint: 1024, // For tablets and above
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600, // For mobile devices
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });

  const whychoosePoints = [
    {
      innerArray: [
        {
          img_url: "../images/wc_img1.svg",
          main_head: "Risk Assessment and Mitigation:",
          desc: `We understand the unique risks associated with marine transit and erection projects. Our team crafts bespoke insurance solutions that align with your specific project requirements, ensuring comprehensive protection against unforeseen perils and financial setbacks.`,
        },

        {
          img_url: "../images/wc_img3.svg",
          main_head: "Advanced Loss of Profit (ALOP) Expertise",
          desc: `Our in-depth understanding of ALOP coverage enables us to assess and mitigate the financial impact of project delays, offering tailored solutions to safeguard your bottom line and ensure project continuity.`,
        },
        {
          img_url: "../images/wc_img4.svg",
          main_head: "Risk Assessment and Management",
          desc: `Leveraging our industry insights, we conduct thorough risk assessments to identify potential vulnerabilities and implement proactive risk management strategies tailored to your project's needs, minimizing the likelihood of delays and financial losses.`,
        },
        {
          img_url: "../images/wc_img2.svg",
          main_head: "Claims Advocacy and Support",
          desc: ` In the event of a claim, our dedicated claims team advocates on your behalf, ensuring prompt and fair claim settlements. We handle the entire claims process, from initial notification to final settlement, allowing you to focus on your core business operations with peace of mind.`,
        },
      ],
    },
    {
      innerArray: [
        {
          img_url: "../images/wc_img8.svg",
          main_head: "Industry Knowledge",
          desc: `Our team of experienced professionals possesses extensive knowledge of the marine and construction sectors, enabling us to provide expert advice and guidance tailored to your industry-specific needs.`,
        },
        {
          img_url: "../images/wc_img5.svg",
          main_head: "Personalized Service",
          desc: `We prioritize building long-term relationships with our clients, offering personalized service and support at every stage of the insurance lifecycle. Our client-centric approach ensures that we understand your unique challenges and objectives, allowing us to deliver customized solutions that meet your evolving needs.`,
        },
        {
          img_url: "../images/wc_img6.svg",
          main_head: "Transparent Communication",
          desc: `We believe in fostering open and transparent communication with our clients, providing clear, concise information about policy terms, coverages, and claims procedures. Our commitment to transparency ensures that you have a clear understanding of your insurance coverage and can make informed decisions with confidence.`,
        },
        {
          img_url: "../images/wc_img7.svg",
          main_head: "Innovative Solutions",
          desc: ` As a forward-thinking insurance broker, we continuously explore innovative insurance solutions and technologies to enhance our service offerings and provide you with cutting-edge solutions that address emerging risks and challenges in the marine and construction industries.`,
        },
      ],
    },
  ];

  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="subProducts_pp">
          <Grid container columnSpacing={3} className="row">
            <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
              <h1>Marine Cum Erection All Risks Insurance Policy with ALOP</h1>
            </Grid>
          </Grid>

          <Box className="coverages-section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md className="mb-margin2">
                <PPAccordionComponent data={coveragePointsArray} />
              </Grid>
              <Grid xs={12} md={"auto"} margin={"auto"}>
                <img
                  src="/images/Marine_delay_ins_img.svg"
                  alt=""
                  style={{
                    width: "100%",
                    maxWidth: isMobile ? "200px" : "300px",
                    margin: "auto",
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="claimProcedure-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom={"24px"} textAlign={"center"}>
                <h2>Claims Procedure</h2>
              </Grid>
              <Grid xs={12}>
                <ul className="claimProcedure_list">
                  <li>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      marginBottom={"24px"}
                    >
                      <Box textAlign={"left"}>
                        <h4>01</h4>
                        <h6>Immediate Notification</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon1.svg"
                        alt=""
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "Notify the insurance company or broker as soon as a loss occurs. "
                      }
                      maxlength={100}
                    />
                  </li>
                  <li>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      marginBottom={"24px"}
                    >
                      <Box textAlign={"left"}>
                        <h4>02</h4>
                        <h6>Claim Documentation</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon2.svg"
                        alt=""
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "Submit a detailed claim form, survey reports, and supporting documents to the insurer."
                      }
                      maxlength={100}
                    />
                  </li>
                  <li>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      marginBottom={"24px"}
                    >
                      <Box textAlign={"left"}>
                        <h4>03</h4>
                        <h6>Claim Evaluation and Settlement:</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon3.svg"
                        alt=""
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "The insurer will assess the claim, conduct an investigation if necessary, and settle the claim based on policy terms and conditions."
                      }
                      maxlength={100}
                    />
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>

          <Box className="termsConditions_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md={8} className="mb-margin2">
                <h2 className="mb-3">Terms and Conditions</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    <span>Policy Period: </span>Typically issued for the
                    duration of the project, with options for extension.
                  </li>
                  <li>
                    <span>Limits of Liability:</span>As per policy terms,
                    subject to deductibles and sub-limits.
                  </li>
                  <li>
                    <span>Deductibles:</span> As specified in the policy
                    schedule.
                  </li>
                </ol>
              </Grid>
              <Grid xs={12} md={4}>
                <img
                  src="../images/termsconditions_img.svg"
                  alt=""
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
            </Grid>
          </Box>

          {/* {exclusions   section included in some pages that why i have created here} */}
          <Box className="excluison_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md={4} order={{ xs: 2, md: 1 }}>
                <img
                  src="../images/exclusions_img.svg"
                  alt=""
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
              <Grid
                xs={12}
                md={8}
                className="mb-margin2"
                order={{ xs: 1, md: 2 }}
              >
                <h2 className="mb-3">Exclusions</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    <span>Intentional Acts: </span> Excludes losses caused
                    intentionally by the insured.
                  </li>
                  <li>
                    <span>War and Nuclear Risks:</span> Excludes losses caused
                    by war, terrorism, nuclear risks, or radioactive
                    contamination.
                  </li>
                </ol>
              </Grid>
            </Grid>
          </Box>

          <Box className="whyChoose_pp_section">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md={6} className="mb-margin2">
                <h2 className="mb-2">
                  Why Choose Amaze Insurance Brokers for Marine Cum EAR Policy
                  with ALOP and Marine DSU?{" "}
                </h2>
                <p className="mb-6">
                  At Amaze Insurance Brokers, we specialize in delivering
                  tailored insurance solutions designed to protect your marine
                  transit and erection/installation projects from unforeseen
                  risks and financial losses. With our deep industry knowledge,
                  technical expertise, and commitment to excellence, we stand as
                  your trusted insurance advisor, guiding you through the
                  complexities of Marine Cum EAR Policy with ALOP and Marine
                  DSU.
                </p>
                <img
                  src="../images/whychoose_pp_img.svg"
                  alt=""
                  style={{ maxWidth: "450px", width: "100%", margin: "auto" }}
                  className="wchoose_img"
                />
              </Grid>
              <Grid xs={12} md={6}>
                {/* <ul className="whyChoosepp_list">
                  <li>
                    <Box className="wc_box">
                      <img src="../images/wc_img1.svg" alt="" />
                      <h6>Customized Coverage</h6>
                      <p>
                        We understand the unique risks associated with marine
                        transit and erection projects. Our team crafts bespoke
                        insurance solutions that align with your specific
                        project requirements, ensuring comprehensive protection
                        against unforeseen perils and financial setbacks.
                      </p>
                    </Box>
                    <Box className="wc_box">
                      <img src="../images/wc_img2.svg" alt="" />
                      <h6>Risk Assessment and Management</h6>
                      <p>
                        Leveraging our industry insights, we conduct thorough
                        risk assessments to identify potential vulnerabilities
                        and implement proactive risk management strategies
                        tailored to your project's needs, minimizing the
                        likelihood of delays and financial losses.
                      </p>
                    </Box>
                  </li>
                  <li>
                    <Box className="wc_box">
                      <img src="../images/wc_img3.svg" alt="" />
                      <h6>Advanced Loss of Profit (ALOP) Expertise</h6>
                      <p>
                        Our in-depth understanding of ALOP coverage enables us
                        to assess and mitigate the financial impact of project
                        delays, offering tailored solutions to safeguard your
                        bottom line and ensure project continuity.
                      </p>
                    </Box>
                    <Box className="wc_box">
                      <img src="../images/wc_img4.svg" alt="" />
                      <h6>Claims Advocacy and Support</h6>
                      <p>
                        In the event of a claim, our dedicated claims team
                        advocates on your behalf, ensuring prompt and fair claim
                        settlements. We handle the entire claims process, from
                        initial notification to final settlement, allowing you
                        to focus on your core business operations with peace of
                        mind.
                      </p>
                    </Box>
                  </li>
                </ul> */}

                <Slider {...settings} className="mt-6 points-slider">
                  {whychoosePoints?.map((data, index) => (
                    <Box sx={{ padding: "24px 12px" }}>
                      <ul className="whyChoosepp_list2">
                        {data.innerArray.map((innerData, index) => (
                          <li>
                            <Box className="wc_box">
                              <img src={innerData.img_url} alt="" />
                              <h6>{innerData.main_head}</h6>
                              <ReadMoreText
                                text={innerData.desc}
                                maxlength={99}
                              />
                            </Box>
                          </li>
                        ))}
                      </ul>
                    </Box>
                  ))}
                </Slider>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default MarineCumErectionALOPInsurance;
