import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { RETAIL_INSURANCE_PATHS } from "../Path/RetailInsurancePaths";
import TractorInsurance from "../../Pages/Desktop/ProductPages/VehicleInsurance/TractorInsurance/TractorInsurance";
import CommercialGoodsVehicle from "../../Pages/Desktop/ProductPages/VehicleInsurance/CommercialGoodsVehicle/CommercialGoodsVehicle";
import CommercialPassenger from "../../Pages/Desktop/ProductPages/VehicleInsurance/CommercialPassengerVehicle/CommercialPassenger";
import MiscellaneousVehicles from "../../Pages/Desktop/ProductPages/VehicleInsurance/MiscellaneousVehicles/MiscellaneousVehicles";
import JewelryShowrooms from "../../Pages/Desktop/ProductPages/MiscellaneousClass/JewelryShowrooms/JewelryShowrooms";
import ShopkeepersInsurance from "../../Pages/Desktop/ProductPages/MiscellaneousClass/ShopkeepersInsurance/ShopkeepersInsurance";
import HouseholderInsurance from "../../Pages/Desktop/ProductPages/MiscellaneousClass/HouseholderInsurance/HouseholderInsurance";

function RetailInsuranceRoutes() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Routes>
        <Route
          path={RETAIL_INSURANCE_PATHS.TRACTOR_INSURANCE}
          element={<TractorInsurance />}
        />
        <Route
          path={RETAIL_INSURANCE_PATHS.COMMERCIAL_GOODS_VEHICLE}
          element={<CommercialGoodsVehicle />}
          />
          <Route
          path={RETAIL_INSURANCE_PATHS.COMMERCIAL_PASSENGER_VEHICLE}
          element={<CommercialPassenger />}
          />
          <Route
            path={RETAIL_INSURANCE_PATHS.MISCELLANEOUS_VEHICLES}
            element={<MiscellaneousVehicles />}
            />
            <Route
            path={RETAIL_INSURANCE_PATHS.JEWELRY_SHOWROOMS}
            element={<JewelryShowrooms />}
            />
            <Route
            path={RETAIL_INSURANCE_PATHS.SHOPKEEPERS_INSURANCE}
            element={<ShopkeepersInsurance />}
            />
            <Route
            path={RETAIL_INSURANCE_PATHS.HOUSEHOLDER_INSURANCE}
            element={<HouseholderInsurance />}
            />
      </Routes>
    </div>
  );
}

export default RetailInsuranceRoutes;
