import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../../../Components/Desktop/Navbar/Navbar";
import MFooter from "../../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../../Components/Mobile/MNavBar/MNavBar";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import "./../../../../../SCSS/ProductPages.scss";
import PPAccordionComponent from "../../../../../Components/PPAccordionComponent/PPAccordionComponent";
import { useState } from "react";
import ReadMoreText from "../../../../../Components/ReadMoreTextComponent/ReadMoreText";

const PlateGlassInsurance = () => {
  const isMobile = useIsMobile();

  const coveragePointsArray = [
    {
      title: "Coverage",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              Plate Glass Insurance provides coverage for damage or breakage to
              glass windows, doors, partitions, and other fixed glass
              installations on the insured premises. This policy typically
              covers accidental breakage, including vandalism, malicious damage,
              and natural perils such as storms or earthquakes.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Wordings",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              The policy wording outlines the terms, conditions, and coverage
              details of Plate Glass Insurance. It specifies the insured perils,
              coverage limits, deductible amounts, premium rates, and claims
              procedures.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Special Features ",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Comprehensive Coverage:</span> Offers broad coverage for
              damage or breakage to plate glass installations, providing
              financial protection against unexpected repair or replacement
              costs.
            </li>
            <li>
              <span>Emergency Repairs: </span> Provides coverage for temporary
              repairs necessary to secure the premises following glass damage,
              helping to minimize business interruption and maintain security.
            </li>
            <li>
              <span>Customizable Coverage: </span> Policies can be tailored to
              meet the specific needs of the insured premises, including
              coverage for signage, decorative glass, or specialized glass
              fixtures.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Enhancements  ",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Glass Signage Coverage: </span> Extends coverage to include
              glass signage or decorative glass features on the insured
              premises, ensuring comprehensive protection for all glass assets.
            </li>
            <li>
              <span>Business Interruption Coverage: </span> Offers optional
              coverage for business interruption losses resulting from glass
              damage, providing reimbursement for lost income and ongoing
              expenses during the restoration period.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Additional Covers:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Lettering and Ornamentation: </span>
              Provides coverage for the cost of re-lettering or reapplying
              ornamentation to damaged glass surfaces, ensuring seamless
              restoration of the insured premises.
            </li>
            <li>
              <span>Temporary Covering Expenses: </span>
              Reimburses the insured for expenses incurred in temporarily
              covering damaged glass areas to prevent further damage or exposure
              to the elements.
            </li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="subProducts_pp">
          <Grid container columnSpacing={3} className="row">
            <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
              <h1>Plate Glass Insurance Policy</h1>
            </Grid>
          </Grid>

          <Box className="coverages-section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md className="mb-margin2">
                <PPAccordionComponent data={coveragePointsArray} />
              </Grid>
              <Grid xs={12} md={"auto"} margin={"auto"}>
                <img
                  src="/images/plate_glass_ins_policy.svg"
                  alt=""
                  style={{
                    width: "100%",
                    maxWidth: isMobile ? "200px" : "275px",
                    margin: "auto",
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="claimProcedure-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom={"24px"} textAlign={"center"}>
                <h2>Claims Procedure</h2>
              </Grid>
              <Grid xs={12} textAlign={"center"}>
                <p>
                  In the event of glass damage covered under the policy, the
                  insured must notify the insurer promptly and provide
                  supporting documentation, such as photographs, estimates, and
                  police reports. The insurer will then assess the claim, verify
                  the damage, and facilitate the claims settlement process in
                  accordance with the policy terms.
                </p>
              </Grid>
            </Grid>
          </Box>

          <Box className="termsConditions_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md={8} className="mb-margin2">
                <h2 className="mb-3">Terms and Conditions</h2>
                <p>
                  The policy's terms and conditions specify the rights and
                  obligations of both the insurer and the insured. These include
                  coverage duration, premium payment terms, deductible amounts,
                  claims reporting procedures, and policy renewal conditions.
                </p>
              </Grid>
              <Grid xs={12} md={4}>
                <img
                  src="../images/termsconditions_img.svg"
                  alt=""
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="excluison_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md={4} order={{ xs: 2, md: 1 }}>
                <img
                  src="../images/exclusions_img.svg"
                  alt=""
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
              <Grid
                xs={12}
                md={8}
                className="mb-margin2"
                order={{ xs: 1, md: 2 }}
              >
                <h2 className="mb-3">Exclusions</h2>
                <p>
                  Plate Glass Insurance policies typically exclude coverage for
                  pre-existing damage, wear and tear, gradual deterioration, or
                  damage caused by intentional acts of the insured or its
                  employees. Additionally, losses resulting from war, nuclear
                  events, or acts of terrorism may be excluded from coverage.
                </p>
              </Grid>
            </Grid>
          </Box>

          <Box className="claimProcedure-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom={"24px"} textAlign={"center"}>
                <h2>Conclusion</h2>
              </Grid>
              <Grid xs={12} textAlign={"center"}>
                <p>
                  Plate Glass Insurance is essential for businesses to protect
                  against financial losses resulting from damage or breakage to
                  glass installations on the insured premises. By partnering
                  with Amaze Insurance Brokers, you gain access to comprehensive
                  coverage options, expert advice, and dedicated support
                  throughout the insurance process.
                </p>
              </Grid>
              <Grid xs={12} textAlign={"center"}>
                <p>
                  For further inquiries or to explore Plate Glass Insurance
                  options tailored to your business needs, contact us today.
                </p>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default PlateGlassInsurance;
