import { Link } from "@mui/material";
import React, { useState } from "react";

const ReadMoreText = ({
  text,
  maxlength,
}: {
  text: string;
  maxlength: number;
}) => {
  const [isReadMore, setIsReadMore] = useState(true);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <div>
      <p
        style={{
          fontSize: "14px",
          lineHeight: "21px",
          color: "rgba(89, 89, 89, 1)",
        }}
      >
        {isReadMore ? text.slice(0, maxlength) : text}
        {text.length > 100 && (
          <Link onClick={toggleReadMore} sx={{ fontWeight: 600,marginLeft:'5px' }}>
            {isReadMore ? "Read More..." : "Read Less..."}
          </Link>
        )}
      </p>
    </div>
  );
};

export default ReadMoreText;
