import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../../../Components/Desktop/Navbar/Navbar";
import MFooter from "../../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../../Components/Mobile/MNavBar/MNavBar";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import "./../../../../../SCSS/ProductPages.scss";
import PPAccordionComponent from "../../../../../Components/PPAccordionComponent/PPAccordionComponent";
import ReadMoreText from "../../../../../Components/ReadMoreTextComponent/ReadMoreText";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const GroupTermLifeInsurance = () => {
  const isMobile = useIsMobile();

  const coveragePointsArray = [
    {
      title: "Coverage:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Death Benefit:</span> Provides a lump-sum amount to the
              nominee in case of the insured's death during the policy term,
              ensuring financial stability for the family.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Special Features:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Group Coverage:</span> Provides coverage to a group of
              employees under a single master policy, making it cost-effective
              for employers.
            </li>
            <li>
              <span>Accelerated Death Benefit: </span> Some policies offer an
              option to advance a portion of the death benefit if the insured is
              diagnosed with a terminal illness, providing financial support
              during critical times.
            </li>
            <li>
              <span>Flexible Coverage Options: </span> Allows employers to
              choose the coverage amount based on the employee's salary,
              designation, or a flat amount for all employees.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Add-on Covers:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Accidental Death Benefit:</span> Offers an additional sum
              assured in case of death due to an accident, providing extra
              financial protection to the insured's family.
            </li>
            <li>
              <span>Permanent Total Disability Benefit: </span> Provides a
              lump-sum amount to the insured in case of permanent total
              disability due to an accident or illness, ensuring continued
              financial support.
            </li>
            <li>
              <span>Spouse and Children Coverage: </span> Some policies offer
              the option to include spouse and children under the same policy
              for an additional premium.
            </li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="subProducts_pp">
          <Grid
            container
            columnSpacing={3}
            className="row"
            marginBottom={"24px"}
          >
            <Grid xs={12} md className="mb-margin2">
              <h1>
                Group Term Life Insurance Policy: Providing Financial Security
                to Employees
              </h1>
            </Grid>
            <Grid xs={12} md={"auto"} margin={"auto"}>
              <img
                src="/images/grp_term_ins_image.svg"
                alt=""
                style={{
                  width: "100%",
                  maxWidth: isMobile ? "220px" : "305px",
                  margin: "auto",
                }}
              />
            </Grid>
          </Grid>

          <Box className="coverages-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12}>
                <PPAccordionComponent data={coveragePointsArray} />
              </Grid>
            </Grid>
          </Box>

          <Box className="claimProcedure-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom={"24px"} textAlign={"center"}>
                <h2>Claims Procedure</h2>
              </Grid>
              <Grid xs={12}>
                <ul className="claimProcedure_list">
                  <li>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      marginBottom={"24px"}
                    >
                      <Box textAlign={"left"}>
                        <h4>01</h4>
                        <h6>Immediate Notification</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon1.svg"
                        alt=""
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "In the event of the insured's death, the nominee or the employer must notify the insurance company or TPA (Third-Party Administrator) as soon as possible."
                      }
                      maxlength={100}
                    />
                  </li>
                  <li>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      marginBottom={"24px"}
                    >
                      <Box textAlign={"left"}>
                        <h4>02</h4>
                        <h6>Claim Documentation</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon2.svg"
                        alt=""
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "Submit a duly filled claim form along with the death certificate, post-mortem report (if applicable), and other necessary documents to the TPA or insurance company."
                      }
                      maxlength={100}
                    />
                  </li>
                  <li>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      marginBottom={"24px"}
                    >
                      <Box textAlign={"left"}>
                        <h4>03</h4>
                        <h6>Claim Settlement</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon3.svg"
                        alt=""
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "Upon verification and approval of the claim, the insurance company will settle the claim amount directly with the nominee or legal heir as per the policy terms and coverage limits."
                      }
                      maxlength={100}
                    />
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>

          <Box className="termsConditions_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md={8} className="mb-margin2">
                <h2 className="mb-3">Terms and Conditions</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    <span>Policy Period: </span> Typically, the policy is issued
                    for a one-year period and needs to be renewed annually.
                  </li>
                  <li>
                    <span>Sum Insured:</span> The sum insured is based on the
                    company's group size and the chosen coverage amount per
                    employee.
                  </li>
                  <li>
                    <span>Premium Payment: </span> Premiums are payable
                    annually, and the cost is shared between the employer and
                    employees based on the policy terms.
                  </li>
                  <li>
                    <span>Age Limit:</span> Coverage is usually available for
                    employees aged between 18 and 65 years.
                  </li>
                </ol>
              </Grid>
              <Grid xs={12} md={4}>
                <img
                  src="../images/termsconditions_img.svg"
                  alt=""
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="excluison_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md={4} order={{ xs: 2, md: 1 }}>
                <img
                  src="../images/exclusions_img.svg"
                  alt=""
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
              <Grid
                xs={12}
                md={8}
                className="mb-margin2"
                order={{ xs: 1, md: 2 }}
              >
                <h2 className="mb-3">Exclusions</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    <span>Suicide Clause:</span> If the insured commits suicide
                    within the first year of the policy, the nominee may receive
                    a reduced benefit or no benefit at all, depending on the
                    policy terms.
                  </li>
                  <li>
                    <span>War and Terrorism:</span> Death caused by war,
                    terrorism, or any illegal activities are generally excluded
                    from the coverage.
                  </li>
                  <li>
                    <span>Participation in Hazardous Activities:</span> Death
                    resulting from participation in hazardous activities like
                    skydiving, mountaineering, etc., unless specifically
                    covered.
                  </li>
                </ol>
              </Grid>
            </Grid>
          </Box>

          <Box className="excluison_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} textAlign={"center"}>
                <h2 className="mb-3">Conclusion</h2>
                <p>
                  A Group Term Life Insurance Policy is a vital Employee Benefit
                  offered by big corporates in India to provide financial
                  security to their employees' families in the unfortunate event
                  of the insured's death. By offering comprehensive coverage,
                  special features, and add-on covers, companies can demonstrate
                  their commitment to the well-being of their employees and
                  their families.
                </p>
              </Grid>
            </Grid>
          </Box>

          <Box className="whyChoose_pp_section">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md={6} className="mb-margin2">
                <h2>Why Choose Amaze Insurance Brokers?</h2>
                <img
                  src="../images/whychoose_pp_img.svg"
                  alt=""
                  style={{ maxWidth: "450px", width: "100%", margin: "auto" }}
                  className="wchoose_img"
                />
              </Grid>
              <Grid xs={12} md={6}>
                <ul className="whyChoosepp_list">
                  <li>
                    <Box className="wc_box">
                      <img src="../images/wc_img1.svg" alt="" />
                      <h6>Expert Guidance</h6>
                      <p>
                        Our experienced professionals will design the best
                        coverage for your employees under Group Term Life
                        Insurance.
                      </p>
                    </Box>
                    <Box className="wc_box">
                      <img src="../images/wc_img2.svg" alt="" />
                      <h6>Seamless Claims Assistance</h6>
                      <p>
                        We provide comprehensive support throughout the claims
                        process.
                      </p>
                    </Box>
                  </li>
                  <li>
                    <Box className="wc_box">
                      <img src="../images/wc_img3.svg" alt="" />
                      <h6>Competitive Quotes</h6>
                      <p>
                        We work with leading insurance companies to secure the
                        best deals for you.
                      </p>
                    </Box>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default GroupTermLifeInsurance;
