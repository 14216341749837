import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../../../Components/Desktop/Navbar/Navbar";
import MFooter from "../../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../../Components/Mobile/MNavBar/MNavBar";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import "./../../../../../SCSS/ProductPages.scss";
import PPAccordionComponent from "../../../../../Components/PPAccordionComponent/PPAccordionComponent";

const AviationLiabilityInsurance = () => {
  const isMobile = useIsMobile();

  const coveragePointsArray = [
    {
      title: "Coverage:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              Aviation Liability Insurance provides coverage for third-party
              bodily injury, property damage, and legal liabilities arising from
              aircraft operations. It protects aircraft owners, operators,
              pilots, and aviation businesses against financial losses resulting
              from accidents, collisions, or incidents involving their aircraft.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Wordings:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              The policy contains specific wordings outlining the scope of
              coverage, conditions, and exclusions. It's essential to review
              these wordings carefully to understand the extent of protection
              offered under the policy.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Special Features:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Third-Party Liability Coverage:</span> Protects against
              claims for bodily injury and property damage caused to third
              parties due to aircraft operations, including ground handling
              activities, passenger injuries, and damage to third-party
              property.
            </li>
            <li>
              <span>Passenger Liability: </span>Covers bodily injury and
              accidental death of passengers onboard the aircraft.
            </li>
            <li>
              <span>Product Liability:</span> Extends coverage for liability
              arising from the sale, manufacture, or distribution of aviation
              products, including aircraft parts and components.
            </li>
            <li>
              <span>Hangarkeepers Liability:</span> Provides coverage for
              liability arising from damage to aircraft or property while under
              the care, custody, or control of the insured within a hangar
              facility.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Enhancements:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Extended Coverage Limits:</span> Policyholders can opt for
              increased liability coverage limits to ensure adequate protection
              against large liability claims.
            </li>
            <li>
              <span>Aviation Products Recall Insurance:</span> Optional coverage
              enhancement may include insurance for the costs associated with
              recalling defective aviation products.
            </li>
            <li>
              <span>Legal Defense Costs:</span> Some policies may include
              coverage for legal defense costs incurred in defending against
              liability claims.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Additional Covers:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Airport Owners and Operators Liability:</span> Protects
              airport operators against liability arising from airport
              operations, including runway accidents, ground handling incidents,
              and premises liability.
            </li>
            <li>
              <span>Airside Liability:</span> Covers liability for accidents or
              damage occurring on the airport's apron, taxiways, and runways.
            </li>
            <li>
              <span> Non-Owned Aircraft Liability:</span> Extends coverage to
              liability arising from the use of non-owned or rented aircraft.
            </li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="subProducts_pp">
          <Grid container columnSpacing={3} className="row">
            <Grid xs={12} textAlign={"center"} marginBottom="24px">
              <h1>Aviation Liability Insurance Policy</h1>
            </Grid>
          </Grid>

          <Box className="coverages-section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md className="mb-margin2">
                <PPAccordionComponent data={coveragePointsArray} />
              </Grid>
              <Grid xs={12} md={"auto"} margin={"auto"}>
                <img
                  src="/images/aviation_liability_img.svg"
                  alt=""
                  style={{
                    width: "100%",
                    maxWidth: isMobile ? "180px" : "236px",
                    margin: "auto",
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="claimProcedure-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom="24px" textAlign="center">
                <h2>Claims Procedure</h2>
                <p>
                  In the event of a liability claim, policyholders must promptly
                  notify the insurer and provide detailed information about the
                  incident. The insurer will then investigate the claim, assess
                  liability, and handle the claims settlement process in
                  accordance with the terms of the policy.
                </p>
              </Grid>
            </Grid>
          </Box>

          <Box className="termsConditions_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems="center"
            >
              <Grid xs={12} md={8} className="mb-margin2">
                <h2 className="mb-3">Terms and Conditions</h2>
                <p>
                  The policy's terms and conditions outline the obligations of
                  both the insurer and the insured. These include premium
                  payment terms, coverage periods, claims reporting procedures,
                  and policy renewal conditions.
                </p>
              </Grid>
              <Grid xs={12} md={4}>
                <img
                  src="../images/termsconditions_img.svg"
                  alt="terms and conditions icon"
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="excluison_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems="center"
            >
              <Grid xs={12} md={4} order={{ xs: 2, md: 1 }}>
                <img
                  src="../images/exclusions_img.svg"
                  alt="exclusions icon"
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
              <Grid
                xs={12}
                md={8}
                className="mb-margin2"
                order={{ xs: 1, md: 2 }}
              >
                <h2 className="mb-3">Exclusions</h2>
                <p>
                  Aviation Liability Insurance typically excludes coverage for
                  intentional acts, war and related perils, punitive damages,
                  and contractual liabilities beyond the scope of the policy.
                </p>
              </Grid>
            </Grid>
          </Box>

          <Box className="excluison_section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} textAlign={"center"}>
                <h2>Conclusion</h2>
                <p className="mb-3">
                  Aviation Liability Insurance is essential for aircraft owners,
                  operators, and aviation businesses to protect against the
                  financial risks associated with aircraft operations. By
                  partnering with Amaze Insurance Brokers, you gain access to
                  comprehensive liability coverage, expert advice, and dedicated
                  support throughout the insurance process.
                </p>
                <p style={{ fontWeight: 600 }}>
                  For further inquiries or to explore Aviation Liability
                  Insurance options tailored to your needs, contact us today.
                </p>
              </Grid>
            </Grid>
          </Box>

          <Box className="whyChoose_pp_section">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md={6} className="mb-margin2">
                <h2 className="mb-2">
                  Why Choose Amaze Insurance Brokers for Your Aviation Hull
                  Insurance Policy?
                </h2>
                <p className="mb-6">
                  Expertise in Aviation Insurance: At Amaze Insurance Brokers,
                  we specialize in aviation insurance and have extensive
                  experience in providing tailored solutions for aircraft owners
                  and operators. Our team of experts understands the unique
                  risks and challenges faced by the aviation industry, allowing
                  us to offer comprehensive coverage that meets your specific
                  needs.
                </p>
                <img
                  src="../images/whychoose_pp_img.svg"
                  alt="why choose us icon"
                  style={{ maxWidth: "450px", width: "100%", margin: "auto" }}
                  className="wchoose_img"
                />
              </Grid>
              <Grid xs={12} md={6}>
                <ul className="whyChoosepp_list">
                  <li>
                    <Box className="wc_box">
                      <img
                        src="../images/wc_img1.svg"
                        alt="expert guidance icon"
                      />
                      <h6>Expert Guidance</h6>
                      <p>
                        Our experienced professionals will help you choose the
                        right coverage under SFSP for your property.
                      </p>
                    </Box>
                    <Box className="wc_box">
                      <img src="../images/wc_img2.svg" alt="" />
                      <h6>Seamless Claims Assistance</h6>
                      <p>
                        We provide comprehensive support throughout the claims
                        process.
                      </p>
                    </Box>
                  </li>
                  <li>
                    <Box className="wc_box">
                      <img
                        src="../images/wc_img3.svg"
                        alt="competitive quotes icon"
                      />
                      <h6>Competitive Quotes</h6>
                      <p>
                        We work with leading insurance companies to secure the
                        best deals for you.
                      </p>
                    </Box>
                  </li>
                </ul>
              </Grid>
              <p className="mt-10">
                At Amaze Insurance Brokers, we are committed to providing
                superior Business Interruption Insurance solutions that protect
                your business and help you recover swiftly from unforeseen
                disruptions. Contact us today to learn more about our
                comprehensive coverage options and how we can safeguard your
                business against financial loss.{" "}
              </p>
            </Grid>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default AviationLiabilityInsurance;
