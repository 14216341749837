import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../../Components/Desktop/Navbar/Navbar";
import MFooter from "../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../Components/Mobile/MNavBar/MNavBar";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import "./../../../../SCSS/ProductPages.scss";

const EmployeeBenefitsInurance = () => {
  const isMobile = useIsMobile();

  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="main_pp">
          <Grid
            container
            columnSpacing={3}
            className="row mb-margin1"
            marginBottom={"80px"}
          >
            <Grid xs={12} textAlign={"center"} className="mb-6">
              <h2 className="mb-6">Employee Benefits Insurances</h2>
              <h5 className="mb-3">
                Employee Benefits: Enhancing Workplace Well-being
              </h5>
              <p>
                In today's competitive business environment, attracting and
                retaining top talent is crucial for the success of big
                corporates in India. Employee Benefits play a significant role
                in this regard, offering a comprehensive suite of financial
                protections and wellness programs to employees. Here's a look at
                some of the popular Employee Benefits available in the Indian
                Insurance Market:
              </p>
            </Grid>
            <Grid xs={12} className="mb-6">
              <h5 className="mb-3">1. Group Health Insurance:</h5>
              <ul>
                <li>
                  <span>Coverage:</span> Provides coverage for medical expenses
                  incurred by employees due to illnesses, injuries, or
                  hospitalization.
                </li>
                <li>
                  <span>Features:</span> Cashless hospitalization, pre and
                  post-hospitalization expenses, day-care procedures, and
                  maternity benefits.
                </li>
                <li>
                  <span>Exclusions:</span> Pre-existing diseases, cosmetic
                  treatments, and alternative treatments unless specified.
                </li>
                <li>
                  <span>Claim Procedure:</span> Employees can avail cashless
                  treatment at network hospitals or claim reimbursement for
                  medical expenses incurred.
                </li>
              </ul>
            </Grid>
            <Grid xs={12} className="mb-6">
              <h5 className="mb-3">
                2. Group Personal Accident (GPA) Insurance:
              </h5>
              <ul>
                <li>
                  <span>Coverage:</span> Offers financial compensation in case
                  of accidental death, disability, or dismemberment due to
                  accidents.
                </li>
                <li>
                  <span>Features:</span> Accidental death benefit, permanent
                  total disability benefit, and temporary total disability
                  benefit.
                </li>
                <li>
                  <span>Exclusions:</span> Self-inflicted injuries, war and
                  terrorism, and participation in hazardous activities.
                </li>
                <li>
                  <span>Claim Procedure:</span> Provides lump-sum compensation
                  to the insured or nominee upon filing a claim with necessary
                  documents.
                </li>
              </ul>
            </Grid>
            <Grid xs={12} className="mb-6">
              <h5 className="mb-3">3. Group Term Life Insurance:</h5>
              <ul>
                <li>
                  <span>Coverage:</span> Provides a lump-sum benefit to the
                  nominee in case of the insured's death during the policy term.
                </li>
                <li>
                  <span>Features:</span> Death benefit, terminal illness
                  benefit, and optional riders for additional coverage.
                </li>
                <li>
                  <span>Exclusions:</span> Suicide within the first year of the
                  policy and participation in hazardous activities.
                </li>
                <li>
                  <span>Claim Procedure:</span> The nominee needs to submit a
                  claim form along with the death certificate and other required
                  documents to claim the benefit.
                </li>
              </ul>
            </Grid>
            <Grid xs={12} className="mb-6">
              <h5 className="mb-3">4. Employee Provident Fund (EPF):</h5>
              <ul>
                <li>
                  <span>Coverage:</span> A retirement savings scheme where both
                  the employer and employee contribute a portion of the
                  employee's salary.
                </li>
                <li>
                  <span>Features:</span> Tax benefits, interest on
                  contributions, and withdrawal options for specific purposes.
                </li>
                <li>
                  <span>Exclusions:</span> Early withdrawal before retirement
                  age and non-contribution to the fund.
                </li>
                <li>
                  <span>Claim Procedure:</span> Employees can withdraw or
                  transfer their EPF balance upon retirement, resignation, or
                  under specific conditions like buying a house, medical
                  emergencies, etc.
                </li>
              </ul>
            </Grid>
            <Grid xs={12} className="mb-6">
              <h5 className="mb-3">5. Employee Gratuity:</h5>
              <ul>
                <li>
                  <span>Coverage:</span> A lump-sum amount paid by the employer
                  to the employee upon retirement, resignation, or termination.
                </li>
                <li>
                  <span>Features:</span> Provides financial security to
                  employees after years of service with the company.
                </li>
                <li>
                  <span>Exclusions:</span> Not applicable for employees with
                  less than 5 years of continuous service.
                </li>
                <li>
                  <span>Claim Procedure:</span> Employees can claim gratuity by
                  submitting a claim form to the employer along with the
                  necessary supporting documents.
                </li>
              </ul>
            </Grid>
            <Grid xs={12}>
              <h5 className="mb-3">6. Employee Stock Options (ESOPs):</h5>
              <ul>
                <li>
                  <span>Coverage:</span> Grants employees the option to purchase
                  company shares at a predetermined price.
                </li>
                <li>
                  <span>Features:</span> Provides an opportunity for employees
                  to share in the company's success and growth.
                </li>
                <li>
                  <span>Exclusions:</span> Varying vesting periods and
                  conditions for exercising the options.
                </li>
                <li>
                  <span>Claim Procedure:</span> Employees can exercise their
                  stock options after fulfilling the vesting conditions and
                  purchase the shares at the predetermined price.
                </li>
              </ul>
            </Grid>
          </Grid>
          <Box
            className="whyChoose_pp_section mb-margin1"
            marginBottom={"80px"}
          >
            <Grid
              container
              columnSpacing={3}
              className="row mb-6"
              alignItems={"center"}
            >
              <Grid xs={12} md={6} className="mb-margin2">
                <h2>Why Choose Amaze Insurance Brokers?</h2>
                <img
                  src="../images/whychoose_pp_img.svg"
                  alt=""
                  style={{ maxWidth: "450px", width: "100%", margin: "auto" }}
                  className="wchoose_img"
                />
              </Grid>
              <Grid xs={12} md={6}>
                <ul className="whyChoosepp_list">
                  <li>
                    <Box className="wc_box">
                      <img src="../images/wc_img1.svg" alt="" />
                      <h6>Expertise You Trust</h6>
                      <p>Decades of experience, customized for you.</p>
                    </Box>
                    <Box className="wc_box">
                      <img src="../images/wc_img2.svg" alt="" />
                      <h6>Comprehensive Coverage</h6>
                      <p>Address niche risks with precision.</p>
                    </Box>
                  </li>
                  <li>
                    <Box className="wc_box">
                      <img src="../images/wc_img3.svg" alt="" />
                      <h6>Simplified Processes</h6>
                      <p>Streamlined guidance from selection to claims.</p>
                    </Box>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default EmployeeBenefitsInurance;
