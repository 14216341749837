import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { PROJECT_INSURANCE_PATHS } from "../Path/ProjectInsurancePaths";
import ProjectInsurance from "../../Pages/Desktop/ProductPages/ProjectInsurance/ProjectInsurance";
import ErectionAllRiskInsurance from "../../Pages/Desktop/ProductPages/ProjectInsurance/ErectionAllRiskInsurance/ErectionAllRiskInsurance";
import DSUInsurance from "../../Pages/Desktop/ProductPages/ProjectInsurance/DSUInsurance/DSUInsurance";
import ALOPInsurance from "../../Pages/Desktop/ProductPages/ProjectInsurance/ALOPInsurance/ALOPInsurance";
import MarineCumErectionALOPInsurance from "../../Pages/Desktop/ProductPages/ProjectInsurance/MarineCumErectionALOPInsurance/MarineCumErectionALOPInsurance";
import ContractorAllRiskInsurance from "../../Pages/Desktop/ProductPages/ProjectInsurance/ContractorAllRiskInsurance/ContractorAllRiskInsurance";

function ProjectInsuranceRoutes() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Routes>
        <Route
          path={PROJECT_INSURANCE_PATHS.PROJECT_INSURANCE}
          element={<ProjectInsurance />}
        />
        <Route
          path={PROJECT_INSURANCE_PATHS.ERECTION_ALL_RISK_INSURANCE}
          element={<ErectionAllRiskInsurance />}
          />
           <Route
          path={PROJECT_INSURANCE_PATHS.CONTRACTOR_ALL_RISK_INSURANCE}
          element={<ContractorAllRiskInsurance />}
          />
          <Route
          path={PROJECT_INSURANCE_PATHS.ALOP}
          element={<ALOPInsurance />}
          />
          <Route
          path={PROJECT_INSURANCE_PATHS.DSU}
          element={<DSUInsurance />}
          />
          <Route
          path={PROJECT_INSURANCE_PATHS.MARINE_CUM}
          element={<MarineCumErectionALOPInsurance />}
          />
      </Routes>
    </div>
  );
}

export default ProjectInsuranceRoutes;
