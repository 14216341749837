export enum LIABILITY_INSURANCE_ROUTES {
    LIABILITY_INSURANCE = "/liability-insurance",
    PUBLIC_LIABILITY_ACT = "/liability-insurance/public-liability-insurance-act",
    COMMERCIAL_GENERAL_LIABILITY = "/liability-insurance/commercial-general-liability-insurance",
    DIRECTORS_AND_OFFICERS_LIABILITY = "/liability-insurance/directors-and-officers-liability-insurance",
    ERRORS_AND_OMISSIONS_LIABILITY = "/liability-insurance/errors-and-omissions-liability",
    CYBER_LIABILITY = "/liability-insurance/cyber-liability-insurance",
    PRODUCT_LIABILITY = "/liability-insurance/product-liability-insurance",
    PROFESSIONAL_INDEMNITY = "/liability-insurance/professional-indemnity-insurance",
    EMPLOYEES_COMPENSATION = "/liability-insurance/employees-compensation-insurance-policy",
  }
  