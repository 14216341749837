import React, { useState } from "react";
import { Box, Link, Rating } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import Navbar from "../../../../Components/Desktop/Navbar/Navbar";
import "./../../../../SCSS/CMSPages.scss";
import Footer from "../../../../Components/Desktop/Footer/Footer";
import { COLORS } from "../../../../SupportingFiles/colors";
import MNavBar from "../../../../Components/Mobile/MNavBar/MNavBar";
import MFooter from "../../../../Components/Mobile/MFooter/MFooter";

const OurSectors = () => {
  const isMobile = useIsMobile();
  return (
    <Box className="cmsPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="cms-inner">
        <Grid container columnSpacing={3} className="row" marginBottom={"40px"}>
          <Grid xs={12} textAlign={"center"}>
            <h2 className="mb-6">Diverse Sectors, Tailored Solutions</h2>
            <p>
              At Amaze Insurance Brokers, we specialize in providing insurance
              solutions across a wide array of sectors, ensuring that businesses
              of all types and sizes have access to comprehensive coverage and
              risk management strategies. Our expertise spans:
            </p>
          </Grid>
        </Grid>

        <Box className="sectors-list-section mb-margin1">
          <Grid container columnSpacing={3} className="row">
            <Grid xs={12}>
              <ul className="sectors_list">
                <li>
                  <h6>
                    <img src="./images/sectorsList_icon1.svg" alt="" />
                    Power Sector
                  </h6>
                  <p>
                    From windmills and hydel plants to solar, biomass, gas, and
                    thermal power projects, we offer specialized insurance
                    solutions to protect assets and mitigate risks in the
                    dynamic energy industry.
                  </p>
                </li>
                <li>
                  <h6>
                    <img src="./images/sectorsList_icon2.svg" alt="" />
                    Project Insurance
                  </h6>
                  <p>
                    We provide seamless project insurance covers for projects of
                    all types, ensuring that your investments are safeguarded
                    against unforeseen risks and challenges.
                  </p>
                </li>
                <li>
                  <h6>
                    <img src="./images/sectorsList_icon3.svg" alt="" />
                    IT & ITES
                  </h6>
                  <p>
                    Our tailored insurance solutions are designed to meet the
                    unique needs of software companies, providing protection
                    against cyber risks, liability claims, and other
                    industry-specific challenges.
                  </p>
                </li>
                <li>
                  <h6>
                    <img src="./images/sectorsList_icon4.svg" alt="" />
                    Pharma Sector
                  </h6>
                  <p>
                    We offer comprehensive insurance coverage for pharmaceutical
                    companies, including big pharma firms listed on the NYSE, to
                    protect against product liability, supply chain disruptions,
                    and regulatory risks.
                  </p>
                </li>
                <li>
                  <h6>
                    <img src="./images/sectorsList_icon5.svg" alt="" />
                    Hospitality
                  </h6>
                  <p>
                    From boutique hotels to 5-star luxury resorts, we offer
                    tailored insurance solutions to protect hospitality
                    businesses against property damage, liability claims, and
                    other industry-specific risks.
                  </p>
                </li>
                <li>
                  <h6>
                    <img src="./images/sectorsList_icon6.svg" alt="" />
                    Hospitals
                  </h6>
                  <p>
                    Our insurance solutions for hospitals, including corporate
                    hospitals, provide comprehensive coverage for property,
                    liability, and medical malpractice risks, ensuring peace of
                    mind for healthcare providers and patients alike.
                  </p>
                </li>
                <li>
                  <h6>
                    <img src="./images/sectorsList_icon7.svg" alt="" />
                    Bio Tech and Clinical Research
                  </h6>
                  <p>
                    We understand the unique risks faced by biotech and clinical
                    research organizations and offer specialized insurance
                    solutions to protect intellectual property, research
                    investments, and regulatory compliance.
                  </p>
                </li>
                <li>
                  <h6>
                    <img src="./images/sectorsList_icon8.svg" alt="" />
                    Manufacturing Sector
                  </h6>
                  <p>
                    Our insurance offerings for the manufacturing sector cover a
                    wide range of risks, including property damage, business
                    interruption, product liability, and supply chain
                    disruptions.
                  </p>
                </li>
                <li>
                  <h6>
                    <img src="./images/sectorsList_icon9.svg" alt="" />
                    Service Sector
                  </h6>
                  <p>
                    We provide insurance solutions tailored to the needs of
                    service-oriented businesses, including professional
                    liability coverage, business interruption insurance, and
                    employee benefits programs.
                  </p>
                </li>
                <li>
                  <h6>
                    <img src="./images/sectorsList_icon10.svg" alt="" />
                    Government Departments/Organizations/Undertakings
                  </h6>
                  <p>
                    We partner with government entities to provide customized
                    insurance solutions that meet regulatory requirements and
                    protect public assets and interests.
                  </p>
                </li>
                <li>
                  <h6>
                    <img src="./images/sectorsList_icon11.svg" alt="" />
                    SMEs/Rural Insurance Covers
                  </h6>
                  <p>
                    Our insurance solutions extend to small and medium-sized
                    enterprises (SMEs) and rural businesses, including
                    specialized covers such as cattle insurance and other
                    agricultural sector protections.
                    <br /> At Amaze Insurance Brokers, we are committed to
                    delivering personalized service, innovative solutions, and
                    unparalleled expertise to clients across all sectors.
                    Contact us today to learn more about how we can tailor our
                    insurance solutions to meet your specific needs and
                    objectives.
                  </p>
                </li>
              </ul>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default OurSectors;
