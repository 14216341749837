import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../../../Components/Desktop/Navbar/Navbar";
import MFooter from "../../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../../Components/Mobile/MNavBar/MNavBar";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import "./../../../../../SCSS/ProductPages.scss";
import PPAccordionComponent from "../../../../../Components/PPAccordionComponent/PPAccordionComponent";

const MoneyInsurancePolicies = () => {
  const isMobile = useIsMobile();

  const coveragePointsArray = [
    {
      title: "Coverage",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              Money Insurance provides coverage for loss of money due to theft,
              robbery, burglary, or any other unforeseen event while the money
              is in transit or kept within the insured premises. This includes
              cash, banknotes, coins, cheques, and other negotiable instruments.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Wordings",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              The policy wording outlines the terms, conditions, and coverage
              details of Money Insurance. It specifies the insured perils,
              coverage limits, deductible amounts, premium rates, and claims
              procedures.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Special Features ",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Comprehensive Coverage:</span> Offers broad coverage for
              loss of money, including theft, robbery, burglary, fire, flood,
              and accidental damage.
            </li>
            <li>
              <span>Transit Coverage: </span> Provides coverage for money while
              it is in transit between locations, such as from the insured
              premises to the bank or vice versa.
            </li>
            <li>
              <span>Cash in Safe: </span> Covers money kept in locked safes or
              strongrooms within the insured premises, offering protection
              against theft or attempted theft.
            </li>
            <li>
              <span>24/7 Coverage: </span> Ensures round-the-clock protection
              for money, providing peace of mind to businesses operating at all
              hours.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Enhancements  ",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Additional Security Measures: </span>
              Offers premium discounts or enhanced coverage options for insured
              premises equipped with security measures such as CCTV cameras,
              alarms, and security personnel.
            </li>
            <li>
              <span>Increased Coverage Limits: </span> Allows businesses to
              increase coverage limits to accommodate higher amounts of cash or
              valuables kept on the premises.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Additional Covers:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Forgery or Alteration: </span>
              Provides coverage for losses resulting from counterfeit or altered
              currency or negotiable instruments.
            </li>
            <li>
              <span>Employee Dishonesty: </span> Offers coverage for losses
              caused by dishonest acts committed by employees, such as
              embezzlement or theft of money.
            </li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="subProducts_pp">
          <Grid container columnSpacing={3} className="row">
            <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
              <h1>Money Insurance Policies</h1>
            </Grid>
          </Grid>

          <Box className="coverages-section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md className="mb-margin2">
                <PPAccordionComponent data={coveragePointsArray} />
              </Grid>
              <Grid xs={12} md={"auto"} margin={"auto"}>
                <img
                  src="/images/money_insurance_img.svg"
                  alt=""
                  style={{
                    width: "100%",
                    maxWidth: isMobile ? "200px" : "250px",
                    margin: "auto",
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="claimProcedure-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom={"24px"} textAlign={"center"}>
                <h2>Claims Procedure</h2>
              </Grid>
              <Grid xs={12} textAlign={"center"}>
                <p>
                  In the event of a loss covered under the policy, the insured
                  must notify the insurer promptly and provide supporting
                  documentation, such as a police report, proof of loss, and
                  evidence of the incident. The insurer will then assess the
                  claim, verify the loss, and facilitate the claims settlement
                  process in accordance with the policy terms.
                </p>
              </Grid>
            </Grid>
          </Box>

          <Box className="termsConditions_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md={8} className="mb-margin2">
                <h2 className="mb-3">Terms and Conditions</h2>
                <p>
                  The policy's terms and conditions specify the rights and
                  obligations of both the insurer and the insured. These include
                  coverage duration, premium payment terms, deductible amounts,
                  claims reporting procedures, and policy renewal conditions.
                </p>
              </Grid>
              <Grid xs={12} md={4}>
                <img
                  src="../images/termsconditions_img.svg"
                  alt=""
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="excluison_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md={4} order={{ xs: 2, md: 1 }}>
                <img
                  src="../images/exclusions_img.svg"
                  alt=""
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
              <Grid
                xs={12}
                md={8}
                className="mb-margin2"
                order={{ xs: 1, md: 2 }}
              >
                <h2 className="mb-3">Exclusions</h2>
                <p>
                  Money Insurance policies typically exclude coverage for losses
                  resulting from war, civil commotion, acts of terrorism, or
                  fraudulent activities not involving theft or robbery.
                </p>
              </Grid>
            </Grid>
          </Box>

          <Box className="claimProcedure-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom={"24px"} textAlign={"center"}>
                <h2>Conclusion</h2>
              </Grid>
              <Grid xs={12} textAlign={"center"}>
                <p>
                  Money Insurance is essential for businesses to protect against
                  financial losses resulting from theft, robbery, or other
                  unforeseen events involving money. By partnering with Amaze
                  Insurance Brokers, you gain access to comprehensive coverage
                  options, expert advice, and dedicated support throughout the
                  insurance process.
                </p>
              </Grid>
              <Grid xs={12} textAlign={"center"}>
                <p>
                  For further inquiries or to explore Money Insurance options
                  tailored to your business needs, contact us today.
                </p>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default MoneyInsurancePolicies;
