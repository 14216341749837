import React from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";

const AboutusTab = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 960 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 959, min: 464 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 463, min: 300 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const expertise_data = [
    {
      imgurl: "./images/sector_exp_icon1.svg",
      title: "",
      desc: "Pharma Sector Including Big Pharma Companies Listed On NYSE And NASDA",
    },
    {
      imgurl: "./images/sector_exp_icon2.svg",
      title: "",
      desc: "Power Sector - Windmills/ Hydel/ Solar/ Biomass/ Gas/ Thermal",
    },
    {
      imgurl: "./images/sector_exp_icon3.svg",
      title: "",
      desc: "IT & ITES - Software Companies, HNIs And Other Individuals",
    },
    {
      imgurl: "./images/sector_exp_icon4.svg",
      title: "",
      desc: "Hospitality - Hotels Including 5-Star Hotels, Hospitals Including Corporate Hospitals",
    },
    {
      imgurl: "./images/sector_exp_icon5.svg",
      title: "",
      desc: "Bio Tech And Clinical Research Organizations",
    },
    {
      imgurl: "./images/sector_exp_icon6.svg",
      title: "",
      desc: "Government Departments/Organizations/Government Undertakings/Subsidiaries",
    },
    {
      imgurl: "./images/sector_exp_icon7.svg",
      title: "",
      desc: "Manufacturing Sector, Service Sector, Rural Sector, SMEs",
    },
    {
      imgurl: "./images/sector_exp_icon8.svg",
      title: "",
      desc: "Other Sectors Include Educational Institutions, FMCG Etc.",
    },
  ];
  // const expertise_data = [
  //   {
  //     imgurl: "./images/es_health_icon.svg",
  //     title: "Health Insurance",
  //     desc: "Individuals/Family Floater Top Up/Critical illness/Cancer Insurance",
  //   },
  //   {
  //     imgurl: "./images/es_carbike_icon.svg",
  //     title: "Car/Bike Insurance",
  //     desc: "Comprehensive/Package Standalone OD Policy(SOD)/Third Party Liability(STP)",
  //   },
  //   {
  //     imgurl: "./images/es_life_icon.svg",
  //     title: "Life Insurance",
  //     desc: "Term/Term(ROP)/Children Plans(Child Plans)/Endowment Plans/Whole Life Plan/Income Plan",
  //   },
  // ];
  return (
    <Box className="aboutusTab">
      <Grid
        container
        columnSpacing={3}
        className="row mb-margin1"
        marginBottom={"80px"}
      >
        <Grid xs={12} textAlign={"center"}>
          <p>
            Professionalism, Technical Competencies, Experience, and Skills are
            a rare combination, which is required to handle insurance portfolio
            of clients on world class levels. These are now available to the
            clients through Amaze Insurance Brokers Private Limited getting the
            clients amazing benefits in terms of coverage, savings in premium,
            timely Renewals of Policies and expeditious & judicious settlement
            of claims.
          </p>
        </Grid>
      </Grid>

      <Box className="comp_sec_section">
        <Grid container columnSpacing={3} className="row" marginBottom={"20px"}>
          <Grid xs={12}>
            <h4 className="mb-2">
              We are insurance brokers licensed by Insurance Regulatory &
              Development Authority of India (IRDAI) vide License No. 549.{" "}
            </h4>
            <p>
              We have professionals with proven professional skills & technical
              competencies in insurance and insurance broking fields with
              decades of experience in managing insurance portfolios of clients
              ranging from SMEs to Big Corporates/Mega Projects/ Industries
              bringing in customer delight.
            </p>
          </Grid>
        </Grid>
        <Grid container columnSpacing={3} className="row" marginBottom={"20px"}>
          <Grid xs={12} md={7} className="mb-margin2">
            <ul className="abt_desc_list">
              <li>
                <Box className="li_inner">
                  <img src="./images/abt_desc_icon1.svg" alt="" />
                  Licensed by IRDAI on 24th February 2022 – License No. 549.
                </Box>
              </li>
              <li>
                <Box className="li_inner">
                  <img src="./images/abt_desc_icon2.svg" alt="" />
                  With well defined Vision, Mission and Quality Policy.
                </Box>
              </li>
              <li>
                <Box className="li_inner">
                  <img src="./images/abt_desc_icon3.svg" alt="" />A Company with
                  Ethics, Values and Principles.
                </Box>
              </li>
              <li>
                <Box className="li_inner">
                  <img src="./images/abt_desc_icon4.svg" alt="" />
                  Founded by Professionals with Rich Experience in Insurance
                  field and Insurance Broking.
                </Box>
              </li>
              <li>
                <Box className="li_inner">
                  <img src="./images/abt_desc_icon5.svg" alt="" />
                  Having Directors/Key Personnel from Insurance, IT, Finance &
                  Medical Fields.
                </Box>
              </li>
              <li>
                <Box className="li_inner">
                  <img src="./images/abt_desc_icon6.svg" alt="" />
                  Committed to the objective of meeting Policyholder’s/client’s
                  expectations through:
                </Box>
                <ul className="subList">
                  <li>Right Policy</li>
                  <li>Right Price</li>
                  <li>
                    Right Time (Policy Issuance/Renewals/Claims Settlement)
                  </li>
                </ul>
              </li>
              <li>
                <Box className="li_inner">
                  <img src="./images/abt_desc_icon7.svg" alt="" />
                  Committed to contribute to the healthy growth of the industry.
                </Box>
              </li>
            </ul>
          </Grid>
          <Grid xs={12} md={5}>
            <img
              src="./images/aboutTab_header.svg"
              alt=""
              style={{ width: "100%", maxWidth: "470px", margin: "auto" }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box className="expertise_section mb-margin1">
        <Grid container columnSpacing={3} className="row" marginBottom={"20px"}>
          <Grid xs={12} textAlign={"center"}>
            <h2 className="mb-2">Expertise</h2>
            <p>Some of the areas of expertise are</p>
          </Grid>
        </Grid>
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12}>
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={false}
              responsive={responsive}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000} // Adjust the speed as needed (in milliseconds)
            >
              {expertise_data?.map((data, index) => (
                <Box key={index}>
                  <Box className="productCard">
                    <Box className="inner">
                      <img src={data.imgurl} alt={data?.title} />
                      {/* <h4>{data?.title}</h4> */}
                      <p className="mt-6">{data?.desc}</p>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Carousel>
          </Grid>
        </Grid>
      </Box>

      <Box className="vision_mission_section mb-margin1">
        <Grid
          container
          columnSpacing={3}
          className="row"
          marginBottom={"20px"}
          alignItems={"center"}
        >
          <Grid xs={12} md={6} className="mb-margin2">
            <h2 className="mb-3">Our Mission & Vision</h2>

            <h5>Vision Statement</h5>
            <p className="mb-2">
              To establish and emerge as one of the most preferred Insurance
              Brokers, providing innovative solutions to ensure total customer
              satisfaction. In the process, we aspire to combine excellence,
              quality and timely service delivery with professionalism in risk
              coverage, premium management, claim handling, risk management and
              Insurance portfolio Management services to all our customers.
              Sustainability will be the key factor whilst demonstrating and
              earning a reputation for good ethics and trusted services to all
              our customers.
            </p>

            <h5>Mission Statement</h5>
            <ul className="bluecircle_ticklist">
              <li>
                To be ethical, principled and value based organization setting
                new benchmarks in the industry.
              </li>
              <li>
                To cater to the insurance needs of all sections of the society
                and all types of enterprises
              </li>
              <li>
                To provide cost effective and comprehensive insurance solutions
                to the clients
              </li>
              <li>
                To bring in customer delight by adhering to the TATs in
                underwriting and claims settlement
              </li>
              <li>
                To design the best of its breed claims administration and
                management systems ensuring speedy settlement of claims
              </li>
              <li>
                To deploy appropriate Risk Management practices across the
                clients’ portfolios
              </li>
            </ul>
          </Grid>
          <Grid xs={12} md={6}>
            <img
              src="./images/vision_misiion_img.svg"
              alt=""
              style={{ width: "100%", maxWidth: "480px", margin: "auto" }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box className="ethics_section mb-margin1">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12} textAlign={"center"}>
            <h2 className="mb-4">Ethics, Values & Principles</h2>
            <ul>
              <li>
                <span>01.</span>Compliance with IRDAI Regulations
              </li>
              <li>
                <span>02.</span>Compliance with other Statutory Bodies – ROC,
                IT, Service Tax etc
              </li>
              <li>
                <span>03.</span>Strict Adherence to the Code of Conduct
              </li>
              <li>
                <span>04.</span>Timely submission of Returns to IRDA (Monthly,
                Quarterly, Half Yearly and Yearly)
              </li>
              <li>
                <span>05.</span>Committed, Trusted and Dedicated Service to the
                clients
              </li>
              <li>
                <span>06.</span>Process Innovation to bring down TATs to minimum
                levels
              </li>
              <li>
                <span>07.</span>Keeping pace with fast developing Technology and
                leveraging the same
              </li>
              <li>
                <span>08.</span>Total Transparency in the transactions
              </li>
            </ul>
          </Grid>
        </Grid>
      </Box>

      <Box className="quality_policy_section mb-margin1">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
            <h2 className="mb-4">Quality policy Statement</h2>
            <p>
              We at Amaze Insurance Brokers (P) Ltd are committed to Quality
              service that ensures total customer satisfaction which meets the
              expectations besides being the most preferred service provider in
              the market
            </p>
          </Grid>

          <Grid xs={12} marginBottom={"24px"}>
            <h4 className="mb-5">Towards this goal , we are committed to </h4>
            <Box className="qps_box mb-6">
              <img src="./images/QPS_icon1.svg" alt="" />
              <p>
                Providing Quality advice through expertise in dealing with
                insurance products
              </p>
            </Box>
            <Box className="qps_box">
              <img src="./images/QPS_icon2.svg" alt="" />
              <p>
                Ensure that the products & services are efficiently and
                consistently provided on time to the customer for all their
                insurance needs at all times in terms of risk coverage, premium
                management, claim handling, risk management and Insurance
                portfolio Management services.
              </p>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AboutusTab;
