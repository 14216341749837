import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Dropdown from "react-multilevel-dropdown";
import "./Navbar.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { COMMON_ROUTES } from "../../../Routing/Path/CommonPaths";
import { PI_ROUTES } from "../../../Routing/Path/PIPaths";
import { MARINE_ROUTES } from "../../../Routing/Path/MarineTransitionPaths";
import { EB_ROUTES } from "../../../Routing/Path/EBPaths";
import { BIINSURANCE_ROUTES } from "../../../Routing/Path/BIInsurancePaths";
import { LIABILITY_INSURANCE_ROUTES } from "../../../Routing/Path/LiabilityInsurancePaths";
import { PROJECT_INSURANCE_PATHS } from "../../../Routing/Path/ProjectInsurancePaths";
import { MISC_PATHS } from "../../../Routing/Path/MiscellaneousPaths";
import { AVIATION_INSURANCE_PATHS } from "../../../Routing/Path/AviationInsurancePaths";
import { RETAIL_INSURANCE_PATHS } from "../../../Routing/Path/RetailInsurancePaths";
import { useLocation } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [transparentBg, setTransparentBg] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);

      if (position > 76) {
        setTransparentBg(false);
      } else {
        setTransparentBg(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const [anchorE2, setAnchorE2] = React.useState<null | HTMLElement>(null);
  const open2 = Boolean(anchorE2);
  const producthandleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorE2(event.currentTarget);
  };
  const producthandleClose = () => {
    setAnchorE2(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const menus = [
    {
      name: "Property & Engineering Insurance",
      link: "",
      type: "page",
      children: [
        {
          subname: "Fire Insurance",
          sublink: `${PI_ROUTES.FIRE_INSURANCE}`,
        },
        {
          subname: "Industrial All Risk Insurance",
          sublink: `${PI_ROUTES.INDUSTRIAL_RISK_POLICY}`,
        },
        // {
        //   subname: "Burglary Insurance",
        //   sublink: "",
        // },
        {
          subname: "Machinery Breakdown Insurance",
          sublink: `${PI_ROUTES.MACHINERY_BREAKDOWN_INSURANCE}`,
        },
        {
          subname: "Electronic Equipment Insurance",
          sublink: `${PI_ROUTES.ELECTRONIC_EQUP_INS_POLICY}`,
        },
        // {
        //   subname: "Boiler and Pressure Plant Insurance",
        //   sublink: "",
        // },
      ],
    },
    {
      name: "Business Interruption Insurance (FLOP & MLOP)",
      link: "",
      type: "page",
      children: [
        {
          subname: "Fire Loss Of Profits (FLOP) Insurance",
          sublink: `${BIINSURANCE_ROUTES.BIINSURANCE_FLOP}`,
          subtype: "",
        },
        {
          subname: "MLOP Insurance",
          sublink: `${BIINSURANCE_ROUTES.BIINSURANCE_MFLOP}`,
          subtype: "",
        },
      ],
    },
    {
      name: "Marine Cargo Insurance ",
      link: "",
      type: "page",
      children: [
        {
          subname: "Marine Cargo Insurance",
          sublink: `${MARINE_ROUTES.MARINE_CARGO}`,
          subtype: "",
        },
        {
          subname: "Marine Sales Turn Over Policy (Marine STOP)",
          sublink: `${MARINE_ROUTES.MARINE_STOP}`,
          subtype: "",
        },
        {
          subname: "Marine Hull Insurance",
          sublink: `${MARINE_ROUTES.MARINE_HULL}`,
          subtype: "",
        },
      ],
    },
    {
      name: "Liability Insurance",
      link: "",
      type: "page",
      children: [
        {
          subname: "Public Liability (Act) Insurance",
          sublink: `${LIABILITY_INSURANCE_ROUTES.PUBLIC_LIABILITY_ACT}`,
          subtype: "",
        },
        {
          subname: "Commercial General Liability (CGL) Insurance",
          sublink: `${LIABILITY_INSURANCE_ROUTES.COMMERCIAL_GENERAL_LIABILITY}`,
          subtype: "",
        },
        {
          subname: "Directors and Officers Liability Insurance",
          sublink: `${LIABILITY_INSURANCE_ROUTES.DIRECTORS_AND_OFFICERS_LIABILITY}`,
          subtype: "",
        },
        {
          subname: "Errors and Omissions Insurance",
          sublink: `${LIABILITY_INSURANCE_ROUTES.ERRORS_AND_OMISSIONS_LIABILITY}`,
          subtype: "",
        },
        {
          subname: "Cyber Liability Insurance",
          sublink: `${LIABILITY_INSURANCE_ROUTES.CYBER_LIABILITY}`,
          subtype: "",
        },
        {
          subname: "Product Liability Insurance",
          sublink: `${LIABILITY_INSURANCE_ROUTES.PRODUCT_LIABILITY}`,
          subtype: "",
        },
        {
          subname: "Professional Indemnity Insurance",
          sublink: `${LIABILITY_INSURANCE_ROUTES.PROFESSIONAL_INDEMNITY}`,
          subtype: "",
        },
        {
          subname: "Employees Compensation (WC) Insurance Policy",
          sublink: `${LIABILITY_INSURANCE_ROUTES.EMPLOYEES_COMPENSATION}`,
          subtype: "",
        },
      ],
    },
    {
      name: "Engineering/Project Insurance",
      link: "",
      type: "page",
      children: [
        {
          subname: "Erection All Risk Insurance",
          sublink: `${PROJECT_INSURANCE_PATHS.ERECTION_ALL_RISK_INSURANCE}`,
          subtype: "",
        },
        {
          subname: "Contractor's All Risk Insurance",
          sublink: `${PROJECT_INSURANCE_PATHS.CONTRACTOR_ALL_RISK_INSURANCE}`,
          subtype: "",
        },
        {
          subname: "Advance Loss of Profit Insurance (ALOP)",
          sublink: `${PROJECT_INSURANCE_PATHS.ALOP}`,
          subtype: "",
        },
        {
          subname: "Delay in Start-Up Insurance (DSU)",
          sublink: `${PROJECT_INSURANCE_PATHS.DSU}`,
          subtype: "",
        },
        {
          subname: "Marine Cum Erection Insurance ",
          sublink: `${PROJECT_INSURANCE_PATHS.MARINE_CUM}`,
          subtype: "",
        },
      ],
    },
    {
      name: "Employee Benefits Insurance",
      link: "",
      type: "page",
      children: [
        {
          subname: "Group Personal Accident Insurance",
          sublink: `${EB_ROUTES.GPA_INSURANCE}`,
          subtype: "",
        },
        {
          subname: "Group Term Life Insurance",
          sublink: `${EB_ROUTES.GTL_INSURANCE}`,
          subtype: "",
        },
        {
          subname: "Group Medical Insurance",
          sublink: `${EB_ROUTES.GRP_MEDICAL_INSURANCE}`,
          subtype: "",
        },
        // {
        //   subname: "Employee Provident Fund (EPF)",
        //   sublink: `${EB_ROUTES.EPF}`,
        //   subtype: "",
        // },
        // {
        //   subname: "Employee State Insurance (ESI)",
        //   sublink: `${EB_ROUTES.ESI}`,
        //   subtype: "",
        // },
      ],
    },
    // {
    //   name: "Financial Lines Insurance",
    //   link: "",
    //   type: "page",
    //   children: [
    //     {
    //       subname: "Crime Insurance",
    //       sublink: "",
    //       subtype: "",
    //     },
    //     {
    //       subname: "Kidnap and Ransom Insurance",
    //       sublink: "",
    //       subtype: "",
    //     },
    //     {
    //       subname: "Fidelity Guarantee Insurance",
    //       sublink: "",
    //       subtype: "",
    //     },
    //     {
    //       subname: "Money Insurance",
    //       sublink: "",
    //       subtype: "",
    //     },
    //   ],
    // },
    {
      name: "Miscellaneous Class",
      link: "",
      type: "page",
      children: [
        {
          subname: "Portable Electronic Equipment Insurance",
          sublink: `${MISC_PATHS.PORTABLE_ELECTRONIC_EQUIPMENT_INSURANCE}`,
          subtype: "",
        },
        {
          subname: "Money Insurance",
          sublink: `${MISC_PATHS.MONEY_INSURANCE_POLICIES}`,
          subtype: "",
        },
        {
          subname: "Employee Crime/Fidelity Guarantee Insurance",
          sublink: `${MISC_PATHS.EMPLOYEE_CRIME_FIDELITY_GUARANTEE_INSURANCE}`,
          subtype: "",
        },
        {
          subname: "Plate Glass Insurance",
          sublink: `${MISC_PATHS.PLATE_GLASS_INSURANCE_POLICY}`,
          subtype: "",
        },
      ],
    },
    {
      name: "Rural Insurance",
      link: "",
      type: "page",
      children: [
        {
          subname: "Crop Insurance",
          sublink: `${COMMON_ROUTES.CROP_INSURANCE}`,
          subtype: "",
        },
        {
          subname: "Weather Insurance",
          sublink: `${COMMON_ROUTES.WEATHER_INSURANCE}`,
          subtype: "",
        },
      ],
    },

    {
      name: "Aviation Hull Insurance",
      link: "",
      type: "page",
      children: [
        {
          subname: "Aviation Hull Insurance",
          sublink: `${AVIATION_INSURANCE_PATHS.AVIATION_HULL_INSURANCE}`,
          subtype: "",
        },
        {
          subname: "Aviation Liability Insurance",
          sublink: `${AVIATION_INSURANCE_PATHS.AVIATION_LIABILITY_INSURANCE}`,
          subtype: "",
        },
        {
          subname: "Aviation Package Insurance",
          sublink: `${AVIATION_INSURANCE_PATHS.AVIATION_PACKAGE}`,
          subtype: "",
        },
      ],
    },
    {
      name: "Retail Insurance",
      link: "",
      type: "page",
      children: [
        {
          subname: "Tractor Insurance",
          sublink: `${RETAIL_INSURANCE_PATHS.TRACTOR_INSURANCE}`,
          subtype: "",
        },
        {
          subname: "Commercial Goods Vehicle Insurance",
          sublink: `${RETAIL_INSURANCE_PATHS.COMMERCIAL_GOODS_VEHICLE}`,
          subtype: "",
        },
        {
          subname: "Commercial Passenger Vehicle Insurance",
          sublink: `${RETAIL_INSURANCE_PATHS.COMMERCIAL_PASSENGER_VEHICLE}`,
          subtype: "",
        },
        {
          subname: "Miscellaneous Vehicles",
          sublink: `${RETAIL_INSURANCE_PATHS.MISCELLANEOUS_VEHICLES}`,
          subtype: "",
        },
        {
          subname: "Jewelry Showrooms Insurance",
          sublink: `${RETAIL_INSURANCE_PATHS.JEWELRY_SHOWROOMS}`,
          subtype: "",
        },
        {
          subname: "Comprehensive Shopkeepers Insurance",
          sublink: `${RETAIL_INSURANCE_PATHS.SHOPKEEPERS_INSURANCE}`,
          subtype: "",
        },
        {
          subname: "Householder's Insurance",
          sublink: `${RETAIL_INSURANCE_PATHS.HOUSEHOLDER_INSURANCE}`,
          subtype: "",
        },
      ],
    },
    {
      name: "Health Insurance",
      link: "https://bimastreet.com/health-insurance",
      type: "page",
      children: [],
    },
    {
      name: "Car Insurance",
      link: "https://bimastreet.com/car-insurance",
      type: "page",
      children: [],
    },
    {
      name: "Bike Insurance",
      link: "https://bimastreet.com/two-wheeler-insurance",
      type: "page",
      children: [],
    },
    {
      name: "Term Insurance",
      link: "https://bimastreet.com/term-insurance",
      type: "page",
      children: [],
    },
  ];
  return (
    <Box className={`Navbar  ${transparentBg ? " " : "box-shadow"}`}>
      <Grid container columnSpacing={3} className="row" alignItems={"center"}>
        <Grid xs="auto">
          <Link onClick={() => navigate(COMMON_ROUTES.HOME)}>
            <img
              src="../images/amaze_broker_logo.svg"
              alt="amaze"
              width={"132px"}
            />
          </Link>
        </Grid>
        <Grid xs className="nav-section text-right">
          <Box marginBottom={"16px"}>
            <ul>
              {location.pathname !== COMMON_ROUTES.HOME && (
                <li style={{ flexShrink: "0" }}>
                  <Link
                    className="nav-link"
                    onClick={() => navigate(COMMON_ROUTES.HOME)}
                  >
                    Home
                  </Link>
                </li>
              )}
              <li style={{ flexShrink: "0" }}>
                <Link
                  className="nav-link"
                  onClick={() => navigate(COMMON_ROUTES.ABOUT_US)}
                >
                  About Us
                </Link>
              </li>
              {/* <li>
                <Button
                  className="nav-link product-link"
                  id="basic-link"
                  aria-controls={open2 ? "basic-menu2" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open2 ? "true" : undefined}
                  onClick={producthandleClick}
                >
                  Our Insurance Solutions 
                </Button>
                <Menu
                  id="basic-menu2"
                  anchorEl={anchorE2}
                  open={open2}
                  onClose={producthandleClose}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.2))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  MenuListProps={{
                    "aria-labelledby": "basic-link",
                  }}
                >
                  <MenuItem>Health Insurance</MenuItem>
                  <MenuItem>Car Insurance</MenuItem>
                  <MenuItem>Two-Wheeler Insurance</MenuItem>
                  <MenuItem>Term Insurance</MenuItem>
                </Menu>
              </li> */}
              <li className="mutilevel">
                <Dropdown
                  title={
                    <>
                      Our Insurance Solutions
                      <KeyboardArrowDownIcon />
                    </>
                  }
                  menuClassName="dropdown"
                >
                  {menus &&
                    menus?.map((item, index) => (
                      <Dropdown.Item
                        key={index}
                        onClick={() => {
                          item.link && window.open(item?.link);
                        }}
                      >
                        {item.name}
                        {item.children && item.children.length > 0 && (
                          <Dropdown.Submenu
                            position="right"
                            className="submenu"
                          >
                            {item.children.map((subitem, subindex) => (
                              <Dropdown.Item
                                className="submenu-item"
                                key={subindex}
                                onClick={() => navigate(subitem.sublink)}
                              >
                                {subitem.subname}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Submenu>
                        )}
                      </Dropdown.Item>
                    ))}
                </Dropdown>
              </li>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => navigate(COMMON_ROUTES.OUR_SERVICES)}
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => navigate(COMMON_ROUTES.OUR_SECTORS)}
                >
                  Sectors
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => navigate(COMMON_ROUTES.CONTACT)}
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </Box>
          <Box>
            <ul>
              <li>
                <Link
                  className="ca-link"
                  onClick={() => navigate(COMMON_ROUTES.IPAR)}
                >
                  Insurance Portfolio Analysis Report
                  <span className="boxblink">
                    <p className="blink_text"> Free</p>
                  </span>
                </Link>
              </li>
              {/* <li>
                <Link
                  className="pir-link"
                  onClick={() =>
                    window.open(
                      "https://bimastreet.com/claims",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                >
                  {" "}
                  Claims Consultancy
                </Link>
              </li> */}
              <li>
                <Link
                  className="logo-link"
                  onClick={() =>
                    window.open(
                      "https://bimastreet.com/",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                >
                  <img src="../images/BimaStreet-Logo.svg" alt="" />
                </Link>
              </li>
              <li>
                <Link
                  className="pir-link"
                  onClick={() =>
                    window.open(
                      "https://login.amazeinsurancebrokers.com/auth/login",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                >
                  {" "}
                  Corporate Login
                </Link>
              </li>
              <li>
                <Link
                  className="login"
                  onClick={() =>
                    window.open(
                      "https://eb.amazeinsurancebrokers.com/auth/dob",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                >
                  Employee Login
                </Link>
              </li>
            </ul>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Navbar;
