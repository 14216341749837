import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../../Components/Desktop/Navbar/Navbar";
import MFooter from "../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../Components/Mobile/MNavBar/MNavBar";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import "./../../../../SCSS/ProductPages.scss";
import PPAccordionComponent from "../../../../Components/PPAccordionComponent/PPAccordionComponent";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const CropInsurance = () => {
  const isMobile = useIsMobile();

  const coveragePointsArray = [
    {
      title: "Coverage:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              Comprehensive Crop Insurance, also known as Pradhan Mantri Fasal
              Bima Yojana (PMFBY), provides financial protection to farmers
              against crop losses due to natural calamities, adverse weather
              conditions, pests, diseases, and other perils. This policy covers
              a wide range of crops, including cereals, pulses, oilseeds,
              fruits, and vegetables.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Wordings: ",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              The policy wording outlines the terms, conditions, and coverage
              details of Comprehensive Crop Insurance (PMFBY). It specifies the
              insured perils, coverage limits, premium rates, sum insured
              calculations, and claims procedures.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Special Features:  ",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Government Subsidy:</span> PMFBY is a government-sponsored
              crop insurance scheme aimed at providing affordable insurance
              coverage to farmers. It offers premium subsidies to reduce the
              financial burden on farmers and promote agricultural resilience.
            </li>
            <li>
              <span>Risk Pooling: </span> The scheme pools risks across a large
              number of farmers and geographical areas, ensuring that losses
              incurred by individual farmers are compensated collectively. This
              risk-sharing mechanism enhances the stability and sustainability
              of the insurance program.
            </li>
            <li>
              <span>Seasonal Coverage: </span>
              PMFBY provides coverage for both Kharif and Rabi crops, offering
              protection throughout the agricultural seasons. It covers
              pre-sowing risks, such as prevented sowing, as well as
              post-harvest risks, including losses during transit and storage.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Enhancements:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Add-On Coverages: </span> Some PMFBY policies may offer
              additional coverages, such as coverage for losses due to localized
              perils, like hailstorms or inundation, or coverage for specific
              high-value crops requiring specialized protection.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Additional Covers:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Post-Harvest Risks: </span>
              Provides coverage for losses occurring after harvest, including
              transit, storage, and market-related risks.
            </li>
            <li>
              <span>Localized Perils: </span>
              Offers coverage for losses resulting from localized perils such as
              hailstorms, cyclones, floods, droughts, pests, and diseases.
            </li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="subProducts_pp">
          <Grid container columnSpacing={3} className="row">
            <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
              <h1>Comprehensive Crop Insurance Policy (PMFBY)</h1>
            </Grid>
          </Grid>

          <Box className="coverages-section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md className="mb-margin2">
                <PPAccordionComponent data={coveragePointsArray} />
              </Grid>
              <Grid xs={12} md={"auto"} margin={"auto"}>
                <img
                  src="/images/crop_insurance_img.svg"
                  alt=""
                  style={{
                    width: "100%",
                    maxWidth: isMobile ? "300px" : "400px",
                    margin: "auto",
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="excluison_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} className="mb-margin2">
                <h2 className="mb-3" style={{ textAlign: "center" }}>
                  Exclusions
                </h2>
              </Grid>
              <Grid xs={12}>
                <p style={{ textAlign: "center" }}>
                  PMFBY policies typically exclude coverage for losses caused by
                  war, civil unrest, nuclear events, willful negligence, or
                  illegal activities. Additionally, losses resulting from
                  insufficient farming practices or inadequate crop management
                  may be excluded from coverage.
                </p>
              </Grid>
            </Grid>
          </Box>

          <Box className="termsConditions_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} className="mb-margin2">
                <h2 className="mb-3" style={{ textAlign: "center" }}>
                  Terms and Conditions
                </h2>
              </Grid>
              <Grid xs={12}>
                <p style={{ textAlign: "center" }}>
                  The policy's terms and conditions specify the rights and
                  obligations of both the insurer and the insured. These include
                  coverage duration, premium payment terms, sum insured
                  calculations, claims reporting procedures, and policy renewal
                  conditions.
                </p>
              </Grid>
            </Grid>
          </Box>

          <Box className="claimProcedure-section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} className="mb-margin2">
                <h2 className="mb-3" style={{ textAlign: "center" }}>
                  Claims Procedure
                </h2>
              </Grid>
              <Grid xs={12}>
                <p style={{ textAlign: "center" }}>
                  In the event of crop loss or damage covered under the policy,
                  farmers must notify the insurance company or their authorized
                  representatives promptly. The insurer will then conduct crop
                  assessments, verify the extent of the loss, and facilitate the
                  claims settlement process in accordance with the policy terms
                  and conditions.
                </p>
              </Grid>
            </Grid>
          </Box>

          <Box className="claimProcedure-section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} className="mb-margin2">
                <h2 className="mb-3" style={{ textAlign: "center" }}>
                  Conclusion
                </h2>
              </Grid>
              <Grid xs={12}>
                <p style={{ textAlign: "center" }}>
                  Comprehensive Crop Insurance (PMFBY) is a vital risk
                  management tool for farmers, offering financial protection
                  against crop losses and promoting agricultural sustainability.
                  By partnering with Amaze Insurance Brokers, farmers gain
                  access to government-sponsored crop insurance schemes, expert
                  advice, and dedicated support throughout the insurance
                  process.
                </p>
              </Grid>

              <Grid xs={12}>
                <p
                  className="mt-3"
                  style={{
                    textAlign: "center",
                    fontWeight: 500,
                    color: "#000000",
                  }}
                >
                  For further inquiries or to explore Comprehensive Crop
                  Insurance options tailored to your farming needs, contact us
                  today.
                </p>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default CropInsurance