import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import MNavBar from "../../../Components/Mobile/MNavBar/MNavBar";
import Navbar from "../../../Components/Desktop/Navbar/Navbar";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import MFooter from "../../../Components/Mobile/MFooter/MFooter";
import Footer from "../../../Components/Desktop/Footer/Footer";
import CustomButton from "../../../Components/InputFields/CustomButton/CustomButton";
import { COMMON_ROUTES } from "../../../Routing/Path/CommonPaths";
import { useNavigate } from "react-router-dom";

const IPAR = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="main_pp">
          <Grid
            container
            columnSpacing={3}
            className="row mb-margin2"
            marginBottom={"40px"}
          >
            <Grid xs={12} textAlign={"center"} className="mb-6">
              <h2 className="mb-6">
                Unlock the Power of Knowledge with Our Free Insurance Portfolio
                Analysis
              </h2>
              <p>
                At Amaze Insurance Brokers, we believe that knowledge is power,
                especially when it comes to protecting your business. That's why
                we're proud to offer our exclusive Insurance Portfolio Analysis
                service to corporate and SME clients, absolutely free of charge.
              </p>
            </Grid>
            <Grid xs={12} className="mb-6">
              <h5 className="mb-3">What is Insurance Portfolio Analysis?</h5>
              <p>
                Our Insurance Portfolio Analysis is a comprehensive assessment
                of your insurance needs, tailored to your unique risk profile,
                business data, and existing policy documentation. Our team of
                experienced insurance experts meticulously examines every aspect
                of your current insurance portfolio to identify potential gaps
                in coverage, assess your risk exposure, and recommend strategic
                enhancements to optimize your protection.
              </p>
            </Grid>
            <Grid xs={12} className="mb-6">
              <h5 className="mb-3">How Does It Work?</h5>
              <p>
                Getting started is easy. Simply provide us with your existing
                policy copies, along with relevant data and information about
                your business operations. Our team will then conduct a thorough
                analysis, leveraging our industry expertise and cutting-edge
                tools to generate actionable insights.
              </p>
            </Grid>
            <Grid xs={12} className="mb-6">
              <h5 className="mb-3">What You'll Get</h5>
              <p>
                Upon completion, you'll receive a detailed Insurance Portfolio
                Analysis Report, outlining our findings, recommendations, and
                actionable strategies to strengthen your insurance coverage.
                From identifying underinsured areas to optimizing policy limits
                and exploring cost-saving opportunities, our report empowers you
                to make informed decisions that safeguard your business against
                unforeseen risks.
              </p>
            </Grid>
          </Grid>
          <Box
            className="whyChoose_pp_section mb-margin1"
            marginBottom={"80px"}
          >
            <Grid
              container
              columnSpacing={3}
              className="row mb-6"
              alignItems={"center"}
            >
              <Grid xs={12} md={6} className="mb-margin2">
                <h2>Why Choose Amaze Insurance Brokers?</h2>
                <img
                  src="../images/whychoose_pp_img.svg"
                  alt=""
                  style={{ maxWidth: "450px", width: "100%", margin: "auto" }}
                  className="wchoose_img"
                />
              </Grid>
              <Grid xs={12} md={6}>
                <ul className="whyChoosepp_list">
                  <li>
                    <Box className="wc_box">
                      <img src="../images/wc_img1.svg" alt="" />
                      <h6>Expertise</h6>
                      <p>
                        Our team comprises seasoned insurance professionals with
                        years of experience in the field, ensuring you receive
                        expert guidance tailored to your specific needs.
                      </p>
                    </Box>
                    <Box className="wc_box">
                      <img src="../images/wc_img2.svg" alt="" />
                      <h6>Transparency</h6>
                      <p>
                        We believe in transparent communication and clarity. Our
                        reports are easy to understand, empowering you to make
                        informed decisions with confidence.
                      </p>
                    </Box>
                  </li>
                  <li>
                    <Box className="wc_box">
                      <img src="../images/wc_img3.svg" alt="" />
                      <h6>Value-Driven</h6>
                      <p>
                        Our Insurance Portfolio Analysis service is provided
                        free of charge, demonstrating our commitment to
                        delivering exceptional value and support to our clients.
                      </p>
                    </Box>
                  </li>
                </ul>
              </Grid>
            </Grid>
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} textAlign={"center"}>
                <p className="mb-6">
                  Don't leave your insurance coverage to chance. Take control of
                  your risk management strategy today with our Free Insurance
                  Portfolio Analysis service. Contact us to schedule your
                  consultation and unlock the power of comprehensive protection
                  for your business.
                </p>
                <CustomButton
                  className="primaryBtn"
                  fullWidth={false}
                  variant="contained"
                  text="Insurance Portfolio Analysis Request Form"
                  onClick={() => navigate(COMMON_ROUTES.IPAR_FORM)}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default IPAR;
