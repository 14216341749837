import React from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

const ManagementPersonnelTab = () => {
  return (
    <Box className="mgmtPersonnelTab">
      <Box className="profileBox">
        <Grid container columnSpacing={3} className="row" alignItems={"center"}>
          <Grid xs={12} md={8} className="mb-margin2">
            <h4 className="name">Smt K. Aruna Devi</h4>
            <h4 className="designation">Founder Director & Vice Chairman</h4>
            <p>
              Smt. K. Aruna Devi, the visionary Founder Director & Vice
              Chairman, commands an illustrious presence within the insurance
              landscape. Her esteemed recognition as the recipient of the Women
              Entrepreneur India Award for Women Leaders in Banking & Insurance
              in 2022 underscores her profound impact. With an exceptional
              tenure spanning 15 years in the insurance broking domain, she
              epitomizes leadership excellence and innovation.
            </p>
          </Grid>
          <Grid xs={12} md={4}>
            <img
              src="./images/Aruna_Devi.png"
              alt=""
              className="personnel_img"
            />
          </Grid>
        </Grid>
      </Box>

      <Box className="profileBox">
        <Grid container columnSpacing={3} className="row" alignItems={"center"}>
          <Grid xs={12} md={4} order={{ xs: 2, md: 1 }}>
            <img
              src="./images/Muralidhara_Reddy.png"
              alt=""
              className="personnel_img"
            />
          </Grid>
          <Grid xs={12} md={8} className="mb-margin2" order={{ xs: 1, md: 2 }}>
            <h4 className="name">Dr K Muralidhara Reddy</h4>
            <h4 className="designation">
              Managing Director & Principal Officer, M.V.Sc, AIII
            </h4>
            <p>
              Dr. K Muralidhara Reddy, affectionately known as Dr Reddy, stands
              as a titan in the insurance realm, boasting an unparalleled legacy
              of 35 years. A pioneering force since the inception of insurance
              broking in India in 2003, he navigates seamlessly across sectors
              such as Pharma, Power, IT & ITES, and Hospitality, delivering
              bespoke solutions of unparalleled quality. Dr Reddy’s client
              roster reads like a who’s who of industry giants, a testament to
              his unwavering commitment to excellence.
            </p>
          </Grid>
        </Grid>
      </Box>

      <Box className="profileBox">
        <Grid container columnSpacing={3} className="row" alignItems={"center"}>
          <Grid xs={12} md={8} className="mb-margin2">
            <h4 className="name">Dr Vishnu Vardhan Reddy</h4>
            <h4 className="designation">Director, MBBS, MS & MCh </h4>
            <p>
              Dr Vishnu Vardhan Reddy, a luminary in the field of urology,
              brings a touch of brilliance to the board. With a distinguished
              tenure at Kamineni Super Specialty Hospital since 2017, coupled
              with his role as Director at Amaze since 2016, he embodies a blend
              of medical expertise and strategic acumen. His steadfast
              dedication to advancing healthcare excellence resonates profoundly
              in his every endeavour.
            </p>
          </Grid>
          <Grid xs={12} md={4}>
            <img
              src="./images/VishnuVardhan_Reddy.png"
              alt=""
              className="personnel_img"
            />
          </Grid>
        </Grid>
      </Box>

      <Box className="profileBox">
        <Grid container columnSpacing={3} className="row" alignItems={"center"}>
          <Grid xs={12} md={4} order={{ xs: 2, md: 1 }}>
            <img
              src="./images/Bhaskar_Rao.png"
              alt=""
              className="personnel_img"
            />
          </Grid>
          <Grid xs={12} md={8} className="mb-margin2" order={{ xs: 1, md: 2 }}>
            <h4 className="name">K.V Bhaskar Rao MA</h4>
            <h4 className="designation">FIII Chief Operating Officer (COO)</h4>
            <p>
              K.V Bhaskar Rao’s stellar career spanning 38 years in the
              insurance domain underscores a legacy of excellence. As Chief
              Operating Officer, his strategic vision and unwavering commitment
              to client-centric solutions have been instrumental in shaping
              organizational success. His distinguished tenure at New India
              Assurance attests to his prowess in underwriting and claims
              settlement, making him a formidable force in the industry.
            </p>
          </Grid>
        </Grid>
      </Box>

      <Box className="profileBox">
        <Grid container columnSpacing={3} className="row" alignItems={"center"}>
          <Grid xs={12} md={8} className="mb-margin2">
            <h4 className="name">D. V. Krishna Reddy</h4>
            <h4 className="designation">MA, FIII, General Manager (Life)</h4>
            <p>
              D. V. Krishna Reddy, a titan in the Life Insurance industry with
              over three decades of expertise, exudes an aura of professionalism
              and proficiency. His tenure at LIC and Bajaj Allianz Life
              Insurance Co. Ltd stands as a testament to his strategic prowess
              and leadership acumen. With an unwavering focus on operational
              excellence and client satisfaction, he continues to raise the bar
              of achievement.
            </p>
          </Grid>
          <Grid xs={12} md={4}>
            <img
              src="./images/Krishna_Reddy.png"
              alt=""
              className="personnel_img"
            />
          </Grid>
        </Grid>
      </Box>

      <Box className="profileBox">
        <Grid container columnSpacing={3} className="row" alignItems={"center"}>
          <Grid xs={12} md={4} order={{ xs: 2, md: 1 }}>
            <img
              src="./images/Satya_Kumar.png"
              alt=""
              className="personnel_img"
            />
          </Grid>
          <Grid xs={12} md={8} className="mb-margin2" order={{ xs: 1, md: 2 }}>
            <h4 className="name">Ch. V. Satya Kumar</h4>
            <h4 className="designation">(B. Com, AIII) – Branch Head</h4>
            <p>
              Ch. V. Satya Kumar's illustrious career spanning 16 years in
              Insurance, Risk Management, and Broking industries exemplifies a
              legacy of excellence. As Branch Head, his astute insights and
              client-centric approach have been instrumental in fostering
              enduring relationships. His adeptness in risk analysis and
              strategic advisory underscores his unwavering commitment to
              delivering bespoke solutions of unparalleled quality.
            </p>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ManagementPersonnelTab;
