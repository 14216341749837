import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../../../Components/Desktop/Navbar/Navbar";
import MFooter from "../../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../../Components/Mobile/MNavBar/MNavBar";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import "./../../../../../SCSS/ProductPages.scss";
import PPAccordionComponent from "../../../../../Components/PPAccordionComponent/PPAccordionComponent";
import ReadMoreText from "../../../../../Components/ReadMoreTextComponent/ReadMoreText";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const FLOPInsurance = () => {
  const isMobile = useIsMobile();

  const coveragePointsArray = [
    {
      title: "Coverage:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Loss of Profits:</span> Covers the reduction in gross profit
              or revenue resulting from the interruption of business operations
              due to insured perils such as fire, flood, earthquake, or other
              specified events.
            </li>
            <li>
              <span>Increased Cost of Working:</span> Covers additional expenses
              incurred by the insured to minimize the loss and expedite the
              resumption of normal business operations.
            </li>
            <li>
              <span>Rent and Utilities:</span> Covers ongoing expenses such as
              rent, utilities, and lease payments during the period of
              interruption.
            </li>
            <li>
              <span>Denial of Access:</span> Covers losses incurred when access
              to the insured premises is prevented or restricted by civil
              authority due to damage to neighbouring properties or public
              infrastructure.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Add-On Covers:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Contingent Business Interruption:</span> Extends coverage to
              include losses resulting from interruptions to the operations of
              key suppliers, customers, or distributors.
            </li>
            <li>
              <span>Dependent Property Coverage:</span> Covers losses arising
              from interruptions to the operations of dependent properties, such
              as suppliers or service providers.
            </li>
            <li>
              <span>Infectious Disease Cover: </span>Provides coverage for
              business interruption losses resulting from the outbreak of
              infectious diseases, including pandemics such as COVID-19.
            </li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="subProducts_pp">
          <Grid container columnSpacing={3} className="row">
            <Grid xs={12} textAlign="center" marginBottom="24px">
              <h1>Fire Loss Of Profits (FLOP) Insurance</h1>

              <p className="mt-3">
                Marine Cargo Insurance is designed to provide coverage for goods
                and merchandise while they are being transported by various
                modes of transportation, such as sea, air, road, or rail. Below
                is a general overview of the coverage, clauses, exclusions,
                terms, conditions, and claims procedure typically associated
                with Marine Transit Insurance in the Indian insurance market.{" "}
              </p>
            </Grid>
          </Grid>

          <Box className="coverages-section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md className="mb-margin2">
                <PPAccordionComponent data={coveragePointsArray} />
              </Grid>
              <Grid xs={12} md={"auto"} margin={"auto"}>
                <img
                  src="/images/flop_ins_img.svg"
                  alt=""
                  style={{
                    width: "100%",
                    maxWidth: isMobile ? "180px" : "228px",
                    margin: "auto",
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="claimProcedure-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom="24px" textAlign="center">
                <h2>Claims Procedure</h2>
              </Grid>
              <Grid xs={12}>
                <ul className="claimProcedure_list">
                  <li>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom="24px"
                    >
                      <Box textAlign="left">
                        <h4>01</h4>
                        <h6>Notification</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon1.svg"
                        alt="notification icon"
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "The insured must notify the insurer immediately upon occurrence of a covered event that may result in a business interruption loss."
                      }
                      maxlength={100}
                    />
                  </li>
                  <li>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      marginBottom={"24px"}
                    >
                      <Box textAlign={"left"}>
                        <h4>02</h4>
                        <h6>Documentation</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon2.svg"
                        alt="documentation icon"
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "The insured must submit a claim form along with supporting documents, including financial statements, profit and loss statements, and other relevant records. "
                      }
                      maxlength={100}
                    />
                  </li>
                  <li>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      marginBottom={"24px"}
                    >
                      <Box textAlign={"left"}>
                        <h4>03</h4>
                        <h6>Investigation</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon3.svg"
                        alt="investigation icon"
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "The insurer may appoint a loss adjuster to assess the extent of the loss and verify the claim. "
                      }
                      maxlength={100}
                    />
                  </li>
                  <li>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      marginBottom={"24px"}
                    >
                      <Box textAlign={"left"}>
                        <h4>04</h4>
                        <h6>Settlement</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon4.svg"
                        alt="settlement icon"
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "Upon approval of the claim, the insurer will indemnify the insured for the covered loss, subject to the terms, conditions, and limits of the policy."
                      }
                      maxlength={100}
                    />
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>

          <Box className="termsConditions_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md={8} className="mb-margin2">
                <h2 className="mb-3">Terms and Conditions</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    <span>Sum Insured: </span> The maximum amount payable by the
                    insurer in the event of a covered loss, typically based on
                    the insured's gross profit or revenue.
                  </li>
                  <li>
                    <span>Indemnity Period:</span> The period for which the
                    insurer will indemnify the insured for loss of profits,
                    usually ranging from 12 to 24 months.
                  </li>
                  <li>
                    <span>Deductibles:</span> The amount that the insured must
                    bear before the insurer becomes liable to pay the claim.
                  </li>
                  <li>
                    <span>Basis of Gross Profit:</span> The calculation of the
                    insured's gross profit, typically based on the sum of net
                    profit and insured standing charges.
                  </li>
                </ol>
              </Grid>
              <Grid xs={12} md={4}>
                <img
                  src="../images/termsconditions_img.svg"
                  alt="terms and conditions icon"
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="excluison_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md={4} order={{ xs: 2, md: 1 }}>
                <img
                  src="../images/exclusions_img.svg"
                  alt="exclusions icon"
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
              <Grid
                xs={12}
                md={8}
                className="mb-margin2"
                order={{ xs: 1, md: 2 }}
              >
                <h2 className="mb-3">Exclusions</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    <span>War and Terrorism: </span> Losses resulting from war,
                    invasion, acts of foreign enemies, terrorism, civil war,
                    rebellion, revolution, insurrection, or military or usurped
                    power.
                  </li>
                  <li>
                    <span>Nuclear Risks: </span> Losses resulting from nuclear
                    reactions, radiation, or radioactive contamination.
                  </li>
                  <li>
                    <span>Wilful Acts:</span> Losses caused intentionally by the
                    insured or any person acting on behalf of the insured.
                  </li>
                  <li>
                    <span>Delayed Start-Up:</span> Losses incurred during the
                    period required to rebuild or repair damaged property before
                    business operations can resume.
                  </li>
                </ol>
              </Grid>
            </Grid>
          </Box>

          <Box className="whyChoose_pp_section">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems="center"
            >
              <Grid xs={12} md={6} className="mb-margin2">
                <h2>Why Choose Amaze Insurance Brokers?</h2>
                <img
                  src="../images/whychoose_pp_img.svg"
                  alt="why choose us icon"
                  style={{ maxWidth: "450px", width: "100%", margin: "auto" }}
                  className="wchoose_img"
                />
              </Grid>
              <Grid xs={12} md={6}>
                <ul className="whyChoosepp_list">
                  <li>
                    <Box className="wc_box">
                      <img
                        src="../images/wc_img1.svg"
                        alt="expert guidance icon"
                      />
                      <h6>Expert Guidance</h6>
                      <p>
                        Our experienced professionals will help you choose the
                        right coverage under SFSP for your property.
                      </p>
                    </Box>
                    <Box className="wc_box">
                      <img
                        src="../images/wc_img2.svg"
                        alt="seamless claims assistance icon"
                      />
                      <h6>Seamless Claims Assistance</h6>
                      <p>
                        We provide comprehensive support throughout the claims
                        process.
                      </p>
                    </Box>
                  </li>
                  <li>
                    <Box className="wc_box">
                      <img
                        src="../images/wc_img3.svg"
                        alt="competitive quotes icon"
                      />
                      <h6>Competitive Quotes</h6>
                      <p>
                        We work with leading insurance companies to secure the
                        best deals for you.
                      </p>
                    </Box>
                  </li>
                </ul>
              </Grid>
              <p className="mt-10">
                At Amaze Insurance Brokers, we are committed to providing
                superior Business Interruption Insurance solutions that protect
                your business and help you recover swiftly from unforeseen
                disruptions. Contact us today to learn more about our
                comprehensive coverage options and how we can safeguard your
                business against financial loss.{" "}
              </p>
            </Grid>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default FLOPInsurance;
