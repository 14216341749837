import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../../../Components/Desktop/Navbar/Navbar";
import MFooter from "../../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../../Components/Mobile/MNavBar/MNavBar";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import "./../../../../../SCSS/ProductPages.scss";
import PPAccordionComponent from "../../../../../Components/PPAccordionComponent/PPAccordionComponent";
import ReadMoreText from "../../../../../Components/ReadMoreTextComponent/ReadMoreText";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const CGLInsurancePolicy = () => {
  const isMobile = useIsMobile();

  const coveragePointsArray = [
    {
      title: "Coverage:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Bodily Injury:</span> Covers bodily injury or death to third
              parties caused by the insured's business operations, products, or
              services.
            </li>
            <li>
              <span>Property Damage:</span> Covers damage to third-party
              property caused by the insured's business operations, products, or
              services.
            </li>
            <li>
              <span>Personal and Advertising Injury:</span> Covers claims
              arising from offenses such as libel, slander, defamation,
              copyright infringement, false arrest, malicious prosecution, and
              invasion of privacy.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Special Features: ",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Worldwide Coverage:</span> Provides coverage for claims
              arising from business activities conducted worldwide, subject to
              policy terms and conditions.
            </li>
            <li>
              <span>Products and Completed Operations Coverage: </span> Extends
              coverage to include liability arising from products sold or
              services completed by the insured.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Enhancements:  ",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Contractual Liability Coverage:</span> Covers liability
              assumed under contract, such as hold harmless agreements, provided
              they are not for the insured's sole negligence or willful
              misconduct.
            </li>
            <li>
              <span> Medical Payments: </span> Provides coverage for medical
              expenses incurred by third parties for injuries sustained on the
              insured's premises or due to the insured's operations, regardless
              of fault.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Add-on Covers:  ",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Cyber Liability:</span> Extends coverage to include
              liability arising from data breaches, cyber-attacks, and other
              cyber risks, including legal defense costs and regulatory fines.
            </li>
            <li>
              <span>Employment Practices Liability: </span> Covers claims
              related to wrongful termination, discrimination, harassment, and
              other employment-related issues.
            </li>
          </ul>
        </>
      ),
    },
  ];


  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="subProducts_pp">
          <Grid container columnSpacing={3} className="row">
            <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
              <h1>Commercial General Liability (CGL) Insurance Policy</h1>
            </Grid>
          </Grid>

          <Box className="coverages-section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md className="mb-margin2">
                <PPAccordionComponent data={coveragePointsArray} />
              </Grid>
              <Grid xs={12} md={"auto"} margin={"auto"}>
                <img
                  src="/images/cgl_policy_img.svg"
                  alt=""
                  style={{
                    width: "100%",
                    maxWidth: isMobile ? "220px" : "309px",
                    margin: "auto",
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="claimProcedure-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom={"24px"} textAlign={"center"}>
                <h2>Claims Procedure</h2>
              </Grid>
              <Grid xs={12}>
                <ul className="claimProcedure_list">
                  <li>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      marginBottom={"24px"}
                    >
                      <Box textAlign={"left"}>
                        <h4>01</h4>
                        <h6>Immediate Notification</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon1.svg"
                        alt="notification icon"
                        style={{
                          width: "52px",
                          height: "52px",
                          borderRadius: "4px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "In case of an incident or claim, the insured must notify the insurance company or broker as soon as practicable.  "
                      }
                      maxlength={100}
                    />
                  </li>
                  <li>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom="24px"
                    >
                      <Box textAlign="left">
                        <h4>02</h4>
                        <h6>Claim Documentation</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon2.svg"
                        alt="documentation icon"
                        style={{
                          width: "52px",
                          height: "52px",
                          borderRadius: "4px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "Submit a detailed claim form along with all relevant documents, including incident reports, witness statements, photographs, and other evidence supporting the claim, to the insurance company.  "
                      }
                      maxlength={100}
                    />
                  </li>
                  <li>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom="24px"
                    >
                      <Box textAlign="left">
                        <h4>03</h4>
                        <h6>Claim Evaluation and Settlement</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon3.svg"
                        alt="evaluation and settlement icon"
                        style={{
                          width: "52px",
                          height: "52px",
                          borderRadius: "4px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "The insurance company will evaluate the claim, conduct an investigation if necessary, and either approve or deny the claim based on the policy terms and conditions. If approved, the insurer will indemnify the insured or reimburse for covered losses.  "
                      }
                      maxlength={100}
                    />
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>

          <Box className="termsConditions_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems="center"
            >
              <Grid xs={12} md={8} className="mb-margin2">
                <h2 className="mb-3">Terms and Conditions</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    <span>Policy Period:</span> Typically, the policy is issued
                    for a one-year period and needs to be renewed annually.
                  </li>
                  <li>
                    <span>Limits of Liability:</span> The policy limit is the
                    maximum amount the insurer will pay for covered claims
                    during the policy period, subject to deductibles and
                    sub-limits.
                  </li>
                  <li>
                    <span>Deductibles:</span> A deductible is the amount the
                    insured must pay before the insurance coverage kicks in.
                  </li>
                </ol>
              </Grid>
              <Grid xs={12} md={4}>
                <img
                  src="../images/termsconditions_img.svg"
                  alt="terms and conditions icon"
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="excluison_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md={4} order={{ xs: 2, md: 1 }}>
                <img
                  src="../images/exclusions_img.svg"
                  alt="exclusions icon"
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
              <Grid
                xs={12}
                md={8}
                className="mb-margin2"
                order={{ xs: 1, md: 2 }}
              >
                <h2 className="mb-3">Exclusions</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    <span>Intentional Acts: </span> Claims arising from
                    intentional acts or violations of laws committed by the
                    insured with the intent to cause harm.
                  </li>
                  <li>
                    <span> Professional Services:</span> Claims arising from the
                    rendering or failure to render professional services, which
                    are typically covered under a Professional Liability or
                    Errors and Omissions policy.
                  </li>
                  <li>
                    <span>Pollution: </span> Claims arising from the release,
                    discharge, or dispersal of pollutants, unless caused by a
                    sudden and accidental event.
                  </li>
                </ol>
              </Grid>
            </Grid>
          </Box>

          <Box className="whyChoose_pp_section">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems="center"
            >
              <Grid xs={12} md={6} className="mb-margin2">
                <h2>Why Choose Amaze Insurance Brokers?</h2>
                <img
                  src="../images/whychoose_pp_img.svg"
                  alt="why choose us icon"
                  style={{ maxWidth: "450px", width: "100%", margin: "auto" }}
                  className="wchoose_img"
                />
              </Grid>
              <Grid xs={12} md={6}>
                <ul className="whyChoosepp_list">
                  <li>
                    <Box className="wc_box">
                      <img
                        src="../images/wc_img1.svg"
                        alt="expert guidance icon"
                      />
                      <h6>Expert Guidance</h6>
                      <p>
                        Our team of experts understands the requirement of
                        limits and coverages under CGL Policy and can provide
                        you with expert guidance and support every step of the
                        way.
                      </p>
                    </Box>
                    <Box className="wc_box">
                      <img
                        src="../images/wc_img2.svg"
                        alt="claim assistance icon"
                      />
                      <h6>Seamless Claims Assistance</h6>
                      <p>
                        We provide comprehensive support throughout the claims
                        process.
                      </p>
                    </Box>
                  </li>
                  <li>
                    <Box className="wc_box">
                      <img src="../images/wc_img3.svg" alt="quotes icon" />
                      <h6>Competitive Quotes</h6>
                      <p>
                        We work with leading insurance companies to secure the
                        best deals for you.
                      </p>
                    </Box>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default CGLInsurancePolicy;
