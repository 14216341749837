import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../../../Components/Desktop/Navbar/Navbar";
import MFooter from "../../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../../Components/Mobile/MNavBar/MNavBar";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import "./../../../../../SCSS/ProductPages.scss";
import PPAccordionComponent from "../../../../../Components/PPAccordionComponent/PPAccordionComponent";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const CommercialPassenger = () => {
  const isMobile = useIsMobile();

  const coveragePointsArray = [
    {
      title: "Coverage:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              Commercial Vehicle Insurance provides financial protection to
              owners of passenger-carrying vehicles against various risks
              encountered during transportation operations. This policy offers
              coverage for damage or loss to the insured vehicle due to
              accidents, theft, fire, natural calamities, and third-party
              liabilities arising from vehicle usage.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Wordings:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              The policy wording outlines the terms, conditions, and coverage
              details of Commercial Vehicle Insurance. It specifies the insured
              perils, coverage limits, deductible amounts, premium rates, and
              claims procedures.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Special Features:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span> Comprehensive Coverage:</span>Offers broad coverage for
              damage or loss to the insured vehicle, including collision, theft,
              fire, vandalism, and natural perils such as floods or storms.
            </li>
            <li>
              <span>Third-Party Liability:</span>Provides coverage for
              third-party bodily injury, property damage, or accidental death
              arising from vehicle usage, ensuring financial protection against
              legal liabilities.
            </li>
            <li>
              <span>Personal Accident Cover:</span>Some policies include
              personal accident cover for the vehicle owner or driver, offering
              financial compensation for accidental death, permanent disability,
              or medical expenses resulting from vehicle accidents.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Enhancements:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Passenger Liability Cover: </span> Offers optional coverage
              for passenger liability, providing financial protection against
              claims arising from bodily injury or death of passengers during
              transportation.
            </li>
            <li>
              <span>Roadside Assistance:</span> Provides optional roadside
              assistance services, including towing, fuel delivery, and
              mechanical repairs, to ensure prompt assistance in case of
              breakdowns or emergencies.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Additional Covers:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Legal Expenses: </span> Offers coverage for legal expenses
              incurred in defending against third-party liability claims or
              pursuing legal action against parties responsible for vehicle
              damage or loss.
            </li>
            <li>
              <span>Loss of Use:</span> Provides coverage for loss of use or
              rental expenses incurred while the insured vehicle is undergoing
              repairs or maintenance due to a covered loss.
            </li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="subProducts_pp">
          <Grid container columnSpacing={3} className="row">
            <Grid xs={12} textAlign={"center"} marginBottom="24px">
              <h1>Commercial Vehicle (Passenger Carrying) Insurance Policy</h1>
            </Grid>
          </Grid>

          <Box className="coverages-section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md className="mb-margin2">
                <PPAccordionComponent data={coveragePointsArray} />
              </Grid>
              <Grid xs={12} md={"auto"} margin={"auto"}>
                <img
                  src="/images/commercial_passenger_vehicle_insurance.svg"
                  alt=""
                  style={{
                    width: "100%",
                    maxWidth: isMobile ? "300px" : "405px",
                    margin: "auto",
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="claimProcedure-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom={"24px"} textAlign="center">
                <h2>Claims Procedure</h2>
                <p>
                  In the event of a covered loss or damage to the insured
                  vehicle, the policyholder must notify the insurer promptly and
                  provide supporting documentation, such as photographs,
                  estimates, and police reports. The insurer will then assess
                  the claim, verify the damage, and facilitate the claims
                  settlement process in accordance with the policy terms and
                  conditions.
                </p>
              </Grid>
            </Grid>
          </Box>

          <Box className="termsConditions_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems="center"
            >
              <Grid xs={12} md={8} className="mb-margin2">
                <h2 className="mb-3">Terms and Conditions</h2>
                <p>
                  The policy's terms and conditions specify the rights and
                  obligations of both the insurer and the insured. These include
                  coverage duration, premium payment terms, deductible amounts,
                  claims reporting procedures, and policy renewal conditions.
                </p>
              </Grid>
              <Grid xs={12} md={4}>
                <img
                  src="../images/termsconditions_img.svg"
                  alt=""
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
            </Grid>
          </Box>

          {/* {exclusions   section included in some pages that why i have created here} */}
          <Box className="excluison_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems="center"
            >
              <Grid xs={12} md={4} order={{ xs: 2, md: 1 }}>
                <img
                  src="../images/exclusions_img.svg"
                  alt=""
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
              <Grid
                xs={12}
                md={8}
                className="mb-margin2"
                order={{ xs: 1, md: 2 }}
              >
                <h2 className="mb-3">Exclusions</h2>
                <p className="mb-3">
                  Commercial Vehicle Insurance policies typically exclude
                  coverage for wear and tear, mechanical breakdowns,
                  consequential losses, intentional acts, racing or
                  competitions, and unauthorized usage of the insured vehicle.
                  Additionally, losses resulting from war, nuclear events, or
                  acts of terrorism may be excluded from coverage.
                </p>
              </Grid>
            </Grid>
          </Box>

          <Box className="excluison_section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} textAlign={"center"}>
                <h2>Conclusion</h2>
                <p className="mb-3">
                  Commercial Vehicle Insurance is essential for businesses
                  engaged in passenger transportation to protect their valuable
                  assets and mitigate financial risks associated with vehicle
                  operations. By partnering with Amaze Insurance Brokers,
                  commercial vehicle owners gain access to comprehensive
                  coverage options, expert advice, and dedicated support
                  throughout the insurance process.
                </p>
                <p style={{ fontWeight: 600 }}>
                  For further inquiries or to explore Commercial Vehicle
                  Insurance options tailored to your needs, contact us today.
                </p>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default CommercialPassenger;
