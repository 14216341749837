import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import PropertyInsurance from "../../Pages/Desktop/ProductPages/PropertyInsurance/PropertyInsurance";
import FireInsurance from "../../Pages/Desktop/ProductPages/PropertyInsurance/FireInsurance/FireInsurance";
import { PI_ROUTES } from "../Path/PIPaths";
import IndustrialAllRiskPolicy from "../../Pages/Desktop/ProductPages/PropertyInsurance/IndustrialAllRiskPolicy/IndustrialAllRiskPolicy";
import ElectronicEquipmentInsurancePolicy from "../../Pages/Desktop/ProductPages/PropertyInsurance/ElectronicEquipmentInsurancePolicy/ElectronicEquipmentInsurancePolicy";
import MachineryBreakdownInsurance from "../../Pages/Desktop/ProductPages/PropertyInsurance/MachineryBreakdownInsurance/MachineryBreakdownInsurance";

function PIROUTES() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Routes>
        <Route
          path={PI_ROUTES.PROPERTY_INSURANCE}
          element={<PropertyInsurance />}
        />
        <Route path={PI_ROUTES.FIRE_INSURANCE} element={<FireInsurance />} />
        <Route path={PI_ROUTES.INDUSTRIAL_RISK_POLICY} element={<IndustrialAllRiskPolicy />} />
        <Route path={PI_ROUTES.ELECTRONIC_EQUP_INS_POLICY} element={<ElectronicEquipmentInsurancePolicy />} />
        <Route path={PI_ROUTES.MACHINERY_BREAKDOWN_INSURANCE} element={<MachineryBreakdownInsurance />} />
      </Routes>
    </div>
  );
}

export default PIROUTES;
