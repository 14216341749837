import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../../../Components/Desktop/Navbar/Navbar";
import MFooter from "../../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../../Components/Mobile/MNavBar/MNavBar";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import "./../../../../../SCSS/ProductPages.scss";
import PPAccordionComponent from "../../../../../Components/PPAccordionComponent/PPAccordionComponent";
import ReadMoreText from "../../../../../Components/ReadMoreTextComponent/ReadMoreText";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const ContractorAllRiskInsurance = () => {
  const isMobile = useIsMobile();

  const coveragePointsArray = [
    {
      title: "Coverage:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Material Damage:</span> Covers physical loss or damage to
              the contract works, construction materials, and equipment during
              the construction period.
            </li>
            <li>
              <span>Third-party Liability:</span> Covers legal liabilities
              arising from bodily injury or property damage to third parties
              caused by the insured's construction activities.
            </li>
            <li>
              <span>
                Advanced Loss of Profits (ALOP) or Delay in Start-up (DSU):{" "}
              </span>{" "}
              Covers financial losses due to delays in project completion caused
              by insured perils, leading to a delay in the commencement of
              business operations.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Special Features:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Transit and Storage:</span> Provides coverage for materials
              and equipment in transit to the site or stored at the project site
              or a designated location.
            </li>
            <li>
              <span>Maintenance Period:</span> Extends coverage during the
              maintenance period following project completion, typically up to
              12 months, to cover defects, errors, or omissions in the insured
              project.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Enhancements:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Additional Customs Duty:</span>Covers additional customs
              duty payable on imported machinery, equipment, and materials
              required for the insured project.
            </li>
            <li>
              <span> Express Freight:</span> Covers the additional cost of
              express freight incurred to repair or replace damaged insured
              items urgently.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Additional Covers:",
      desc: (
        <>
          <p className="mb-2">
            Contractors' All Risks (CAR) Insurance Policy offers various
            additional covers that can be tailored to meet the specific needs
            and risks associated with construction projects. These additional
            covers provide enhanced protection and can be added to the standard
            policy to provide comprehensive coverage. Here are some common
            additional covers available under the CAR Insurance Policy:
          </p>
          <ul className="circle_ticklist">
            <li>
              <span>Third-Party Property Damage (TPPD): </span>
              Extends coverage to include legal liabilities arising from damage
              to third-party property caused by the insured's construction
              activities.
            </li>
            <li>
              <span> Terrorism Cover:</span>
              Extends coverage to include loss or damage caused by acts of
              terrorism, sabotage, or political violence.
            </li>
            <li>
              <span>Express Freight:</span> Covers the additional cost of
              express freight incurred to repair or replace damaged insured
              items urgently.
            </li>
            <li>
              <span>Additional Customs Duty:</span>Covers additional customs
              duty payable on imported machinery, equipment, and materials
              required for the insured project.
            </li>
            <li>
              <span>Maintenance Cover:</span> Extends coverage during the
              maintenance period following project completion, typically up to
              12 months, to cover defects, errors, or omissions in the insured
              project.
            </li>
            <li>
              <span>Storage Risks Cover:</span> Provides coverage for materials
              and equipment in transit to the site or stored at the project site
              or a designated location against risks of loss or damage.
            </li>
            <li>
              <span>Off-site Storage Cover:</span> Extends coverage to include
              materials and equipment stored at off-site locations away from the
              project site, subject to certain conditions and limitations.
            </li>
            <li>
              <span>Professional Fees Cover:</span>Covers the additional costs
              of professional fees incurred for architects, engineers, and
              consultants in the event of a claim to rectify the insured damage.
            </li>
            <li>
              <span>Debris Removal Cover:</span>Covers the cost of removing
              debris following damage to insured property, up to a specified
              limit.
            </li>
            <li>
              <span>Errors and Omissions Cover:</span>
              Covers financial losses due to errors or omissions in the insured
              project design or specifications, leading to rectification costs.
            </li>
            <li>
              <span>Air Freight Cover:</span> Covers the additional cost of air
              freight incurred for urgent delivery of replacement parts or
              equipment following insured damage.
            </li>
            <li>
              <span> Loss of Profit or Delay in Start-up (DSU) Cover:</span>
              Covers financial losses due to delays in project completion caused
              by insured perils, leading to a delay in the commencement of
              business operations.
            </li>
          </ul>
          <p className="mt-2">
            It's important to note that the availability and terms of these
            additional covers can vary between insurance providers.
          </p>
        </>
      ),
    },
  ];


  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="subProducts_pp">
          <Grid container columnSpacing={3} className="row">
            <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
              <h1>Contractors' All Risks (CAR) Insurance Policy</h1>
            </Grid>
          </Grid>

          <Box className="coverages-section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md className="mb-margin2">
                <PPAccordionComponent data={coveragePointsArray} />
              </Grid>
              <Grid xs={12} md={"auto"} margin={"auto"}>
                <img
                  src="/images/contractor_all_risk_policy.svg"
                  alt=""
                  style={{
                    width: "100%",
                    maxWidth: isMobile ? "200px" : "270px",
                    margin: "auto",
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="claimProcedure-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom={"24px"} textAlign={"center"}>
                <h2>Claims Procedure</h2>
              </Grid>
              <Grid xs={12}>
                <ul className="claimProcedure_list">
                  <li>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      marginBottom={"24px"}
                    >
                      <Box textAlign={"left"}>
                        <h4>01</h4>
                        <h6>Immediate Notification</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon1.svg"
                        alt=""
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "In case of an incident or claim, the insured must notify the insurance company or broker as soon as practicable."
                      }
                      maxlength={100}
                    />
                  </li>
                  <li>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      marginBottom={"24px"}
                    >
                      <Box textAlign={"left"}>
                        <h4>02</h4>
                        <h6>Claim Documentation</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon2.svg"
                        alt=""
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "Submit a detailed claim form along with all relevant documents, including incident reports, repair estimates, project schedule, and other evidence supporting the claim, to the insurance company."
                      }
                      maxlength={100}
                    />
                  </li>
                  <li>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      marginBottom={"24px"}
                    >
                      <Box textAlign={"left"}>
                        <h4>03</h4>
                        <h6>Claim Evaluation and Settlement:</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon3.svg"
                        alt=""
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "The insurance company will evaluate the claim, conduct an investigation if necessary, and either approve or deny the claim based on the policy terms and conditions. If approved, the insurer will indemnify the insured or reimburse for covered losses."
                      }
                      maxlength={100}
                    />
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>

          <Box className="termsConditions_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md={8} className="mb-margin2">
                <h2 className="mb-3">Terms and Conditions</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    <span>Policy Period: </span> Typically, the policy is issued
                    for the duration of the construction project, including the
                    erection/installation period and the maintenance period.
                  </li>
                  <li>
                    <span>Limits of Liability:</span> The policy limit is the
                    maximum amount the insurer will pay for covered claims
                    during the policy period, subject to deductibles and
                    sub-limits.
                  </li>
                  <li>
                    <span>Deductibles:</span> A deductible is the amount the
                    insured must pay before the insurance coverage kicks in.
                  </li>
                </ol>
              </Grid>
              <Grid xs={12} md={4}>
                <img
                  src="../images/termsconditions_img.svg"
                  alt=""
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
            </Grid>
          </Box>

          {/* {exclusions   section included in some pages that why i have created here} */}
          <Box className="excluison_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md={4} order={{ xs: 2, md: 1 }}>
                <img
                  src="../images/exclusions_img.svg"
                  alt=""
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
              <Grid
                xs={12}
                md={8}
                className="mb-margin2"
                order={{ xs: 1, md: 2 }}
              >
                <h2 className="mb-3">Exclusions</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    <span>Wear and Tear: </span> Damage due to normal wear and
                    tear, gradual deterioration, or gradual depreciation of
                    insured items.
                  </li>
                  <li>
                    <span>Wilful Acts:</span> Damage caused intentionally by the
                    insured or any of its employees.
                  </li>
                  <li>
                    <span>War and Nuclear Risks:</span> Damage caused due to
                    war, invasion, act of foreign enemy, hostilities, civil war,
                    rebellion, revolution, nuclear risks, or radioactive
                    contamination.
                  </li>
                </ol>
              </Grid>
            </Grid>
          </Box>

          <Box className="whyChoose_pp_section">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md={6} className="mb-margin2">
                <h2 className="mb-2">Why Choose Amaze Insurance Brokers?</h2>
                <p className="mb-6">
                  At Amaze Insurance Brokers, we specialize in providing
                  tailored insurance solutions, including comprehensive
                  Contractors’ All Risks (CAR) Insurance Policies, designed to
                  protect your construction projects against unforeseen risks
                  and challenges. Our team of experienced professionals works
                  closely with you to understand your unique requirements and
                  identify the right coverage options to safeguard your
                  investments.
                </p>
                <img
                  src="../images/whychoose_pp_img.svg"
                  alt=""
                  style={{ maxWidth: "450px", width: "100%", margin: "auto" }}
                  className="wchoose_img"
                />
              </Grid>
              <Grid xs={12} md={6}>
                <ul className="whyChoosepp_list">
                  <li>
                    <Box className="wc_box">
                      <img src="../images/wc_img1.svg" alt="" />
                      <h6>Customized Coverage</h6>
                      <p>
                        We design bespoke CAR Insurance Policies that align with
                        your project's specific needs, ensuring you have the
                        right protection in place.
                      </p>
                    </Box>
                    <Box className="wc_box">
                      <img src="../images/wc_img2.svg" alt="" />
                      <h6>Risk Management</h6>
                      <p>
                        We provide expert risk assessment and management advice
                        to help mitigate potential risks and minimize exposures,
                        enhancing the resilience of your construction projects.
                      </p>
                    </Box>
                    <Box className="wc_box">
                      <img src="../images/wc_img4.svg" alt="" />
                      <h6>Client Support</h6>
                      <p>
                        Our commitment to client satisfaction drives us to
                        deliver exceptional service at every touchpoint,
                        offering guidance, support, and advice whenever you need
                        it.
                      </p>
                    </Box>
                  </li>
                  <li>
                    <Box className="wc_box">
                      <img src="../images/wc_img3.svg" alt="" />
                      <h6>Claims Assistance</h6>
                      <p>
                        In the event of a claim, our dedicated claims team
                        assists you throughout the claims process, from initial
                        notification to final settlement, ensuring a smooth and
                        hassle-free experience.
                      </p>
                    </Box>
                    <Box className="wc_box">
                      <img src="../images/wc_img5.svg" alt="" />
                      <h6>Policy Review & Renewal</h6>
                      <p>
                        We regularly review your insurance policies to ensure
                        they remain relevant and provide adequate coverage,
                        recommending enhancements or adjustments as your project
                        evolves.
                      </p>
                    </Box>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default ContractorAllRiskInsurance;
