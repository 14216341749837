import React, { useState } from "react";
import { Box, Link, Rating } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import Navbar from "../../../../Components/Desktop/Navbar/Navbar";
import "./../../../../SCSS/CMSPages.scss";
import Footer from "../../../../Components/Desktop/Footer/Footer";
import { COLORS } from "../../../../SupportingFiles/colors";
import MNavBar from "../../../../Components/Mobile/MNavBar/MNavBar";
import MFooter from "../../../../Components/Mobile/MFooter/MFooter";

const WhyChooseUs = () => {
  const isMobile = useIsMobile();
  return (
    <Box className="cmsPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="cms-inner">
        <Grid
          container
          columnSpacing={3}
          className="row mb-margin2"
          marginBottom={"80px"}
        >
          <Grid xs={12} textAlign={"center"}>
            <h2 className="mb-6">Why Choose Amaze Insurance Brokers</h2>
            <p>
              At Amaze Insurance Brokers, we understand that selecting the right
              insurance partner is a critical decision for individuals and
              businesses alike. Here's why we stand out as your preferred
              choice:
            </p>
          </Grid>
        </Grid>

        <Box className="whychoose-list-section mb-margin1">
          <Grid container columnSpacing={3} className="row">
            <Grid xs={12}>
              <ul className="whychoose_list">
                <li>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    marginBottom={"24px"}
                  >
                    <Box>
                      <h4>01</h4>
                      <h6>Power Sector</h6>
                    </Box>
                    <img
                      src="./images/why_choose_list_icon1.svg"
                      alt=""
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "5px",
                      }}
                    />
                  </Box>
                  <hr />
                  <p>
                    From windmills and hydel plants to solar, biomass, gas, and
                    thermal power projects, we offer specialized insurance
                    solutions to protect assets and mitigate risks in the
                    dynamic energy industry.
                  </p>
                </li>
                <li>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    marginBottom={"24px"}
                  >
                    <Box>
                      <h4>02</h4>
                      <h6>Proper and Adequate Coverage</h6>
                    </Box>
                    <img
                      src="./images/why_choose_list_icon2.svg"
                      alt=""
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "5px",
                      }}
                    />
                  </Box>
                  <hr />
                  <p>
                    We understand the importance of proper and adequate
                    insurance coverage to protect your business against
                    unforeseen risks and liabilities. Our team of experts
                    conducts thorough assessments and evaluations to ensure that
                    your insurance program is tailored to meet your specific
                    needs and objectives. With our personalized approach, you
                    can rest assured that your assets, operations, and
                    liabilities are adequately covered.
                  </p>
                </li>
                <li>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    marginBottom={"24px"}
                  >
                    <Box>
                      <h4>03</h4>
                      <h6>Expertise</h6>
                    </Box>
                    <img
                      src="./images/why_choose_list_icon3.svg"
                      alt=""
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "5px",
                      }}
                    />
                  </Box>
                  <hr />
                  <p>
                    With decades of industry experience and a team of seasoned
                    professionals, we bring unparalleled expertise to the table.
                    Our in-depth knowledge of the insurance landscape allows us
                    to provide tailored solutions that address your unique needs
                    and challenges.
                  </p>
                </li>
                <li>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    marginBottom={"24px"}
                  >
                    <Box>
                      <h4>04</h4>
                      <h6>Tailored Solutions</h6>
                    </Box>
                    <img
                      src="./images/why_choose_list_icon4.svg"
                      alt=""
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "5px",
                      }}
                    />
                  </Box>
                  <hr />
                  <p>
                    We recognize that every client is unique, which is why we
                    take a customized approach to insurance planning. Whether
                    you're an individual, a small business, or a large
                    corporation, we work closely with you to design a tailored
                    insurance program that meets your specific needs and budget.
                  </p>
                </li>
                <li>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    marginBottom={"24px"}
                  >
                    <Box>
                      <h4>05</h4>
                      <h6>Proactive Risk Management</h6>
                    </Box>
                    <img
                      src="./images/why_choose_list_icon5.svg"
                      alt=""
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "5px",
                      }}
                    />
                  </Box>
                  <hr />
                  <p>
                    Beyond insurance, we provide proactive risk management
                    services to help you identify, assess, and mitigate
                    potential risks to your assets and operations. Our risk
                    management experts offer practical advice and strategies to
                    minimize exposure and protect your bottom line.
                  </p>
                </li>
                <li>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    marginBottom={"24px"}
                  >
                    <Box>
                      <h4>06</h4>
                      <h6>Commitment to Excellence</h6>
                    </Box>
                    <img
                      src="./images/why_choose_list_icon6.svg"
                      alt=""
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "5px",
                      }}
                    />
                  </Box>
                  <hr />
                  <p>
                    At Amaze Insurance Brokers, excellence is not just a goal –
                    it's our standard. We are committed to delivering
                    exceptional service, innovative solutions, and measurable
                    results that exceed your expectations and contribute to your
                    success.
                  </p>
                </li>
                <li>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    marginBottom={"24px"}
                  >
                    <Box>
                      <h4>07</h4>
                      <h6>Trusted Partner</h6>
                    </Box>
                    <img
                      src="./images/why_choose_list_icon7.svg"
                      alt=""
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "5px",
                      }}
                    />
                  </Box>
                  <hr />
                  <p>
                    When you choose Amaze Insurance Brokers, you're not just
                    getting an insurance provider – you're gaining a trusted
                    partner in your journey towards financial security and peace
                    of mind. We are committed to your success and will always go
                    the extra mile to ensure your satisfaction.
                  </p>
                </li>
                <li>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    marginBottom={"24px"}
                  >
                    <Box>
                      <h4>08</h4>
                      <h6>Proactive Risk Management</h6>
                    </Box>
                    <img
                      src="./images/why_choose_list_icon8.svg"
                      alt=""
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "5px",
                      }}
                    />
                  </Box>
                  <hr />
                  <p>
                    Beyond insurance, we provide proactive risk management
                    services to help you identify, assess, and mitigate
                    potential risks to your assets and operations. Our risk
                    management experts offer practical advice and strategies to
                    minimize exposure and protect your bottom line.
                  </p>
                </li>
                <li>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    marginBottom={"24px"}
                  >
                    <Box>
                      <h4>09</h4>
                      <h6>Speedy and Hassle-Free Claims Settlement</h6>
                    </Box>
                    <img
                      src="./images/why_choose_list_icon9.svg"
                      alt=""
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "5px",
                      }}
                    />
                  </Box>
                  <hr />
                  <p>
                    In the event of a claim, we understand the importance of
                    prompt and efficient claims settlement to minimize
                    disruptions to your business operations. Our dedicated
                    claims management team works tirelessly to ensure speedy and
                    hassle-free claims processing, advocating on your behalf to
                    facilitate fair and timely settlements. With our support,
                    you can navigate the claims process with ease and
                    confidence, knowing that we have your best interests at
                    heart.
                  </p>
                </li>
              </ul>
            </Grid>
          </Grid>
        </Box>

        <Box className="amaze-differnce-section mb-margin1">
          <Grid
            container
            columnSpacing={3}
            className="row"
            alignItems={"center"}
          >
            <Grid xs={12} md={6} className="mb-margin2">
              <img
                src="./images/amaze_differnce_sec_img.svg"
                alt=""
                style={{ width: "100%", maxWidth: "480px", margin: "auto" }}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <h2 className="mb-3">Experience the amaze difference today! </h2>
              <p>
                Contact us to learn more about our insurance solutions and
                discover why Amaze Insurance Brokers is the right choice for all
                your insurance needs. Let us help you navigate the complexities
                of insurance and protect what matters most to you.
              </p>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default WhyChooseUs;
