import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../../../Components/Desktop/Navbar/Navbar";
import MFooter from "../../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../../Components/Mobile/MNavBar/MNavBar";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import "./../../../../../SCSS/ProductPages.scss";
import PPAccordionComponent from "../../../../../Components/PPAccordionComponent/PPAccordionComponent";
import { useState } from "react";
import ReadMoreText from "../../../../../Components/ReadMoreTextComponent/ReadMoreText";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const FireInsurance = () => {
  const isMobile = useIsMobile();

  const coveragePointsArray = [
    {
      title: "Coverage:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Fire:</span> Covers damage or destruction caused by fire,
              lightning, explosion/implosion, and aircraft damage.
            </li>
            <li>
              <span>Allied Perils:</span> Covers additional perils such as riot,
              strike, malicious damage, storm, cyclone, tempest, flood,
              inundation, impact damage, subsidence, landslide, bursting or
              overflowing of water tanks, apparatus, and pipes.
            </li>
            <li>
              <span>Impact Damage: </span> Covers damage caused by impact by any
              road vehicle or animal.
            </li>
            <li>
              <span>Spontaneous Combustion:</span> Covers damage caused by
              spontaneous combustion.
            </li>
            <li>
              <span>Earthquake (Fire and Shock):</span> Covers damage caused by
              earthquake fire and/or shock.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Exclusions:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>War and Nuclear Risks:</span> Damage or destruction
              resulting from war, invasion, act of foreign enemy, hostilities,
              civil war, rebellion, revolution, insurrection, military or
              usurped power, and nuclear reactions.
            </li>
            <li>
              <span>Wilful Negligence:</span> Damage caused by the wilful act or
              gross negligence of the insured. Wear and Tear: Damage due to wear
              and tear, gradual deterioration, inherent defects, or faulty
              workmanship.
            </li>
            <li>
              <span>Wear and Tear:</span> Damage due to wear and tear, gradual
              deterioration, inherent defects, or faulty workmanship.
            </li>
            <li>
              <span>Consequential Loss:</span> Loss of profit, loss of market,
              or any other consequential loss arising out of damage to the
              insured property.
            </li>
            <li>
              <span>Loss of Documents:</span> Loss or damage to documents,
              manuscripts, business books, computer software, or data.
            </li>
          </ul>
        </>
      ),
    },
  ];

  const [settings] = useState({
    className: "center",
    // centerMode: true,
    infinite: true,
    // centerPadding: "200px",
    slidesToShow: 3,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 2000,
    // cssEase: "linear",
    arrows: true,
    dots: true,
    responsive: [
      {
        breakpoint: 1024, // For tablets and above
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600, // For mobile devices
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });

  const pointsArray = [
    {
      title: "Doctors and Medical Practitioners",
      desc: "Including general practitioners, surgeons, specialists, and other healthcare professionals who provide medical advice and treatment.",
    },
    {
      title: "Lawyers and Legal Professionals",
      desc: "Including solicitors, barristers, legal consultants, and paralegals who provide legal advice and representation.",
    },
    {
      title: "Architects and Engineers",
      desc: "Including architects, structural engineers, civil engineers, and other professionals involved in the design and construction industry.",
    },
    {
      title: "Accountants and Financial Advisors",
      desc: "Including certified public accountants (CPAs), chartered accountants, financial planners, and investment advisors who provide financial advice and services.",
    },
  ];

  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="subProducts_pp">
          <Grid container columnSpacing={3} className="row">
            <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
              <h1>Fire Insurance</h1>
            </Grid>
          </Grid>

          <Box className="coverages-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12}>
                <h2 className="mb-6">
                  Standard Fire and Special Perils Policy
                </h2>
              </Grid>
              <Grid xs={12} md className="mb-margin2">
                <PPAccordionComponent data={coveragePointsArray} />
              </Grid>
              <Grid xs={12} md={"auto"} margin={"auto"}>
                <img
                  src="/images/fire_insurance_img.svg"
                  alt=""
                  style={{
                    width: "100%",
                    maxWidth: isMobile ? "250px" : "355px",
                    margin: "auto",
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="claimProcedure-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom={"24px"} textAlign={"center"}>
                <h2>Claims Procedure</h2>
              </Grid>
              <Grid xs={12}>
                <ul className="claimProcedure_list">
                  <li>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      marginBottom={"24px"}
                    >
                      <Box textAlign={"left"}>
                        <h4>01</h4>
                        <h6>Notification</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon1.svg"
                        alt=""
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "The insured must notify the insurer immediately in the event of a loss covered under the policy."
                      }
                      maxlength={100}
                    />
                  </li>
                  <li>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      marginBottom={"24px"}
                    >
                      <Box textAlign={"left"}>
                        <h4>02</h4>
                        <h6>Documentation</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon2.svg"
                        alt=""
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "The insured must submit a duly filled claim form along with supporting documents such as the The insured must submit a duly filled claim form along with supporting documents such as the"
                      }
                      maxlength={100}
                    />
                  </li>
                  <li>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      marginBottom={"24px"}
                    >
                      <Box textAlign={"left"}>
                        <h4>03</h4>
                        <h6>Survey</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon3.svg"
                        alt=""
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "The insurer may appoint a surveyor to assess the extent of the damage and determine the claim amount."
                      }
                      maxlength={100}
                    />
                  </li>
                  <li>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      marginBottom={"24px"}
                    >
                      <Box textAlign={"left"}>
                        <h4>04</h4>
                        <h6>Settlement</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon4.svg"
                        alt=""
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "Upon approval of the claim, the insurer will settle the claim amount after deducting Upon approval of the claim, the insurer will settle the claim amount after deducting"
                      }
                      maxlength={100}
                    />
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>

          <Box className="termsConditions_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md={8} className="mb-margin2">
                <h2 className="mb-3">Terms and Conditions</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    <span>Sum Insured: </span> The maximum amount for which the
                    insurer is liable in the event of a claim.
                  </li>
                  <li>
                    <span>Deductibles:</span> The portion of the claim amount
                    that the insured must bear before the insurer pays the
                    remaining amount.
                  </li>
                  <li>
                    <span>Policy Period:</span> The duration for which the
                    policy provides coverage, typically one year.
                  </li>
                  <li>
                    <span>Subrogation:</span> The insurer has the right to
                    recover the amount paid for a claim from any third party
                    responsible for the loss.
                  </li>
                  <li>
                    <span>Policy Renewal:</span> The insured must renew the
                    policy annually to continue coverage.
                  </li>
                </ol>
              </Grid>
              <Grid xs={12} md={4}>
                <img
                  src="../images/termsconditions_img.svg"
                  alt=""
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="whyChoose_pp_section">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md={6} className="mb-margin2">
                <h2>Why Choose Amaze Insurance Brokers?</h2>
                <img
                  src="../images/whychoose_pp_img.svg"
                  alt=""
                  style={{ maxWidth: "450px", width: "100%", margin: "auto" }}
                  className="wchoose_img"
                />
              </Grid>
              <Grid xs={12} md={6}>
                <ul className="whyChoosepp_list">
                  <li>
                    <Box className="wc_box">
                      <img src="../images/wc_img1.svg" alt="" />
                      <h6>Expert Guidance</h6>
                      <p>
                        Our experienced professionals will ensure proper and
                        adequate coverage for your Electronic Equipment.
                      </p>
                    </Box>
                    <Box className="wc_box">
                      <img src="../images/wc_img2.svg" alt="" />
                      <h6>Seamless Claims Assistance</h6>
                      <p>
                        We provide comprehensive support throughout the claims
                        process.
                      </p>
                    </Box>
                  </li>
                  <li>
                    <Box className="wc_box">
                      <img src="../images/wc_img3.svg" alt="" />
                      <h6>Competitive Quotes</h6>
                      <p>
                        We work with leading insurance companies to secure the
                        best deals for you.
                      </p>
                    </Box>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default FireInsurance;
