import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../../../Components/Desktop/Navbar/Navbar";
import MFooter from "../../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../../Components/Mobile/MNavBar/MNavBar";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import "./../../../../../SCSS/ProductPages.scss";
import PPAccordionComponent from "../../../../../Components/PPAccordionComponent/PPAccordionComponent";

const EmployeeProvidentFund = () => {
  const isMobile = useIsMobile();

  const coveragePointsArray = [
    {
      title: "Coverage:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              A retirement savings scheme where both the employer and employee
              contribute a portion of the employee's salary.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Features:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              Tax benefits, interest on contributions, and withdrawal options
              for specific purposes.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Exclusions:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              Early withdrawal before retirement age and non-contribution to the
              fund.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Claim Procedure: ",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              Employees can withdraw or transfer their EPF balance upon
              retirement, resignation, or under specific conditions like buying
              a house, medical emergencies, etc.
            </li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="subProducts_pp">
          <Grid container columnSpacing={3} className="row">
            <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
              <h1>Employee Provident Fund (EPF)</h1>
            </Grid>
          </Grid>

          <Box className="coverages-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12}>
                <PPAccordionComponent data={coveragePointsArray} />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default EmployeeProvidentFund;
