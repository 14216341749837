import React from 'react';
import CommonRoutes from './Routing/Routes/CommonRoutes';
import { BrowserRouter } from 'react-router-dom';
import PIROUTES from './Routing/Routes/PIRoutes';
import MarineTransitionRoutes from './Routing/Routes/MarineTransitionRoutes';
import EBROUTES from './Routing/Routes/EBRoutes';
import BIInsuranceRoutes from './Routing/Routes/BIInsuranceRoutes';
import LiabiltyInsuranceRoutes from './Routing/Routes/LiabilityInsuranceRoutes';
import ProjectInsuranceRoutes from './Routing/Routes/ProjectInsuranceRoutes';
import MIscellaneousRoutes from './Routing/Routes/MIscellaneousRoutes';
import AviationInsuranceRoutes from './Routing/Routes/AviationInsuranceRoutes';
import RetailInsuranceRoutes from './Routing/Routes/RetailInsuranceRoutes';
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import useIsMobile from "./SupportingFiles/MobileProvider";
import CallIcon from "@mui/icons-material/Call";
import "./App.scss";
import { Link } from "@mui/material";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

function App() {
  const isMobile = useIsMobile();
  return (
    <>
      <BrowserRouter>
        <ToastContainer />
        <CommonRoutes />
        <PIROUTES />
        <EBROUTES />
        <MarineTransitionRoutes />
        <BIInsuranceRoutes />
        <ProjectInsuranceRoutes />
        <LiabiltyInsuranceRoutes />
        <MIscellaneousRoutes />
        <AviationInsuranceRoutes />
        <RetailInsuranceRoutes />
      </BrowserRouter>
      <div className="floating_btn">
        <Link
          className="contact_icon"
          href="https://linktw.in/DkEJqT"
          target="_blank"
        >
          <WhatsAppIcon />
        </Link>
      </div>
    </>
  );
}

export default App;
