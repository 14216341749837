import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../../../Components/Desktop/Navbar/Navbar";
import MFooter from "../../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../../Components/Mobile/MNavBar/MNavBar";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import "./../../../../../SCSS/ProductPages.scss";
import PPAccordionComponent from "../../../../../Components/PPAccordionComponent/PPAccordionComponent";
import ReadMoreText from "../../../../../Components/ReadMoreTextComponent/ReadMoreText";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const EmployeesCompensationInsurancePolicy = () => {
  const isMobile = useIsMobile();

  const coveragePointsArray = [
    {
      title: "Coverage:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Medical Expenses:</span> Covers reasonable and necessary
              medical expenses incurred for the treatment of work-related
              injuries or illnesses sustained by employees.
            </li>
            <li>
              <span>Temporary Total Disablement:</span> Provides compensation
              for loss of earnings due to temporary total disablement resulting
              from work-related injuries or illnesses.
            </li>
            <li>
              <span>Permanent Disablement: </span>Offers compensation for
              permanent disablement or death caused by work-related injuries or
              illnesses, based on the severity and nature of the disability or
              death.
            </li>
            <li>
              <span>Legal Expenses:</span> Covers legal costs and expenses
              incurred in defending claims arising from work-related injuries or
              illnesses, subject to policy limits.{" "}
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Additional Covers:  ",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Occupational Diseases:</span> Extends coverage to include
              occupational diseases contracted by employees due to exposure to
              hazardous substances or conditions in the workplace.
            </li>
            <li>
              <span>Funeral Expenses:</span> Provides coverage for funeral
              expenses in the unfortunate event of an employee's death due to
              work-related injuries or illnesses.
            </li>
            <li>
              <span>Rehabilitation Expenses:</span> Covers expenses incurred for
              vocational rehabilitation services aimed at assisting disabled
              employees in returning to work or adapting to new employment
              opportunities.{" "}
            </li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="subProducts_pp">
          <Grid container columnSpacing={3} className="row">
            <Grid xs={12} textAlign="center" marginBottom="24px">
              <h1>Employees Compensation Insurance Policy</h1>

              <p className="mt-3">
                Employees Compensation Insurance, also known as Workmen's
                Compensation Insurance, provides financial protection to
                employers against legal liabilities arising from work-related
                injuries, illnesses, or death suffered by their employees during
                the course of employment. This insurance ensures that employers
                can meet statutory obligations to compensate employees for
                occupational injuries and illnesses, thereby fostering a safe
                and secure workplace environment.
              </p>
            </Grid>
          </Grid>
          <Box className="coverages-section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md className="mb-margin2">
                <PPAccordionComponent data={coveragePointsArray} />
              </Grid>
              <Grid xs={12} md={"auto"} margin={"auto"}>
                <img
                  src="/images/employees_compensation_policy.svg"
                  alt=""
                  style={{
                    width: "100%",
                    maxWidth: isMobile ? "180px" : "236px",
                    margin: "auto",
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="claimProcedure-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom="24px" textAlign="center">
                <h2>Claims Procedure</h2>
              </Grid>
              <Grid xs={12}>
                <ul className="claimProcedure_list">
                  <li>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom="24px"
                    >
                      <Box textAlign="left">
                        <h4>01</h4>
                        <h6>Immediate Notification</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon1.svg"
                        alt="notification icon"
                        style={{
                          width: "52px",
                          height: "52px",
                          borderRadius: "4px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        " In the event of a work-related injury, illness, or death, the employer must notify the insurance company or broker as soon as practicable. "
                      }
                      maxlength={100}
                    />
                  </li>
                  <li>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom="24px"
                    >
                      <Box textAlign="left">
                        <h4>02</h4>
                        <h6>Claim Documentation</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon2.svg"
                        alt="documentation icon"
                        style={{
                          width: "52px",
                          height: "52px",
                          borderRadius: "4px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "Submit a detailed claim form along with all relevant documents, including medical reports, accident reports, witness statements, and other evidence supporting the claim, to the insurance company.  "
                      }
                      maxlength={100}
                    />
                  </li>
                  <li>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom="24px"
                    >
                      <Box textAlign="left">
                        <h4>03</h4>
                        <h6>Claim Evaluation and Settlement</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon3.svg"
                        alt="evaluation and settlement icon"
                        style={{
                          width: "52px",
                          height: "52px",
                          borderRadius: "4px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        " The insurance company will evaluate the claim, conduct an investigation if necessary, and either approve or deny the claim based on the policy terms and conditions. If approved, the insurer will indemnify the insured for covered losses.  "
                      }
                      maxlength={100}
                    />
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>

          <Box className="termsConditions_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems="center"
            >
              <Grid xs={12} md={8} className="mb-margin2">
                <h2 className="mb-3">Terms and Conditions</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    <span>Policy Period:</span> Typically, the policy is issued
                    for a one-year period, with the option to renew annually.
                  </li>
                  <li>
                    <span>Limits of Liability:</span> The policy limit is the
                    maximum amount the insurer will pay for covered claims
                    during the policy period, subject to deductibles and
                    sub-limits.
                  </li>
                  <li>
                    <span>Deductibles:</span>A deductible is the amount the
                    insured must pay before the insurance coverage kicks in.
                  </li>
                </ol>
              </Grid>
              <Grid xs={12} md={4}>
                <img
                  src="../images/termsconditions_img.svg"
                  alt="terms and conditions icon"
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="excluison_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems="center"
            >
              <Grid xs={12} md={4} order={{ xs: 2, md: 1 }}>
                <img
                  src="../images/exclusions_img.svg"
                  alt="exclusions icon"
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
              <Grid
                xs={12}
                md={8}
                className="mb-margin2"
                order={{ xs: 1, md: 2 }}
              >
                <h2 className="mb-3">Exclusions</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    <span>Self-Inflicted Injuries: </span> Excludes injuries or
                    illnesses intentionally self-inflicted by the employee.
                  </li>
                  <li>
                    <span>Intoxication or Substance Abuse:</span> Excludes
                    injuries or illnesses resulting from the employee's
                    intoxication or abuse of alcohol, drugs, or other
                    substances.
                  </li>
                  <li>
                    <span>War and Nuclear Risks:</span> Excludes injuries or
                    illnesses caused by war, invasion, act of foreign enemy,
                    hostilities, civil war, rebellion, revolution, nuclear
                    risks, or radioactive contamination.
                  </li>
                </ol>
              </Grid>
            </Grid>
          </Box>

          <Box className="whyChoose_pp_section">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems="center"
            >
              <Grid xs={12} md={6} className="mb-margin2">
                <h2>Why Choose Amaze Insurance Brokers?</h2>
                <img
                  src="../images/whychoose_pp_img.svg"
                  alt="why choose us icon"
                  style={{ maxWidth: "450px", width: "100%", margin: "auto" }}
                  className="wchoose_img"
                />
              </Grid>
              <Grid xs={12} md={6}>
                <ul className="whyChoosepp_list">
                  <li>
                    <Box className="wc_box">
                      <img src="../images/wc_img1.svg" alt="assessment icon" />
                      <h6>Risk Assessment & Prevention</h6>
                      <p>
                        We provide expert risk assessment and prevention advice
                        to help employers identify potential workplace hazards
                        and implement measures to mitigate risks and improve
                        workplace safety.
                      </p>
                    </Box>
                    <Box className="wc_box">
                      <img src="../images/wc_img2.svg" alt="tailored icon" />
                      <h6>Tailored Solutions</h6>
                      <p>
                        We offer customized insurance solutions tailored to the
                        specific needs and risks of the employer's industry,
                        workforce, and operations, ensuring comprehensive
                        coverage and peace of mind.
                      </p>
                    </Box>
                  </li>
                  <li>
                    <Box className="wc_box">
                      <img src="../images/wc_img3.svg" alt="compliance icon" />
                      <h6>Compliance Assistance</h6>
                      <p>
                        We assist employers in understanding and complying with
                        statutory requirements related to employees'
                        compensation, ensuring adherence to relevant labor laws
                        and regulations.
                      </p>
                    </Box>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default EmployeesCompensationInsurancePolicy;
