import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../../../Components/Desktop/Navbar/Navbar";
import MFooter from "../../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../../Components/Mobile/MNavBar/MNavBar";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import "./../../../../../SCSS/ProductPages.scss";
import PPAccordionComponent from "../../../../../Components/PPAccordionComponent/PPAccordionComponent";
import ReadMoreText from "../../../../../Components/ReadMoreTextComponent/ReadMoreText";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const ElectronicEquipmentInsurancePolicy = () => {
  const isMobile = useIsMobile();

  const coveragePointsArray = [
    {
      title: "Coverage:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Accidental Damage:</span> Covers accidental physical damage
              to electronic equipment, including computers, servers,
              telecommunication equipment, and other electronic devices.
            </li>
            <li>
              <span>Breakdown: </span> Covers damage due to electrical or
              mechanical breakdown, internal malfunction, or defects in
              materials or workmanship.
            </li>
            <li>
              <span>External Perils: </span> Covers damage caused by fire,
              lightning, explosion, flood, storm, cyclone, and other specified
              perils.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Special Features:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Worldwide Coverage:</span> Provides coverage for electronic
              equipment while in transit or temporarily located outside the
              insured premises.
            </li>
            <li>
              <span>Deterioration of Data:</span> Covers the cost of restoring
              or recreating data lost due to damage to the insured electronic
              equipment.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Enhancements",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Business Interruption:</span> Extends coverage to include
              loss of income or additional expenses incurred due to business
              interruption caused by damage to insured electronic equipment.
            </li>
            <li>
              <span>Temporary Hire Costs:</span> Covers the cost of hiring
              temporary replacement equipment following damage to insured
              electronic equipment.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Add-On Covers:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Third-party Liability:</span> Extends coverage to include
              liability arising from damage to third-party property or bodily
              injury caused by the insured electronic equipment.
            </li>
            <li>
              <span>Data Protection and Cyber Risks:</span> Extends coverage
              to include liability and losses arising from data breaches,
              cyber-attacks, and other cyber risks related to insured electronic
              equipment.
            </li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="subProducts_pp">
          <Grid container columnSpacing={3} className="row">
            <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
              <h1>Electronic Equipment Insurance Policy</h1>
            </Grid>
          </Grid>

          <Box className="coverages-section mb-margin1">
            <Grid container columnSpacing={3} className="row" alignItems={"center"}>
              <Grid xs={12} md>
                <PPAccordionComponent data={coveragePointsArray} />
              </Grid>
              <Grid xs={12} md={"auto"}>
                <img
                  src="/images/electronic_equipment_insurance_img.svg"
                  alt=""
                  style={{
                    width: "100%",
                    maxWidth: isMobile ? "230px" : "303px",
                    margin: "auto",
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="claimProcedure-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom={"24px"} textAlign={"center"}>
                <h2>Claims Procedure</h2>
              </Grid>
              <Grid xs={12}>
                <ul className="claimProcedure_list">
                  <li>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      marginBottom={"24px"}
                    >
                      <Box textAlign={"left"}>
                        <h4>01</h4>
                        <h6>Immediate Notification</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon1.svg"
                        alt=""
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        " In case of an incident or claim, the insured must notify the insurance company or broker as soon as practicable."
                      }
                      maxlength={100}
                    />
                  </li>
                  <li>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      marginBottom={"24px"}
                    >
                      <Box textAlign={"left"}>
                        <h4>02</h4>
                        <h6>Claim Documentation</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon2.svg"
                        alt=""
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "Submit a detailed claim form along with all relevant documents, including incident reports, repair estimates, purchase invoices, and other evidence supporting the claim, to the insurance company."
                      }
                      maxlength={100}
                    />
                  </li>
                  <li>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      marginBottom={"24px"}
                    >
                      <Box textAlign={"left"}>
                        <h4>03</h4>
                        <h6>Claim Evaluation and Settlement:</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon3.svg"
                        alt=""
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "The insurance company will evaluate the claim, conduct an investigation if necessary, and either approve or deny the claim based on the policy terms and conditions. If approved, the insurer will indemnify the insured or reimburse for covered losses."
                      }
                      maxlength={100}
                    />
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>

          <Box className="termsConditions_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md={8} className="mb-margin2">
                <h2 className="mb-3">Terms and Conditions</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    <span>Policy Period: </span> Typically, the policy is issued
                    for a one-year period and needs to be renewed annually.
                  </li>
                  <li>
                    <span>Limits of Liability:</span> The policy limit is the
                    maximum amount the insurer will pay for covered claims
                    during the policy period, subject to deductibles and
                    sub-limits.
                  </li>
                  <li>
                    <span>Deductibles:</span> A deductible is the amount the
                    insured must pay before the insurance coverage kicks in.
                  </li>
                </ol>
              </Grid>
              <Grid xs={12} md={4}>
                <img
                  src="../images/termsconditions_img.svg"
                  alt=""
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
            </Grid>
          </Box>

          {/* {exclusions   section included in some pages that why i have created here} */}
          <Box className="excluison_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md={4} order={{ xs: 2, md: 1 }}>
                <img
                  src="../images/exclusions_img.svg"
                  alt=""
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
              <Grid
                xs={12}
                md={8}
                className="mb-margin2"
                order={{ xs: 1, md: 2 }}
              >
                <h2 className="mb-3">Exclusions</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    <span>Wear and Tear:</span> Damage due to normal wear and
                    tear, gradual deterioration, or gradual depreciation of
                    electronic equipment.
                  </li>
                  <li>
                    <span>Wilful Acts:</span> Damage caused intentionally by the
                    insured or any of its employees.
                  </li>
                  <li>
                    <span>War and Terrorism: </span> Damage caused due to war,
                    invasion, act of foreign enemy, hostilities, civil war,
                    rebellion, revolution, or terrorism.
                  </li>
                </ol>
              </Grid>
            </Grid>
          </Box>

          <Box className="whyChoose_pp_section">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md={6} className="mb-margin2">
                <h2>Why Choose Amaze Insurance Brokers?</h2>
                <img
                  src="../images/whychoose_pp_img.svg"
                  alt=""
                  style={{ maxWidth: "450px", width: "100%", margin: "auto" }}
                  className="wchoose_img"
                />
              </Grid>
              <Grid xs={12} md={6}>
                <ul className="whyChoosepp_list">
                  <li>
                    <Box className="wc_box">
                      <img src="../images/wc_img1.svg" alt="" />
                      <h6>Expert Guidance</h6>
                      <p>
                        Our experienced professionals will ensure proper and
                        adequate coverage for your Electronic Equipment.
                      </p>
                    </Box>
                    <Box className="wc_box">
                      <img src="../images/wc_img2.svg" alt="" />
                      <h6>Seamless Claims Assistance</h6>
                      <p>
                        We provide comprehensive support throughout the claims
                        process.
                      </p>
                    </Box>
                  </li>
                  <li>
                    <Box className="wc_box">
                      <img src="../images/wc_img3.svg" alt="" />
                      <h6>Competitive Quotes</h6>
                      <p>
                        We work with leading insurance companies to secure the
                        best deals for you.
                      </p>
                    </Box>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default ElectronicEquipmentInsurancePolicy;
