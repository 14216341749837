import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../../../Components/Desktop/Navbar/Navbar";
import MFooter from "../../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../../Components/Mobile/MNavBar/MNavBar";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import "./../../../../../SCSS/ProductPages.scss";
import PPAccordionComponent from "../../../../../Components/PPAccordionComponent/PPAccordionComponent";
import ReadMoreText from "../../../../../Components/ReadMoreTextComponent/ReadMoreText";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const MLOPInsurance = () => {
  const isMobile = useIsMobile();

  const coveragePointsArray = [
    {
      title: "Coverage:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Loss of Profits:</span> We understand that every moment of
              downtime means lost revenue. Our policy covers the reduction in
              gross profit or revenue resulting from the interruption of
              business operations due to machinery breakdown.
            </li>
            <li>
              <span>Increased Cost of Working:</span> We've got your back when
              it comes to minimizing losses. Our coverage extends to additional
              expenses incurred by your business to expedite the resumption of
              operations and mitigate the impact of downtime.
            </li>
            <li>
              <span>Rent and Utilities:</span> Even when your machinery is out
              of commission, your business expenses continue. Our policy covers
              ongoing expenses such as rent, utilities, and lease payments
              during the period of interruption.
            </li>
            <li>
              <span>Machinery Repair or Replacement: </span> We're committed to
              getting your business back on track. Our policy covers the cost of
              repairing or replacing damaged machinery to restore operations to
              pre-loss levels.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Add-On Covers:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>External Damage:</span> Sometimes, machinery breakdowns are
              caused by external factors beyond your control. Our optional
              coverage extends to damage caused by fire, flood, earthquake, or
              other specified perils.
            </li>
            <li>
              <span>Operator Error:</span> We understand that accidents happen.
              Our add-on cover provides protection against losses resulting from
              machinery breakdown due to operator error or negligence.
            </li>
            <li>
              <span>Dependent Property Coverage: </span> Your business relies on
              a network of suppliers and service providers. Our policy covers
              losses arising from interruptions to the operations of dependent
              properties, ensuring that your supply chain remains robust and
              resilient.
            </li>
          </ul>
        </>
      ),
    },
  ];


  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="subProducts_pp">
          <Grid container columnSpacing={3} className="row">
            <Grid xs={12} textAlign="center" marginBottom="24px">
              <h1>Machinery Breakdown Loss of Profits Insurance (MLOP)</h1>

              <p className="mt-3">
                In the fast-paced world of business, the unexpected breakdown of
                critical machinery can grind operations to a halt, causing
                significant financial losses. That's where Machinery Breakdown
                Loss of Profits Insurance steps in, offering a lifeline to
                businesses by covering the financial fallout of equipment
                failures. At Amaze Insurance Brokers, we offer comprehensive
                solutions tailored to the unique needs of your business,
                ensuring that you're protected against the uncertainties of
                machinery breakdowns.
              </p>
            </Grid>
          </Grid>

          <Box className="coverages-section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md className="mb-margin2">
                <PPAccordionComponent data={coveragePointsArray} />
              </Grid>
              <Grid xs={12} md={"auto"} margin={"auto"}>
                <img
                  src="/images/mlop_ins_img.svg"
                  alt=""
                  style={{
                    width: "100%",
                    maxWidth: isMobile ? "220px" : "319px",
                    margin: "auto",
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="claimProcedure-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom="24px" textAlign="center">
                <h2>Claims Procedure</h2>
              </Grid>
              <Grid xs={12}>
                <ul className="claimProcedure_list">
                  <li>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom="24px"
                    >
                      <Box textAlign="left">
                        <h4>01</h4>
                        <h6>Notification</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon1.svg"
                        alt="notification icon"
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "Your peace of mind is our top priority. You must notify us immediately upon the occurrence of a covered machinery breakdown that may result in a loss of profits. "
                      }
                      maxlength={100}
                    />
                  </li>
                  <li>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom="24px"
                    >
                      <Box textAlign="left">
                        <h4>02</h4>
                        <h6>Documentation</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon2.svg"
                        alt="documentation icon"
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "We've streamlined the claims process for your convenience. Simply submit a claim form along with supporting documents, including repair estimates, financial statements, and profit and loss statements. "
                      }
                      maxlength={100}
                    />
                  </li>
                  <li>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom="24px"
                    >
                      <Box textAlign="left">
                        <h4>03</h4>
                        <h6>Investigation</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon3.svg"
                        alt="investigation icon"
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "Our team of experts will swing into action, appointing a loss adjuster to assess the extent of the loss and verify your claim. "
                      }
                      maxlength={100}
                    />
                  </li>
                  <li>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom="24px"
                    >
                      <Box textAlign="left">
                        <h4>04</h4>
                        <h6>Settlement</h6>
                      </Box>
                      <img
                        src="../images/claim_procedure_icon4.svg"
                        alt="settlement icon"
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Box>
                    <ReadMoreText
                      text={
                        "Once your claim is approved, we'll promptly indemnify you for the covered loss, subject to the terms, conditions, and limits of the policy. "
                      }
                      maxlength={100}
                    />
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>

          <Box className="termsConditions_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems="center"
            >
              <Grid xs={12} md={8} className="mb-margin2">
                <h2 className="mb-3">Terms and Conditions</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    <span>Sum Insured: </span> We provide coverage up to the
                    maximum amount payable by the insurer in the event of a
                    covered loss, typically based on your business's gross
                    profit or revenue.
                  </li>
                  <li>
                    <span>Indemnity Period:</span> We're here for the long haul.
                    Our policy provides coverage for loss of profits over a
                    specified period, usually ranging from 12 to 24 months.
                  </li>
                  <li>
                    <span>Deductibles:</span> We believe in fair and transparent
                    pricing. Our policies feature deductibles, which represent
                    the amount that you're responsible for before the insurer
                    becomes liable to pay the claim.
                  </li>
                  <li>
                    <span>Basis of Gross Profit:</span> Our calculations are
                    based on your business's gross profit, taking into account
                    the sum of net profit and insured standing charges.
                  </li>
                </ol>
              </Grid>
              <Grid xs={12} md={4}>
                <img
                  src="../images/termsconditions_img.svg"
                  alt="terms and conditions icon"
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="excluison_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems="center"
            >
              <Grid xs={12} md={4} order={{ xs: 2, md: 1 }}>
                <img
                  src="../images/exclusions_img.svg"
                  alt="exclusions icon"
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
              <Grid
                xs={12}
                md={8}
                className="mb-margin2"
                order={{ xs: 1, md: 2 }}
              >
                <h2 className="mb-3">Exclusions</h2>
                <ol type="1" className="ordered-list">
                  <li>
                    <span>Wear and Tear: </span> We don't cover damage resulting
                    from wear and tear, gradual deterioration, inherent defects,
                    or faulty workmanship.
                  </li>
                  <li>
                    <span>Wilful Acts: </span> Losses caused intentionally by
                    you or any person acting on your behalf are not covered
                    under our policy.
                  </li>
                  <li>
                    <span>Consequential Loss:</span> We're focused on protecting
                    your bottom line. Our policy excludes loss of profit, loss
                    of market, or any other consequential loss arising out of
                    machinery breakdown.
                  </li>
                  <li>
                    <span>Delayed Start-Up:</span> We're here to support you
                    through the recovery process. However, losses incurred
                    during the period required to repair or replace damaged
                    machinery before business operations can resume are not
                    covered.
                  </li>
                </ol>
              </Grid>
            </Grid>
          </Box>

          <Box className="whyChoose_pp_section">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems="center"
            >
              <Grid xs={12} md={6} className="mb-margin2">
                <h2>Why Choose Amaze Insurance Brokers?</h2>
                <img
                  src="../images/whychoose_pp_img.svg"
                  alt=""
                  style={{ maxWidth: "450px", width: "100%", margin: "auto" }}
                  className="wchoose_img"
                />
              </Grid>
              <Grid xs={12} md={6}>
                <ul className="whyChoosepp_list">
                  <li>
                    <Box className="wc_box">
                      <img
                        src="../images/wc_img1.svg"
                        alt="expert guidance icon"
                      />
                      <h6>Expert Guidance</h6>
                      <p>
                        Our experienced professionals will help you choose the
                        right coverage for your MLOP Requirement.
                      </p>
                    </Box>
                    <Box className="wc_box">
                      <img
                        src="../images/wc_img2.svg"
                        alt="claim assistance icon"
                      />
                      <h6>Seamless Claims Assistance</h6>
                      <p>
                        We provide comprehensive support throughout the claims
                        process.
                      </p>
                    </Box>
                  </li>
                  <li>
                    <Box className="wc_box">
                      <img
                        src="../images/wc_img3.svg"
                        alt="competitive quotes icon"
                      />
                      <h6>Competitive Quotes</h6>
                      <p>
                        We work with leading insurance companies to secure the
                        best deals for you.
                      </p>
                    </Box>
                  </li>
                </ul>
              </Grid>
              <p className="mt-10">
                At Amaze Insurance Brokers, we're more than just an insurance
                provider – we're your partner in success. Contact us today to
                learn more about our Machinery Breakdown Loss of Profits
                Insurance solutions and how we can help protect your business
                against operational disruptions and financial losses.{" "}
              </p>
            </Grid>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default MLOPInsurance;
