import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { BIINSURANCE_ROUTES } from "../Path/BIInsurancePaths";
import FLOPInsurance from "../../Pages/Desktop/ProductPages/BusinessInterruptionInsurance/FLOPInsurance/FLOPInsurance";
import MLOPInsurance from "../../Pages/Desktop/ProductPages/BusinessInterruptionInsurance/MLOPInsurance/MLOPInsurance";
import BusinessInterruptionInsurance from "../../Pages/Desktop/ProductPages/BusinessInterruptionInsurance/BusinessInterruptionInsurance";

function MarineTransitionRoutes() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Routes>
      <Route
          path={BIINSURANCE_ROUTES.BIINSURANCE}
          element={<BusinessInterruptionInsurance />}
        />
        <Route
          path={BIINSURANCE_ROUTES.BIINSURANCE_FLOP}
          element={<FLOPInsurance />}
        />
        <Route
          path={BIINSURANCE_ROUTES.BIINSURANCE_MFLOP}
          element={<MLOPInsurance />}
          />
      </Routes>
    </div>
  );
}

export default MarineTransitionRoutes;
