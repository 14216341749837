import { Box } from "@mui/material";
import { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import MNavBar from "../../../../Components/Mobile/MNavBar/MNavBar";
import Navbar from "../../../../Components/Desktop/Navbar/Navbar";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import MFooter from "../../../../Components/Mobile/MFooter/MFooter";
import Footer from "../../../../Components/Desktop/Footer/Footer";
import CustomButton from "../../../../Components/InputFields/CustomButton/CustomButton";
import RKTextField from "../../../../Components/InputFields/RKTextField/RKTextField";
import CustomCheckbox from "../../../../Components/InputFields/CustomCheckbox/CustomCheckbox";
import DocUpload from "../../../../Components/DocUpload/DocUpload";
import { isEmpty, validateEmail, validateFullName, validateMobileNumber, validatePdfFile } from "../../../../SupportingFiles/HelpingFunction";
import { HOME_SERVICES } from "../../../../Services/Home/HomeServices";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const IPARForm = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const [formFields, setFormFields] = useState<{
    companyName: { value: string; warn_status: boolean };
    contactPerson: { value: string; warn_status: boolean };
    mobile: { value: string; warn_status: boolean };
    email: { value: string; warn_status: boolean };
    companyAddress: { value: string; warn_status: boolean };
    additionalInfo: { value: string; warn_status: boolean };
    policyDoc: {
      value: any;
      fileName: string;
      fileType: string;
      warn_status: boolean;
    };
    termsAndConditions: { value: boolean; warn_status: boolean };
  }>({
    companyName: { value: "", warn_status: false },
    contactPerson: { value: "", warn_status: false },
    mobile: { value: "", warn_status: false },
    email: { value: "", warn_status: false },
    companyAddress: { value: "", warn_status: false },
    additionalInfo: { value: "", warn_status: false },
    policyDoc: {
      value: null,
      fileName: "",
      fileType: "",
      warn_status: false,
    },
    termsAndConditions: { value: false, warn_status: false },
  });

  const updateFieldsState = (attrName: string, value: string | Boolean) => {
    setFormFields((prev) => ({ ...prev, [attrName]: { value: value } }));
  };

  const handleFileSelect = (files: FileList | null, fieldName: any) => {
    if (files && files.length > 0) {
      const file = files[0];

      setFormFields((prev) => ({
        ...prev,
        [fieldName]: {
          value: file,
          fileName: files[0].name,
          fileType: file.type,
          warn_status: false,
        },
      }));
    } else {
      setFormFields((prev) => ({
        ...prev,
        [fieldName]: {
          value: null,
          fileName: "",
          fileType: "",
          warn_status: true,
        },
      }));
    }
  };

  const validateForm = () => {
    let hasError = false;
    let data = { ...formFields };
    data = {
      ...data,
      companyName: {
        ...data.companyName,
        warn_status: isEmpty(data.companyName.value),
      },
      contactPerson: {
        ...data.contactPerson,
        warn_status: !validateFullName(data.contactPerson.value),
      },
      mobile: {
        ...data.mobile,
        warn_status: !validateMobileNumber(data.mobile.value),
      },
      email: {
        ...data.email,
        warn_status: !validateEmail(data.email.value),
      },
      companyAddress: {
        ...data.companyAddress,
        warn_status: isEmpty(data.companyAddress.value),
      },
      additionalInfo: {
        ...data.additionalInfo,
        warn_status: isEmpty(data.additionalInfo.value),
      },
      policyDoc: {
        ...data.policyDoc,
        warn_status: isEmpty(data.policyDoc.value),
      },
      termsAndConditions: {
        ...data.termsAndConditions,
        warn_status: !data.termsAndConditions.value,
      },
    };
    setFormFields(data);

    if (
      data.companyName.warn_status ||
      data.contactPerson.warn_status ||
      data.mobile.warn_status ||
      data.email.warn_status ||
      data.companyAddress.warn_status ||
      data.policyDoc.warn_status ||
      data.termsAndConditions.warn_status
    ) {
      hasError = true;
    }
    if (!hasError) {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    const toastID = toast.loading("Please wait...");
    const formData = new FormData();
    formData.append('username', formFields.contactPerson.value);
    formData.append('email', formFields.email.value);
    formData.append('number', formFields.mobile.value);
    formData.append('files', formFields.policyDoc.value);
    formData.append('productName', formFields.additionalInfo.value);
    formData.append('company', formFields.companyName.value);
    formData.append('address', formFields.companyAddress.value);

    const onSuccess = (data: any) => {
      console.log(data.message, "fgnfgnnfggn")

      if (data.message == 'success') {
        toast.dismiss(toastID);
        toast.success(
          "Thank you for your query! Our team will reach out to you shortly."
        );
        navigate("/");
      } else {
        toast.error(
          "Something went wrong"
        );
      }
      setFormFields({
        companyName: { value: "", warn_status: false },
        contactPerson: { value: "", warn_status: false },
        mobile: { value: "", warn_status: false },
        email: { value: "", warn_status: false },
        companyAddress: { value: "", warn_status: false },
        additionalInfo: { value: "", warn_status: false },
        policyDoc: {
          value: null,
          fileName: "",
          fileType: "",
          warn_status: false,
        },
        termsAndConditions: { value: false, warn_status: false },
      });
    };
    const onError = (data: any) => { };
    HOME_SERVICES.ANALYSIS_FORM_SUBMIT(onSuccess, onError, formData);
  };

  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="main_pp">
          <Grid
            container
            columnSpacing={3}
            className="row mb-margin2"
            marginBottom={"40px"}
            justifyContent={"center"}
          >
            <Grid xs={12} textAlign={"center"} className="mb-6">
              <h2 className="mb-6">
                Insurance Portfolio Analysis Request Form
              </h2>
              <p>
                Thank you for your interest in our Free Insurance Portfolio
                Analysis service. To get started, please fill out the following
                form and provide us with the necessary details and information
                about your business and existing insurance policies. Our team
                will conduct a thorough analysis and prepare a comprehensive
                Insurance Portfolio Analysis Report tailored to your needs.
              </p>
            </Grid>
            <Grid xs={12} md={10}>
              <Box className="formBox">
                <Grid container columnSpacing={3} marginBottom={"24px"}>
                  <Grid xs={12} textAlign={"center"}>
                    <h3 className="mb-2">Fill the Details</h3>
                  </Grid>
                </Grid>
                <Grid container columnSpacing={3}>
                  <Grid xs={12} className="mb-4">
                    <h5>Company Information:</h5>
                  </Grid>
                  <Grid xs={12} md={6}>
                    <RKTextField
                      class_name="inputField mb-6"
                      title={"Company Name"}
                      value={formFields.companyName.value}
                      attrName={"companyName"}
                      value_update={updateFieldsState}
                      warn_status={formFields.companyName.warn_status}
                      error_message="Enter Company Name"
                    />
                  </Grid>
                  <Grid xs={12} md={6}>
                    <RKTextField
                      class_name="inputField mb-6"
                      title={"Contact Person"}
                      value={formFields.contactPerson.value}
                      attrName={"contactPerson"}
                      value_update={updateFieldsState}
                      validation_type="NAME"
                      warn_status={formFields.contactPerson.warn_status}
                      error_message="Enter full name"
                    />
                  </Grid>
                  <Grid xs={12} md={6}>
                    <RKTextField
                      class_name="inputField mb-6"
                      title={"Email Address"}
                      value={formFields.email.value}
                      attrName={"email"}
                      value_update={updateFieldsState}
                      warn_status={formFields.email.warn_status}
                      error_message="Enter valid email"
                    />
                  </Grid>
                  <Grid xs={12} md={6}>
                    <RKTextField
                      class_name="inputField mb-6"
                      title={"Mobile"}
                      value={formFields.mobile.value}
                      attrName={"mobile"}
                      value_update={updateFieldsState}
                      warn_status={formFields.mobile.warn_status}
                      max_length={10}
                      validation_type="NUMBER"
                      error_message="Enter mobile number"
                    />
                  </Grid>
                  <Grid xs={12}>
                    <RKTextField
                      class_name="inputField mb-6"
                      title={"Address"}
                      value={formFields.companyAddress.value}
                      attrName={"companyAddress"}
                      value_update={updateFieldsState}
                      warn_status={formFields.companyAddress.warn_status}
                      error_message="Enter address"
                    />
                  </Grid>
                </Grid>
                <Grid container columnSpacing={3} className="mb-8">
                  <Grid xs={12} className="mb-3">
                    <h5>Insurance Policy Details:</h5>
                  </Grid>
                  <Grid xs={12} md={6}>
                    <DocUpload
                      buttonLabel={
                        formFields.policyDoc.fileName || "Choose file"
                      }
                      onFileSelect={(files) =>
                        handleFileSelect(files, "policyDoc")
                      }
                      warn_status={formFields.policyDoc.warn_status}
                      error_message={"Upload file"}
                    />
                  </Grid>
                </Grid>
                <Grid container columnSpacing={3}>
                  <Grid xs={12} className="mb-4">
                    <h5>Additional Information:</h5>
                  </Grid>
                  <Grid xs={12}>
                    <RKTextField
                      class_name="inputField mb-6"
                      title={"Additional Information you want to share "}
                      value={formFields.additionalInfo.value}
                      attrName={"additionalInfo"}
                      value_update={updateFieldsState}
                      warn_status={false}
                    />
                  </Grid>
                </Grid>
                <Grid container columnSpacing={3}>
                  <Grid xs={12} className="mb-6">
                    <CustomCheckbox
                      label={
                        "By submitting this form, you acknowledge that you are authorizing Amaze Insurance Brokers to conduct an analysis of your insurance portfolio based on the information provided. You also agree to receive communications from us regarding the Insurance Portfolio Analysis Report and related services."
                      }
                      attrName={"termsAndConditions"}
                      value={formFields.termsAndConditions.value}
                      // value_update={handleCheckboxChange}
                      value_update={updateFieldsState}
                    />
                  </Grid>
                </Grid>

                <Grid container columnSpacing={3}>
                  <Grid xs={12} textAlign={"center"}>
                    <CustomButton
                      className="primaryBtn"
                      fullWidth={false}
                      variant="contained"
                      text="Submit Details"
                      onClick={() => validateForm()}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default IPARForm;
