import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../../../Components/Desktop/Navbar/Navbar";
import MFooter from "../../../../../Components/Mobile/MFooter/MFooter";
import MNavBar from "../../../../../Components/Mobile/MNavBar/MNavBar";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import "./../../../../../SCSS/ProductPages.scss";
import PPAccordionComponent from "../../../../../Components/PPAccordionComponent/PPAccordionComponent";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const AviationPackagePolicy = () => {
  const isMobile = useIsMobile();

  const coveragePointsArray = [
    {
      title: "Coverage:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              Aviation Package Policy offers comprehensive coverage for various
              risks associated with aircraft operations, including physical
              damage, liability, hull, and spares. It provides a bundled
              solution to address the insurance needs of aircraft owners,
              operators, and aviation businesses, offering convenience and peace
              of mind.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Wordings:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              The policy contains specific wordings detailing the scope of
              coverage, conditions, and exclusions. It's essential to review
              these wordings carefully to understand the extent of protection
              offered under the policy.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Special Features:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>All-in-One Coverage:</span> Combines multiple insurance
              coverages into a single policy, including Aviation Hull Insurance,
              Aviation Liability Insurance, and other optional coverages.
            </li>
            <li>
              <span>Customized Solutions:</span> Policies can be tailored to
              meet the unique needs and requirements of individual aircraft
              owners/operators, ensuring comprehensive protection against
              various risks.
            </li>
            <li>
              <span>Cost-Effective:</span> Bundling multiple coverages into one
              policy may result in cost savings compared to purchasing separate
              policies for each coverage type.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Enhancements:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Extended Coverage Limits:</span> Policyholders can opt for
              increased coverage limits for hull, liability, or other specific
              coverages to ensure adequate protection against high-value assets
              or liabilities.
            </li>
            <li>
              <span> Additional Insureds:</span> Allows for the inclusion of
              additional insured parties, such as lessors, financiers, or
              operators, under the policy for broader protection.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Additional Covers:",
      desc: (
        <>
          <ul className="circle_ticklist">
            <li>
              <span>Loss of License Insurance:</span> Provides coverage for
              pilots in the event of the loss or suspension of their pilot's
              license due to medical reasons or other covered causes.
            </li>
            <li>
              <span>Personal Accident Insurance:</span> Extends coverage to
              pilots, crew members, or passengers for accidental death,
              permanent disability, or medical expenses resulting from
              aviation-related incidents.
            </li>
            <li>
              <span>Aircraft Spares Insurance:</span> Covers spare parts,
              equipment, and tools essential for aircraft maintenance and
              operation against loss or damage.
            </li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <Box className="productPages-layout">
      {isMobile ? <MNavBar /> : <Navbar />}

      <Box className="pp-inner">
        <Box className="subProducts_pp">
          <Grid container columnSpacing={3} className="row">
            <Grid xs={12} textAlign="center" marginBottom="24px">
              <h1>Aviation Package Policy</h1>
            </Grid>
          </Grid>

          <Box className="coverages-section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems={"center"}
            >
              <Grid xs={12} md className="mb-margin2">
                <PPAccordionComponent data={coveragePointsArray} />
              </Grid>
              <Grid xs={12} md={"auto"} margin={"auto"}>
                <img
                  src="/images/aviation_package_policy_img.svg"
                  alt=""
                  style={{
                    width: "100%",
                    maxWidth: isMobile ? "300px" : "414px",
                    margin: "auto",
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="claimProcedure-section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} marginBottom="24px" textAlign="center">
                <h2>Claims Procedure</h2>
                <p>
                  In the event of a claim, policyholders must promptly notify
                  the insurer and provide detailed information about the
                  incident. The insurer will then investigate the claim, assess
                  liability or damage, and handle the claims settlement process
                  in accordance with the terms of the policy.
                </p>
              </Grid>
            </Grid>
          </Box>

          <Box className="termsConditions_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems="center"
            >
              <Grid xs={12} md={8} className="mb-margin2">
                <h2 className="mb-3">Terms and Conditions</h2>
                <p>
                  The policy's terms and conditions outline the obligations of
                  both the insurer and the insured. These include premium
                  payment terms, coverage periods, claims reporting procedures,
                  and policy renewal conditions.
                </p>
              </Grid>
              <Grid xs={12} md={4}>
                <img
                  src="../images/termsconditions_img.svg"
                  alt="terms and conditions icon"
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
            </Grid>
          </Box>

          {/* {exclusions   section included in some pages that why i have created here} */}
          <Box className="excluison_section mb-margin1">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems="center"
            >
              <Grid xs={12} md={4} order={{ xs: 2, md: 1 }}>
                <img
                  src="../images/exclusions_img.svg"
                  alt="exclusions icon"
                  style={{ maxWidth: "234px", width: "100%", margin: "auto" }}
                />
              </Grid>
              <Grid
                xs={12}
                md={8}
                className="mb-margin2"
                order={{ xs: 1, md: 2 }}
              >
                <h2 className="mb-3">Exclusions</h2>
                <p>
                  Aviation Package Policy typically excludes coverage for
                  intentional acts, war and related perils, wear and tear,
                  gradual deterioration, and other specific exclusions as
                  outlined in the policy wording.
                </p>
              </Grid>
            </Grid>
          </Box>

          <Box className="excluison_section mb-margin1">
            <Grid container columnSpacing={3} className="row">
              <Grid xs={12} textAlign={"center"}>
                <h2>Conclusion</h2>
                <p className="mb-3">
                  Aviation Package Policy offers a comprehensive and convenient
                  solution for aircraft owners, operators, and aviation
                  businesses to protect their assets and liabilities. By
                  partnering with Amaze Insurance Brokers, you gain access to
                  tailored insurance solutions, expert advice, and dedicated
                  support throughout the insurance process.
                </p>
                <p style={{ fontWeight: 600 }}>
                  For further inquiries or to explore Aviation Liability
                  Insurance options tailored to your needs, contact us today.
                </p>
              </Grid>
            </Grid>
          </Box>

          <Box className="whyChoose_pp_section">
            <Grid
              container
              columnSpacing={3}
              className="row"
              alignItems="center"
            >
              <Grid xs={12} md={6} className="mb-margin2">
                <h2 className="mb-2">
                  Why Choose Amaze Insurance Brokers for Your Aviation Hull
                  Insurance Policy?
                </h2>
                <p className="mb-6">
                  Expertise in Aviation Insurance: At Amaze Insurance Brokers,
                  we specialize in aviation insurance and have extensive
                  experience in providing tailored solutions for aircraft owners
                  and operators. Our team of experts understands the unique
                  risks and challenges faced by the aviation industry, allowing
                  us to offer comprehensive coverage that meets your specific
                  needs.
                </p>
                <img
                  src="../images/whychoose_pp_img.svg"
                  alt=""
                  style={{ maxWidth: "450px", width: "100%", margin: "auto" }}
                  className="wchoose_img"
                />
              </Grid>
              <Grid xs={12} md={6}>
                <ul className="whyChoosepp_list">
                  <li>
                    <Box className="wc_box">
                      <img
                        src="../images/wc_img1.svg"
                        alt="expert guidance icon"
                      />
                      <h6>Expert Guidance</h6>
                      <p>
                        Our experienced professionals will help you choose the
                        right coverage under SFSP for your property.
                      </p>
                    </Box>
                    <Box className="wc_box">
                      <img src="../images/wc_img2.svg" alt="" />
                      <h6>Seamless Claims Assistance</h6>
                      <p>
                        We provide comprehensive support throughout the claims
                        process.
                      </p>
                    </Box>
                  </li>
                  <li>
                    <Box className="wc_box">
                      <img
                        src="../images/wc_img3.svg"
                        alt="competitive quotes icon"
                      />
                      <h6>Competitive Quotes</h6>
                      <p>
                        We work with leading insurance companies to secure the
                        best deals for you.
                      </p>
                    </Box>
                  </li>
                </ul>
              </Grid>
              <p className="mt-10">
                At Amaze Insurance Brokers, we are committed to providing
                superior Business Interruption Insurance solutions that protect
                your business and help you recover swiftly from unforeseen
                disruptions. Contact us today to learn more about our
                comprehensive coverage options and how we can safeguard your
                business against financial loss.{" "}
              </p>
            </Grid>
          </Box>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default AviationPackagePolicy;
